
import api from "@/utils/api";
import { EventBus } from '@/utils/utils';

var restful = {
    Get(url) {
        EventBus.startLoad();
        return new Promise((resolve, reject) => {
            api.get(url).then(response => {
                EventBus.endLoad();
                if (response.status != 200) {
                    console.log(`Error obteniendo url ${url}. `, response);
                    reject(response);
                    return;
                }
                if (Object.keys(response.data).includes("error")) {
                    console.log("Error controlado. ", response.data.error);
                    reject(response.data);
                    return;
                }
                resolve(response.data);
            }).catch(error => {
                EventBus.endLoad();
                console.log(error);
                reject(error);
            });
        });
    },
    Post(url, item) {
        return new Promise((resolve, reject) => {
            api.post(url, item).then(response => {
                if (response.status != 201 && response.status != 200) {
                    console.log(`Error creando. url ${url}. `, response);
                    reject(response);
                    return;
                }
                resolve(response.data);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    Put(url, item) {
        return new Promise((resolve, reject) => {
            api.put(url, item).then(response => {
                if (response.status != 200) {
                    console.log(`Error actualizando. url ${url}. `, response);
                    reject(response);
                    return;
                }
                resolve(response.data);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    Patch(url, item) {
        return new Promise((resolve, reject) => {
            api.patch(url, item).then(response => {
                if (response.status != 200) {
                    console.log(`Error patching. url ${url}. `, response);
                    reject(response);
                    return;
                }
                resolve(response.data);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    Delete(url) {
        return new Promise((resolve, reject) => {
            api.delete(url).then(response => {
                if (response.status != 204) {
                    console.log(`Error eliminando. url ${url}. `, response);
                    reject(response);
                    return;
                }
                resolve(response.data);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    }
};

export default restful;
