import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    competences: [],
    competences_url: '/mesh/competence'
}

const getters = {
    [names.COMPETENCES]: state => {
        if (!get.store.state.user) return [];
        return state.competences.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_COMPETENCES]: (state, competences) => {
        competences.forEach(element => {
            let index = state.competences.findIndex(x => x.id == element.id);
            if (index != -1) state.competences.splice(index, 1, element);
            else state.competences.push(element);
        });
    },
    [names.MUTATE_COMPETENCE]: (state, competence) => {
        let index = state.competences.findIndex(x => x.id == competence.id);
        if (index != -1) state.competences.splice(index, 1, competence);
        else state.competences.push(competence);
    },
    [names.MUTATE_DELETE_COMPETENCE]: (state, competence_id) => {
        let index = state.competences.findIndex(x => x.id == competence_id);
        if (index != -1) state.competences.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_COMPETENCE]: ({
        commit,
        state
    }, competence_id) => {
        return new Promise((resolve) => {
            const competence = state.competences.find(x => x.id == competence_id);
            if (competence) {
                resolve(competence);
                return;
            }
            restful.Get(`${state.competences_url}/${competence_id}/`)
                .then(response => {
                    commit(names.MUTATE_COMPETENCE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_COMPETENCES]: ({
        commit,
        state
    }, egress_profile_id) => {
        return new Promise((resolve) => {
            let partial_ur = '';
            if (egress_profile_id) partial_ur = '?egress_profile=' + egress_profile_id;
            restful.Get(`${state.competences_url}/${partial_ur}`)
                .then(response => {
                    commit(names.MUTATE_COMPETENCES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_COMPETENCE]: ({
        commit,
        state
    }, competence) => {
        return new Promise((resolve) => {
            restful.Post(`${state.competences_url}/`, competence)
                .then(response => {
                    commit(names.MUTATE_COMPETENCE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_COMPETENCE]: ({
        commit,
        state
    }, competence) => {
        return new Promise((resolve) => {
            restful.Put(`${state.competences_url}/${competence.id}/`, competence)
                .then(response => {
                    commit(names.MUTATE_COMPETENCE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_COMPETENCE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.competences_url}/${payload.competence_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_COMPETENCE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_COMPETENCE]: ({
        commit,
        state
    }, competence_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.competences_url}/${competence_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_COMPETENCE, competence_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};