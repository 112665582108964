import restful from "@/utils/restful";
import * as names from "@/store/names";
import * as get from "@/store/store";

const state = {
    evaluatee_assessments: [],
    evaluatee_assessments_url: "/assessment2/evaluatee-assessment",
};

const getters = {
    [names.EVALUATEE_ASSESSMENTS]: (state) => {
        if (!get.store.state.user) return [];
        return state.evaluatee_assessments
            .filter((x) => x.school == get.store.state.user.school)
            .sort((a, b) => a.order - b.order);
    },
};

const mutations = {
    [names.MUTATE_EVALUATEE_ASSESSMENTS]: (state, evaluatee_assessments) => {
        evaluatee_assessments.forEach((element) => {
            let index = state.evaluatee_assessments.findIndex((x) => x.id == element.id);
            if (index != -1) state.evaluatee_assessments.splice(index, 1, element);
            else state.evaluatee_assessments.push(element);
        });
    },
    [names.MUTATE_EVALUATEE_ASSESSMENT]: (state, evaluatee_assessment) => {
        let index = state.evaluatee_assessments.findIndex((x) => x.id == evaluatee_assessment.id);
        if (index != -1) state.evaluatee_assessments.splice(index, 1, evaluatee_assessment);
        else state.evaluatee_assessments.push(evaluatee_assessment);
    },
    [names.MUTATE_DELETE_EVALUATEE_ASSESSMENT]: (state, evaluatee_assessment_id) => {
        let index = state.evaluatee_assessments.findIndex((x) => x.id == evaluatee_assessment_id);
        if (index != -1) state.evaluatee_assessments.splice(index, 1);
    },
};

const actions = {
    [names.FETCH_EVALUATEE_ASSESSMENT]: ({ commit, state }, evaluatee_assessment_id) => {
        return new Promise((resolve) => {
            restful
                .Get(`${state.evaluatee_assessments_url}/${evaluatee_assessment_id}/`)
                .then((response) => {
                    commit(names.MUTATE_EVALUATEE_ASSESSMENT, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATEE_ASSESSMENTS]: ({ commit, state }, payload) => {
        return new Promise((resolve) => {
            if (!payload && state.evaluatee_assessments.length > 0) {
                resolve(state.evaluatee_assessments);
                return;
            }
            let url_append = '?all_data=True';
            if (payload) {
                // Estructura del payload.id = 1
                // Estructura del payload.ids = [1,2,3]
                if (payload.id) url_append += '&id=' + payload.id
                else if (payload.ids && payload.ids.length > 0)
                    url_append += '&id__in=' + payload.ids.join(",")

                // Estructura del payload.evaluatee_id = 1
                // Estructura del payload.evaluatee_ids = [1,2,3]
                if (payload.evaluatee_id) url_append += '&evaluatee__id=' + payload.evaluatee_id
                else if (payload.evaluatee_ids && payload.evaluatee_ids.length > 0)
                    url_append += '&evaluatee__id__in=' + payload.evaluatee_ids.join(",")

                // Estructura del payload.assessment_id = 1
                // Estructura del payload.assessment_ids = [1,2,3]
                if (payload.assessment_id) url_append += '&assessment__id=' + payload.assessment_id
                else if (payload.assessment_ids && payload.assessment_ids.length > 0)
                    url_append += '&assessment__id__in=' + payload.assessment_ids.join(",")

            }
            if (url_append === '?all_data=True') return resolve([])
            restful.Get(`${state.evaluatee_assessments_url}/${url_append}`).then((response) => {
                commit(names.MUTATE_EVALUATEE_ASSESSMENTS, response.results);
                resolve(response.results);
            });
        });
    },
    [names.POST_EVALUATEE_ASSESSMENT]: ({ commit, state }, evaluatee_assessment) => {
        return new Promise((resolve) => {
            restful
                .Post(`${state.evaluatee_assessments_url}/`, evaluatee_assessment)
                .then((response) => {
                    commit(names.MUTATE_EVALUATEE_ASSESSMENT, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATEE_ASSESSMENT]: ({ commit, state }, evaluatee_assessment) => {
        return new Promise((resolve) => {
            restful
                .Put(`${state.evaluatee_assessments_url}/${evaluatee_assessment.id}/`, evaluatee_assessment)
                .then((response) => {
                    commit(names.MUTATE_EVALUATEE_ASSESSMENT, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATEE_ASSESSMENT]: ({ commit, state }, payload) => {
        return new Promise((resolve) => {
            restful
                .Patch(
                    `${state.evaluatee_assessments_url}/${payload.evaluatee_assessment_id}/`,
                    payload.item
                )
                .then((response) => {
                    commit(names.MUTATE_EVALUATEE_ASSESSMENT, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATEE_ASSESSMENT]: ({ commit, state }, evaluatee_assessment_id) => {
        return new Promise((resolve) => {
            restful
                .Delete(`${state.evaluatee_assessments_url}/${evaluatee_assessment_id}/`)
                .then((response) => {
                    commit(names.MUTATE_DELETE_EVALUATEE_ASSESSMENT, evaluatee_assessment_id);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
