import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    session_plannings: [],
    session_plannings_url: '/teaching/session-planning'
}

const getters = {
    [names.SESSION_PLANNINGS]: state => {
        if (!get.store.state.user) return [];
        return state.session_plannings.filter((x) => x.school == get.store.state.user.school).sort(function(a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        });
    },
}

const mutations = {
    [names.MUTATE_SESSION_PLANNINGS]: (state, session_plannings) => {
        session_plannings.forEach(element => {
            let index = state.session_plannings.findIndex(x => x.id == element.id);
            if (index != -1) state.session_plannings.splice(index, 1, element);
            else state.session_plannings.push(element);
        });
    },
    [names.MUTATE_SESSION_PLANNING]: (state, session_planning) => {
        let index = state.session_plannings.findIndex(x => x.id == session_planning.id);
        if (index != -1) state.session_plannings.splice(index, 1, session_planning);
        else state.session_plannings.push(session_planning);
    },
    [names.MUTATE_DELETE_SESSION_PLANNING]: (state, session_planning_id) => {
        let index = state.session_plannings.findIndex(x => x.id == session_planning_id);
        if (index != -1) state.session_plannings.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_SESSION_PLANNING]: ({
        commit,
        state
    }, session_planning_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.session_plannings_url}/${session_planning_id}/`)
                .then(response => {
                    commit(names.MUTATE_SESSION_PLANNING, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_SESSION_PLANNINGS]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            let partial_url = '';
            if (payload) {
                if (payload.matter_id) partial_url = '?matter=' + payload.matter_id;
                if (payload.egress_profile_matter_id) partial_url = '?ep_matter=' + payload.egress_profile_matter_id;
                if (payload.section_id) partial_url = '?section=' + payload.section_id;
            }
            restful.Get(`${state.session_plannings_url}/${partial_url}`)
                .then(response => {
                    commit(names.MUTATE_SESSION_PLANNINGS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_SESSION_PLANNING]: ({
        commit,
        state
    }, session_planning) => {
        return new Promise((resolve) => {
            restful.Post(`${state.session_plannings_url}/`, session_planning)
                .then(response => {
                    commit(names.MUTATE_SESSION_PLANNING, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_SESSION_PLANNING]: ({
        commit,
        state
    }, session_planning) => {
        return new Promise((resolve) => {
            restful.Put(`${state.session_plannings_url}/${session_planning.id}/`, session_planning)
                .then(response => {
                    commit(names.MUTATE_SESSION_PLANNING, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_SESSION_PLANNING]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.session_plannings_url}/${payload.session_planning_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_SESSION_PLANNING, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_SESSION_PLANNING]: ({
        commit,
        state
    }, session_planning_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.session_plannings_url}/${session_planning_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_SESSION_PLANNING, session_planning_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};