import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    periods: [],
    periods_url: '/mesh/period'
}

const getters = {
    [names.PERIODS]: state => {
        if (!get.store.state.user) return [];
        return state.periods.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_PERIODS]: (state, periods) => {
        periods.forEach(element => {
            let index = state.periods.findIndex(x => x.id == element.id);
            if (index != -1) state.periods.splice(index, 1, element);
            else state.periods.push(element);
        });
    },
    [names.MUTATE_PERIOD]: (state, period) => {
        let index = state.periods.findIndex(x => x.id == period.id);
        if (index != -1) state.periods.splice(index, 1, period);
        else state.periods.push(period);
    },
    [names.MUTATE_DELETE_PERIOD]: (state, career_id) => {
        let index = state.periods.findIndex(x => x.id == career_id);
        if (index != -1) state.periods.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_PERIOD]: ({
        commit,
        state
    }, period_id) => {
        return new Promise((resolve) => {
            const instance = state.periods.find(x => x.id == period_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.periods_url}/${period_id}/`)
                .then(response => {
                    commit(names.MUTATE_PERIOD, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_PERIODS]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.periods.length > 0) {
                resolve(state.periods);
                return;
            }
            restful.Get(`${state.periods_url}/`)
                .then(response => {
                    commit(names.MUTATE_PERIODS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_PERIOD]: ({
        commit,
        state
    }, period) => {
        return new Promise((resolve) => {
            restful.Post(`${state.periods_url}/`, period)
                .then(response => {
                    commit(names.MUTATE_PERIOD, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_PERIOD]: ({
        commit,
        state
    }, period) => {
        return new Promise((resolve) => {
            restful.Put(`${state.periods_url}/${period.id}/`, period)
                .then(response => {
                    commit(names.MUTATE_PERIOD, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_PERIOD]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.periods_url}/${payload.career_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_PERIOD, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_PERIOD]: ({
        commit,
        state
    }, career_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.periods_url}/${career_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_PERIOD, career_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};