import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    question_tests: [],
    question_tests_url: '/evaluation/question-test',
    question_tests_score: [],
    question_tests_score_url: '/evaluation/question-test-score',
};

const getters = {
    [names.QUESTION_TESTS]: state => {
        if (!get.store.state.user) return [];
        return state.question_tests.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.QUESTION_TESTS_SCORE]: state => {
        if (!get.store.state.user) return [];
        return state.question_tests_score.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
};

const mutations = {
    [names.MUTATE_QUESTION_TEST]: (state, question_test) => {
        let index = state.question_tests.findIndex(x => x.id == question_test.id);
        if (index != -1) state.question_tests.splice(index, 1, question_test);
        else state.question_tests.push(question_test);
    },
    [names.MUTATE_QUESTION_TESTS]: (state, question_tests) => {
        question_tests.forEach(element => {
            let index = state.question_tests.findIndex(x => x.id == element.id);
            if (index != -1) state.question_tests.splice(index, 1, element);
            else state.question_tests.push(element);
        });
    },
    [names.MUTATE_DELETE_QUESTION_TEST]: (state, question_test_id) => {
        let index = state.question_tests.findIndex(x => x.id == question_test_id);
        if (index != -1) state.question_tests.splice(index, 1);
    },
    [names.MUTATE_QUESTION_TEST_SCORE]: (state, question_test_score) => {
        let index = state.question_tests_score.findIndex(x => x.id == question_test_score.id);
        if (index != -1) state.question_tests_score.splice(index, 1, question_test_score);
        else state.question_tests_score.push(question_test_score);
    },
    [names.MUTATE_QUESTION_TESTS_SCORE]: (state, question_tests_score) => {
        question_tests_score.forEach(element => {
            let index = state.question_tests_score.findIndex(x => x.id == element.id);
            if (index != -1) state.question_tests_score.splice(index, 1, element);
            else state.question_tests_score.push(element);
        });
    },
    [names.MUTATE_DELETE_QUESTION_TEST_SCORE]: (state, question_test_score_id) => {
        let index = state.question_tests_score.findIndex(x => x.id == question_test_score_id);
        if (index != -1) state.question_tests_score.splice(index, 1);
    },
};

const actions = {
    [names.FETCH_QUESTION_TEST]: ({
        commit,
        state
    }, question_test_id) => {
        return new Promise((resolve) => {
            const question_test = state.question_tests.find(x => x.id == question_test_id);
            if (question_test) {
                resolve(question_test);
                return;
            }
            restful.Get(`${state.question_tests_url}/${question_test_id}/`)
                .then(response => {
                    commit(names.MUTATE_QUESTION_TEST, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_QUESTION_TESTS]: ({
        commit,
        state
    }, evaluation_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.question_tests_url}/?evaluation=${evaluation_id}`)
                .then(response => {
                    commit(names.MUTATE_QUESTION_TESTS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_QUESTION_TEST]: ({
        commit,
        state
    }, question_test) => {
        return new Promise((resolve) => {
            restful.Post(`${state.question_tests_url}/`, question_test)
                .then(response => {
                    commit(names.MUTATE_QUESTION_TEST, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_QUESTION_TEST]: ({
        commit,
        state
    }, question_test) => {
        return new Promise((resolve) => {
            restful.Put(`${state.question_tests_url}/${question_test.id}/`, question_test)
                .then(response => {
                    commit(names.MUTATE_QUESTION_TEST, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_QUESTION_TEST]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.question_tests_url}/${payload.question_test_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_QUESTION_TEST, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_QUESTION_TEST]: ({
        commit,
        state
    }, question_test_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.question_tests_url}/${question_test_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_QUESTION_TEST, question_test_id);
                    resolve(response);
                });
        });
    },
    [names.FETCH_QUESTION_TEST_SCORE]: ({
        commit,
        state
    }, question_test_score_id) => {
        return new Promise((resolve) => {
            const question_test_score = state.question_tests_score.find(x => x.id == question_test_score_id);
            if (question_test_score) {
                resolve(question_test_score);
                return;
            }
            restful.Get(`${state.question_tests_score_url}/${question_test_score_id}/`)
                .then(response => {
                    commit(names.MUTATE_QUESTION_TEST_SCORE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_QUESTION_TESTS_SCORE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '';
            if (payload) {
                if (payload.question_test_id && payload.user_id)
                    url_append += '?question_test=' + payload.question_test_id + '&student=' + payload.user_id
                else if (payload.question_test_id)
                    url_append += '?question_test=' + payload.question_test_id
                else if (payload.user_id)
                    url_append += '?student=' + payload.user_id
            }
            restful.Get(`${state.question_tests_score_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_QUESTION_TESTS_SCORE, response);
                    resolve(response);
                });
        });
    },
    [names.POST_QUESTION_TEST_SCORE]: ({
        commit,
        state
    }, question_test_score) => {
        return new Promise((resolve) => {
            restful.Post(`${state.question_tests_score_url}/`, question_test_score)
                .then(response => {
                    commit(names.MUTATE_QUESTION_TESTS_SCORE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_QUESTION_TEST_SCORE]: ({
        commit,
        state
    }, question_test_score) => {
        return new Promise((resolve) => {
            restful.Put(`${state.question_tests_score_url}/${question_test_score.id}/`, question_test_score)
                .then(response => {
                    commit(names.MUTATE_QUESTION_TEST_SCORE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_QUESTION_TEST_SCORE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.question_tests_score_url}/${payload.question_test_score_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_QUESTION_TEST_SCORE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_QUESTION_TEST_SCORE]: ({
        commit,
        state
    }, question_test_score_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.question_tests_score_url}/${question_test_score_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_QUESTION_TEST_SCORE, question_test_score_id);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};