import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    courses_wrapper: [],
    courses_wrapper_url: '/mesh/course-wrapper',
    base_matters_wrapper: [],
    base_matters_wrapper_url: '/mesh/base-matter-wrapper'
}

const getters = {
    [names.COURSES_WRAPPER]: state => {
        if (!get.store.state.user) return [];
        return state.courses_wrapper.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.BASE_MATTERS_WRAPPER]: state => {
        if (!get.store.state.user) return [];
        return state.base_matters_wrapper.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_COURSES_WRAPPER]: (state, courses_wrapper) => {
        courses_wrapper.forEach(element => {
            let index = state.courses_wrapper.findIndex(x => x.id == element.id);
            if (index != -1) state.courses_wrapper.splice(index, 1, element);
            else state.courses_wrapper.push(element);
        });
    },
    [names.MUTATE_COURSE_WRAPPER]: (state, course_wrapper) => {
        let index = state.courses_wrapper.findIndex(x => x.id == course_wrapper.id);
        if (index != -1) state.courses_wrapper.splice(index, 1, course_wrapper);
        else state.courses_wrapper.push(course_wrapper);
    },
    [names.MUTATE_DELETE_COURSE_WRAPPER]: (state, course_wrapper_id) => {
        let index = state.courses_wrapper.findIndex(x => x.id == course_wrapper_id);
        if (index != -1) state.courses_wrapper.splice(index, 1);
    },
    [names.MUTATE_BASE_MATTERS_WRAPPER]: (state, base_matters_wrapper) => {
        base_matters_wrapper.forEach(element => {
            let index = state.base_matters_wrapper.findIndex(x => x.id == element.id);
            if (index != -1) state.base_matters_wrapper.splice(index, 1, element);
            else state.base_matters_wrapper.push(element);
        });
    },
    [names.MUTATE_BASE_MATTER_WRAPPER]: (state, base_matter_wrapper) => {
        let index = state.base_matters_wrapper.findIndex(x => x.id == base_matter_wrapper.id);
        if (index != -1) state.base_matters_wrapper.splice(index, 1, base_matter_wrapper);
        else state.base_matters_wrapper.push(base_matter_wrapper);
    },
    [names.MUTATE_DELETE_BASE_MATTER_WRAPPER]: (state, base_matter_wrapper_id) => {
        let index = state.base_matters_wrapper.findIndex(x => x.id == base_matter_wrapper_id);
        if (index != -1) state.base_matters_wrapper.splice(index, 1);
    },
    ["MUTATE_DELETE_MATTER_IN_BASE_MATTER_WRAPPER"]: (state, matter_id) => {
        let index1 = state.base_matters_wrapper.findIndex(x => x.matters.includes(matter_id));
        while (index1 != -1) {
            const index2 = state.base_matters_wrapper[index1].matters.findIndex(x => x == matter_id);
            if (index2 != -1) {
                state.base_matters_wrapper[index1].matters.splice(index2, 1);
            }
            index1 = state.base_matters_wrapper.findIndex(x => x.matters.includes(matter_id));
        }
    },
}

const actions = {
    [names.FETCH_COURSE_WRAPPER]: ({
        commit,
        state
    }, course_wrapper_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.courses_wrapper_url}/${course_wrapper_id}/`)
                .then(response => {
                    commit(names.MUTATE_COURSE_WRAPPER, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_COURSES_WRAPPER]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '';
            if (payload) {
                if (payload.egress_profile_id) {
                    if (state.courses_wrapper.filter(x => x.egress_profile == payload.egress_profile_id).length > 0) {
                        resolve(state.courses_wrapper.filter(x => x.egress_profile == payload.egress_profile_id));
                        return;
                    } else
                        url_append += '?egress_profile=' + payload.egress_profile_id
                }
            }
            restful.Get(`${state.courses_wrapper_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_COURSES_WRAPPER, response);
                    resolve(response);
                });
        });
    },
    [names.POST_COURSE_WRAPPER]: ({
        commit,
        state
    }, course_wrapper) => {
        return new Promise((resolve) => {
            restful.Post(`${state.courses_wrapper_url}/`, course_wrapper)
                .then(response => {
                    commit(names.MUTATE_COURSE_WRAPPER, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_COURSE_WRAPPER]: ({
        commit,
        state
    }, course_wrapper) => {
        return new Promise((resolve) => {
            restful.Put(`${state.courses_wrapper_url}/${course_wrapper.id}/`, course_wrapper)
                .then(response => {
                    commit(names.MUTATE_COURSE_WRAPPER, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_COURSE_WRAPPER]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.courses_wrapper_url}/${payload.course_wrapper_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_COURSE_WRAPPER, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_COURSE_WRAPPER]: ({
        commit,
        state
    }, course_wrapper_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.courses_wrapper_url}/${course_wrapper_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_COURSE_WRAPPER, course_wrapper_id);
                    resolve(response);
                });
        });
    },

    [names.FETCH_BASE_MATTER_WRAPPER]: ({
        commit,
        state
    }, base_matter_wrapper_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.base_matters_wrapper_url}/${base_matter_wrapper_id}/`)
                .then(response => {
                    commit(names.MUTATE_BASE_MATTER_WRAPPER, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_BASE_MATTERS_WRAPPER]: ({
        commit,
        state,
    }) => {
        return new Promise((resolve) => {
            restful.Get(`${state.base_matters_wrapper_url}/`)
                .then(response => {
                    commit(names.MUTATE_BASE_MATTERS_WRAPPER, response);
                    resolve(response);
                });
        });
    },
    [names.POST_BASE_MATTER_WRAPPER]: ({
        commit,
        state
    }, base_matter_wrapper) => {
        return new Promise((resolve) => {
            restful.Post(`${state.base_matters_wrapper_url}/`, base_matter_wrapper)
                .then(response => {
                    commit(names.MUTATE_BASE_MATTER_WRAPPER, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_BASE_MATTER_WRAPPER]: ({
        commit,
        state
    }, base_matter_wrapper) => {
        return new Promise((resolve) => {
            restful.Put(`${state.base_matters_wrapper_url}/${base_matter_wrapper.id}/`, base_matter_wrapper)
                .then(response => {
                    commit(names.MUTATE_BASE_MATTER_WRAPPER, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_BASE_MATTER_WRAPPER]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.base_matters_wrapper_url}/${payload.base_matter_wrapper_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_BASE_MATTER_WRAPPER, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_BASE_MATTER_WRAPPER]: ({
        commit,
        state
    }, base_matter_wrapper_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.base_matters_wrapper_url}/${base_matter_wrapper_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_BASE_MATTER_WRAPPER, base_matter_wrapper_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};