
export default [
    {
        path: '/',
        name: 'Welcome',
        component: () =>
            import("@/components/public/Welcome"),
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import("@/components/public/Login"),
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import("@/components/public/About"),
    },
    {
        path: '/consultancy',
        name: 'Consultancy',
        component: () =>
            import("@/components/public/Consultancy"),
    },
    {
        path: '/training',
        name: 'Training',
        component: () =>
            import("@/components/public/Training"),
    },
    {
        path: '/reset_password/:email/:code',
        name: 'ResetPassword',
        component: () =>
            import("@/components/public/ResetPassword"),
    },
    {
        path: '/welcome_password_change/:email/:code',
        name: 'WelcomePasswordChange',
        component: () =>
            import("@/components/public/WelcomePasswordChange"),
    },
    {
        path: '/solutions',
        name: 'SolutionsView',
        component: () =>
            import("@/components/new-page/SolutionsView"),
    },
    {
        path: '/about-us',
        name: 'AboutUsView',
        component: () =>
            import("@/components/new-page/AboutUsView"),
    },
    {
        path: '/training-view',
        name: 'TrainingView',
        component: () =>
            import("@/components/new-page/TrainingView"),
    },
    {
        path: '*',
        name: 'Page404',
        component: () =>
            import("@/components/public/Page404"),
    },
]