
import Vue from 'vue';
import axios from 'axios';
import router from '@/router/index';
import public_routes from '@/router/public';
import { backend_usecured_routes } from '@/utils/utils';
import { URL } from './globals';
import { store } from '../store/store';


const instance = axios.create({
    baseURL: URL
});

instance.interceptors.request.use(config => {
    // #NOTE: La siguiente linea no funciona todavia porque el home / es disponible tanto para usuarios registrados o no.
    // public_routes.map(x => x.name).includes(router.currentRoute.name)

    // Si la ruta es pública en el backend o es para pedir cualquier token no se chequea la validez del token actual.
    if (backend_usecured_routes.some(r => config.url.startsWith(r))) {
        return config;
    }
    // A continuación se valida si el token está por expirar y se refresca antes de efectuar la petición.
    const expires_in = store.state.expires_at - Date.now();
    const time_untill_expiration = 1000 * 60 * 5; // 5 minutes
    if (expires_in < time_untill_expiration) {
        console.log('Refreshing token!!!');
        if (!store.state.refresh_token) {
            // console.log(store.state.refresh_token);
            if (!public_routes.map(x => x.name).includes(router.currentRoute.name)) {
                router.push({ name: "Login" });
            }
            store.commit('clearLoginData');
            return Promise.reject('Missing refresh token.');
        }
        // #NOTE: Importante hacer la petición sin usar la instancia para evitar posible ciclo infinito si algo falla.
        return axios.post(URL + '/token/refresh/', { refresh: store.state.refresh_token })
            .then(response => {
                // Update the token and the expiration time in the store.
                const token = response.data.access;
                const refresh_token = response.data.refresh;
                store.commit('setTokens', { token, refresh_token });
                // Retry the original request with the new token.
                config.headers.common['Authorization'] = 'Bearer ' + token;
                return Promise.resolve(config);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    } else if (store.state.jwt_token != null) {
        // Esta condición es redundante! Se puede eliminar o no, da igual.
        config.headers.common['Authorization'] = 'Bearer ' + store.state.jwt_token;
    }
    return config;
});

instance.interceptors.response.use(response => {
    return Promise.resolve(response);
}, error => {
    console.log(error);
    if (error.response.status === 401) {
        console.log(error.response.status);
        router.push({ name: 'Welcome' });
    }
    else if (error.response.status === 403) {
        Vue.swal.fire({
            title: "No tiene permiso para realizar esa acción"
        }).then(() => {
            location.reload();
        });
    }
    else if (error.response.status === 400) {
        // `${error.response.statusText} - Code: ${error.response.status} - Error: ${error.response.request.responseText}`,
        Vue.swal.fire(
            "Se ha producido un error en la petición",
            `Error: ${error.response.request.responseText}`,
            "warning"
        );
    }
    return Promise.reject(error);
});

export default instance;
