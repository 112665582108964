import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    ra_macros: [],
    ra_macros_url: '/teaching/ra-macro'
}

const getters = {
    [names.RA_MACROS]: state => {
        if (!get.store.state.user) return [];
        return state.ra_macros.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_RA_MACROS]: (state, ra_macros) => {
        ra_macros.forEach(element => {
            let index = state.ra_macros.findIndex(x => x.id == element.id);
            if (index != -1) state.ra_macros.splice(index, 1, element);
            else state.ra_macros.push(element);
        });
    },
    [names.MUTATE_RA_MACRO]: (state, ra_macro) => {
        let index = state.ra_macros.findIndex(x => x.id == ra_macro.id);
        if (index != -1) state.ra_macros.splice(index, 1, ra_macro);
        else state.ra_macros.push(ra_macro);
    },
    [names.MUTATE_DELETE_RA_MACRO]: (state, ra_macro_id) => {
        let index = state.ra_macros.findIndex(x => x.id == ra_macro_id);
        if (index != -1) state.ra_macros.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_RA_MACRO]: ({
        commit,
        state
    }, ra_macro_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.ra_macros_url}/${ra_macro_id}/`)
                .then(response => {
                    commit(names.MUTATE_RA_MACRO, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_RA_MACROS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '';
            if (payload) {
                if (payload.matter_id) {
                    if (state.ra_macros.filter(x => x == payload.matter_id).length > 0) {
                        resolve(state.ra_macros);
                        return;
                    } else url_append += '?mattter=' + payload.matter_id;
                } else if (payload.egress_profile_id)
                    url_append += '?egress_profile=' + payload.egress_profile_id;
            }
            restful.Get(`${state.ra_macros_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_RA_MACROS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_RA_MACRO]: ({
        commit,
        state
    }, ra_macro) => {
        return new Promise((resolve) => {
            restful.Post(`${state.ra_macros_url}/`, ra_macro)
                .then(response => {
                    commit(names.MUTATE_RA_MACRO, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_RA_MACRO]: ({
        commit,
        state
    }, ra_macro) => {
        return new Promise((resolve) => {
            restful.Put(`${state.ra_macros_url}/${ra_macro.id}/`, ra_macro)
                .then(response => {
                    commit(names.MUTATE_RA_MACRO, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_RA_MACRO]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.ra_macros_url}/${payload.ra_macro_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_RA_MACRO, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_RA_MACRO]: ({
        commit,
        state
    }, ra_macro_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.ra_macros_url}/${ra_macro_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_RA_MACRO, ra_macro_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};