import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    profile_cycles: [],
    profile_cycles_url: '/mesh/egress-profile-cycle'
}

const getters = {
    [names.PROFILE_CYCLES]: state => {
        if (!get.store.state.user) return [];
        return state.profile_cycles.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_PROFILE_CYCLES]: (state, profile_cycles) => {
        profile_cycles.forEach(element => {
            let index = state.profile_cycles.findIndex(x => x.id == element.id);
            if (index != -1) state.profile_cycles.splice(index, 1, element);
            else state.profile_cycles.push(element);
        });
    },
    [names.MUTATE_PROFILE_CYCLE]: (state, profile_cycles) => {
        let index = state.profile_cycles.findIndex(x => x.id == profile_cycles.id);
        if (index != -1) state.profile_cycles.splice(index, 1, profile_cycles);
        else state.profile_cycles.push(profile_cycles);
    },
    [names.MUTATE_DELETE_PROFILE_CYCLE]: (state, profile_cycle_id) => {
        let index = state.profile_cycles.findIndex(x => x.id == profile_cycle_id);
        if (index != -1) state.profile_cycles.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_PROFILE_CYCLE]: ({
        commit,
        state
    }, profile_cycle_id) => {
        return new Promise((resolve) => {
            const profile_cycles = state.profile_cycles.find(x => x.id == profile_cycle_id);
            if (profile_cycles) {
                resolve(profile_cycles);
                return;
            }
            restful.Get(`${state.profile_cycles_url}/${profile_cycle_id}/`)
                .then(response => {
                    commit(names.MUTATE_PROFILE_CYCLE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_PROFILE_CYCLES]: ({
        commit,
        state
    }, search_payload) => {
        return new Promise((resolve) => {
            let arr = [];
            if (search_payload && search_payload.egress_profile_id) arr.push('egress_profile=' + search_payload.egress_profile_id);
            if (search_payload && search_payload.cycle_id) arr.push('cycle=' + search_payload.cycle_id);
            let partial_ur = '';
            if (arr.length > 0) partial_ur = '?' + arr.join('&&');
            restful.Get(`${state.profile_cycles_url}/${partial_ur}`)
                .then(response => {
                    commit(names.MUTATE_PROFILE_CYCLES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_PROFILE_CYCLE]: ({
        commit,
        state
    }, profile_cycles) => {
        return new Promise((resolve) => {
            restful.Post(`${state.profile_cycles_url}/`, profile_cycles)
                .then(response => {
                    commit(names.MUTATE_PROFILE_CYCLE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_PROFILE_CYCLE]: ({
        commit,
        state
    }, profile_cycle) => {
        return new Promise((resolve) => {
            restful.Put(`${state.profile_cycles_url}/${profile_cycle.id}/`, profile_cycle)
                .then(response => {
                    commit(names.MUTATE_PROFILE_CYCLE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_PROFILE_CYCLE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.profile_cycles_url}/${payload.profile_cycle_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_PROFILE_CYCLE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_PROFILE_CYCLE]: ({
        commit,
        state
    }, profile_cycle_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.profile_cycles_url}/${profile_cycle_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_PROFILE_CYCLE, profile_cycle_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};