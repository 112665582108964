import restful from "@/utils/restful";
import * as names from "@/store/names";
import * as get from "@/store/store";

const state = {
  communes: [],
  communes_url: "/common/commune",
};

const getters = {
  [names.COMMUNES]: (state) => {
    if (!get.store.state.user) return [];
    return state.communes
      .filter((x) => x.school == get.store.state.user.school)
      .sort((a, b) => a.name - b.name);
  },
};

const mutations = {
  [names.MUTATE_COMMUNES]: (state, communes) => {
    communes.forEach((element) => {
      let index = state.communes.findIndex((x) => x.id == element.id);
      if (index != -1) state.communes.splice(index, 1, element);
      else state.communes.push(element);
    });
  },
  [names.MUTATE_COMMUNE]: (state, commune) => {
    let index = state.communes.findIndex((x) => x.id == commune.id);
    if (index != -1) state.communes.splice(index, 1, commune);
    else state.communes.push(commune);
  },
  [names.MUTATE_DELETE_COMMUNE]: (state, commune_id) => {
    let index = state.communes.findIndex((x) => x.id == commune_id);
    if (index != -1) state.communes.splice(index, 1);
  },
};

const actions = {
  [names.FETCH_COMMUNE]: ({ commit, state }, commune_id) => {
    return new Promise((resolve) => {
      restful
        .Get(`${state.communes_url}/${commune_id}/`)
        .then((response) => {
          commit(names.MUTATE_COMMUNE, response);
          resolve(response);
        });
    });
  },
  [names.FETCH_COMMUNES]: ({ commit, state }, force) => {
    return new Promise((resolve) => {
      if (!force && state.communes.length > 0) {
        resolve(state.communes);
        return;
      }
      restful.Get(`${state.communes_url}/`).then((response) => {
        commit(names.MUTATE_COMMUNES, response);
        resolve(response);
      });
    });
  },
  [names.POST_COMMUNE]: ({ commit, state }, commune) => {
    return new Promise((resolve) => {
      restful
        .Post(`${state.communes_url}/`, commune)
        .then((response) => {
          commit(names.MUTATE_COMMUNE, response);
          resolve(response);
        });
    });
  },
  [names.UPDATE_COMMUNE]: ({ commit, state }, commune) => {
    return new Promise((resolve) => {
      restful
        .Put(`${state.communes_url}/${commune.id}/`, commune)
        .then((response) => {
          commit(names.MUTATE_COMMUNE, response);
          resolve(response);
        });
    });
  },
  [names.PATCH_COMMUNE]: ({ commit, state }, payload) => {
    return new Promise((resolve) => {
      restful
        .Patch(
          `${state.communes_url}/${payload.commune_id}/`,
          payload.item
        )
        .then((response) => {
          commit(names.MUTATE_COMMUNE, response);
          resolve(response);
        });
    });
  },
  [names.DELETE_COMMUNE]: ({ commit, state }, commune_id) => {
    return new Promise((resolve) => {
      restful
        .Delete(`${state.communes_url}/${commune_id}/`)
        .then((response) => {
          commit(names.MUTATE_DELETE_COMMUNE, commune_id);
          resolve(response);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
