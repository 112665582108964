import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    evaluations: [],
    evaluations_url: '/teaching/evaluation',
    evaluation_types: [],
    evaluation_types_url: '/teaching/evaluation-types',
    evaluation_categories: [],
    evaluation_categories_url: '/teaching/evaluation-categories',
    instrument_sub_types: [],
    instrument_sub_types_url: '/evaluation/sub-type',
    sections_evaluations: [],
    sections_evaluations_url: '/teaching/section-evaluation',
    automated_score_types: [],
    automated_score_types_url: '/evaluation/automated-score-type',
}

const getters = {
    [names.SECTION_EVALUATIONS]: state => {
        if (!get.store.state.user) return [];
        return state.sections_evaluations.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.EVALUATIONS]: state => {
        if (!get.store.state.user) return [];
        return state.evaluations.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.EVALUATION]: (state, ) => id => {
        return state.evaluations.find(x => x.id == id);
    },
    [names.EVALUATION_TYPES]: state => {
        // TODO: No cuenta con filtro dado que es un dato estático en el Backend
        return state.evaluation_types;
    },
    [names.AUTOMATED_SCORE_TYPES]: state => {
        // TODO: No cuenta con filtro dado que es un dato estático en el Backend
        return state.automated_score_types;
    },
    [names.EVALUATION_CATEGORIES]: state => {
        // TODO: No cuenta con filtro dado que es un dato estático en el Backend
        return state.evaluation_categories;
    },
    [names.INSTRUMENT_SUB_TYPES]: state => {
        // TODO: No cuenta con filtro dado que es un dato estático en el Backend
        return state.instrument_sub_types;
    },
}

const mutations = {
    [names.MUTATE_EVALUATIONS]: (state, evaluations) => {
        evaluations.forEach(element => {
            let index = state.evaluations.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluations.splice(index, 1, element);
            else state.evaluations.push(element);
        });
    },
    [names.MUTATE_EVALUATION]: (state, evaluation) => {
        let index = state.evaluations.findIndex(x => x.id == evaluation.id);
        if (index != -1) state.evaluations.splice(index, 1, evaluation);
        else state.evaluations.push(evaluation);
    },
    [names.MUTATE_DELETE_EVALUATION]: (state, evaluation_id) => {
        let index = state.evaluations.findIndex(x => x.id == evaluation_id);
        if (index != -1) state.evaluations.splice(index, 1);
    },
    [names.MUTATE_SECTION_EVALUATIONS]: (state, sections_evaluations) => {
        sections_evaluations.forEach(element => {
            let index = state.sections_evaluations.findIndex(x => x.id == element.id);
            if (index != -1) state.sections_evaluations.splice(index, 1, element);
            else state.sections_evaluations.push(element);
        });
    },
    [names.MUTATE_SECTION_EVALUATION]: (state, section_evaluation) => {
        let index = state.sections_evaluations.findIndex(x => x.id == section_evaluation.id);
        if (index != -1) state.sections_evaluations.splice(index, 1, section_evaluation);
        else state.sections_evaluations.push(section_evaluation);
    },
    [names.MUTATE_DELETE_SECTION_EVALUATION]: (state, section_evaluation_id) => {
        let index = state.sections_evaluations.findIndex(x => x.id == section_evaluation_id);
        if (index != -1) state.sections_evaluations.splice(index, 1);
    },
    [names.MUTATE_EVALUATION_TYPES]: (state, evaluation_types) => {
        evaluation_types.forEach(element => {
            let index = state.evaluation_types.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluation_types.splice(index, 1, element);
            else state.evaluation_types.push(element);
        });
    },
    [names.MUTATE_AUTOMATED_SCORE_TYPES]: (state, automated_score_types) => {
        automated_score_types.forEach(element => {
            let index = state.automated_score_types.findIndex(x => x.id == element.id);
            if (index != -1) state.automated_score_types.splice(index, 1, element);
            else state.automated_score_types.push(element);
        });
    },
    [names.MUTATE_EVALUATION_CATEGORIES]: (state, evaluation_categories) => {
        evaluation_categories.forEach(element => {
            let index = state.evaluation_categories.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluation_categories.splice(index, 1, element);
            else state.evaluation_categories.push(element);
        });
    },
    [names.MUTATE_INSTRUMENT_SUB_TYPES]: (state, instrument_sub_types) => {
        instrument_sub_types.forEach(element => {
            let index = state.instrument_sub_types.findIndex(x => x.id == element.id);
            if (index != -1) state.instrument_sub_types.splice(index, 1, element);
            else state.instrument_sub_types.push(element);
        });
    },
}

const actions = {
    [names.FETCH_EVALUATION]: ({
        commit,
        state
    }, evaluation_id) => {
        return new Promise((resolve) => {
            const instance = state.evaluations.find(x => x.id == evaluation_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.evaluations_url}/${evaluation_id}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATIONS_BY_STUDY_UNIT]: ({
        commit,
        state
    }, study_unit_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.evaluations_url}/?study_unit=${study_unit_id}`)
                .then(response => {
                    commit(names.MUTATE_EVALUATIONS, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATIONS_BY_SECTION]: ({
        commit,
        state
    }, section_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.evaluations_url}/?section=${section_id}`)
                .then(response => {
                    commit(names.MUTATE_EVALUATIONS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_EVALUATION]: ({
        commit,
        state
    }, evaluation) => {
        return new Promise((resolve) => {
            restful.Post(`${state.evaluations_url}/`, evaluation)
                .then(response => {
                    commit(names.MUTATE_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATION]: ({
        commit,
        state
    }, evaluation) => {
        return new Promise((resolve) => {
            restful.Put(`${state.evaluations_url}/${evaluation.id}/`, evaluation)
                .then(response => {
                    commit(names.MUTATE_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.evaluations_url}/${payload.evaluation_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATION]: ({
        commit,
        state
    }, evaluation_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.evaluations_url}/${evaluation_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EVALUATION, evaluation_id);
                    resolve(response);
                });
        });
    },
    [names.FETCH_SECTION_EVALUATIONS]: ({
        commit,
        state
    }, evaluation_id) => {
        return new Promise((resolve) => {
            let partial_ur = '';
            if (evaluation_id) partial_ur = '?evaluation=' + evaluation_id;
            restful.Get(`${state.sections_evaluations_url}/${partial_ur}`)
                .then(response => {
                    commit(names.MUTATE_SECTION_EVALUATIONS, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_SECTION_EVALUATION]: ({
        commit,
        state
    }, section_evaluation_id) => {
        return new Promise((resolve) => {
            const instance = state.sections_evaluations.find(x => x.id == section_evaluation_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.sections_evaluations_url}/${section_evaluation_id}/`)
                .then(response => {
                    commit(names.MUTATE_SECTION_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.POST_SECTION_EVALUATION]: ({
        commit,
        state
    }, section_evaluation) => {
        return new Promise((resolve) => {
            restful.Post(`${state.sections_evaluations_url}/`, section_evaluation)
                .then(response => {
                    commit(names.MUTATE_SECTION_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_SECTION_EVALUATION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.sections_evaluations_url}/${payload.section_evaluation_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_SECTION_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_SECTION_EVALUATION]: ({
        commit,
        state
    }, section_evaluation_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.sections_evaluations_url}/${section_evaluation_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_SECTION_EVALUATION, section_evaluation_id);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATION_TYPES]: ({
        commit,
        state
    }, ) => {
        return new Promise((resolve) => {
            if (state.evaluation_types.length > 0) {
                resolve(state.evaluation_types);
                return;
            }
            restful.Get(`${state.evaluation_types_url}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_TYPES, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_AUTOMATED_SCORE_TYPES]: ({
        commit,
        state
    }, ) => {
        return new Promise((resolve) => {
            if (state.automated_score_types.length > 0) {
                resolve(state.automated_score_types);
                return;
            }
            restful.Get(`${state.automated_score_types_url}/`)
                .then(response => {
                    commit(names.MUTATE_AUTOMATED_SCORE_TYPES, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATION_CATEGORIES]: ({
        commit,
        state
    }, ) => {
        return new Promise((resolve) => {
            if (state.evaluation_categories.length > 0) {
                resolve(state.evaluation_categories);
                return;
            }
            restful.Get(`${state.evaluation_categories_url}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_CATEGORIES, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_INSTRUMENT_SUB_TYPES]: ({
        commit,
        state
    }, ) => {
        return new Promise((resolve) => {
            if (state.instrument_sub_types.length > 0) {
                resolve(state.instrument_sub_types);
                return;
            }
            restful.Get(`${state.instrument_sub_types_url}/`)
                .then(response => {
                    commit(names.MUTATE_INSTRUMENT_SUB_TYPES, response);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};