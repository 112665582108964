import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    files: [],
    files_url: '/common/file'
};

const getters = {
    [names.FILES]: state => {
        if (!get.store.state.user) return [];
        return state.files.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
};

const mutations = {
    [names.MUTATE_FILE]: (state, cycle) => {
        let index = state.files.findIndex(x => x.id == cycle.id);
        if (index != -1) state.files.splice(index, 1, cycle);
        else state.files.push(cycle);
    },
    [names.MUTATE_DELETE_FILE]: (state, cycle_id) => {
        let index = state.files.findIndex(x => x.id == cycle_id);
        if (index != -1) state.files.splice(index, 1);
    },
};

const actions = {
    [names.FETCH_FILE]: ({
        commit,
        state
    }, file_id) => {
        return new Promise((resolve) => {
            const file = state.files.find(x => x.id == file_id);
            if (file) {
                resolve(file);
                return;
            }
            restful.Get(`${state.files_url}/${file_id}/`)
                .then(response => {
                    commit(names.MUTATE_FILE, response);
                    resolve(response);
                });
        });
    },
    [names.POST_FILE]: ({
        commit,
        state
    }, file) => {
        return new Promise((resolve) => {
            restful.Post(`${state.files_url}/`, file)
                .then(response => {
                    commit(names.MUTATE_FILE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_FILE]: ({
        commit,
        state
    }, file) => {
        return new Promise((resolve) => {
            restful.Put(`${state.files_url}/${file.id}/`, file)
                .then(response => {
                    commit(names.MUTATE_FILE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_FILE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.files_url}/${payload.file_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_FILE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_FILE]: ({
        commit,
        state
    }, file_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.files_url}/${file_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_FILE, file_id);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};