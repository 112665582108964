// import { isValidJwt } from '@/utils/utils';
// import router from '@/router/index';

export const getAppName = state => {
    return state.app_name;
};

export const getToken = state => {
    return state.jwt_token;
};

export const getUser = state => {
    return state.user;
};

export const isLoged = state => {
    return state.is_loged;
}

export const getExpirationTime = state => {
    return state.expires_at;
}

// export const isLoged = state => {
//     // let is_loged = isValidJwt(state.jwt_token);
//     let is_loged = isValidJwt(localStorage.jwt_token);
//     console.log("State.isLoged: ", is_loged)
//     if (!is_loged) {
//         // localStorage.clear();
//         // localStorage.jwt_token = null;
//         // localStorage.refresh_token = null;
//         // localStorage.user = null;
//         // localStorage.user_position = null;
//         // localStorage.permissions = null;
//         // localStorage.alternative_names = null;
        
//         // state.jwt_token = null;
//         // state.refresh_token = null;
//         // state.user = null;
//         // state.user_access = null;
//         // state.permissions = null;
//         // state.alternative_names.alternative_names = [];
//         // state.autonomous_teaching_activities.autonomous_teaching_activities = [];
//         // state.bibliographic_resource_types.bibliographic_resource_types = [];
//         // state.campuses.campuses = [];
//         // state.careers.careers = [];
//         // state.certifications.certifications = [];
//         // state.competence_types.competence_types = [];
//         // state.competences.competences = [];
//         // state.contents.contents = [];
//         // state.cycles.cycles = [];
//         // state.direct_teaching_activities.direct_teaching_activities = [];
//         // state.egress_profile_competence.egress_profile_competence = [];
//         // state.egress_profile_cycle.egress_profile_cycle = [];
//         // state.egress_profiles.egress_profiles = [];
//         // state.egress_profiles_matters.egress_profiles_matters = [];
//         // state.evaluation_criterias.evaluation_criterias = [];
//         // state.evaluation_feedbacks.evaluation_feedbacks = [];
//         // state.evaluation_instruments.evaluation_instruments = [];
//         // state.evaluation_planning.evaluation_planning = [];
//         // state.faculties.faculties = [];
//         // state.files.files = [];
//         // state.formation_areas.formation_areas = [];
//         // state.formation_lines.formation_lines = [];
//         // state.images.images = [];
//         // state.institution_competences.institution_competences = [];
//         // state.learning_results.learning_results = [];
//         // state.matter_contributions.matter_contributions = [];
//         // state.matter_performance_indicators.matter_performance_indicators = [];
//         // state.matter_types.matter_types = [];
//         // state.matters.matters = [];
//         // state.methodological_strategies.methodological_strategies = [];
//         // state.modalities.modalities = [];
//         // state.performance_indicators.performance_indicators = [];
//         // state.periods.periods = [];
//         // state.procedure_evidences.procedure_evidences = [];
//         // state.question_tests.question_tests = [];
//         // state.ra_macros.ra_macros = [];
//         // state.ra_micros.ra_micros = [];
//         // state.regimes.regimes = [];
//         // state.sections.sections = [];
//         // state.session_dates.session_dates = [];
//         // state.session_plannings.session_plannings = [];
//         // state.shifts.shifts = [];
//         // state.study_plan_comments.study_plan_comments = [];
//         // state.study_units.study_units = [];
//         // state.support_resource_types.support_resource_types = [];
//         // state.support_resources.support_resources = [];
//         // state.taxonomies.taxonomies = [];
//         // state.users.users = [];
//         // state.tests.question_options = [];
//         // state.tests.question_types = [];
//         // state.tests.questions = [];
//         // state.tests.score_types = [];
//         // state.test_answers.redaction_answers = [];
//         // state.test_answers.select_answers = [];
//         // state.positions.access = [];
//         // state.rubrics.rubric_achievement_descriptors = [];
//         // state.rubrics.rubric_achievements = [];
//         // state.rubrics.rubric_instances = [];
//         // state.rubrics.rubric_scores = [];
//         // state.rubrics.rubrics = [];
//         // state.evaluations.instrument_sub_types = [];
//         // state.evaluations.evaluation_types = [];
//         // state.profile_types = [];
//         // state.evaluations.evaluations = [];
//         // state.regions.regions = []
//         // state.communes.communes = []
//         // state.countries.countries = []
//         // state.courses_wrapper.courses_wrapper = []
//         // router.replace({name: 'Welcome'})
//         if (router.currentRoute.name != null && 
//             router.currentRoute.name != 'Welcome' && 
//             router.currentRoute.name != 'ResetPassword') {
//                 router.push({ name: 'Welcome' });
//         }
//     }
//     return is_loged;
// };

export const getInstitutionId = state => {
    // Cuando se refresca la pagina state.user_institution_id Pasa un 1 en vez de tomar user.istitution_id
    // y se coloco con un if ya que al inicio queda como null user.insitution_id.
    if (state.user) return state.user.school;
    return state.user_institution_id;
};

export const getInstitution = state => {
    return state.institution;
};

export const getInstitutionLogo = state => {
    return state.institution_logo;
};

export const hasPermission = state => permission => {
    if (state.permissions) {
        if (state.permissions.find(x => x == permission)) return true;
    }
    return false;
};