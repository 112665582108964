import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    access: [],
    access_url: '/teaching/access',
    user_access: JSON.parse(localStorage.getItem('user_position')),
    table_access_url: '/teaching/table-access',
    user_table_access: JSON.parse(localStorage.getItem('table_access')),
}

const getters = {
    [names.POSITIONS]: state => {
        if (!get.store.state.user) return [];
        return state.access.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.USER_POSITION]: state => {
        return state.user_access;
    },
    [names.TABLE_ACCESS]: state => {
        return state.user_table_access;
    },
    // canEditMatter: state => matter_id => {
    //     if (!state.user_access) return false;
    //     const index = state.user_access.permited_matters.findIndex(x => x == matter_id);
    //     if (index == -1) return false;
    //     return true
    // },
    // canEditCareer: state => career_id => {
    //     if (!state.user_access) return false;
    //     const index = state.user_access.career_id.findIndex(x => x == career_id);
    //     if (index == -1) return false;
    //     return true
    // },
}

const mutations = {
    [names.MUTATE_CURRENT_POSITION]: (state, user_access) => {
        state.user_access = user_access;
        localStorage.setItem("user_position", JSON.stringify(user_access));
    },
    [names.MUTATE_TABLE_ACCESS]: (state, user_table_access) => {
        state.user_table_access = user_table_access;
        localStorage.setItem("table_access", JSON.stringify(user_table_access));
    },
    [names.MUTATE_POSITIONS]: (state, access) => {
        access.forEach(element => {
            let index = state.access.findIndex(x => x.id == element.id);
            if (index != -1) state.access.splice(index, 1, element);
            else state.access.push(element);
        });
    },
    [names.MUTATE_POSITION]: (state, position) => {
        let index = state.access.findIndex(x => x.id == position.id);
        if (index != -1) state.access.splice(index, 1, position);
        else state.access.push(position);
    },
    [names.MUTATE_DELETE_POSITION]: (state, career_id) => {
        let index = state.access.findIndex(x => x.id == career_id);
        if (index != -1) state.access.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_POSITION]: ({
        commit,
        state
    }, access_id) => {
        return new Promise((resolve) => {
            const instance = state.access.find(x => x.id == access_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.access_url}/${access_id}/`)
                .then(response => {
                    commit(names.MUTATE_POSITION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_POSITIONS]: ({
        commit,
        state,
    }, user_id) => {
        return new Promise((resolve) => {
            const position = state.access.find(x => x.user == user_id);
            if (position) {
                resolve(position);
                return;
            }
            restful.Get(`${state.access_url}/?user=${user_id}`)
                .then(response => {
                    // console.log(response)
                    commit(names.MUTATE_POSITIONS, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_CURRENT_POSITION]: ({
        commit,
        state,
    }, user_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.access_url}/?user=${user_id}`)
                .then(response => {
                    commit(names.MUTATE_POSITIONS, response);
                    if (response.length > 0) {
                        const position = response[0];
                        // console.log("FETCH_CURRENT_POSITION ", position);
                        if (position.user == user_id) {
                            restful.Get(`${state.table_access_url}/?access=${position.id}`).then(table_access => {
                                commit(names.MUTATE_TABLE_ACCESS, table_access);
                            })
                            commit(names.MUTATE_CURRENT_POSITION, position);
                        }
                    }
                    resolve(response);
                });
        });
    },
    [names.POST_POSITION]: ({
        commit,
        state
    }, access) => {
        return new Promise((resolve) => {
            restful.Post(`${state.access_url}/`, access)
                .then(response => {
                    commit(names.MUTATE_POSITION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_POSITION]: ({
        commit,
        state
    }, access) => {
        return new Promise((resolve) => {
            restful.Put(`${state.access_url}/${access.id}/`, access)
                .then(response => {
                    commit(names.MUTATE_POSITION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_POSITION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            // Para que es necesario el carreer_id en el patch hacia position?
            restful.Patch(`${state.access_url}/${payload.career_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_POSITION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_POSITION]: ({
        commit,
        state
    }, career_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.access_url}/${career_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_POSITION, career_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};