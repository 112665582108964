import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    formation_lines: [],
    formation_lines_url: '/mesh/formation-line'
}

const getters = {
    [names.FORMATION_LINES]: state => {
        if (!get.store.state.user) return [];
        return state.formation_lines.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_FORMATION_LINES]: (state, formation_lines) => {
        formation_lines.forEach(element => {
            let index = state.formation_lines.findIndex(x => x.id == element.id);
            if (index != -1) state.formation_lines.splice(index, 1, element);
            else state.formation_lines.push(element);
        });
    },
    [names.MUTATE_FORMATION_LINE]: (state, formation_line) => {
        let index = state.formation_lines.findIndex(x => x.id == formation_line.id);
        if (index != -1) state.formation_lines.splice(index, 1, formation_line);
        else state.formation_lines.push(formation_line);
    },
    [names.MUTATE_DELETE_FORMATION_LINE]: (state, formation_line_id) => {
        let index = state.formation_lines.findIndex(x => x.id == formation_line_id);
        if (index != -1) state.formation_lines.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_FORMATION_LINE]: ({
        commit,
        state
    }, formation_line_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.formation_lines_url}/${formation_line_id}/`)
                .then(response => {
                    commit(names.MUTATE_FORMATION_LINE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_FORMATION_LINES]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.formation_lines.length > 0) {
                resolve(state.formation_lines);
                return;
            }
            restful.Get(`${state.formation_lines_url}/`)
                .then(response => {
                    commit(names.MUTATE_FORMATION_LINES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_FORMATION_LINE]: ({
        commit,
        state
    }, formation_line) => {
        return new Promise((resolve) => {
            restful.Post(`${state.formation_lines_url}/`, formation_line)
                .then(response => {
                    commit(names.MUTATE_FORMATION_LINE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_FORMATION_LINE]: ({
        commit,
        state
    }, formation_line) => {
        return new Promise((resolve) => {
            restful.Put(`${state.formation_lines_url}/${formation_line.id}/`, formation_line)
                .then(response => {
                    commit(names.MUTATE_FORMATION_LINE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_FORMATION_LINE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.formation_lines_url}/${payload.formation_line_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_FORMATION_LINE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_FORMATION_LINE]: ({
        commit,
        state
    }, formation_line_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.formation_lines_url}/${formation_line_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_FORMATION_LINE, formation_line_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};