import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    session_dates: [],
    session_dates_url: '/teaching/session-date'
}

const getters = {
    [names.SESSION_DATES]: state => {
        if (!get.store.state.user) return [];
        return state.session_dates.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_SESSION_DATES]: (state, session_dates) => {
        session_dates.forEach(element => {
            let index = state.session_dates.findIndex(x => x.id == element.id);
            if (index != -1) state.session_dates.splice(index, 1, element);
            else state.session_dates.push(element);
        });
    },
    [names.MUTATE_SESSION_DATE]: (state, session_date) => {
        let index = state.session_dates.findIndex(x => x.id == session_date.id);
        if (index != -1) state.session_dates.splice(index, 1, session_date);
        else state.session_dates.push(session_date);
    },
    [names.MUTATE_DELETE_SESSION_DATE]: (state, session_date_id) => {
        let index = state.session_dates.findIndex(x => x.id == session_date_id);
        if (index != -1) state.session_dates.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_SESSION_DATE]: ({
        commit,
        state
    }, session_date_id) => {
        return new Promise((resolve) => {
            const instance = state.session_dates.find(x => x.id == session_date_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.session_dates_url}/${session_date_id}/`)
                .then(response => {
                    commit(names.MUTATE_SESSION_DATE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_SESSION_DATES]: ({
        commit,
        state
    }, section_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.session_dates_url}/?section=${section_id}`)
                .then(response => {
                    commit(names.MUTATE_SESSION_DATES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_SESSION_DATE]: ({
        commit,
        state
    }, session_date) => {
        return new Promise((resolve) => {
            restful.Post(`${state.session_dates_url}/`, session_date)
                .then(response => {
                    commit(names.MUTATE_SESSION_DATE, response);
                    commit('SECTION_ADD_SESSION_DATE', { section_id: session_date.section, session_date_id: response.id })
                    resolve(response);
                });
        });
    },
    [names.POST_BULK_SESSION_DATES]: ({
        commit,
        state
    }, session_dates) => {
        return new Promise((resolve) => {
            let response_list = [];
            let counter = 0;
            session_dates.forEach(tmp_session_date => {
                counter += 1;
                restful.Post(`${state.session_dates_url}/`, tmp_session_date)
                    .then(response => {
                        response_list.push(response);
                        counter -= 1;
                        if (counter == 0) {
                            commit(names.MUTATE_SESSION_DATES, response_list);
                            commit('SECTION_ADD_SESSION_DATES', response_list);
                            resolve(response_list);
                        }
                    });
            });

        });
    },
    [names.UPDATE_SESSION_DATE]: ({
        commit,
        state
    }, session_date) => {
        return new Promise((resolve) => {
            restful.Put(`${state.session_dates_url}/${session_date.id}/`, session_date)
                .then(response => {
                    commit(names.MUTATE_SESSION_DATE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_SESSION_DATE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.session_dates_url}/${payload.session_date_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_SESSION_DATE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_SESSION_DATE]: ({
        commit,
        state
    }, session_date_id) => {
        return new Promise((resolve) => {
            const section_id = state.session_dates.find(x => x.id == session_date_id).section;
            restful.Delete(`${state.session_dates_url}/${session_date_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_SESSION_DATE, session_date_id);
                    commit('SECTION_DELETE_SESSION_DATE', { section_id: section_id, session_date_id: session_date_id })
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};