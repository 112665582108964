import restful from '@/utils/restful';
import * as names from '@/store/names';

const state = {
    images: [],
    images_url: '/common/image'
};

const getters = {
    [names.IMAGES]: state => {
        //TODO: images no cuenta con school en sus atributos.
        return state.images;
    },
};

const mutations = {
    [names.MUTATE_IMAGE]: (state, cycle) => {
        let index = state.images.findIndex(x => x.id == cycle.id);
        if (index != -1) state.images.splice(index, 1, cycle);
        else state.images.push(cycle);
    },
    [names.MUTATE_DELETE_IMAGE]: (state, cycle_id) => {
        let index = state.images.findIndex(x => x.id == cycle_id);
        if (index != -1) state.images.splice(index, 1);
    },
};

const actions = {
    [names.FETCH_IMAGE]: ({
        commit,
        state
    }, image_id) => {
        return new Promise((resolve) => {
            const image = state.images.find(x => x.id == image_id);
            if (image) {
                resolve(image);
                return;
            }
            restful.Get(`${state.images_url}/${image_id}/`)
                .then(response => {
                    commit(names.MUTATE_IMAGE, response);
                    resolve(response);
                });
        });
    },
    [names.POST_IMAGE]: ({
        commit,
        state
    }, image) => {
        return new Promise((resolve) => {
            restful.Post(`${state.images_url}/`, image)
                .then(response => {
                    commit(names.MUTATE_IMAGE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_IMAGE]: ({
        commit,
        state
    }, image) => {
        return new Promise((resolve) => {
            restful.Put(`${state.images_url}/${image.id}/`, image)
                .then(response => {
                    commit(names.MUTATE_IMAGE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_IMAGE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.images_url}/${payload.image_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_IMAGE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_IMAGE]: ({
        commit,
        state
    }, image_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.images_url}/${image_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_IMAGE, image_id);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};