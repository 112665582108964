import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    taxonomies: [],
    taxonomies_url: '/mesh/taxonomy'
};

const getters = {
    [names.TAXONOMIES]: state => {
        if (!get.store.state.user) return [];
        return state.taxonomies.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.TAXONOMY]: state => {
        return state.taxonomies.find(x => x.active && x.school == get.store.state.user.school);
    },
};

const mutations = {
    [names.MUTATE_TAXONOMIES]: (state, taxonomies) => {
        taxonomies.forEach((element, element_index) => {
            let index = state.taxonomies.findIndex(x => x.id == element.id);
            if (index != -1) state.taxonomies.splice(index, 1, element);
            else state.taxonomies.push(element);
            if (element_index === taxonomies.length - 1)
                localStorage.setItem("taxonomies", JSON.stringify(state.taxonomies));
        });
    },
    [names.MUTATE_TAXONOMY]: (state, taxonomy) => {
        let index = state.taxonomies.findIndex(x => x.id == taxonomy.id);
        if (index != -1) {
            state.taxonomies.splice(index, 1, taxonomy);
            localStorage.setItem("taxonomies", JSON.stringify(state.taxonomies));
        }
        else {
            state.taxonomies.push(taxonomy);
            localStorage.setItem("taxonomies", JSON.stringify(state.taxonomies));
        }
    },
    [names.MUTATE_DELETE_TAXONOMY]: (state, taxonomy_id) => {
        let index = state.taxonomies.findIndex(x => x.id == taxonomy_id);
        if (index != -1) {
            state.taxonomies.splice(index, 1);
            localStorage.setItem("taxonomies", JSON.stringify(state.taxonomies));
        }
    },
    ["MUTATE_CHANGE_VERBS_BY_TAXONOMY"]: (state, payload) => {
        const index1 = state.taxonomies.findIndex(t => t.cognitive_levels.map(c => c.id).includes(payload.cognitive_level_id));
        if (index1 != -1) {
            const index2 = state.taxonomies[index1].cognitive_levels.findIndex(t => t.id == payload.cognitive_level_id)
            if (index2 != -1) {
                state.taxonomies[index1].cognitive_levels[index2].verbs = payload.verbs
                localStorage.setItem("taxonomies", JSON.stringify(state.taxonomies));
            }
        }
    },
};

const actions = {
    [names.FETCH_TAXONOMY]: ({
        commit,
        state
    }, taxonomy_id) => {
        return new Promise((resolve) => {
            const instance = state.taxonomies.find(x => x.id == taxonomy_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.taxonomies_url}/${taxonomy_id}/`)
                .then(response => {
                    commit(names.MUTATE_TAXONOMY, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_TAXONOMIES]: ({
        commit,
        state,
        // getters,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.taxonomies.length > 0) {
                resolve(state.taxonomies);
                return;
            }
            if (localStorage.getItem('taxonomies')) {
                let taxonomies = JSON.parse(localStorage.getItem('taxonomies'));
                if (taxonomies != null) {
                    taxonomies = taxonomies.filter((x) => x.school == get.store.state.user.school);
                    if (taxonomies.length > 0) {
                        commit(names.MUTATE_TAXONOMIES, taxonomies);
                        resolve(taxonomies);
                        return;
                    }
                }
            }
            restful.Get(`${state.taxonomies_url}/`)
                .then(response => {
                    commit(names.MUTATE_TAXONOMIES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_TAXONOMY]: ({
        commit,
        state
    }, taxonomy) => {
        return new Promise((resolve) => {
            restful.Post(`${state.taxonomies_url}/`, taxonomy)
                .then(response => {
                    commit(names.MUTATE_TAXONOMY, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_TAXONOMY]: ({
        commit,
        state
    }, taxonomy) => {
        return new Promise((resolve) => {
            restful.Put(`${state.taxonomies_url}/${taxonomy.id}/`, taxonomy)
                .then(response => {
                    commit(names.MUTATE_TAXONOMY, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_TAXONOMY]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.taxonomies_url}/${payload.taxonomy_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_TAXONOMY, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_TAXONOMY]: ({
        commit,
        state
    }, taxonomy_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.taxonomies_url}/${taxonomy_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_TAXONOMY, taxonomy_id);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};