import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    matter_contributions: [],
    matter_contributions_url: '/mesh/matter-contribution'
}

const getters = {
    [names.MATTER_CONTRIBUTIONS]: state => {
        if (!get.store.state.user) return [];
        return state.matter_contributions.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_MATTER_CONTRIBUTIONS]: (state, matter_contributions) => {
        matter_contributions.forEach(element => {
            let index = state.matter_contributions.findIndex(x => x.id == element.id);
            if (index != -1) state.matter_contributions.splice(index, 1, element);
            else state.matter_contributions.push(element);
        });
    },
    [names.MUTATE_MATTER_CONTRIBUTION]: (state, matter_contribution) => {
        let index = state.matter_contributions.findIndex(x => x.id == matter_contribution.id);
        if (index != -1) state.matter_contributions.splice(index, 1, matter_contribution);
        else state.matter_contributions.push(matter_contribution);
    },
    [names.MUTATE_DELETE_MATTER_CONTRIBUTION]: (state, matter_contribution_id) => {
        let index = state.matter_contributions.findIndex(x => x.id == matter_contribution_id);
        if (index != -1) state.matter_contributions.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_MATTER_CONTRIBUTION]: ({
        commit,
        state
    }, matter_contribution_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.matter_contributions_url}/${matter_contribution_id}/`)
                .then(response => {
                    commit(names.MUTATE_MATTER_CONTRIBUTION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_MATTER_CONTRIBUTIONS]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.matter_contributions.length > 0) {
                resolve(state.matter_contributions);
                return;
            }
            restful.Get(`${state.matter_contributions_url}/`)
                .then(response => {
                    commit(names.MUTATE_MATTER_CONTRIBUTIONS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_MATTER_CONTRIBUTION]: ({
        commit,
        state
    }, matter_contribution) => {
        return new Promise((resolve) => {
            restful.Post(`${state.matter_contributions_url}/`, matter_contribution)
                .then(response => {
                    commit(names.MUTATE_MATTER_CONTRIBUTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_MATTER_CONTRIBUTION]: ({
        commit,
        state
    }, matter_contribution) => {
        return new Promise((resolve) => {
            restful.Put(`${state.matter_contributions_url}/${matter_contribution.id}/`, matter_contribution)
                .then(response => {
                    commit(names.MUTATE_MATTER_CONTRIBUTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_MATTER_CONTRIBUTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.matter_contributions_url}/${payload.matter_contribution_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_MATTER_CONTRIBUTION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_MATTER_CONTRIBUTION]: ({
        commit,
        state
    }, matter_contribution_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.matter_contributions_url}/${matter_contribution_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_MATTER_CONTRIBUTION, matter_contribution_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};