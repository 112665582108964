import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    evaluation_feedbacks: [],
    evaluation_feedbacks_url: '/mesh/evaluation-feedback'
}

const getters = {
    [names.EVALUATION_FEEDBACKS]: state => {
        if (!get.store.state.user) return [];
        return state.evaluation_feedbacks.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_EVALUATION_FEEDBACKS]: (state, evaluation_feedbacks) => {
        evaluation_feedbacks.forEach(element => {
            let index = state.evaluation_feedbacks.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluation_feedbacks.splice(index, 1, element);
            else state.evaluation_feedbacks.push(element);
        });
    },
    [names.MUTATE_EVALUATION_FEEDBACK]: (state, evaluation_feedbacks) => {
        let index = state.evaluation_feedbacks.findIndex(x => x.id == evaluation_feedbacks.id);
        if (index != -1) state.evaluation_feedbacks.splice(index, 1, evaluation_feedbacks);
        else state.evaluation_feedbacks.push(evaluation_feedbacks);
    },
    [names.MUTATE_DELETE_EVALUATION_FEEDBACK]: (state, evaluation_feedbacks_id) => {
        let index = state.evaluation_feedbacks.findIndex(x => x.id == evaluation_feedbacks_id);
        if (index != -1) state.evaluation_feedbacks.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_EVALUATION_FEEDBACK]: ({
        commit,
        state
    }, evaluation_feedbacks_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.evaluation_feedbacks_url}/${evaluation_feedbacks_id}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_FEEDBACK, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATION_FEEDBACKS]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.evaluation_feedbacks.length > 0) {
                resolve(state.evaluation_feedbacks);
                return;
            }
            restful.Get(`${state.evaluation_feedbacks_url}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_FEEDBACKS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_EVALUATION_FEEDBACK]: ({
        commit,
        state
    }, evaluation_feedbacks) => {
        return new Promise((resolve) => {
            restful.Post(`${state.evaluation_feedbacks_url}/`, evaluation_feedbacks)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_FEEDBACK, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATION_FEEDBACK]: ({
        commit,
        state
    }, evaluation_feedbacks) => {
        return new Promise((resolve) => {
            restful.Put(`${state.evaluation_feedbacks_url}/${evaluation_feedbacks.id}/`, evaluation_feedbacks)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_FEEDBACK, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATION_FEEDBACK]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.evaluation_feedbacks_url}/${payload.evaluation_feedbacks_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_FEEDBACK, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATION_FEEDBACK]: ({
        commit,
        state
    }, evaluation_feedbacks_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.evaluation_feedbacks_url}/${evaluation_feedbacks_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EVALUATION_FEEDBACK, evaluation_feedbacks_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};