import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    study_plan_comments: [],
    study_plan_comments_url: '/mesh/study-plan-comment'
}

const getters = {
    [names.STUDY_PLAN_COMMENTS]: state => {
        if (!get.store.state.user) return [];
        return state.study_plan_comments.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_STUDY_PLAN_COMMENTS]: (state, study_plan_comments) => {
        study_plan_comments.forEach(element => {
            let index = state.study_plan_comments.findIndex(x => x.id == element.id);
            if (index != -1) state.study_plan_comments.splice(index, 1, element);
            else state.study_plan_comments.push(element);
        });
    },
    [names.MUTATE_STUDY_PLAN_COMMENT]: (state, study_plan_comment) => {
        let index = state.study_plan_comments.findIndex(x => x.id == study_plan_comment.id);
        if (index != -1) state.study_plan_comments.splice(index, 1, study_plan_comment);
        else state.study_plan_comments.push(study_plan_comment);
    },
    [names.MUTATE_DELETE_STUDY_PLAN_COMMENT]: (state, study_plan_comment_id) => {
        let index = state.study_plan_comments.findIndex(x => x.id == study_plan_comment_id);
        if (index != -1) state.study_plan_comments.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_STUDY_PLAN_COMMENT]: ({
        commit,
        state
    }, study_plan_comment_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.study_plan_comments_url}/${study_plan_comment_id}/`)
                .then(response => {
                    commit(names.MUTATE_STUDY_PLAN_COMMENT, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_STUDY_PLAN_COMMENTS]: ({
        commit,
        state
    }, egress_profile_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.study_plan_comments_url}/?egress_profile=${egress_profile_id}`)
                .then(response => {
                    commit(names.MUTATE_STUDY_PLAN_COMMENTS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_STUDY_PLAN_COMMENT]: ({
        commit,
        state
    }, study_plan_comment) => {
        return new Promise((resolve) => {
            restful.Post(`${state.study_plan_comments_url}/`, study_plan_comment)
                .then(response => {
                    commit(names.MUTATE_STUDY_PLAN_COMMENT, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_STUDY_PLAN_COMMENT]: ({
        commit,
        state
    }, study_plan_comment) => {
        return new Promise((resolve) => {
            restful.Put(`${state.study_plan_comments_url}/${study_plan_comment.id}/`, study_plan_comment)
                .then(response => {
                    commit(names.MUTATE_STUDY_PLAN_COMMENT, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_STUDY_PLAN_COMMENT]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.study_plan_comments_url}/${payload.study_plan_comment_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_STUDY_PLAN_COMMENT, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_STUDY_PLAN_COMMENT]: ({
        commit,
        state
    }, study_plan_comment_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.study_plan_comments_url}/${study_plan_comment_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_STUDY_PLAN_COMMENT, study_plan_comment_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};