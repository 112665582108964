import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    methodological_strategies: [],
    methodological_strategies_url: '/mesh/methodological-strategy'
}

const getters = {
    [names.METHODOLOGICAL_STRATEGIES]: state => {
        if (!get.store.state.user) return [];
        return state.methodological_strategies.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_METHODOLOGICAL_STRATEGIES]: (state, methodological_strategies) => {
        methodological_strategies.forEach(element => {
            let index = state.methodological_strategies.findIndex(x => x.id == element.id);
            if (index != -1) state.methodological_strategies.splice(index, 1, element);
            else state.methodological_strategies.push(element);
        });
    },
    [names.MUTATE_METHODOLOGICAL_STRATEGY]: (state, methodological_strategy) => {
        let index = state.methodological_strategies.findIndex(x => x.id == methodological_strategy.id);
        if (index != -1) state.methodological_strategies.splice(index, 1, methodological_strategy);
        else state.methodological_strategies.push(methodological_strategy);
    },
    [names.MUTATE_DELETE_METHODOLOGICAL_STRATEGY]: (state, methodological_strategy_id) => {
        let index = state.methodological_strategies.findIndex(x => x.id == methodological_strategy_id);
        if (index != -1) state.methodological_strategies.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_METHODOLOGICAL_STRATEGY]: ({
        commit,
        state
    }, methodological_strategy_id) => {
        return new Promise((resolve) => {
            const methodological_strategy = state.methodological_strategies.find(x => x.id == methodological_strategy_id)
            if (methodological_strategy) {
                resolve(methodological_strategy);
                return;
            } else
                restful.Get(`${state.methodological_strategies_url}/${methodological_strategy_id}/`)
                    .then(response => {
                        commit(names.MUTATE_METHODOLOGICAL_STRATEGY, response);
                        resolve(response);
                    });
        });
    },
    [names.FETCH_METHODOLOGICAL_STRATEGIES]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.methodological_strategies.length > 0) {
                resolve(state.methodological_strategies);
                return;
            }
            restful.Get(`${state.methodological_strategies_url}/`)
                .then(response => {
                    commit(names.MUTATE_METHODOLOGICAL_STRATEGIES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_METHODOLOGICAL_STRATEGY]: ({
        commit,
        state
    }, methodological_strategy) => {
        return new Promise((resolve) => {
            restful.Post(`${state.methodological_strategies_url}/`, methodological_strategy)
                .then(response => {
                    commit(names.MUTATE_METHODOLOGICAL_STRATEGY, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_METHODOLOGICAL_STRATEGY]: ({
        commit,
        state
    }, methodological_strategy) => {
        return new Promise((resolve) => {
            restful.Put(`${state.methodological_strategies_url}/${methodological_strategy.id}/`, methodological_strategy)
                .then(response => {
                    commit(names.MUTATE_METHODOLOGICAL_STRATEGY, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_METHODOLOGICAL_STRATEGY]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.methodological_strategies_url}/${payload.methodological_strategy_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_METHODOLOGICAL_STRATEGY, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_METHODOLOGICAL_STRATEGY]: ({
        commit,
        state
    }, methodological_strategy_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.methodological_strategies_url}/${methodological_strategy_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_METHODOLOGICAL_STRATEGY, methodological_strategy_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};