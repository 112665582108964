import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    modalities: [],
    modalities_url: '/mesh/modality',
    modalities_egress_profiles: [],
    modalities_egress_profiles_url: '/mesh/modality-egress-profile'
}

const getters = {
    [names.MODALITIES]: state => {
        if (!get.store.state.user) return [];
        return state.modalities.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.MODALITIES_EGRESS_PROFILE]: state => {
        if (!get.store.state.user) return [];
        return state.modalities_egress_profiles.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_MODALITIES]: (state, modalities) => {
        modalities.forEach(element => {
            let index = state.modalities.findIndex(x => x.id == element.id);
            if (index != -1) state.modalities.splice(index, 1, element);
            else state.modalities.push(element);
        });
    },
    [names.MUTATE_MODALITY]: (state, modality) => {
        let index = state.modalities.findIndex(x => x.id == modality.id);
        if (index != -1) state.modalities.splice(index, 1, modality);
        else state.modalities.push(modality);
    },
    [names.MUTATE_DELETE_MODALITY]: (state, modality_id) => {
        let index = state.modalities.findIndex(x => x.id == modality_id);
        if (index != -1) state.modalities.splice(index, 1);
    },
    [names.MUTATE_MODALITIES_EGRESS_PROFILE]: (state, modalities_egress_profiles) => {
        modalities_egress_profiles.forEach(element => {
            let index = state.modalities_egress_profiles.findIndex(x => x.id == element.id);
            if (index != -1) state.modalities_egress_profiles.splice(index, 1, element);
            else state.modalities_egress_profiles.push(element);
        });
    },
    [names.MUTATE_MODALITY_EGRESS_PROFILE]: (state, modality) => {
        let index = state.modalities_egress_profiles.findIndex(x => x.id == modality.id);
        if (index != -1) state.modalities_egress_profiles.splice(index, 1, modality);
        else state.modalities_egress_profiles.push(modality);
    },
    [names.MUTATE_DELETE_MODALITY_EGRESS_PROFILE]: (state, modality_id) => {
        let index = state.modalities_egress_profiles.findIndex(x => x.id == modality_id);
        if (index != -1) state.modalities_egress_profiles.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_MODALITY]: ({
        commit,
        state
    }, modality_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.modalities_url}/${modality_id}/`)
                .then(response => {
                    commit(names.MUTATE_MODALITY, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_MODALITIES]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.modalities.length > 0) {
                resolve(state.modalities);
                return;
            }
            restful.Get(`${state.modalities_url}/`)
                .then(response => {
                    commit(names.MUTATE_MODALITIES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_MODALITY]: ({
        commit,
        state
    }, modality) => {
        return new Promise((resolve) => {
            restful.Post(`${state.modalities_url}/`, modality)
                .then(response => {
                    commit(names.MUTATE_MODALITY, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_MODALITY]: ({
        commit,
        state
    }, modality) => {
        return new Promise((resolve) => {
            restful.Put(`${state.modalities_url}/${modality.id}/`, modality)
                .then(response => {
                    commit(names.MUTATE_MODALITY, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_MODALITY]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.modalities_url}/${payload.modality_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_MODALITY, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_MODALITY]: ({
        commit,
        state
    }, modality_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.modalities_url}/${modality_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_MODALITY, modality_id);
                    resolve(response);
                });
        });
    },
    [names.FETCH_MODALITY_EGRESS_PROFILE]: ({
        commit,
        state
    }, modality_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.modalities_egress_profiles_url}/${modality_id}/`)
                .then(response => {
                    commit(names.MUTATE_MODALITY_EGRESS_PROFILE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_MODALITIES_EGRESS_PROFILE]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.modalities_egress_profiles.length > 0) {
                resolve(state.modalities_egress_profiles);
                return;
            }
            restful.Get(`${state.modalities_egress_profiles_url}/`)
                .then(response => {
                    commit(names.MUTATE_MODALITIES_EGRESS_PROFILE, response);
                    resolve(response);
                });
        });
    },
    [names.POST_MODALITY_EGRESS_PROFILE]: ({
        commit,
        state
    }, modality) => {
        return new Promise((resolve) => {
            restful.Post(`${state.modalities_egress_profiles_url}/`, modality)
                .then(response => {
                    commit(names.MUTATE_MODALITY_EGRESS_PROFILE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_MODALITY_EGRESS_PROFILE]: ({
        commit,
        state
    }, modality) => {
        return new Promise((resolve) => {
            restful.Put(`${state.modalities_egress_profiles_url}/${modality.id}/`, modality)
                .then(response => {
                    commit(names.MUTATE_MODALITY_EGRESS_PROFILE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_MODALITY_EGRESS_PROFILE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.modalities_egress_profiles_url}/${payload.id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_MODALITY_EGRESS_PROFILE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_MODALITY_EGRESS_PROFILE]: ({
        commit,
        state
    }, modality_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.modalities_egress_profiles_url}/${modality_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_MODALITY_EGRESS_PROFILE, modality_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};