// src/filters/filters.js

import Vue from 'vue';
import moment from 'moment-timezone';

moment.tz.setDefault('America/Santiago');
require('moment/locale/es');


Vue.filter('toDateTime', function (date) {
    if (!date) return "";
    return moment(date).format("YYYY-MM-DD HH:mm");
});

Vue.filter('toDate', function (date) {
    if (!date) return "";
    return moment(date).format("YYYY-MM-DD");
});

Vue.filter('Round', function (number) {
    return +(Math.round(Number(number) + "e+2") + "e-2");
});

Vue.filter('FormatToDate', function (date) {
    if (!date) return "";
    return moment(date).format("DD/MM/YYYY");
});

Vue.filter('FormatToTime', function (date) {
    if (!date) return "";
    return moment(date).format("HH:mm");
});

Vue.filter('FormatToYear', function (date) {
    if (!date) return "";
    return moment(date).format("YYYY");
});

Vue.filter('FormatToDateTime', function (date) {
    if (!date) return "";
    return moment(date).format("DD/MM/YYYY HH:mm");
});
Vue.filter('FormatToTimeDate', function (date) {
    if (!date) return "";
    return moment(date).format("HH:mm DD/MM/YYYY");
});

Vue.filter('FormatToFullDateEsp', function (date) {
    if (!date) return "";
    return moment(date).format('dddd, D [de] MMMM [de] YYYY');
});

