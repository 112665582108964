<template>
  <div>
    <footer
      id="footer-component"
      class="noprint footer"
      :class="{ 'custom-height': app_name == 'KEY LEARNING' }"
    >
      <div class="footer-container">
        <!-- <div class="row">
          <div class="col-sm" style="text-align: start;">
            <a href="http://www.pronosworld.com/pronosworld/home.html">¿QUIÉNES SOMOS?</a>
            <hr />
            <div style="line-height: 10px;">
              <div class="col">
                <span style="line-height:22px;">
                  PronosWorld es una consultora dedicada a la asesoría y capacitación en las áreas educacional, empresarial e industrial.
                  Editora y comercializadora de libros y materiales digitales.
                </span>
              </div>
            </div>
          </div>

          <div class="col-sm" style="text-align: start;"><b>
            SANTIAGO DE CHILE</b>
            <hr />
            <div >
              <div class="col">
                <unicon name="location-point" fill="#24a6c4"></unicon>
                <span
                  style="line-height:22px; "
                >Av. Américo Vespucio Sur #107 Oficina 207. Las Condes. Santiago.</span>
              </div>

              <div class="col">
                <unicon name="phone" fill="#24a6c4"></unicon>
                <span>+56 (2) 2 321 3350</span>
              </div>
              <div class="col">
                <b-icon icon="envelope" animation="pulse" color="#24a6c4"></b-icon>
                <span>info@pronos.cl</span>
              </div>
              <div class="col">
                <unicon name="postcard" fill="#24a6c4"></unicon>
                <span>+56 (2) 2340 1299</span>
              </div>
            </div>
          </div>
          <div class="col-sm" style="text-align: start;">
            <b>
            MIAMI
            </b>
            <hr />
            <div>
              <div class="col">
                <unicon name="location-point" fill="#5ec2d9"></unicon>
                <span
                  style="line-height:22px;"
                >8980 W. Flagler St. Depto. 209. Miami. Florida. CP 33174</span>
              </div>
              <div class="col">
                <unicon name="phone" fill="#5ec2d9"></unicon>
                <span>305 552 7045</span>
              </div>
              <div class="col">
                <b-icon icon="envelope" animation="pulse" color="#5ec2d9"></b-icon>
                <span>info@pronosworld.com</span>
              </div>
            </div>
            
          </div>
        
        </div>  -->
        <section v-if="app_name == 'KEY LEARNING' && !isLoged">
          <div class="footer-widgets mt-1">
            <div class="footer-contact-text">
              <img
                class="kl-white-logo"
                width="300"
                height="72"
                src="@/assets/logo_blanco_pequenno.png"
                alt=""
              />
              <h6>
                Av. Américo Vespucio Sur #107, of. 207, Las Condes, Santiago.
              </h6>
              <h6>+56(2) 2321 3350 / +56(9) 7708 1206</h6>
              <h6>
                <a
                  href="mailto:keylearningspa@gmail.com?Subject=Deseo%20solicitar%20un%20demo%20de%20la%20plataforma."
                  >keylearningspa@gmail.com</a
                >
              </h6>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.29149792493!2d-70.58645458509271!3d-33.41564390311228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf184f4b3fdd%3A0x29ef29eff8bdb9b5!2sAv.%20Am%C3%A9rico%20Vespucio%20Sur%20107%2C%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses!2scl!4v1616698714194!5m2!1ses!2scl"
              width="400"
              height="300"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
            <div class="footer-nav">
              <h5 class="text-left ml-4 mt-2">Sitio</h5>
              <ul>
                <li class="footer-nav-item">
                  <router-link :to="{ name: 'About' }"
                    ><b-icon icon="chevron-right" class="mr-1"></b-icon>Quiénes
                    Somos</router-link
                  >
                </li>
                <li class="ml-3 mt-1" style="font-size: 17px">
                  Servicios Complementarios
                  <ul>
                    <li class="footer-nav-item">
                      <router-link :to="{ name: 'Consultancy' }"
                        ><b-icon icon="chevron-right" class="mr-1"></b-icon
                        >Asesorías</router-link
                      >
                    </li>
                    <li class="footer-nav-item">
                      <router-link :to="{ name: 'Training' }"
                        ><b-icon icon="chevron-right" class="mr-1"></b-icon
                        >Capacitación</router-link
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <div
          class="fixed-copyright"
          :class="{ copyright: app_name == 'KEY LEARNING' }"
        >
          Copyright © KEY LEARNING SPA 2021. Todos los derechos reservados.
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { APP_NAME } from "@/utils/globals";
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  data() {
    return {
      app_name: APP_NAME,
    };
  },
  computed: {
    ...mapGetters({
      isLoged: "isLoged",
    }),
  },
};
</script>

<style scoped>
.footer-container {
  margin: 1% 5%;
}
.footer-widgets {
  display: flex;
}
.footer-contact-text {
  width: 300px;
  color: #14aab6;
  margin-top: 1%;
  margin-right: 5.5%;
}
.footer-nav {
  margin-left: 1%;
  min-width: 250px;
}
.footer-nav-item {
  font-size: 16px;
}
ul li {
  list-style-type: none;
  text-align: left;
  margin-left: 0;
}
li a {
  color: #fff;
  padding: 4px 8px;
  border-radius: 10px;
  transition: 0.15s;
}
.kl-white-logo {
  margin-top: -5%;
  margin-bottom: 7%;
}
li a:hover {
  text-decoration: none;
  color: #fff;
  background-color: rgb(59, 59, 59);
  transition: 0.15s;
}

.footer {
  position: fixed;
  bottom: 0;
  z-index: 1;
  background: rgb(75, 75, 75);
  width: 100%;
  height: 100px;
  display: flex;
  color: #c3c3c3;
  padding-left: 10%;
  padding-top: 1%;
  line-height: 30px;
  font-size: 15px;
  transition: all 0.3s;
}
.footer h1 {
  font-size: 5em;
}

.footer.active {
  width: calc(100% + 250px);
}

a {
  color: #c3c3c3;
  font-weight: bold;
}

.copyright {
  justify-content: center;
  text-align: center;
  margin-top: 25px;
  margin-left: 2%;
}

.fixed-copyright {
  position: fixed;
  width: 100%;
  bottom: 0;
  margin-bottom: 1%;
  left: 0;
}

.custom-height {
  height: 400px !important;
}

.hide-info {
  display: none;
}
@media only screen and (min-width: 1800px) {
  .footer {
    padding-left: 20vw;
  }
}
@media only screen and (min-width: 1600px) {
  .footer {
    padding-left: 18vw;
  }
}

@media only screen and (max-width: 1268px) {
  .footer {
    padding-left: 5%;
    font-size: 13px;
  }
  .copyright {
    margin-left: 2%;
  }
}

@media only screen and (min-width: 844px) and (max-width: 1025px) {
  .footer {
    padding-left: 4%;
    padding-top: 10%;
    font-size: 13px;
  }
  .footer-contact-text {
    margin-left: 10%;
    max-width: 200px;
  }
  .kl-white-logo {
    width: 200px;
    height: 50px;
  }
  iframe {
    width: 250px;
    height: 200px;
  }
  .copyright {
    margin-left: 2%;
  }
}

@media only screen and (max-width: 844px) {
  .footer {
    padding-left: 1%;
    padding-top: 5%;
    font-size: 12px;
  }
  .footer-contact-text {
    margin-left: 1%;
    max-width: 180px;
  }
  .kl-white-logo {
    width: 180px;
    height: 45px;
  }
  iframe {
    width: 250px;
    height: 200px;
  }
}
</style>