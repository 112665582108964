import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    rubric_instruments: [],
    rubric_instruments_url: '/evaluations2/rubric',
    rubric_presets: [],
    rubric_presets_url: '/evaluations2/rubric-preset',
    rubric_preset_units: [],
    rubric_preset_units_url: '/evaluations2/rubric-preset-unit',
    rubric_achievements: [],
    rubric_achievement_url: '/evaluations2/rubric-performance-level',
    rubric_achievement_descriptors: [],
    rubric_achievement_descriptors_url: '/evaluations2/rubric-criteria-performance-level',
    rubric_instances: [],
    rubric_instances_url: '/evaluations2/rubric-criteria',
};

const getters = {
    [names.NEW_RUBRIC_INSTRUMENTS]: state => {
        if (!get.store.state.user) return [];
        return state.rubric_instruments.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.RUBRIC_PRESETS]: state => {
        if (!get.store.state.user) return [];
        return state.rubric_presets.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.order - b.order);
    },
    [names.RUBRIC_PRESET_UNITS]: state => {
        if (!get.store.state.user) return [];
        return state.rubric_preset_units.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.order - b.order);
    },
    [names.NEW_RUBRIC_CRITERIAS]: state => {
        if (!get.store.state.user) return [];
        return state.rubric_instances.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.order - b.order);
    },
    [names.NEW_RUBRIC_ACHIEVEMENTS]: state => {
        if (!get.store.state.user) return [];
        return state.rubric_achievements.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.order - b.order);
    },
    [names.NEW_RUBRIC_DESCRIPTORS]: state => {
        if (!get.store.state.user) return [];
        return state.rubric_achievement_descriptors.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
};

const mutations = {
    [names.MUTATE_NEW_RUBRIC_INSTRUMENT]: (state, rubric_instrument) => {
        let index = state.rubric_instruments.findIndex(x => x.id == rubric_instrument.id);
        if (index != -1) state.rubric_instruments.splice(index, 1, rubric_instrument);
        else state.rubric_instruments.push(rubric_instrument);
    },
    [names.MUTATE_DELETE_NEW_RUBRIC_INSTRUMENT]: (state, rubric_instrument_id) => {
        let index = state.rubric_instruments.findIndex(x => x.id == rubric_instrument_id);
        if (index != -1) state.rubric_instruments.splice(index, 1);
    },
    [names.MUTATE_RUBRIC_PRESETS]: (state, rubric_presets) => {
        rubric_presets.forEach(element => {
            let index = state.rubric_presets.findIndex(x => x.id == element.id);
            if (index != -1) state.rubric_presets.splice(index, 1, element);
            else state.rubric_presets.push(element);
        });
    },
    [names.MUTATE_RUBRIC_PRESET]: (state, rubric_preset) => {
        let index = state.rubric_presets.findIndex(x => x.id == rubric_preset.id);
        if (index != -1) state.rubric_presets.splice(index, 1, rubric_preset);
        else state.rubric_presets.push(rubric_preset);
    },
    [names.MUTATE_DELETE_RUBRIC_PRESET]: (state, rubric_preset_id) => {
        let index = state.rubric_presets.findIndex(x => x.id == rubric_preset_id);
        if (index != -1) state.rubric_presets.splice(index, 1);
    },
    ["MUTATE_DELETE_RUBRIC_PRESET_UNIT_BY_DELETE_RUBRIC_PRESET"]: (state, rubric_preset_unit_id) => {
        let index = state.rubric_preset_units.findIndex(x => x.rubric_preset == rubric_preset_unit_id);
        if (index != -1) {
            state.rubric_preset_units.splice(index, 1);
            index = state.rubric_preset_units.findIndex(x => x.rubric_preset == rubric_preset_unit_id);
        }
        let index2 = state.rubric_instruments.findIndex(x => x.type == rubric_preset_unit_id);
        if (index2 != -1) {
            state.rubric_instruments[index2].rubric_type_default = null
            state.rubric_instruments[index2].type = null
        }
    },
    [names.MUTATE_RUBRIC_PRESET_UNITS]: (state, rubric_preset_units) => {
        rubric_preset_units.forEach(element => {
            let index = state.rubric_preset_units.findIndex(x => x.id == element.id);
            if (index != -1) state.rubric_preset_units.splice(index, 1, element);
            else state.rubric_preset_units.push(element);
        });
    },
    [names.MUTATE_RUBRIC_PRESET_UNIT]: (state, rubric_preset_unit) => {
        let index = state.rubric_preset_units.findIndex(x => x.id == rubric_preset_unit.id);
        if (index != -1) state.rubric_preset_units.splice(index, 1, rubric_preset_unit);
        else state.rubric_preset_units.push(rubric_preset_unit);
    },
    [names.MUTATE_DELETE_RUBRIC_PRESET_UNIT]: (state, rubric_preset_unit_id) => {
        let index = state.rubric_preset_units.findIndex(x => x.id == rubric_preset_unit_id);
        if (index != -1) state.rubric_preset_units.splice(index, 1);
    },
    ["MUTATE_CHANGE_NEW_RUBRIC_TYPE_BY_NEW_RUBRIC"]: (state, rubric_preset_unit) => {
        const index = state.rubric_instruments.findIndex(x => x.id == rubric_preset_unit.rubric);
        if (index != -1) {
            state.rubric_instruments[index].rubric_type_default = rubric_preset_unit.id
            state.rubric_instruments[index].preset = rubric_preset_unit.preset
        }
    },
    [names.MUTATE_NEW_RUBRIC_ACHIEVEMENT]: (state, rubric_achievement) => {
        let index = state.rubric_achievements.findIndex(x => x.id == rubric_achievement.id);
        if (index != -1) state.rubric_achievements.splice(index, 1, rubric_achievement);
        else state.rubric_achievements.push(rubric_achievement);
    },
    [names.MUTATE_DELETE_NEW_RUBRIC_ACHIEVEMENT]: (state, rubric_achievement_id) => {
        let index = state.rubric_achievements.findIndex(x => x.id == rubric_achievement_id);
        if (index != -1) state.rubric_achievements.splice(index, 1);
    },
    [names.MUTATE_NEW_RUBRIC_CRITERIA]: (state, rubric_instance) => {
        let index = state.rubric_instances.findIndex(x => x.id == rubric_instance.id);
        if (index != -1) state.rubric_instances.splice(index, 1, rubric_instance);
        else state.rubric_instances.push(rubric_instance);
    },
    [names.MUTATE_DELETE_NEW_RUBRIC_CRITERIA]: (state, rubric_instance_id) => {
        let index = state.rubric_instances.findIndex(x => x.id == rubric_instance_id);
        if (index != -1) state.rubric_instances.splice(index, 1);
    },
    [names.MUTATE_NEW_RUBRIC_DESCRIPTOR]: (state, achievement_descriptor) => {
        let index = state.rubric_achievement_descriptors.findIndex(x => x.id == achievement_descriptor.id);
        if (index != -1) state.rubric_achievement_descriptors.splice(index, 1, achievement_descriptor);
        else state.rubric_achievement_descriptors.push(achievement_descriptor);
    },
    [names.MUTATE_DELETE_NEW_RUBRIC_DESCRIPTOR]: (state, achievement_descriptor_id) => {
        let index = state.rubric_achievement_descriptors.findIndex(x => x.id == achievement_descriptor_id);
        if (index != -1) state.rubric_achievement_descriptors.splice(index, 1);
    },
};

const actions = {
    [names.FETCH_NEW_RUBRIC_INSTRUMENT]: ({
        commit,
        dispatch,
        state
    }, rubric_instrument_id) => {
        return new Promise((resolve) => {
            const rubric_instrument = state.rubric_instruments.find(x => x.id == rubric_instrument_id);
            if (rubric_instrument) {
                resolve(rubric_instrument);
                return;
            }
            restful.Get(`${state.rubric_instruments_url}/${rubric_instrument_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_RUBRIC_INSTRUMENT, response);
                    dispatch(names.FETCH_NEW_RUBRIC_ACHIEVEMENTS, response.id);
                    dispatch(names.FETCH_NEW_RUBRIC_CRITERIAS, response.id);
                    dispatch(names.FETCH_NEW_RUBRIC_DESCRIPTORS, response.id);
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_RUBRIC_INSTRUMENTS]: ({
        commit,
        dispatch,
        state
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.test_id) url_append += '&test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.rubric_instruments_url}/${url_append}`)
                .then(response => {
                    response.results.forEach(element => {
                        commit(names.MUTATE_NEW_RUBRIC_INSTRUMENT, element);
                        dispatch(names.FETCH_NEW_RUBRIC_ACHIEVEMENTS, element.id);
                        dispatch(names.FETCH_NEW_RUBRIC_CRITERIAS, element.id);
                        dispatch(names.FETCH_NEW_RUBRIC_DESCRIPTORS, element.id);
                    });
                    resolve(response.results);
                });
        });
    },
    [names.POST_NEW_RUBRIC_INSTRUMENT]: ({
        commit,
        state
    }, rubric_instrument) => {
        return new Promise((resolve) => {
            restful.Post(`${state.rubric_instruments_url}/`, rubric_instrument)
                .then(response => {
                    commit(names.MUTATE_NEW_RUBRIC_INSTRUMENT, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_RUBRIC_INSTRUMENT]: ({
        commit,
        state
    }, rubric_instrument) => {
        return new Promise((resolve) => {
            restful.Put(`${state.rubric_instruments_url}/${rubric_instrument.id}/`, rubric_instrument)
                .then(response => {
                    commit(names.MUTATE_NEW_RUBRIC_INSTRUMENT, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_RUBRIC_INSTRUMENT]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.rubric_instruments_url}/${payload.rubric_instrument_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_RUBRIC_INSTRUMENT, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_RUBRIC_INSTRUMENT]: ({
        commit,
        state
    }, rubric_instrument_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.rubric_instruments_url}/${rubric_instrument_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_NEW_RUBRIC_INSTRUMENT, rubric_instrument_id);
                    resolve(response);
                });
        });
    },
    // New RUBRIC_PRESETS
    [names.FETCH_RUBRIC_PRESETS]: ({
        commit,
        state
    },) => {
        return new Promise((resolve) => {
            if (state.rubric_presets.length > 0) {
                resolve(state.rubric_presets);
                return;
            }
            restful.Get(`${state.rubric_presets_url}/?all_data=True`)
                .then(response => {
                    commit(names.MUTATE_RUBRIC_PRESETS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_RUBRIC_PRESET]: ({
        commit,
        state
    }, rubric_preset) => {
        return new Promise((resolve) => {
            restful.Post(`${state.rubric_presets_url}/`, rubric_preset)
                .then(response => {
                    commit(names.MUTATE_RUBRIC_PRESET, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_RUBRIC_PRESET]: ({
        commit,
        state
    }, rubric_preset) => {
        return new Promise((resolve) => {
            restful.Put(`${state.rubric_presets_url}/${rubric_preset.id}/`, rubric_preset)
                .then(response => {
                    commit(names.MUTATE_RUBRIC_PRESET, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_RUBRIC_PRESET]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.rubric_presets_url}/${payload.rubric_preset_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_RUBRIC_PRESET, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_RUBRIC_PRESET]: ({
        commit,
        state
    }, rubric_preset_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.rubric_presets_url}/${rubric_preset_id}/`)
                .then(response => {
                    commit("MUTATE_DELETE_RUBRIC_PRESET_UNIT_BY_DELETE_RUBRIC_PRESET", rubric_preset_id);
                    commit(names.MUTATE_DELETE_RUBRIC_PRESET, rubric_preset_id);
                    resolve(response);
                });
        });
    },
    // New RUBRIC_PRESET_UNIT
    [names.FETCH_RUBRIC_PRESET_UNITS]: ({
        commit,
        state
    },) => {
        return new Promise((resolve) => {
            if (state.rubric_preset_units.length > 0) {
                resolve(state.rubric_preset_units);
                return;
            }
            restful.Get(`${state.rubric_preset_units_url}/?all_data=True`)
                .then(response => {
                    commit(names.MUTATE_RUBRIC_PRESET_UNITS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_RUBRIC_PRESET_UNIT]: ({
        commit,
        state
    }, rubric_preset_unit) => {
        return new Promise((resolve) => {
            restful.Post(`${state.rubric_preset_units_url}/`, rubric_preset_unit)
                .then(response => {
                    commit(names.MUTATE_RUBRIC_PRESET_UNIT, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_RUBRIC_PRESET_UNIT]: ({
        commit,
        state
    }, rubric_preset_unit) => {
        return new Promise((resolve) => {
            restful.Put(`${state.rubric_preset_units_url}/${rubric_preset_unit.id}/`, rubric_preset_unit)
                .then(response => {
                    commit(names.MUTATE_RUBRIC_PRESET_UNIT, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_RUBRIC_PRESET_UNIT]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.rubric_preset_units_url}/${payload.rubric_preset_unit_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_RUBRIC_PRESET_UNIT, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_RUBRIC_PRESET_UNIT]: ({
        commit,
        state
    }, rubric_preset_unit_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.rubric_preset_units_url}/${rubric_preset_unit_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_RUBRIC_PRESET_UNIT, rubric_preset_unit_id);
                    resolve(response);
                });
        });
    },
    // NEW_RUBRIC_ACHIEVEMENTS
    [names.FETCH_NEW_RUBRIC_ACHIEVEMENT]: ({
        commit,
        state
    }, rubric_achievement_id) => {
        return new Promise((resolve) => {
            const rubric_achievement = state.rubric_achievements.find(x => x.id == rubric_achievement_id);
            if (rubric_achievement) {
                resolve(rubric_achievement);
                return;
            }
            restful.Get(`${state.rubric_achievement_url}/${rubric_achievement_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_RUBRIC_ACHIEVEMENT, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_RUBRIC_ACHIEVEMENTS]: ({
        commit,
        state
    }, rubric_instrument_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.rubric_achievement_url}/?all_data=True&rubric__id=${rubric_instrument_id}`)
                .then(response => {
                    response.results.forEach(element => {
                        commit(names.MUTATE_NEW_RUBRIC_ACHIEVEMENT, element);
                    });
                    resolve(response.results);
                });
        });
    },
    [names.POST_NEW_RUBRIC_ACHIEVEMENT]: ({
        commit,
        state
    }, rubric_achievement) => {
        return new Promise((resolve) => {
            restful.Post(`${state.rubric_achievement_url}/`, rubric_achievement)
                .then(response => {
                    commit(names.MUTATE_NEW_RUBRIC_ACHIEVEMENT, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_RUBRIC_ACHIEVEMENT]: ({
        commit,
        state
    }, rubric_achievement) => {
        return new Promise((resolve) => {
            restful.Put(`${state.rubric_achievement_url}/${rubric_achievement.id}/`, rubric_achievement)
                .then(response => {
                    commit(names.MUTATE_NEW_RUBRIC_ACHIEVEMENT, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_RUBRIC_ACHIEVEMENT]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.rubric_achievement_url}/${payload.rubric_achievement_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_RUBRIC_ACHIEVEMENT, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_RUBRIC_ACHIEVEMENT]: ({
        commit,
        state
    }, rubric_achievement_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.rubric_achievement_url}/${rubric_achievement_id}/`)
                .then(() => {
                    commit(names.MUTATE_DELETE_NEW_RUBRIC_ACHIEVEMENT, rubric_achievement_id);
                    resolve(rubric_achievement_id);
                });
        });
    },
    // NEW_RUBRIC_CRITERIAS
    [names.FETCH_NEW_RUBRIC_CRITERIA]: ({
        commit,
        state
    }, rubric_instance_id) => {
        return new Promise((resolve) => {
            const instance = state.rubric_instances.find(x => x.id == rubric_instance_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.rubric_instances_url}/${rubric_instance_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_RUBRIC_CRITERIA, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_RUBRIC_CRITERIAS]: ({
        commit,
        state
    }, rubric_instrument_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.rubric_instances_url}/?all_data=True&rubric__id=${rubric_instrument_id}`)
                .then(response => {
                    response.results.forEach(element => {
                        commit(names.MUTATE_NEW_RUBRIC_CRITERIA, element);
                    });
                    resolve(response.results);
                });
        });
    },
    [names.POST_NEW_RUBRIC_CRITERIA]: ({
        commit,
        state
    }, rubric_instance) => {
        return new Promise((resolve) => {
            restful.Post(`${state.rubric_instances_url}/`, rubric_instance)
                .then(response => {
                    commit(names.MUTATE_NEW_RUBRIC_CRITERIA, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_RUBRIC_CRITERIA]: ({
        commit,
        state
    }, rubric_instance) => {
        return new Promise((resolve) => {
            restful.Put(`${state.rubric_instances_url}/${rubric_instance.id}/`, rubric_instance)
                .then(response => {
                    commit(names.MUTATE_NEW_RUBRIC_CRITERIA, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_RUBRIC_CRITERIA]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.rubric_instances_url}/${payload.rubric_instance_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_RUBRIC_CRITERIA, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_RUBRIC_CRITERIA]: ({
        commit,
        state
    }, rubric_instance_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.rubric_instances_url}/${rubric_instance_id}/`)
                .then(() => {
                    commit(names.MUTATE_DELETE_NEW_RUBRIC_CRITERIA, rubric_instance_id);
                    resolve(rubric_instance_id);
                });
        });
    },
    // Descriptors
    [names.FETCH_NEW_RUBRIC_DESCRIPTOR]: ({
        commit,
        state
    }, rubric_descriptor_id) => {
        return new Promise((resolve) => {
            const instance = state.rubric_achievement_descriptors.find(x => x.id == rubric_descriptor_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.rubric_achievement_descriptors_url}/${rubric_descriptor_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_RUBRIC_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_RUBRIC_DESCRIPTORS]: ({
        commit,
        state
    }, rubric_instance_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.rubric_achievement_descriptors_url}/?all_data=True&rubric_criteria__rubric__id=${rubric_instance_id}`)
                .then(response => {
                    response.results.forEach(element => {
                        commit(names.MUTATE_NEW_RUBRIC_DESCRIPTOR, element);
                    });
                    resolve(response.results);
                });
        });
    },
    [names.FETCH_NEW_RUBRIC_DESCRIPTORS_BY]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve,) => {
            const instance = state.rubric_achievement_descriptors.find(
                (x) => x.rubric_criteria == payload.rubric_criteria_id &&
                    x.rubric_performance_level == payload.rubric_achievement_id);
            if (instance) {
                return resolve(instance);
            }
            restful.Get(`${state.rubric_achievement_descriptors_url}/?rubric_criteria__id=${payload.rubric_criteria_id}&rubric_performance_level__id=${payload.rubric_achievement_id}`)
                .then(response => {
                    response.results.forEach(element => {
                        commit(names.MUTATE_NEW_RUBRIC_DESCRIPTOR, element);
                    });
                    if (response.results.length > 0) {
                        resolve(response.results[0]);
                    } else resolve(null)
                });
        });
    },
    [names.POST_NEW_RUBRIC_DESCRIPTOR]: ({
        commit,
        state
    }, rubric_descriptor) => {
        return new Promise((resolve) => {
            restful.Post(`${state.rubric_achievement_descriptors_url}/`, rubric_descriptor)
                .then(response => {
                    commit(names.MUTATE_NEW_RUBRIC_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_RUBRIC_DESCRIPTOR]: ({
        commit,
        state
    }, rubric_descriptor) => {
        return new Promise((resolve) => {
            restful.Put(`${state.rubric_achievement_descriptors_url}/${rubric_descriptor.id}/`, rubric_descriptor)
                .then(response => {
                    commit(names.MUTATE_NEW_RUBRIC_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_RUBRIC_DESCRIPTOR]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.rubric_achievement_descriptors_url}/${payload.rubric_descriptor_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_RUBRIC_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_RUBRIC_DESCRIPTOR]: ({
        commit,
        state
    }, rubric_descriptor_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.rubric_achievement_descriptors_url}/${rubric_descriptor_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_NEW_RUBRIC_DESCRIPTOR, rubric_descriptor_id);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};