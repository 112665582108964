import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    profile_types: [],
    profile_types_url: '/mesh/egress-profile-type'
}

const getters = {
    [names.PROFILE_TYPES]: state => {
        if (!get.store.state.user) return [];
        return state.profile_types.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_PROFILE_TYPES]: (state, profile_types) => {
        profile_types.forEach(element => {
            let index = state.profile_types.findIndex(x => x.id == element.id);
            if (index != -1) state.profile_types.splice(index, 1, element);
            else state.profile_types.push(element);
        });
    },
    [names.MUTATE_PROFILE_TYPE]: (state, profile_types) => {
        let index = state.profile_types.findIndex(x => x.id == profile_types.id);
        if (index != -1) state.profile_types.splice(index, 1, profile_types);
        else state.profile_types.push(profile_types);
    },
    [names.MUTATE_DELETE_PROFILE_TYPE]: (state, profile_type_id) => {
        let index = state.profile_types.findIndex(x => x.id == profile_type_id);
        if (index != -1) state.profile_types.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_PROFILE_TYPE]: ({
        commit,
        state
    }, profile_type_id) => {
        return new Promise((resolve) => {
            const profile_types = state.profile_types.find(x => x.id == profile_type_id);
            if (profile_types) {
                resolve(profile_types);
                return;
            }
            restful.Get(`${state.profile_types_url}/${profile_type_id}/`)
                .then(response => {
                    commit(names.MUTATE_PROFILE_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_PROFILE_TYPES]: ({
        commit,
        state
    }) => {
        return new Promise((resolve) => {
            restful.Get(`${state.profile_types_url}/`)
                .then(response => {
                    commit(names.MUTATE_PROFILE_TYPES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_PROFILE_TYPE]: ({
        commit,
        state
    }, profile_types) => {
        return new Promise((resolve) => {
            restful.Post(`${state.profile_types_url}/`, profile_types)
                .then(response => {
                    commit(names.MUTATE_PROFILE_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_PROFILE_TYPE]: ({
        commit,
        state
    }, profile_type) => {
        return new Promise((resolve) => {
            restful.Put(`${state.profile_types_url}/${profile_type.id}/`, profile_type)
                .then(response => {
                    commit(names.MUTATE_PROFILE_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_PROFILE_TYPE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.profile_types_url}/${payload.profile_type_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_PROFILE_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_PROFILE_TYPE]: ({
        commit,
        state
    }, profile_type_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.profile_types_url}/${profile_type_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_PROFILE_TYPE, profile_type_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};