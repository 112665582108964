import restful from '@/utils/restful';
import * as names from '@/store/names';

const state = {
    evaluation_instruments: [],
    evaluation_instruments_url: '/teaching/evaluation-types/'
}

const getters = {
    [names.EVALUATION_INSTRUMENTS]: state => {
        //TODO: No cuenta con school dado que es un Choices estático en el back
        return state.evaluation_instruments;
    },
}

const mutations = {
    [names.MUTATE_EVALUATION_INSTRUMENTS]: (state, evaluation_instruments) => {
        evaluation_instruments.forEach(element => {
            let index = state.evaluation_instruments.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluation_instruments.splice(index, 1, element);
            else state.evaluation_instruments.push(element);
        });
    },
    [names.MUTATE_EVALUATION_INSTRUMENT]: (state, evaluation_instrument) => {
        let index = state.evaluation_instruments.findIndex(x => x.id == evaluation_instrument.id);
        if (index != -1) state.evaluation_instruments.splice(index, 1, evaluation_instrument);
        else state.evaluation_instruments.push(evaluation_instrument);
    },
    [names.MUTATE_DELETE_EVALUATION_INSTRUMENT]: (state, evaluation_instrument_id) => {
        let index = state.evaluation_instruments.findIndex(x => x.id == evaluation_instrument_id);
        if (index != -1) state.evaluation_instruments.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_EVALUATION_INSTRUMENT]: ({
        commit,
        state
    }, evaluation_instrument_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.autonomous_teaching_activities_url}/${evaluation_instrument_id}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_INSTRUMENT, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATION_INSTRUMENTS]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.evaluation_instruments.length > 0) {
                resolve(state.evaluation_instruments);
                return;
            }
            restful.Get(`${state.evaluation_instruments_url}`)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_INSTRUMENTS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_EVALUATION_INSTRUMENT]: ({
        commit,
        state
    }, evaluation_instrument) => {
        return new Promise((resolve) => {
            restful.Post(`${state.evaluation_instruments_url}/`, evaluation_instrument)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_INSTRUMENT, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATION_INSTRUMENT]: ({
        commit,
        state
    }, evaluation_instrument) => {
        return new Promise((resolve) => {
            restful.Put(`${state.evaluation_instruments_url}/${evaluation_instrument.id}/`, evaluation_instrument)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_INSTRUMENT, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATION_INSTRUMENT]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.evaluation_instruments_url}/${payload.evaluation_instrument_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_INSTRUMENT, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATION_INSTRUMENT]: ({
        commit,
        state
    }, evaluation_instrument_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.evaluation_instruments_url}/${evaluation_instrument_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EVALUATION_INSTRUMENT, evaluation_instrument_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};