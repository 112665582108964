import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    sections: [],
    sections_url: '/teaching/section'
}

const getters = {
    [names.SECTIONS]: state => {
        if (!get.store.state.user) return [];
        return state.sections.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_SECTIONS]: (state, sections) => {
        sections.forEach(element => {
            let index = state.sections.findIndex(x => x.id == element.id);
            if (index != -1) state.sections.splice(index, 1, element);
            else state.sections.push(element);
        });
    },
    [names.MUTATE_SECTION]: (state, section) => {
        let index = state.sections.findIndex(x => x.id == section.id);
        if (index != -1) state.sections.splice(index, 1, section);
        else state.sections.push(section);
    },
    [names.MUTATE_DELETE_SECTION]: (state, section_id) => {
        let index = state.sections.findIndex(x => x.id == section_id);
        if (index != -1) state.sections.splice(index, 1);
    },
    'SECTION_ADD_SESSION_DATE': (state, payload) => {
        let index = state.sections.findIndex(x => x.id == payload.section_id);
        if (index != -1) {
            state.sections[index].session_dates.push(payload.session_date_id);
        }
    },
    'SECTION_ADD_SESSION_DATES': (state, session_dates) => {
        session_dates.forEach(session_date => {
            let index = state.sections.findIndex(x => x.id == session_date.section);
            if (index != -1) {
                state.sections[index].session_dates.push(session_date.id);
            }
        });
    },
    'SECTION_DELETE_SESSION_DATE': (state, payload) => {
        let index = state.sections.findIndex(x => x.id == payload.section_id);
        if (index != -1) {
            const session_date_index = state.sections[index].session_dates.findIndex(x => x == payload.session_date_id);
            if (session_date_index != -1)
                state.sections[index].session_dates.splice(session_date_index, 1);
        }
    },
}

const actions = {
    [names.FETCH_SECTION]: ({
        commit,
        state
    }, section_id) => {
        return new Promise((resolve) => {
            const instance = state.sections.find(x => x.id == section_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.sections_url}/${section_id}/`)
                .then(response => {
                    commit(names.MUTATE_SECTION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_SECTIONS]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            let partial_url = '';
            if (payload) {
                if (payload.matter_id) partial_url = '?matter=' + payload.matter_id;
                if (payload.egress_profile_matter_id) partial_url = '?egress_profile_matter=' + payload.egress_profile_matter_id;
                if (payload.egress_profile_id) partial_url = '?egress_profile=' + payload.egress_profile_id;
                if (payload.professor_id) partial_url = '?professor=' + payload.professor_id;
            }
            restful.Get(`${state.sections_url}/${partial_url}`)
                .then(response => {
                    commit(names.MUTATE_SECTIONS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_SECTION]: ({
        commit,
        state
    }, section) => {
        return new Promise((resolve) => {
            restful.Post(`${state.sections_url}/`, section)
                .then(response => {
                    commit(names.MUTATE_SECTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_SECTION]: ({
        commit,
        state
    }, section) => {
        return new Promise((resolve) => {
            restful.Put(`${state.sections_url}/${section.id}/`, section)
                .then(response => {
                    commit(names.MUTATE_SECTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_SECTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.sections_url}/${payload.section_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_SECTION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_SECTION]: ({
        commit,
        state
    }, career_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.sections_url}/${career_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_SECTION, career_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};