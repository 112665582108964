

import { expiresAt } from '@/utils/utils';
// import router from '@/router/index';

export const setUser = (state, user) => {
    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
};

export const setIsLoged = (state, is_loged) => {
    state.is_loged = is_loged;
};

export const setTokens = (state, data) => {
    const { token, refresh_token } = data;
    state.jwt_token = token;
    state.refresh_token = refresh_token;
    state.expires_at = expiresAt(token);

    localStorage.setItem('jwt_token', token);
    localStorage.setItem('refresh_token', refresh_token);
};

export const clearLoginData = (state) => {
    state.jwt_token = null;
    state.refresh_token = null;
    state.expires_at = null
    state.is_loged = false;
    localStorage.clear();
    state.user = null;
    state.institution = null;
    state.permissions = null;
    state.institution_logo = null;
    state.user_institution_id = null;
    state.positions.user_access = null;
    state.utils.allows_crud = false
    state.utils.visibility_crud = false
    state.alternative_names.alternative_names = [];
    state.autonomous_teaching_activities.autonomous_teaching_activities = [];
    state.bibliographic_resource_types.bibliographic_resource_types = [];
    state.campuses.campuses = [];
    state.careers.careers = [];
    state.certifications.certifications = [];
    state.communes.communes = [];
    state.competence_types.competence_types = [];
    state.competences.competences = [];
    state.contents.contents = [];
    state.countries.countries = [];
    state.cycles.cycles = [];
    state.direct_teaching_activities.direct_teaching_activities = [];
    state.egress_profile_cycle.egress_profile_cycle = [];
    state.egress_profile_type.egress_profile_type = [];
    state.evaluation_feedbacks.evaluation_feedbacks = [];
    state.evaluation_instruments.evaluation_instruments = [];
    state.evaluation_planning.evaluation_planning = [];
    state.faculties.faculties = [];
    state.files.files = [];
    state.formation_areas.formation_areas = [];
    state.formation_lines.formation_lines = [];
    state.images.images = [];
    state.institution_competences.institution_competences = [];
    state.instructives.instructives = [];
    state.learning_results.learning_results = [];
    state.matter_contributions.matter_contributions = [];
    state.matter_performance_indicators.matter_performance_indicators = [];
    state.matter_types.matter_types = [];
    state.methodological_strategies.methodological_strategies = [];
    state.performance_indicators.performance_indicators = [];
    state.periods.periods = [];
    state.procedure_evidences.procedure_evidences = [];
    state.ra_macros.ra_macros = [];
    state.ra_micros.ra_micros = [];
    state.regimes.regimes = [];
    state.regions.regions = [];
    state.sections.sections = [];
    state.session_dates.session_dates = [];
    state.session_plannings.session_plannings = [];
    state.shifts.shifts = [];
    state.study_plan_comments.study_plan_comments = [];
    state.study_units.study_units = [];
    state.support_resource_types.support_resource_types = [];
    state.support_resources.support_resources = [];
    state.taxonomies.taxonomies = [];
    state.users.users = [];
    // #TODO: Eliminar, al parecer ya no es necesario.
    // if (router.currentRoute.name != null &&
    //     router.currentRoute.name != 'Welcome' &&
    //     router.currentRoute.name != 'ResetPassword')
    //     router.push({ name: 'Welcome' });
    state.courses_wrapper.courses_wrapper = []
    state.courses_wrapper.base_matters_wrapper = []
    state.egress_profile_competence.profile_competences = []
    state.egress_profile_competence.profile_competences_mention = []
    state.egress_profiles.egress_profiles = []
    state.egress_profiles.egress_profile_descriptors = []
    state.egress_profiles.ep_middle_descriptors = []
    state.egress_profiles_matters.matter_codes = [];
    state.egress_profiles_matters.egress_profiles_matters = [];
    state.egress_profiles_matters.egress_profile_matter_descriptors = [];
    state.egress_profiles_matters.epm_middle_descriptors = [];
    state.evaluation_criterias.evaluation_criterias = [];
    state.evaluation_criterias.evaluation_criterias_macros = [];
    state.evaluations.evaluations = [];
    state.evaluations.evaluation_types = [];
    state.evaluations.sections_evaluations = [];
    state.evaluations.instrument_sub_types = [];
    state.evaluations.evaluation_categories = [];
    state.evaluations.automated_score_types = [];
    state.matters.matters = [];
    state.matters.m_middle_descriptors = [];
    state.modalities.modalities = [];
    state.modalities.modalities_egress_profiles = [];
    state.positions.access = [];
    state.positions.user_table_access = [];
    state.question_tests.question_tests = [];
    state.question_tests.question_tests_score = [];
    state.ra_base.egr_profile_ra_bases = [];
    state.ra_base.ra_bases = [];
    state.rubrics.achievement_descriptors = [];
    state.rubrics.observation_achievements = [];
    state.rubrics.observation_instances = [];
    state.rubrics.observation_instruments = [];
    state.rubrics.observation_scores = [];
    state.study_environments.study_environments = [];
    state.study_environments.study_environments_time_allocation = [];
    state.test_answers.redaction_answers = [];
    state.test_answers.select_answers = [];
    state.tests.question_options = [];
    state.tests.question_types = [];
    state.tests.questions = [];
    state.tests.score_types = [];
    state.time_allocations.time_allocations = [];
    state.time_allocations.matter_time_allocations = [];
    state.time_allocations.ep_matter_time_allocations = [];
};

export const setUserPermissions = (state, permissions) => {
    state.permissions = permissions;
    localStorage.setItem("permissions", JSON.stringify(permissions));
};

export const setUserInstitution = (state, institution) => {
    state.institution = institution;
    state.user_institution_id = institution.id;
    localStorage.setItem("institution", JSON.stringify(institution));
};

export const setInstitutionLogo = (state, logo_url) => {
    state.institution_logo = logo_url;
    localStorage.setItem("institution_logo", logo_url);
};

export const setUserSectionProfessor = (state, payload) => {
    if (state.user.id == payload.user_id &&
        payload.section.professors.includes(payload.user_id)) {
        state.user.sections_as_professor.push(payload.section.id);
        localStorage.setItem("user", JSON.stringify(state.user));
    }
}

export const UpdateUserSectionProfessor = (state, payload) => {
    let index = state.user.sections_as_professor.findIndex(
        (r) => r == payload.section.id
    );
    if (index != -1) {
        if (payload.section.professors.includes(payload.user_id))
            state.user.sections_as_professor.splice(index, 1, payload.section.id);
        else state.user.sections_as_professor.splice(index, 1);
        localStorage.setItem("user", JSON.stringify(state.user));
    }
    else if (payload.section.professors.includes(payload.user_id)) {
        state.user.sections_as_professor.push(payload.section.id);
        localStorage.setItem("user", JSON.stringify(state.user));
    }
}

export const DeleteUserSectionProfessor = (state, payload) => {
    let index = state.user.sections_as_professor.findIndex(
        (r) => r == payload.section.id
    );
    if (index != -1) {
        state.user.sections_as_professor.splice(index, 1);
        localStorage.setItem("user", JSON.stringify(state.user));
    }
}
