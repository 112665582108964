import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    questions: [],
    question_types: [],
    score_types: [],
    question_options: [],
    question_types_url: '/evaluation/question-type',
    score_types_url: '/evaluation/score-type',
    questions_url: '/evaluation/question',
    multiple_choice_questions_options_url: '/evaluation/multiple-choice-question-option',
    multiple_choice_questions_url: '/evaluation/multiple-choice-question',
};

const getters = {
    [names.QUESTIONS]: state => {
        if (!get.store.state.user) return [];
        return state.questions.filter((x) => x.school == get.store.state.user.school).sort(function(a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        });
    },
    [names.QUESTION_TYPES]: state => {
        //Son datos estáticos
        return state.question_types;
    },
    [names.SCORE_TYPES]: state => {
        //Son datos estáticos 
        return state.score_types;
    },
    [names.MULTIPLE_CHOICE_QUESTION_OPTIONS]: state => {
        if (!get.store.state.user) return [];
        return state.question_options.filter((x) => x.school == get.store.state.user.school).sort(function(a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        });
    },
};

const mutations = {
    [names.MUTATE_QUESTION]: (state, question) => {
        let index = state.questions.findIndex(x => x.id == question.id);
        if (index != -1) state.questions.splice(index, 1, question);
        else state.questions.push(question);
    },
    [names.MUTATE_QUESTIONS]: (state, questions) => {
        questions.forEach(element => {
            let index = state.questions.findIndex(x => x.id == element.id);
            if (index != -1) state.questions.splice(index, 1, element);
            else state.questions.push(element);
        });
    },
    [names.MUTATE_DELETE_QUESTION]: (state, question_id) => {
        let index = state.questions.findIndex(x => x.id == question_id);
        if (index != -1) state.questions.splice(index, 1);
    },
    [names.MUTATE_QUESTION_TYPES]: (state, question_types) => {
        state.question_types = [];
        state.question_types = [...question_types];
    },
    [names.MUTATE_SCORE_TYPES]: (state, score_types) => {
        state.score_types = [];
        state.score_types = [...score_types];
    },
    [names.MUTATE_MULTIPLE_CHOICE_QUESTION_OPTIONS]: (state, question_options) => {
        question_options.forEach(element => {
            let index = state.question_options.findIndex(x => x.id == element.id);
            if (index != -1) state.question_options.splice(index, 1, element);
            else state.question_options.push(element);
        });
    },
    [names.MUTATE_MULTIPLE_CHOICE_QUESTION_OPTION]: (state, option) => {
        let index = state.question_options.findIndex(x => x.id == option.id);
        if (index != -1) state.question_options.splice(index, 1, option);
        else state.question_options.push(option);
    },
    [names.MUTATE_DELETE_MULTIPLE_CHOICE_QUESTION_OPTION]: (state, option_id) => {
        let index = state.question_options.findIndex(x => x.id == option_id);
        if (index != -1) state.question_options.splice(index, 1);
    },
};

const actions = {
    [names.FETCH_QUESTION]: ({
        commit,
        state
    }, question_id) => {
        return new Promise((resolve) => {
            const question = state.questions.find(x => x.id == question_id);
            if (question) {
                resolve(question);
                return;
            }
            restful.Get(`${state.questions_url}/${question_id}/`)
                .then(response => {
                    commit(names.MUTATE_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_MULTIPLE_CHOICE_QUESTION]: ({
        commit,
        state
    }, select_question_id) => {
        return new Promise((resolve) => {
            const question = state.questions.find(x => x.id == select_question_id);
            if (question) {
                if (question.how_many_selected) {
                    resolve(question);
                    return;
                }
            }
            restful.Get(`${state.multiple_choice_questions_url}/${select_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_MULTIPLE_CHOICE_QUESTIONS]: ({
        commit,
        state
    }, question_test_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.multiple_choice_questions_url}/?question_test=${question_test_id}`)
                .then(response => {
                    commit(names.MUTATE_QUESTIONS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_MULTIPLE_CHOICE_QUESTION]: ({
        commit,
        state
    }, select_question) => {
        return new Promise((resolve) => {
            restful.Post(`${state.multiple_choice_questions_url}/`, select_question)
                .then(response => {
                    commit(names.MUTATE_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_QUESTIONS]: ({
        commit,
        dispatch,
        state
    }, question_test_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.questions_url}/?question_test=${question_test_id}`)
                .then(response => {
                    commit(names.MUTATE_QUESTIONS, response);
                    if (response.question_type == 3) {
                        dispatch(names.FETCH_MULTIPLE_CHOICE_QUESTION, response.id)
                    }
                    resolve(response);
                });
        });
    },
    [names.POST_QUESTION]: ({
        commit,
        state
    }, question) => {
        return new Promise((resolve) => {
            restful.Post(`${state.questions_url}/`, question)
                .then(response => {
                    commit(names.MUTATE_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_QUESTION]: ({
        commit,
        state
    }, question) => {
        return new Promise((resolve) => {
            restful.Put(`${state.questions_url}/${question.id}/`, question)
                .then(response => {
                    commit(names.MUTATE_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_QUESTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.questions_url}/${payload.question_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_MULTIPLE_CHOICE_QUESTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.multiple_choice_questions_url}/${payload.question_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_QUESTION]: ({
        commit,
        state
    }, question_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.questions_url}/${question_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_QUESTION, question_id);
                    resolve(response);
                });
        });
    },
    [names.FETCH_QUESTION_TYPES]: ({
        commit,
        state
    }) => {
        return new Promise((resolve) => {
            restful.Get(`${state.question_types_url}/`)
                .then(response => {
                    commit(names.MUTATE_QUESTION_TYPES, response);
                    resolve(response);
                });
        });
    },
    // Tipos de Puntuaje
    [names.FETCH_SCORE_TYPES]: ({
        commit,
        state
    }) => {
        return new Promise((resolve) => {
            restful.Get(`${state.score_types_url}/`)
                .then(response => {
                    commit(names.MUTATE_SCORE_TYPES, response);
                    resolve(response);
                });
        });
    },
    // Opciones de Preguntas
    [names.FETCH_MULTIPLE_CHOICE_QUESTION_OPTION]: ({
        commit,
        state
    }, option_id) => {
        return new Promise((resolve) => {
            const option = state.question_options.find(x => x.id == option_id);
            if (option) {
                resolve(option);
                return;
            }
            restful.Get(`${state.multiple_choice_questions_options_url}/${option_id}/`)
                .then(response => {
                    commit(names.MUTATE_MULTIPLE_CHOICE_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_MULTIPLE_CHOICE_QUESTION_OPTIONS]: ({
        commit,
        state
    }, question_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.multiple_choice_questions_options_url}/?question=${question_id}`)
                .then(response => {
                    commit(names.MUTATE_MULTIPLE_CHOICE_QUESTION_OPTIONS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_MULTIPLE_CHOICE_QUESTION_OPTION]: ({
        commit,
        state
    }, option) => {
        return new Promise((resolve) => {
            restful.Post(`${state.multiple_choice_questions_options_url}/`, option)
                .then(response => {
                    commit(names.MUTATE_MULTIPLE_CHOICE_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_MULTIPLE_CHOICE_QUESTION_OPTION]: ({
        commit,
        state
    }, option) => {
        return new Promise((resolve) => {
            restful.Put(`${state.multiple_choice_questions_options_url}/${option.id}/`, option)
                .then(response => {
                    commit(names.MUTATE_MULTIPLE_CHOICE_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_MULTIPLE_CHOICE_QUESTION_OPTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.multiple_choice_questions_options_url}/${payload.option_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_MULTIPLE_CHOICE_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_MULTIPLE_CHOICE_QUESTION_OPTION]: ({
        commit,
        state
    }, option_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.multiple_choice_questions_options_url}/${option_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_MULTIPLE_CHOICE_QUESTION_OPTION, option_id);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};