import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    campuses: [],
    campuses_url: '/mesh/campus'
};

const getters = {
    [names.CAMPUSES]: state => {
        if (!get.store.state.user) return [];
        return state.campuses.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
};

const mutations = {
    [names.MUTATE_CAMPUSES]: (state, campuses) => {
        campuses.forEach(element => {
            let index = state.campuses.findIndex(x => x.id == element.id);
            if (index != -1) state.campuses.splice(index, 1, element);
            else state.campuses.push(element);
        });
    },
    [names.MUTATE_CAMPUS]: (state, campus) => {
        let index = state.campuses.findIndex(x => x.id == campus.id);
        if (index != -1) state.campuses.splice(index, 1, campus);
        else state.campuses.push(campus);
    },
    [names.MUTATE_DELETE_CAMPUS]: (state, campus_id) => {
        let index = state.campuses.findIndex(x => x.id == campus_id);
        if (index != -1) state.campuses.splice(index, 1);
    },
};

const actions = {
    [names.FETCH_CAMPUS]: ({
        commit,
        state
    }, campus_id) => {
        return new Promise((resolve) => {
            const campus = state.campuses.find(x => x.id == campus_id);
            if (campus) {
                resolve(campus);
                return;
            }
            restful.Get(`${state.campuses_url}/${campus_id}/`)
                .then(response => {
                    commit(names.MUTATE_CAMPUS, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_CAMPUSES]: ({
        commit,
        state,
    }) => {
        return new Promise((resolve) => {
            restful.Get(`${state.campuses_url}/`)
                .then(response => {
                    commit(names.MUTATE_CAMPUSES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_CAMPUS]: ({
        commit,
        state
    }, campus) => {
        return new Promise((resolve) => {
            restful.Post(`${state.campuses_url}/`, campus)
                .then(response => {
                    commit(names.MUTATE_CAMPUS, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_CAMPUS]: ({
        commit,
        state
    }, campus) => {
        return new Promise((resolve) => {
            restful.Put(`${state.campuses_url}/${campus.id}/`, campus)
                .then(response => {
                    commit(names.MUTATE_CAMPUS, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_CAMPUS]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.campuses_url}/${payload.campus_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_CAMPUS, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_CAMPUS]: ({
        commit,
        state
    }, campus_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.campuses_url}/${campus_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_CAMPUS, campus_id);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};