
export default [
    {
        path: '/manage-evaluations/',
        name: 'ManageEvaluationsView',
        component: () =>
            import("@/components/new-evaluations/ManageEvaluations/ManageEvaluationsView"),
    },
    {
        path: '/evaluations/v2/form/:evaluation_id/',
        name: 'NewEvaluationView',
        component: () =>
            import("@/components/new-evaluations/Evaluation/NewEvaluationView"),
    },
    {
        path: '/rubric-preset/',
        name: 'RubricPresetView',
        component: () =>
            import("@/components/new-evaluations/Rubrics/RubricsPreset/RubricPresetView"),
    },
    {
        path: '/evaluations/v2/answer/:evaluation_id/',
        name: 'EvaluateeView',
        component: () =>
            import("@/components/new-evaluations/Evaluation/Evaluatee/EvaluateeView"),
    },
    {
        path: '/evaluations/v2/instrument-correction/:evaluation_id/',
        name: 'EvaluatorView',
        component: () =>
            import("@/components/new-evaluations/Evaluation/Evaluators/EvaluatorView"),
    },
    {
        path: '/evaluations/full-grading/v2/:section_id/',
        name: 'NewFullGradingView',
        component: () =>
            import("@/components/new-dashboard/NewFullGradingView"),
    },
    {
        path: '/evaluations/student-full-grading/v2/',
        name: 'StudentGradeView',
        component: () =>
            import("@/components/new-dashboard/StudentGradeView"),
    },
    {
        path: '/evaluations/evaluator-evaluations/v2/:section_id/',
        name: 'EvaluatorEvaluationsListView',
        component: () =>
            import("@/components/new-dashboard/EvaluatorEvaluationsListView"),
    },
    {
        path: '/evaluations/evaluatee-evaluations/v2/:section_id/',
        name: 'EvaluateeEvaluationsListView',
        component: () =>
            import("@/components/new-dashboard/EvaluateeEvaluationsListView"),
    },
];