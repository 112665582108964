import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    alternative_names: [],
    alternative_names_url: '/common/alternative-names'

}

const getters = {
    [names.ALTERNATIVE_NAMES]: state => {
        if (!get.store.state.user) return [];
        return state.alternative_names.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_ALTERNATIVE_NAMES]: (state, alternative_names) => {
        alternative_names.forEach(element => {
            let index = state.alternative_names.findIndex(x => x.id == element.id);
            if (index != -1) {
                state.alternative_names.splice(index, 1, element);
                localStorage.setItem("alternative_names", JSON.stringify(state.alternative_names));
            } else {
                state.alternative_names.push(element);
                localStorage.setItem("alternative_names", JSON.stringify(state.alternative_names));
            }
        });
    },
    [names.MUTATE_ALTERNATIVE_NAME]: (state, alternative_name) => {
        let index = state.alternative_names.findIndex(x => x.id == alternative_name.id);
        if (index != -1) {
            state.alternative_names.splice(index, 1, alternative_name);
            localStorage.setItem("alternative_names", JSON.stringify(state.alternative_names));
        } else {
            state.alternative_names.push(alternative_name);
            localStorage.setItem("alternative_names", JSON.stringify(state.alternative_names));
        }
    },
    [names.MUTATE_DELETE_ALTERNATIVE_NAME]: (state, alternative_name_id) => {
        let index = state.alternative_names.findIndex(x => x.id == alternative_name_id);
        if (index != -1) {
            state.alternative_names.splice(index, 1);
            localStorage.setItem("alternative_names", JSON.stringify(state.alternative_names));
        }
    },
}

const actions = {
    [names.FETCH_ALTERNATIVE_NAME]: ({
        commit,
        state
    }, alternative_name_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.alternative_names_url}/${alternative_name_id}/`)
                .then(response => {
                    commit(names.MUTATE_ALTERNATIVE_NAME, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_ALTERNATIVE_NAMES]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.alternative_names.length > 0) {
                resolve(state.alternative_names);
                return;
            }
            if (localStorage.getItem('alternative_names')) {
                let alternative_names = JSON.parse(localStorage.getItem('alternative_names'));
                if (alternative_names != null) {
                    alternative_names = alternative_names.filter((x) => x.school == get.store.state.user.school);
                    if (alternative_names.length > 0) {
                        commit(names.MUTATE_ALTERNATIVE_NAMES, alternative_names);
                        resolve(alternative_names);
                        return;
                    }
                }
            }
            restful.Get(`${state.alternative_names_url}/`)
                .then(response => {
                    commit(names.MUTATE_ALTERNATIVE_NAMES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_ALTERNATIVE_NAME]: ({
        commit,
        state
    }, alternative_name) => {
        return new Promise((resolve) => {
            restful.Post(`${state.alternative_names_url}/`, alternative_name)
                .then(response => {
                    commit(names.MUTATE_ALTERNATIVE_NAME, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_ALTERNATIVE_NAME]: ({
        commit,
        state
    }, alternative_name) => {
        return new Promise((resolve) => {
            restful.Put(`${state.alternative_names_url}/${alternative_name.id}/`, alternative_name)
                .then(response => {
                    commit(names.MUTATE_ALTERNATIVE_NAME, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_ALTERNATIVE_NAME]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.alternative_names_url}/${payload.alternative_name_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_ALTERNATIVE_NAME, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_ALTERNATIVE_NAME]: ({
        commit,
        state
    }, alternative_name_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.alternative_names_url}/${alternative_name_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_ALTERNATIVE_NAME, alternative_name_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};