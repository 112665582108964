import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    study_units: [],
    study_units_url: '/teaching/study-unit'
}

const getters = {
    [names.STUDY_UNITS]: state => {
        if (!get.store.state.user) return [];
        return state.study_units.filter((x) => x.school == get.store.state.user.school).sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        });
    },
    [names.STUDY_UNIT]: (state,) => id => {
        return state.study_units.find(x => x.id == id);
    },
}

const mutations = {
    [names.MUTATE_STUDY_UNITS]: (state, study_units) => {
        study_units.forEach(element => {
            let index = state.study_units.findIndex(x => x.id == element.id);
            if (index != -1) state.study_units.splice(index, 1, element);
            else state.study_units.push(element);
        });
        state.study_units.sort(function (a, b) {
            return ((a.order < b.order) ? -1 : ((a.order > b.order) ? 1 : 0));
        });
    },
    [names.MUTATE_STUDY_UNIT]: (state, study_unit) => {
        let index = state.study_units.findIndex(x => x.id == study_unit.id);
        if (index != -1) state.study_units.splice(index, 1, study_unit);
        else state.study_units.push(study_unit);
        state.study_units.sort(function (a, b) {
            return ((a.order < b.order) ? -1 : ((a.order > b.order) ? 1 : 0));
        });
    },
    [names.MUTATE_DELETE_STUDY_UNIT]: (state, study_unit_id) => {
        let index = state.study_units.findIndex(x => x.id == study_unit_id);
        if (index != -1) state.study_units.splice(index, 1);
    },
    ["MUTATE_STUDY_UNITS_BY_DELETE_STUDY_COMPETENCE"]: (state, study_competence_id) => {
        let index = state.study_units.findIndex(x => x.temp_competence_unit == study_competence_id);
        while (index != -1) {
            state.study_units[index].temp_competence_unit = null
            index = state.study_units.findIndex(x => x.temp_competence_unit == study_competence_id);
        }
    },
}

const actions = {
    [names.FETCH_STUDY_UNIT]: ({
        commit,
        state
    }, study_unit_id) => {
        return new Promise((resolve) => {
            const instance = state.study_units.find(x => x.id == study_unit_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.study_units_url}/${study_unit_id}/`)
                .then(response => {
                    commit(names.MUTATE_STUDY_UNIT, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_STUDY_UNITS]: ({
        commit,
        state
    }, matter_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.study_units_url}/?matter=${matter_id}`)
                .then(response => {
                    commit(names.MUTATE_STUDY_UNITS, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_STUDY_UNITS_BY_CICLE_AND_PROFILE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '';
            if (payload) {
                if (payload.egress_profile_id && payload.cycle_id)
                    url_append += '?egress_profile=' + payload.egress_profile_id + '&cycle=' + payload.cycle_id;
                else if (payload.egress_profile_id)
                    url_append += '?egress_profile=' + payload.egress_profile_id;
                else if (payload.cycle_id)
                    url_append += '?cycle=' + payload.cycle_id;
            }
            restful.Get(`${state.study_units_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_STUDY_UNITS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_STUDY_UNIT]: ({
        commit,
        state
    }, study_unit) => {
        return new Promise((resolve) => {
            restful.Post(`${state.study_units_url}/`, study_unit)
                .then(response => {
                    commit(names.MUTATE_STUDY_UNIT, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_STUDY_UNIT]: ({
        commit,
        state
    }, study_unit) => {
        return new Promise((resolve) => {
            restful.Put(`${state.study_units_url}/${study_unit.id}/`, study_unit)
                .then(response => {
                    commit(names.MUTATE_STUDY_UNIT, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_STUDY_UNIT]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.study_units_url}/${payload.study_unit_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_STUDY_UNIT, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_STUDY_UNIT]: ({
        commit,
        state
    }, study_unit_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.study_units_url}/${study_unit_id}/`)
                .then(response => {
                    commit("MUTATE_EVALUATION_CRITERIA_BY_DELETE_STUDY_UNIT", study_unit_id);
                    commit(names.MUTATE_DELETE_STUDY_UNIT, study_unit_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};