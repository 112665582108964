import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    matter_types: [],
    matter_types_url: '/mesh/matter-type'
}

const getters = {
    [names.MATTER_TYPES]: state => {
        if (!get.store.state.user) return [];
        return state.matter_types.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_MATTER_TYPES]: (state, matter_types) => {
        matter_types.forEach(element => {
            let index = state.matter_types.findIndex(x => x.id == element.id);
            if (index != -1) state.matter_types.splice(index, 1, element);
            else state.matter_types.push(element);
        });
    },
    [names.MUTATE_MATTER_TYPE]: (state, matter_type) => {
        let index = state.matter_types.findIndex(x => x.id == matter_type.id);
        if (index != -1) state.matter_types.splice(index, 1, matter_type);
        else state.matter_types.push(matter_type);
    },
    [names.MUTATE_DELETE_MATTER_TYPE]: (state, matter_type_id) => {
        let index = state.matter_types.findIndex(x => x.id == matter_type_id);
        if (index != -1) state.matter_types.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_MATTER_TYPE]: ({
        commit,
        state
    }, matter_type_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.matter_types_url}/${matter_type_id}/`)
                .then(response => {
                    commit(names.MUTATE_MATTER_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_MATTER_TYPES]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.matter_types.length > 0) {
                resolve(state.matter_types);
                return;
            }
            restful.Get(`${state.matter_types_url}/`)
                .then(response => {
                    commit(names.MUTATE_MATTER_TYPES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_MATTER_TYPE]: ({
        commit,
        state
    }, matter_type) => {
        return new Promise((resolve) => {
            restful.Post(`${state.matter_types_url}/`, matter_type)
                .then(response => {
                    commit(names.MUTATE_MATTER_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_MATTER_TYPE]: ({
        commit,
        state
    }, matter_type) => {
        return new Promise((resolve) => {
            restful.Put(`${state.matter_types_url}/${matter_type.id}/`, matter_type)
                .then(response => {
                    commit(names.MUTATE_MATTER_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_MATTER_TYPE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.matter_types_url}/${payload.matter_type_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_MATTER_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_MATTER_TYPE]: ({
        commit,
        state
    }, matter_type_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.matter_types_url}/${matter_type_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_MATTER_TYPE, matter_type_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};