import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    assessment_types: [],
    assessment_types_url: '/assessment2/assessment-type'

}

const getters = {
    [names.ASSESSMENT_TYPES]: state => {
        if (!get.store.state.user) return [];
        return state.assessment_types.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_ASSESSMENT_TYPES]: (state, assessment_types) => {
        assessment_types.forEach(element => {
            let index = state.assessment_types.findIndex(x => x.id == element.id);
            if (index != -1) {
                state.assessment_types.splice(index, 1, element);
                localStorage.setItem("assessment_types", JSON.stringify(state.assessment_types));
            } else {
                state.assessment_types.push(element);
                localStorage.setItem("assessment_types", JSON.stringify(state.assessment_types));
            }
        });
    },
    [names.MUTATE_ASSESSMENT_TYPE]: (state, assessment_type) => {
        let index = state.assessment_types.findIndex(x => x.id == assessment_type.id);
        if (index != -1) {
            state.assessment_types.splice(index, 1, assessment_type);
            localStorage.setItem("assessment_types", JSON.stringify(state.assessment_types));
        } else {
            state.assessment_types.push(assessment_type);
            localStorage.setItem("assessment_types", JSON.stringify(state.assessment_types));
        }
    },
    [names.MUTATE_DELETE_ASSESSMENT_TYPE]: (state, assessment_type_id) => {
        let index = state.assessment_types.findIndex(x => x.id == assessment_type_id);
        if (index != -1) {
            state.assessment_types.splice(index, 1);
            localStorage.setItem("assessment_types", JSON.stringify(state.assessment_types));
        }
    },
}

const actions = {
    [names.FETCH_ASSESSMENT_TYPE]: ({
        commit,
        state
    }, assessment_type_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.assessment_types_url}/${assessment_type_id}/`)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_ASSESSMENT_TYPES]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.assessment_types.length > 0) {
                resolve(state.assessment_types);
                return;
            }
            if (localStorage.getItem('assessment_types')) {
                let assessment_types = JSON.parse(localStorage.getItem('assessment_types'));
                if (assessment_types != null) {
                    assessment_types = assessment_types.filter((x) => x.school == get.store.state.user.school);
                    if (assessment_types.length > 0) {
                        commit(names.MUTATE_ASSESSMENT_TYPES, assessment_types);
                        resolve(assessment_types);
                        return;
                    }
                }
            }
            restful.Get(`${state.assessment_types_url}/`)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT_TYPES, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_ASSESSMENT_TYPE]: ({
        commit,
        state
    }, assessment_type) => {
        return new Promise((resolve) => {
            restful.Post(`${state.assessment_types_url}/`, assessment_type)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_ASSESSMENT_TYPE]: ({
        commit,
        state
    }, assessment_type) => {
        return new Promise((resolve) => {
            restful.Put(`${state.assessment_types_url}/${assessment_type.id}/`, assessment_type)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_ASSESSMENT_TYPE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.assessment_types_url}/${payload.assessment_type_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_ASSESSMENT_TYPE]: ({
        commit,
        state
    }, assessment_type_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.assessment_types_url}/${assessment_type_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_ASSESSMENT_TYPE, assessment_type_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};