import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    support_resource_types: [],
    support_resource_types_url: '/teaching/support-resource-type'
}

const getters = {
    [names.SUPPORT_RESOURCE_TYPES]: state => {
        if (!get.store.state.user) return [];
        return state.support_resource_types.filter((x) => x.school == get.store.state.user.school).sort(function(a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        });
    },
}

const mutations = {
    [names.MUTATE_SUPPORT_RESOURCE_TYPES]: (state, support_resource_types) => {
        support_resource_types.forEach(element => {
            let index = state.support_resource_types.findIndex(x => x.id == element.id);
            if (index != -1) state.support_resource_types.splice(index, 1, element);
            else state.support_resource_types.push(element);
        });
    },
    [names.MUTATE_SUPPORT_RESOURCE_TYPE]: (state, support_resource_type) => {
        let index = state.support_resource_types.findIndex(x => x.id == support_resource_type.id);
        if (index != -1) state.support_resource_types.splice(index, 1, support_resource_type);
        else state.support_resource_types.push(support_resource_type);
    },
    [names.MUTATE_DELETE_SUPPORT_RESOURCE_TYPE]: (state, support_resource_type_id) => {
        let index = state.support_resource_types.findIndex(x => x.id == support_resource_type_id);
        if (index != -1) state.support_resource_types.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_SUPPORT_RESOURCE_TYPE]: ({
        commit,
        state
    }, support_resource_type_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.support_resource_types_url}/${support_resource_type_id}/`)
                .then(response => {
                    commit(names.MUTATE_SUPPORT_RESOURCE_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_SUPPORT_RESOURCE_TYPES]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.support_resource_types.length > 0) {
                resolve(state.support_resource_types);
                return;
            }
            restful.Get(`${state.support_resource_types_url}/`)
                .then(response => {
                    commit(names.MUTATE_SUPPORT_RESOURCE_TYPES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_SUPPORT_RESOURCE_TYPE]: ({
        commit,
        state
    }, support_resource_type) => {
        return new Promise((resolve) => {
            restful.Post(`${state.support_resource_types_url}/`, support_resource_type)
                .then(response => {
                    commit(names.MUTATE_SUPPORT_RESOURCE_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_SUPPORT_RESOURCE_TYPE]: ({
        commit,
        state
    }, support_resource_type) => {
        return new Promise((resolve) => {
            restful.Put(`${state.support_resource_types_url}/${support_resource_type.id}/`, support_resource_type)
                .then(response => {
                    commit(names.MUTATE_SUPPORT_RESOURCE_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_SUPPORT_RESOURCE_TYPE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.support_resource_types_url}/${payload.support_resource_type_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_SUPPORT_RESOURCE_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_SUPPORT_RESOURCE_TYPE]: ({
        commit,
        state
    }, support_resource_type_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.support_resource_types_url}/${support_resource_type_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_SUPPORT_RESOURCE_TYPE, support_resource_type_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};