import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    procedure_evidences: [],
    procedure_evidences_url: '/mesh/procedure-evidence'
}

const getters = {
    [names.PROCEDURE_EVIDENCES]: state => {
        if (!get.store.state.user) return [];
        return state.procedure_evidences.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_PROCEDURE_EVIDENCES]: (state, procedure_evidences) => {
        procedure_evidences.forEach(element => {
            let index = state.procedure_evidences.findIndex(x => x.id == element.id);
            if (index != -1) state.procedure_evidences.splice(index, 1, element);
            else state.procedure_evidences.push(element);
        });
    },
    [names.MUTATE_PROCEDURE_EVIDENCE]: (state, procedure_evidence) => {
        let index = state.procedure_evidences.findIndex(x => x.id == procedure_evidence.id);
        if (index != -1) state.procedure_evidences.splice(index, 1, procedure_evidence);
        else state.procedure_evidences.push(procedure_evidence);
    },
    [names.MUTATE_DELETE_PROCEDURE_EVIDENCE]: (state, procedure_evidence_id) => {
        let index = state.procedure_evidences.findIndex(x => x.id == procedure_evidence_id);
        if (index != -1) state.procedure_evidences.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_PROCEDURE_EVIDENCE]: ({
        commit,
        state
    }, procedure_evidence_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.procedure_evidences_url}/${procedure_evidence_id}/`)
                .then(response => {
                    commit(names.MUTATE_PROCEDURE_EVIDENCE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_PROCEDURE_EVIDENCES]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.procedure_evidences.length > 0) {
                resolve(state.procedure_evidences);
                return;
            }
            restful.Get(`${state.procedure_evidences_url}/`)
                .then(response => {
                    commit(names.MUTATE_PROCEDURE_EVIDENCES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_PROCEDURE_EVIDENCE]: ({
        commit,
        state
    }, procedure_evidence) => {
        return new Promise((resolve) => {
            restful.Post(`${state.procedure_evidences_url}/`, procedure_evidence)
                .then(response => {
                    commit(names.MUTATE_PROCEDURE_EVIDENCE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_PROCEDURE_EVIDENCE]: ({
        commit,
        state
    }, procedure_evidence) => {
        return new Promise((resolve) => {
            restful.Put(`${state.procedure_evidences_url}/${procedure_evidence.id}/`, procedure_evidence)
                .then(response => {
                    commit(names.MUTATE_PROCEDURE_EVIDENCE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_PROCEDURE_EVIDENCE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.procedure_evidences_url}/${payload.procedure_evidence_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_PROCEDURE_EVIDENCE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_PROCEDURE_EVIDENCE]: ({
        commit,
        state
    }, procedure_evidence_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.procedure_evidences_url}/${procedure_evidence_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_PROCEDURE_EVIDENCE, procedure_evidence_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};