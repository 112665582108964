import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    shifts: [],
    shifts_url: '/mesh/shift'
};

const getters = {
    [names.SHIFTS]: state => {
        if (!get.store.state.user) return [];
        return state.shifts.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
};

const mutations = {
    [names.MUTATE_SHIFTS]: (state, shifts) => {
        shifts.forEach(element => {
            let index = state.shifts.findIndex(x => x.id == element.id);
            if (index != -1) state.shifts.splice(index, 1, element);
            else state.shifts.push(element);
        });
    },
    [names.MUTATE_SHIFT]: (state, shift) => {
        let index = state.shifts.findIndex(x => x.id == shift.id);
        if (index != -1) state.shifts.splice(index, 1, shift);
        else state.shifts.push(shift);
    },
};

const actions = {
    [names.FETCH_SHIFTS]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.shifts.length > 0) {
                resolve(state.shifts);
                return;
            }
            restful.Get(`${state.shifts_url}/`)
                .then(response => {
                    commit(names.MUTATE_SHIFTS, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_SHIFT]: ({
        commit,
        state,
    }, shift_id) => {
        return new Promise((resolve) => {
            const shift = state.shifts.find(x => x.id == shift_id);
            if (shift) {
                resolve(shift);
                return;
            }
            restful.Get(`${state.shifts_url}/${shift_id}/`)
                .then(response => {
                    commit(names.MUTATE_SHIFT, response);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};