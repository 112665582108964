import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    matters: [],
    matters_url: '/teaching/matter',
    m_middle_descriptors: [],
    m_middle_descriptor_url: '/mesh/m-middle-descriptor',
    matter_categories: [],
    matter_categories_url: '/teaching/matter-category',
    learning_experiences: [],
    learning_experiences_url: '/teaching/learning-experience',
    matter_evaluations: [],
    matter_evaluations_url: '/teaching/matter-evaluation',
    // Choices de EvaluationTypes
    epm_evaluation_types: [],
    epm_evaluation_type_url: '/teaching/epm-evaluation-types',
}

const getters = {
    [names.MATTERS]: state => {
        if (!get.store.state.user) return [];
        return state.matters.filter((x) => x.school == get.store.state.user.school).sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        });
    },
    [names.M_MIDDLE_DESCRIPTORS]: state => {
        if (!get.store.state.user) return [];
        return state.m_middle_descriptors.filter((x) => x.school == get.store.state.user.school);
    },
    [names.MATTER_CATEGORIES]: state => {
        if (!get.store.state.user) return [];
        return state.matter_categories.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.LEARNING_EXPERIENCES]: state => {
        if (!get.store.state.user) return [];
        return state.learning_experiences.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.MATTER_EVALUATIONS]: state => {
        if (!get.store.state.user) return [];
        return state.matter_evaluations.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.EPM_EVALUATION_TYPES]: state => {
        return state.epm_evaluation_types;
    },
}

const mutations = {
    [names.MUTATE_MATTERS]: (state, matters) => {
        matters.forEach(element => {
            let index = state.matters.findIndex(x => x.id == element.id);
            if (index != -1) state.matters.splice(index, 1, element);
            else state.matters.push(element);
        });
    },
    [names.MUTATE_MATTER]: (state, matter) => {
        let index = state.matters.findIndex(x => x.id == matter.id);
        if (index != -1) state.matters.splice(index, 1, matter);
        else state.matters.push(matter);
    },
    [names.MUTATE_DELETE_MATTER]: (state, matter_id) => {
        let index = state.matters.findIndex(x => x.id == matter_id);
        if (index != -1) state.matters.splice(index, 1);
    },
    ["MUTATE_DELETE__STUDY_ENVIRONMENT_BY_MATTERS"]: (state, study_environment_id) => {
        let index = state.matters.findIndex(x => x.study_environments.includes(study_environment_id));
        while (index != -1) {
            let index2 = state.matters[index].study_environments.findIndex(x => x == study_environment_id);
            if (index2 != -1) {
                state.matters[index].study_environments.splice(index2, 1);
                index = state.matters.findIndex(x => x.study_environments.includes(study_environment_id));
            }
        }
    },
    [names.MUTATE_M_MIDDLE_DESCRIPTOR]: (state, m_middle_descriptor) => {
        let index = state.m_middle_descriptors.findIndex(x => x.id == m_middle_descriptor.id);
        if (index != -1) state.m_middle_descriptors.splice(index, 1, m_middle_descriptor);
        else state.m_middle_descriptors.push(m_middle_descriptor);
    },
    [names.MUTATE_M_MIDDLE_DESCRIPTORS]: (state, m_middle_descriptors) => {
        m_middle_descriptors.forEach(element => {
            let index = state.m_middle_descriptors.findIndex(x => x.id == element.id);
            if (index != -1) state.m_middle_descriptors.splice(index, 1, element);
            else state.m_middle_descriptors.push(element);
        });
    },
    [names.MUTATE_DELETE_M_MIDDLE_DESCRIPTORS]: (state, m_middle_descriptor_id) => {
        let index = state.m_middle_descriptors.findIndex(x => x.id == m_middle_descriptor_id);
        if (index != -1) state.m_middle_descriptors.splice(index, 1);
    },
    ["MUTATE_DELETE_M_MIDDLE_DESCRIPTOR_BY_EGRESS_PROFILE_MATTER_DESCRIPTORS"]: (state, egress_profile_matter_descriptor_id) => {
        let index = state.m_middle_descriptors.findIndex(x => x.descriptor == egress_profile_matter_descriptor_id);
        while (index != -1) {
            state.m_middle_descriptors.splice(index, 1);
            index = state.m_middle_descriptors.findIndex(x => x.descriptor == egress_profile_matter_descriptor_id);
        }
    },
    [names.MUTATE_MATTER_CATEGORIES]: (state, matter_categories) => {
        matter_categories.forEach(element => {
            let index = state.matter_categories.findIndex(x => x.id == element.id);
            if (index != -1) state.matter_categories.splice(index, 1, element);
            else state.matter_categories.push(element);
        });
    },
    [names.MUTATE_MATTER_CATEGORY]: (state, matter_category) => {
        let index = state.matter_categories.findIndex(x => x.id == matter_category.id);
        if (index != -1) state.matter_categories.splice(index, 1, matter_category);
        else state.matter_categories.push(matter_category);
    },
    [names.MUTATE_DELETE_MATTER_CATEGORY]: (state, matter_category_id) => {
        let index = state.matter_categories.findIndex(x => x.id == matter_category_id);
        if (index != -1) state.matter_categories.splice(index, 1);
    },
    [names.MUTATE_LEARNING_EXPERIENCES]: (state, learning_experiences) => {
        learning_experiences.forEach(element => {
            let index = state.learning_experiences.findIndex(x => x.id == element.id);
            if (index != -1) state.learning_experiences.splice(index, 1, element);
            else state.learning_experiences.push(element);
        });
    },
    [names.MUTATE_LEARNING_EXPERIENCE]: (state, learning_experience) => {
        let index = state.learning_experiences.findIndex(x => x.id == learning_experience.id);
        if (index != -1) state.learning_experiences.splice(index, 1, learning_experience);
        else state.learning_experiences.push(learning_experience);
    },
    [names.MUTATE_DELETE_LEARNING_EXPERIENCE]: (state, learning_experience_id) => {
        let index = state.learning_experiences.findIndex(x => x.id == learning_experience_id);
        if (index != -1) state.learning_experiences.splice(index, 1);
    },
    [names.MUTATE_MATTER_EVALUATIONS]: (state, matter_evaluations) => {
        matter_evaluations.forEach(element => {
            let index = state.matter_evaluations.findIndex(x => x.id == element.id);
            if (index != -1) state.matter_evaluations.splice(index, 1, element);
            else state.matter_evaluations.push(element);
        });
    },
    [names.MUTATE_MATTER_EVALUATION]: (state, matter_evaluation) => {
        let index = state.matter_evaluations.findIndex(x => x.id == matter_evaluation.id);
        if (index != -1) state.matter_evaluations.splice(index, 1, matter_evaluation);
        else state.matter_evaluations.push(matter_evaluation);
    },
    [names.MUTATE_DELETE_MATTER_EVALUATION]: (state, matter_evaluation_id) => {
        let index = state.matter_evaluations.findIndex(x => x.id == matter_evaluation_id);
        if (index != -1) state.matter_evaluations.splice(index, 1);
    },
    [names.MUTATE_EPM_EVALUATION_TYPES]: (state, epm_evaluation_types) => {
        epm_evaluation_types.forEach(element => {
            let index = state.epm_evaluation_types.findIndex(x => x.id == element.id);
            if (index != -1) state.epm_evaluation_types.splice(index, 1, element);
            else state.epm_evaluation_types.push(element);
        });
    },
}

const actions = {
    [names.FETCH_MATTER]: ({
        commit,
        state
    }, matter_id) => {
        return new Promise((resolve) => {
            const matter = state.matters.find(x => x.id == matter_id);
            if (matter) {
                resolve(matter);
                return;
            }
            restful.Get(`${state.matters_url}/${matter_id}/`)
                .then(response => {
                    commit(names.MUTATE_MATTER, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_MATTERS]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '';
            if (payload) {
                if (payload.egress_profile_id && payload.cycle_id)
                    url_append += '?egress_profile=' + payload.egress_profile_id + '&cycle=' + payload.cycle_id;
                else if (payload.egress_profile_id)
                    url_append += '?egress_profile=' + payload.egress_profile_id;
                else if (payload.cycle_id)
                    url_append += '?cycle=' + payload.cycle_id;
                if (payload.career_id)
                    url_append += '?career=' + payload.career_id;
                if (payload.is_elective)
                    url_append += '?is_elective=' + payload.is_elective;
            }
            restful.Get(`${state.matters_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_MATTERS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_MATTER]: ({
        commit,
        state
    }, matter) => {
        return new Promise((resolve) => {
            restful.Post(`${state.matters_url}/`, matter)
                .then(response => {
                    commit(names.MUTATE_MATTER, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_MATTER]: ({
        commit,
        state
    }, matter) => {
        return new Promise((resolve) => {
            restful.Put(`${state.matters_url}/${matter.id}/`, matter)
                .then(response => {
                    commit(names.MUTATE_MATTER, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_MATTER]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.matters_url}/${payload.matter_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_MATTER, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_MATTER]: ({
        commit,
        state
    }, matter_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.matters_url}/${matter_id}/`)
                .then(response => {
                    commit("MUTATE_DELETE_EGRESS_PROFILE_MATTER_BY_BASE_MATTER", matter_id);
                    commit("MUTATE_DELETE_MATTER_IN_BASE_MATTER_WRAPPER", matter_id);
                    commit(names.MUTATE_DELETE_MATTER, matter_id);
                    resolve(response);
                });
        });
    },
    [names.FETCH_M_MIDDLE_DESCRIPTORS]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            let arr = [];
            if (payload.descriptor_id) arr.push('descriptor__id=' + payload.descriptor_id);
            if (payload.matter_id) arr.push('matter=' + payload.matter_id);
            let partial_url = '';
            if (arr.length > 0) partial_url = '?' + arr.join('&&');
            restful.Get(`${state.m_middle_descriptor_url}/${partial_url}`)
                .then(response => {
                    commit(names.MUTATE_M_MIDDLE_DESCRIPTORS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_M_MIDDLE_DESCRIPTORS]: ({
        commit,
        state
    }, m_middle_descriptor) => {
        return new Promise((resolve) => {
            restful.Post(`${state.m_middle_descriptor_url}/`, m_middle_descriptor)
                .then(response => {
                    commit(names.MUTATE_M_MIDDLE_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_M_MIDDLE_DESCRIPTORS]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.m_middle_descriptor_url}/${payload.id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_M_MIDDLE_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_M_MIDDLE_DESCRIPTORS]: ({
        commit,
        state
    }, id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.m_middle_descriptor_url}/${id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_M_MIDDLE_DESCRIPTORS, id);
                    resolve(response);
                });
        });
    },
    // MATTER_CATEGORY
    [names.FETCH_MATTER_CATEGORY]: ({
        commit,
        state
    }, matter_category_id) => {
        return new Promise((resolve) => {
            const matter_category = state.matter_categories.find(x => x.id == matter_category_id);
            if (matter_category) {
                resolve(matter_category);
                return;
            }
            restful.Get(`${state.matter_categories_url}/${matter_category_id}/`)
                .then(response => {
                    commit(names.MUTATE_MATTER_CATEGORY, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_MATTER_CATEGORIES]: ({
        commit,
        state,
    },) => {
        return new Promise((resolve) => {
            if (state.matter_categories.length > 0) {
                resolve(state.matter_categories);
                return;
            }
            restful.Get(`${state.matter_categories_url}/`)
                .then(response => {
                    commit(names.MUTATE_MATTER_CATEGORIES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_MATTER_CATEGORY]: ({
        commit,
        state
    }, matter_category) => {
        return new Promise((resolve) => {
            restful.Post(`${state.matter_categories_url}/`, matter_category)
                .then(response => {
                    commit(names.MUTATE_MATTER_CATEGORY, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_MATTER_CATEGORY]: ({
        commit,
        state
    }, matter_category) => {
        return new Promise((resolve) => {
            restful.Put(`${state.matter_categories_url}/${matter_category.id}/`, matter_category)
                .then(response => {
                    commit(names.MUTATE_MATTER_CATEGORY, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_MATTER_CATEGORY]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.matter_categories_url}/${payload.matter_category_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_MATTER_CATEGORY, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_MATTER_CATEGORY]: ({
        commit,
        state
    }, matter_category_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.matter_categories_url}/${matter_category_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_MATTER_CATEGORY, matter_category_id);
                    resolve(response);
                });
        });
    },
    // LEARNING_EXPERIENCE
    [names.FETCH_LEARNING_EXPERIENCE]: ({
        commit,
        state
    }, learning_experience_id) => {
        return new Promise((resolve) => {
            const learning_experience = state.learning_experiences.find(x => x.id == learning_experience_id);
            if (learning_experience) {
                resolve(learning_experience);
                return;
            }
            restful.Get(`${state.learning_experiences_url}/${learning_experience_id}/`)
                .then(response => {
                    commit(names.MUTATE_LEARNING_EXPERIENCE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_LEARNING_EXPERIENCES]: ({
        commit,
        state,
    }, matter_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.learning_experiences_url}/?matter=${matter_id}`)
                .then(response => {
                    commit(names.MUTATE_LEARNING_EXPERIENCES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_LEARNING_EXPERIENCE]: ({
        commit,
        state
    }, learning_experience) => {
        return new Promise((resolve) => {
            restful.Post(`${state.learning_experiences_url}/`, learning_experience)
                .then(response => {
                    commit(names.MUTATE_LEARNING_EXPERIENCE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_LEARNING_EXPERIENCE]: ({
        commit,
        state
    }, learning_experience) => {
        return new Promise((resolve) => {
            restful.Put(`${state.learning_experiences_url}/${learning_experience.id}/`, learning_experience)
                .then(response => {
                    commit(names.MUTATE_LEARNING_EXPERIENCE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_LEARNING_EXPERIENCE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.learning_experiences_url}/${payload.learning_experience_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_LEARNING_EXPERIENCE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_LEARNING_EXPERIENCE]: ({
        commit,
        state
    }, learning_experience_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.learning_experiences_url}/${learning_experience_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_LEARNING_EXPERIENCE, learning_experience_id);
                    resolve(response);
                });
        });
    },
    //Matter Evaluation
    [names.FETCH_MATTER_EVALUATION]: ({
        commit,
        state
    }, matter_evaluation_id) => {
        return new Promise((resolve) => {
            const matter_evaluation = state.matter_evaluations.find(x => x.id == matter_evaluation_id);
            if (matter_evaluation) {
                resolve(matter_evaluation);
                return;
            }
            restful.Get(`${state.matter_evaluations_url}/${matter_evaluation_id}/`)
                .then(response => {
                    commit(names.MUTATE_MATTER_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_MATTER_EVALUATIONS]: ({
        commit,
        state,
    }, matter_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.matter_evaluations_url}/?matter=${matter_id}`)
                .then(response => {
                    commit(names.MUTATE_MATTER_EVALUATIONS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_MATTER_EVALUATION]: ({
        commit,
        state
    }, matter_evaluation) => {
        return new Promise((resolve) => {
            restful.Post(`${state.matter_evaluations_url}/`, matter_evaluation)
                .then(response => {
                    commit(names.MUTATE_MATTER_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_MATTER_EVALUATION]: ({
        commit,
        state
    }, matter_evaluation) => {
        return new Promise((resolve) => {
            restful.Put(`${state.matter_evaluations_url}/${matter_evaluation.id}/`, matter_evaluation)
                .then(response => {
                    commit(names.MUTATE_MATTER_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_MATTER_EVALUATION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.matter_evaluations_url}/${payload.matter_evaluation_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_MATTER_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_MATTER_EVALUATION]: ({
        commit,
        state
    }, matter_evaluation_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.matter_evaluations_url}/${matter_evaluation_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_MATTER_EVALUATION, matter_evaluation_id);
                    resolve(response);
                });
        });
    },
    // CHOICE EPM_EVALUATION_TYPES
    [names.FETCH_EPM_EVALUATION_TYPES]: ({
        commit,
        state,
    },) => {
        return new Promise((resolve) => {
            if (state.epm_evaluation_types.length > 0) {
                resolve(state.epm_evaluation_types);
                return;
            }
            restful.Get(`${state.epm_evaluation_type_url}/`)
                .then(response => {
                    commit(names.MUTATE_EPM_EVALUATION_TYPES, response);
                    resolve(response);
                });
        });
    },

}

export default {
    state,
    getters,
    mutations,
    actions,
};