import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    evaluatee_evaluations: [],
    evaluatee_evaluations_url: '/evaluations2/evaluatee-evaluation',
    evaluatee_tests: [],
    evaluatee_tests_url: '/evaluations2/evaluatee-test',
    evaluatee_rubrics: [],
    evaluatee_rubrics_url: '/evaluations2/evaluatee-rubric',
    evaluatee_rubric_criteria_performance_levels: [],
    evaluatee_rubric_criteria_performance_levels_url: '/evaluations2/evaluatee-rubric-criteria-performance-level',
    evaluatee_rubric_criteria_performance_level_evaluators: [],
    evaluatee_rubric_criteria_performance_level_evaluators_url: '/evaluations2/evaluatee-rubric-criteria-performance-level-evaluator',
    evaluatee_redaction_questions: [],
    evaluatee_redaction_questions_url: '/evaluations2/evaluatee-redaction-question',
    evaluatee_multiple_choice_questions: [],
    evaluatee_multiple_choice_questions_url: '/evaluations2/evaluatee-multiple-choice-question',
    evaluatee_multiple_choice_question_options: [],
    evaluatee_multiple_choice_question_options_url: '/evaluations2/evaluatee-multiple-choice-question-option',
    evaluatee_matching_questions: [],
    evaluatee_matching_questions_url: '/evaluations2/evaluatee-matching-question',
    evaluatee_matching_question_units: [],
    evaluatee_matching_question_units_url: '/evaluations2/evaluatee-matching-question-unit',
}

const getters = {
    [names.EVALUATEE_EVALUATIONS]: state => {
        if (!get.store.state.user) return [];
        return state.evaluatee_evaluations.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.EVALUATEE_TESTS]: state => {
        if (!get.store.state.user) return [];
        return state.evaluatee_tests.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.EVALUATEE_RUBRICS]: state => {
        if (!get.store.state.user) return [];
        return state.evaluatee_rubrics.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS]: state => {
        if (!get.store.state.user) return [];
        return state.evaluatee_rubric_criteria_performance_levels.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS]: state => {
        if (!get.store.state.user) return [];
        return state.evaluatee_rubric_criteria_performance_level_evaluators.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.EVALUATEE_REDACTION_QUESTIONS]: state => {
        if (!get.store.state.user) return [];
        return state.evaluatee_redaction_questions.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.EVALUATEE_MULTIPLE_CHOICE_QUESTIONS]: state => {
        if (!get.store.state.user) return [];
        return state.evaluatee_multiple_choice_questions.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS]: state => {
        if (!get.store.state.user) return [];
        return state.evaluatee_multiple_choice_question_options.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.EVALUATEE_MATCHING_QUESTIONS]: state => {
        if (!get.store.state.user) return [];
        return state.evaluatee_matching_questions.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.EVALUATEE_MATCHING_QUESTION_UNITS]: state => {
        if (!get.store.state.user) return [];
        return state.evaluatee_matching_question_units.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}
const mutations = {
    // EVALUATEE_EVALUATIONS evaluatee_evaluations
    [names.MUTATE_EVALUATEE_EVALUATIONS]: (state, evaluatee_evaluations) => {
        evaluatee_evaluations.forEach(element => {
            let index = state.evaluatee_evaluations.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluatee_evaluations.splice(index, 1, element);
            else state.evaluatee_evaluations.push(element);
        });
    },
    [names.MUTATE_EVALUATEE_EVALUATION]: (state, evaluatee_evaluation) => {
        let index = state.evaluatee_evaluations.findIndex(x => x.id == evaluatee_evaluation.id);
        if (index != -1) state.evaluatee_evaluations.splice(index, 1, evaluatee_evaluation);
        else state.evaluatee_evaluations.push(evaluatee_evaluation);
    },
    [names.MUTATE_DELETE_EVALUATEE_EVALUATION]: (state, evaluatee_evaluation_id) => {
        let index = state.evaluatee_evaluations.findIndex(x => x.id == evaluatee_evaluation_id);
        if (index != -1) state.evaluatee_evaluations.splice(index, 1);
    },
    // EVALUATEE_TESTS evaluatee_tests
    [names.MUTATE_EVALUATEE_TESTS]: (state, evaluatee_tests) => {
        evaluatee_tests.forEach(element => {
            let index = state.evaluatee_tests.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluatee_tests.splice(index, 1, element);
            else state.evaluatee_tests.push(element);
        });
    },
    [names.MUTATE_EVALUATEE_TEST]: (state, evaluatee_test) => {
        let index = state.evaluatee_tests.findIndex(x => x.id == evaluatee_test.id);
        if (index != -1) state.evaluatee_tests.splice(index, 1, evaluatee_test);
        else state.evaluatee_tests.push(evaluatee_test);
    },
    [names.MUTATE_DELETE_EVALUATEE_TEST]: (state, evaluatee_test_id) => {
        let index = state.evaluatee_tests.findIndex(x => x.id == evaluatee_test_id);
        if (index != -1) state.evaluatee_tests.splice(index, 1);
    },
    ["MUTATE_DELETE_STUDENT_FILE_IN_EVALUATEE_TEST"]: (state, payload) => {
        const index = state.evaluatee_tests.findIndex(x => x.id == payload.evaluatee_test_id);
        if (index != -1) {
            const index2 = state.evaluatee_tests[index].student_attachments.findIndex(x => x == payload.file_id);
            if (index2 != -1)
                state.evaluatee_tests[index].student_attachments.splice(index2, 1);
        }
    },
    ["MUTATE_DELETE_EVALUATOR_FILE_IN_EVALUATEE_TEST"]: (state, payload) => {
        const index = state.evaluatee_tests.findIndex(x => x.id == payload.evaluatee_test_id);
        if (index != -1) {
            const index2 = state.evaluatee_tests[index].evaluator_attachments.findIndex(x => x == payload.file_id);
            if (index2 != -1)
                state.evaluatee_tests[index].evaluator_attachments.splice(index2, 1);
        }
    },
    // EVALUATEE_RUBRICS evaluatee_rubrics
    [names.MUTATE_EVALUATEE_RUBRICS]: (state, evaluatee_rubrics) => {
        evaluatee_rubrics.forEach(element => {
            let index = state.evaluatee_rubrics.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluatee_rubrics.splice(index, 1, element);
            else state.evaluatee_rubrics.push(element);
        });
    },
    [names.MUTATE_EVALUATEE_RUBRIC]: (state, evaluatee_rubric) => {
        let index = state.evaluatee_rubrics.findIndex(x => x.id == evaluatee_rubric.id);
        if (index != -1) state.evaluatee_rubrics.splice(index, 1, evaluatee_rubric);
        else state.evaluatee_rubrics.push(evaluatee_rubric);
    },
    [names.MUTATE_DELETE_EVALUATEE_RUBRIC]: (state, evaluatee_rubric_id) => {
        let index = state.evaluatee_rubrics.findIndex(x => x.id == evaluatee_rubric_id);
        if (index != -1) state.evaluatee_rubrics.splice(index, 1);
    },
    // EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS evaluatee_rubric_criteria_performance_levels
    [names.MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS]: (state, evaluatee_rubric_criteria_performance_levels) => {
        evaluatee_rubric_criteria_performance_levels.forEach(element => {
            let index = state.evaluatee_rubric_criteria_performance_levels.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluatee_rubric_criteria_performance_levels.splice(index, 1, element);
            else state.evaluatee_rubric_criteria_performance_levels.push(element);
        });
    },
    [names.MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL]: (state, evaluatee_rubric_criteria_performance_level) => {
        let index = state.evaluatee_rubric_criteria_performance_levels.findIndex(x => x.id == evaluatee_rubric_criteria_performance_level.id);
        if (index != -1) state.evaluatee_rubric_criteria_performance_levels.splice(index, 1, evaluatee_rubric_criteria_performance_level);
        else state.evaluatee_rubric_criteria_performance_levels.push(evaluatee_rubric_criteria_performance_level);
    },
    [names.MUTATE_DELETE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL]: (state, evaluatee_rubric_criteria_performance_level_id) => {
        let index = state.evaluatee_rubric_criteria_performance_levels.findIndex(x => x.id == evaluatee_rubric_criteria_performance_level_id);
        if (index != -1) state.evaluatee_rubric_criteria_performance_levels.splice(index, 1);
    },
    // EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS evaluatee_rubric_criteria_performance_level_evaluators
    [names.MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS]: (state, evaluatee_rubric_criteria_performance_level_evaluators) => {
        evaluatee_rubric_criteria_performance_level_evaluators.forEach(element => {
            let index = state.evaluatee_rubric_criteria_performance_level_evaluators.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluatee_rubric_criteria_performance_level_evaluators.splice(index, 1, element);
            else state.evaluatee_rubric_criteria_performance_level_evaluators.push(element);
        });
    },
    [names.MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR]: (state, evaluatee_rubric_criteria_performance_level_evaluator) => {
        let index = state.evaluatee_rubric_criteria_performance_level_evaluators.findIndex(x => x.id == evaluatee_rubric_criteria_performance_level_evaluator.id);
        if (index != -1) state.evaluatee_rubric_criteria_performance_level_evaluators.splice(index, 1, evaluatee_rubric_criteria_performance_level_evaluator);
        else state.evaluatee_rubric_criteria_performance_level_evaluators.push(evaluatee_rubric_criteria_performance_level_evaluator);
    },
    [names.MUTATE_DELETE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR]: (state, evaluatee_rubric_criteria_performance_level_evaluator_id) => {
        let index = state.evaluatee_rubric_criteria_performance_level_evaluators.findIndex(x => x.id == evaluatee_rubric_criteria_performance_level_evaluator_id);
        if (index != -1) state.evaluatee_rubric_criteria_performance_level_evaluators.splice(index, 1);
    },
    // EVALUATEE_REDACTION_QUESTIONS evaluatee_redaction_questions
    [names.MUTATE_EVALUATEE_REDACTION_QUESTIONS]: (state, evaluatee_redaction_questions) => {
        evaluatee_redaction_questions.forEach(element => {
            let index = state.evaluatee_redaction_questions.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluatee_redaction_questions.splice(index, 1, element);
            else state.evaluatee_redaction_questions.push(element);
        });
    },
    [names.MUTATE_EVALUATEE_REDACTION_QUESTION]: (state, evaluatee_redaction_question) => {
        let index = state.evaluatee_redaction_questions.findIndex(x => x.id == evaluatee_redaction_question.id);
        if (index != -1) state.evaluatee_redaction_questions.splice(index, 1, evaluatee_redaction_question);
        else state.evaluatee_redaction_questions.push(evaluatee_redaction_question);
    },
    [names.MUTATE_DELETE_EVALUATEE_REDACTION_QUESTION]: (state, evaluatee_redaction_question_id) => {
        let index = state.evaluatee_redaction_questions.findIndex(x => x.id == evaluatee_redaction_question_id);
        if (index != -1) state.evaluatee_redaction_questions.splice(index, 1);
    },
    // EVALUATEE_MULTIPLE_CHOICE_QUESTIONS evaluatee_multiple_choice_questions
    [names.MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTIONS]: (state, evaluatee_multiple_choice_questions) => {
        evaluatee_multiple_choice_questions.forEach(element => {
            let index = state.evaluatee_multiple_choice_questions.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluatee_multiple_choice_questions.splice(index, 1, element);
            else state.evaluatee_multiple_choice_questions.push(element);
        });
    },
    [names.MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION]: (state, evaluatee_multiple_choice_question) => {
        let index = state.evaluatee_multiple_choice_questions.findIndex(x => x.id == evaluatee_multiple_choice_question.id);
        if (index != -1) state.evaluatee_multiple_choice_questions.splice(index, 1, evaluatee_multiple_choice_question);
        else state.evaluatee_multiple_choice_questions.push(evaluatee_multiple_choice_question);
    },
    [names.MUTATE_DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION]: (state, evaluatee_multiple_choice_question_id) => {
        let index = state.evaluatee_multiple_choice_questions.findIndex(x => x.id == evaluatee_multiple_choice_question_id);
        if (index != -1) state.evaluatee_multiple_choice_questions.splice(index, 1);
    },
    // EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS evaluatee_multiple_choice_question_options
    [names.MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS]: (state, evaluatee_multiple_choice_question_options) => {
        evaluatee_multiple_choice_question_options.forEach(element => {
            let index = state.evaluatee_multiple_choice_question_options.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluatee_multiple_choice_question_options.splice(index, 1, element);
            else state.evaluatee_multiple_choice_question_options.push(element);
        });
    },
    [names.MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION]: (state, evaluatee_multiple_choice_question_option) => {
        let index = state.evaluatee_multiple_choice_question_options.findIndex(x => x.id == evaluatee_multiple_choice_question_option.id);
        if (index != -1) state.evaluatee_multiple_choice_question_options.splice(index, 1, evaluatee_multiple_choice_question_option);
        else state.evaluatee_multiple_choice_question_options.push(evaluatee_multiple_choice_question_option);
    },
    [names.MUTATE_DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION]: (state, evaluatee_multiple_choice_question_option_id) => {
        let index = state.evaluatee_multiple_choice_question_options.findIndex(x => x.id == evaluatee_multiple_choice_question_option_id);
        if (index != -1) state.evaluatee_multiple_choice_question_options.splice(index, 1);
    },
    // EVALUATEE_MATCHING_QUESTIONS evaluatee_matching_questions
    [names.MUTATE_EVALUATEE_MATCHING_QUESTIONS]: (state, evaluatee_matching_questions) => {
        evaluatee_matching_questions.forEach(element => {
            let index = state.evaluatee_matching_questions.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluatee_matching_questions.splice(index, 1, element);
            else state.evaluatee_matching_questions.push(element);
        });
    },
    [names.MUTATE_EVALUATEE_MATCHING_QUESTION]: (state, evaluatee_matching_question) => {
        let index = state.evaluatee_matching_questions.findIndex(x => x.id == evaluatee_matching_question.id);
        if (index != -1) state.evaluatee_matching_questions.splice(index, 1, evaluatee_matching_question);
        else state.evaluatee_matching_questions.push(evaluatee_matching_question);
    },
    [names.MUTATE_DELETE_EVALUATEE_MATCHING_QUESTION]: (state, evaluatee_matching_question_id) => {
        let index = state.evaluatee_matching_questions.findIndex(x => x.id == evaluatee_matching_question_id);
        if (index != -1) state.evaluatee_matching_questions.splice(index, 1);
    },
    // EVALUATEE_MATCHING_QUESTION_UNITS evaluatee_matching_question_units
    [names.MUTATE_EVALUATEE_MATCHING_QUESTION_UNITS]: (state, evaluatee_matching_question_units) => {
        evaluatee_matching_question_units.forEach(element => {
            let index = state.evaluatee_matching_question_units.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluatee_matching_question_units.splice(index, 1, element);
            else state.evaluatee_matching_question_units.push(element);
        });
    },
    [names.MUTATE_EVALUATEE_MATCHING_QUESTION_UNIT]: (state, evaluatee_matching_question_unit) => {
        let index = state.evaluatee_matching_question_units.findIndex(x => x.id == evaluatee_matching_question_unit.id);
        if (index != -1) state.evaluatee_matching_question_units.splice(index, 1, evaluatee_matching_question_unit);
        else state.evaluatee_matching_question_units.push(evaluatee_matching_question_unit);
    },
    [names.MUTATE_DELETE_EVALUATEE_MATCHING_QUESTION_UNIT]: (state, evaluatee_matching_question_unit_id) => {
        let index = state.evaluatee_matching_question_units.findIndex(x => x.id == evaluatee_matching_question_unit_id);
        if (index != -1) state.evaluatee_matching_question_units.splice(index, 1);
    },
}
const actions = {
    // EVALUATEE_EVALUATIONS evaluatee_evaluations
    [names.FETCH_EVALUATEE_EVALUATION]: ({
        commit,
        state
    }, evaluatee_evaluation_id) => {
        return new Promise((resolve) => {
            const instance = state.evaluatee_evaluations.find(x => x.id == evaluatee_evaluation_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.evaluatee_evaluations_url}/${evaluatee_evaluation_id}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATEE_EVALUATIONS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.evaluatee_id = 1
            // Estructura del payload.evaluatees_ids = [1,2,3]
            // Estructura del payload.evaluation_id = 1
            // Estructura del payload.evaluations_ids = [1,2,3]
            if (payload) {
                if (payload.evaluatee_id) url_append += '&evaluatee__id=' + payload.evaluatee_id
                else if (payload.evaluatees_ids) {
                    if (payload.evaluatees_ids.length > 0)
                        url_append += '&evaluatee__id__in=' + payload.evaluatees_ids.join(",")
                    else return resolve([])
                }
                if (payload.evaluation_id) url_append += '&evaluation__id=' + payload.evaluation_id
                else if (payload.evaluations_ids) {
                    if (payload.evaluations_ids.length > 0)
                        url_append += '&evaluation__id__in=' + payload.evaluations_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.evaluatee_evaluations_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_EVALUATIONS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_EVALUATEE_EVALUATION]: ({
        commit,
        state
    }, evaluatee_evaluation) => {
        return new Promise((resolve) => {
            restful.Post(`${state.evaluatee_evaluations_url}/`, evaluatee_evaluation)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATEE_EVALUATION]: ({
        commit,
        state
    }, evaluatee_evaluation) => {
        return new Promise((resolve) => {
            restful.Put(`${state.evaluatee_evaluations_url}/${evaluatee_evaluation.id}/`, evaluatee_evaluation)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATEE_EVALUATION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.evaluatee_evaluations_url}/${payload.evaluatee_evaluation_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATEE_EVALUATION]: ({
        commit,
        state
    }, evaluatee_evaluation_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.evaluatee_evaluations_url}/${evaluatee_evaluation_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EVALUATEE_EVALUATION, evaluatee_evaluation_id);
                    resolve(response);
                });
        });
    },
    // EVALUATEE_TESTS evaluatee_tests
    [names.FETCH_EVALUATEE_TEST]: ({
        commit,
        state
    }, evaluatee_test_id) => {
        return new Promise((resolve) => {
            const instance = state.evaluatee_tests.find(x => x.id == evaluatee_test_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.evaluatee_tests_url}/${evaluatee_test_id}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_TEST, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATEE_TESTS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.evaluatee_id = 1
            // Estructura del payload.evaluatees_ids = [1,2,3]
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.evaluatee_id) url_append += '&evaluatee__id=' + payload.evaluatee_id
                else if (payload.evaluatees_ids) {
                    if (payload.evaluatees_ids.length > 0)
                        url_append += '&evaluatee__id__in=' + payload.evaluatees_ids.join(",")
                    else return resolve([])
                }
                if (payload.test_id) url_append += '&test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.evaluatee_tests_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_TESTS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_EVALUATEE_TEST]: ({
        commit,
        state
    }, evaluatee_test) => {
        return new Promise((resolve) => {
            restful.Post(`${state.evaluatee_tests_url}/`, evaluatee_test)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_TEST, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATEE_TEST]: ({
        commit,
        state
    }, evaluatee_test) => {
        return new Promise((resolve) => {
            restful.Put(`${state.evaluatee_tests_url}/${evaluatee_test.id}/`, evaluatee_test)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_TEST, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATEE_TEST]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.evaluatee_tests_url}/${payload.evaluatee_test_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_TEST, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATEE_TEST]: ({
        commit,
        state
    }, evaluatee_test_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.evaluatee_tests_url}/${evaluatee_test_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EVALUATEE_TEST, evaluatee_test_id);
                    resolve(response);
                });
        });
    },
    // EVALUATEE_RUBRICS evaluatee_rubrics
    [names.FETCH_EVALUATEE_RUBRIC]: ({
        commit,
        state
    }, evaluatee_rubric_id) => {
        return new Promise((resolve) => {
            const instance = state.evaluatee_rubrics.find(x => x.id == evaluatee_rubric_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.evaluatee_rubrics_url}/${evaluatee_rubric_id}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_RUBRIC, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATEE_RUBRICS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.evaluatee_id = 1
            // Estructura del payload.evaluatees_ids = [1,2,3]
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.evaluatee_id) url_append += '&evaluatee__id=' + payload.evaluatee_id
                else if (payload.evaluatees_ids) {
                    if (payload.evaluatees_ids.length > 0)
                        url_append += '&evaluatee__id__in=' + payload.evaluatees_ids.join(",")
                    else return resolve([])
                }
                if (payload.test_id) url_append += '&rubric__test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&rubric__test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.evaluatee_rubrics_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_RUBRICS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_EVALUATEE_RUBRIC]: ({
        commit,
        state
    }, evaluatee_rubric) => {
        return new Promise((resolve) => {
            restful.Post(`${state.evaluatee_rubrics_url}/`, evaluatee_rubric)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_RUBRIC, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATEE_RUBRIC]: ({
        commit,
        state
    }, evaluatee_rubric) => {
        return new Promise((resolve) => {
            restful.Put(`${state.evaluatee_rubrics_url}/${evaluatee_rubric.id}/`, evaluatee_rubric)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_RUBRIC, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATEE_RUBRIC]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.evaluatee_rubrics_url}/${payload.evaluatee_rubric_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_RUBRIC, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATEE_RUBRIC]: ({
        commit,
        state
    }, evaluatee_rubric_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.evaluatee_rubrics_url}/${evaluatee_rubric_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EVALUATEE_RUBRIC, evaluatee_rubric_id);
                    resolve(response);
                });
        });
    },
    // EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS evaluatee_rubric_criteria_performance_levels
    [names.FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL]: ({
        commit,
        state
    }, evaluatee_rubric_criteria_performance_level_id) => {
        return new Promise((resolve) => {
            const instance = state.evaluatee_rubric_criteria_performance_levels.find(x => x.id == evaluatee_rubric_criteria_performance_level_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.evaluatee_rubric_criteria_performance_levels_url}/${evaluatee_rubric_criteria_performance_level_id}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.evaluatee_id = 1
            // Estructura del payload.evaluatees_ids = [1,2,3]
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.evaluatee_id) url_append += '&evaluatee__id=' + payload.evaluatee_id
                else if (payload.evaluatees_ids) {
                    if (payload.evaluatees_ids.length > 0)
                        url_append += '&evaluatee__id__in=' + payload.evaluatees_ids.join(",")
                    else return resolve([])
                }
                if (payload.test_id) url_append += '&test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.evaluatee_rubric_criteria_performance_levels_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL]: ({
        commit,
        state
    }, evaluatee_rubric_criteria_performance_level) => {
        return new Promise((resolve) => {
            restful.Post(`${state.evaluatee_rubric_criteria_performance_levels_url}/`, evaluatee_rubric_criteria_performance_level)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL]: ({
        commit,
        state
    }, evaluatee_rubric_criteria_performance_level) => {
        return new Promise((resolve) => {
            restful.Put(`${state.evaluatee_rubric_criteria_performance_levels_url}/${evaluatee_rubric_criteria_performance_level.id}/`, evaluatee_rubric_criteria_performance_level)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.evaluatee_rubric_criteria_performance_levels_url}/${payload.evaluatee_rubric_criteria_performance_level_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL]: ({
        commit,
        state
    }, evaluatee_rubric_criteria_performance_level_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.evaluatee_rubric_criteria_performance_levels_url}/${evaluatee_rubric_criteria_performance_level_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL, evaluatee_rubric_criteria_performance_level_id);
                    resolve(response);
                });
        });
    },
    // EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS evaluatee_rubric_criteria_performance_level_evaluators
    [names.FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR]: ({
        commit,
        state
    }, evaluatee_rubric_criteria_performance_level_evaluator_id) => {
        return new Promise((resolve) => {
            const instance = state.evaluatee_rubric_criteria_performance_level_evaluators.find(x => x.id == evaluatee_rubric_criteria_performance_level_evaluator_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.evaluatee_rubric_criteria_performance_level_evaluators_url}/${evaluatee_rubric_criteria_performance_level_evaluator_id}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS]: ({
        commit,
        state,
    },
        payload
    ) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Falta implementar Filtros.
            // Estructura del payload.evaluator_id = 1
            // Estructura del payload.evaluators_ids = [1,2,3]
            // Estructura del payload.rubric_performance_level_id = 1
            // Estructura del payload.rubric_performance_levels_ids = [1,2,3]
            // Estructura del payload.evaluatee_rubric_criteria_performance_level_id = 1
            // Estructura del payload.evaluatee_rubric_criteria_performance_levels_ids = [1,2,3]
            if (payload) {
                if (payload.evaluator_id) url_append += '&evaluator__id=' + payload.evaluator_id
                else if (payload.evaluators_ids) {
                    if (payload.evaluators_ids.length > 0) url_append += '&evaluator__id__in=' + payload.evaluators_ids.join(",")
                    else return resolve([])
                }
                // if (payload.rubric_performance_level_id) url_append += '&rubric_performance_level__id=' + payload.rubric_performance_level_id
                // else if (payload.rubric_performance_levels_ids) url_append += '&rubric_performance_level__id__in=' + payload.rubric_performance_levels_ids.join(",")
                if (payload.evaluatee_rubric_criteria_performance_level_id) url_append += '&evaluatee_rubric_criteria_performance_level__id=' + payload.evaluatee_rubric_criteria_performance_level_id
                else if (payload.evaluatee_rubric_criteria_performance_levels_ids) {
                    if (payload.evaluatee_rubric_criteria_performance_levels_ids.length > 0)
                        url_append += '&evaluatee_rubric_criteria_performance_level__id__in=' + payload.evaluatee_rubric_criteria_performance_levels_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.evaluatee_rubric_criteria_performance_level_evaluators_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR]: ({
        commit,
        state
    }, evaluatee_rubric_criteria_performance_level_evaluator) => {
        return new Promise((resolve) => {
            restful.Post(`${state.evaluatee_rubric_criteria_performance_level_evaluators_url}/`, evaluatee_rubric_criteria_performance_level_evaluator)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR]: ({
        commit,
        state
    }, evaluatee_rubric_criteria_performance_level_evaluator) => {
        return new Promise((resolve) => {
            restful.Put(`${state.evaluatee_rubric_criteria_performance_level_evaluators_url}/${evaluatee_rubric_criteria_performance_level_evaluator.id}/`, evaluatee_rubric_criteria_performance_level_evaluator)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.evaluatee_rubric_criteria_performance_level_evaluators_url}/${payload.evaluatee_rubric_criteria_performance_level_evaluator_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR]: ({
        commit,
        state
    }, evaluatee_rubric_criteria_performance_level_evaluator_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.evaluatee_rubric_criteria_performance_level_evaluators_url}/${evaluatee_rubric_criteria_performance_level_evaluator_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR, evaluatee_rubric_criteria_performance_level_evaluator_id);
                    resolve(response);
                });
        });
    },
    // EVALUATEE_REDACTION_QUESTIONS evaluatee_redaction_questions
    [names.FETCH_EVALUATEE_REDACTION_QUESTION]: ({
        commit,
        state
    }, evaluatee_redaction_question_id) => {
        return new Promise((resolve) => {
            const instance = state.evaluatee_redaction_questions.find(x => x.id == evaluatee_redaction_question_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.evaluatee_redaction_questions_url}/${evaluatee_redaction_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_REDACTION_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATEE_REDACTION_QUESTIONS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.evaluatee_id = 1
            // Estructura del payload.evaluatees_ids = [1,2,3]
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.evaluatee_id) url_append += '&evaluatee__id=' + payload.evaluatee_id
                else if (payload.evaluatees_ids) {
                    if (payload.evaluatees_ids.length > 0)
                        url_append += '&evaluatee__id__in=' + payload.evaluatees_ids.join(",")
                    else return resolve([])
                }
                if (payload.test_id) url_append += '&redaction_question__test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&redaction_question__test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.evaluatee_redaction_questions_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_REDACTION_QUESTIONS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_EVALUATEE_REDACTION_QUESTION]: ({
        commit,
        state
    }, evaluatee_redaction_question) => {
        return new Promise((resolve) => {
            restful.Post(`${state.evaluatee_redaction_questions_url}/`, evaluatee_redaction_question)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_REDACTION_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATEE_REDACTION_QUESTION]: ({
        commit,
        state
    }, evaluatee_redaction_question) => {
        return new Promise((resolve) => {
            restful.Put(`${state.evaluatee_redaction_questions_url}/${evaluatee_redaction_question.id}/`, evaluatee_redaction_question)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_REDACTION_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATEE_REDACTION_QUESTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.evaluatee_redaction_questions_url}/${payload.evaluatee_redaction_question_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_REDACTION_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATEE_REDACTION_QUESTION]: ({
        commit,
        state
    }, evaluatee_redaction_question_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.evaluatee_redaction_questions_url}/${evaluatee_redaction_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EVALUATEE_REDACTION_QUESTION, evaluatee_redaction_question_id);
                    resolve(response);
                });
        });
    },
    // EVALUATEE_MULTIPLE_CHOICE_QUESTIONS evaluatee_multiple_choice_questions
    [names.FETCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION]: ({
        commit,
        state
    }, evaluatee_multiple_choice_question_id) => {
        return new Promise((resolve) => {
            const instance = state.evaluatee_multiple_choice_questions.find(x => x.id == evaluatee_multiple_choice_question_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.evaluatee_multiple_choice_questions_url}/${evaluatee_multiple_choice_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATEE_MULTIPLE_CHOICE_QUESTIONS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.evaluatee_id = 1
            // Estructura del payload.evaluatees_ids = [1,2,3]
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.evaluatee_id) url_append += '&evaluatee__id=' + payload.evaluatee_id
                else if (payload.evaluatees_ids) {
                    if (payload.evaluatees_ids.length > 0)
                        url_append += '&evaluatee__id__in=' + payload.evaluatees_ids.join(",")
                    else return resolve([])
                }
                if (payload.test_id) url_append += '&multiple_choice_question__test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0) url_append += '&multiple_choice_question__test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.evaluatee_multiple_choice_questions_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTIONS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_EVALUATEE_MULTIPLE_CHOICE_QUESTION]: ({
        commit,
        state
    }, evaluatee_multiple_choice_question) => {
        return new Promise((resolve) => {
            restful.Post(`${state.evaluatee_multiple_choice_questions_url}/`, evaluatee_multiple_choice_question)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION]: ({
        commit,
        state
    }, evaluatee_multiple_choice_question) => {
        return new Promise((resolve) => {
            restful.Put(`${state.evaluatee_multiple_choice_questions_url}/${evaluatee_multiple_choice_question.id}/`, evaluatee_multiple_choice_question)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.evaluatee_multiple_choice_questions_url}/${payload.evaluatee_multiple_choice_question_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION]: ({
        commit,
        state
    }, evaluatee_multiple_choice_question_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.evaluatee_multiple_choice_questions_url}/${evaluatee_multiple_choice_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION, evaluatee_multiple_choice_question_id);
                    resolve(response);
                });
        });
    },
    // EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS evaluatee_multiple_choice_question_options
    [names.FETCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION]: ({
        commit,
        state
    }, evaluatee_multiple_choice_question_option_id) => {
        return new Promise((resolve) => {
            const instance = state.evaluatee_multiple_choice_question_options.find(x => x.id == evaluatee_multiple_choice_question_option_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.evaluatee_multiple_choice_question_options_url}/${evaluatee_multiple_choice_question_option_id}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.evaluatee_id = 1
            // Estructura del payload.evaluatees_ids = [1,2,3]
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.evaluatee_id) url_append += '&evaluatee_multiple_choice_question__evaluatee__id=' + payload.evaluatee_id
                else if (payload.evaluatees_ids) {
                    if (payload.evaluatees_ids.length > 0)
                        url_append += '&evaluatee_multiple_choice_question__evaluatee__id__in=' + payload.evaluatees_ids.join(",")
                    else return resolve([])
                }

                if (payload.test_id) url_append += '&multiple_choice_question_option__multiple_choice_question__test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&multiple_choice_question_option__multiple_choice_question__test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.evaluatee_multiple_choice_question_options_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION]: ({
        commit,
        state
    }, evaluatee_multiple_choice_question_option) => {
        return new Promise((resolve) => {
            restful.Post(`${state.evaluatee_multiple_choice_question_options_url}/`, evaluatee_multiple_choice_question_option)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION]: ({
        commit,
        state
    }, evaluatee_multiple_choice_question_option) => {
        return new Promise((resolve) => {
            restful.Put(`${state.evaluatee_multiple_choice_question_options_url}/${evaluatee_multiple_choice_question_option.id}/`, evaluatee_multiple_choice_question_option)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.evaluatee_multiple_choice_question_options_url}/${payload.evaluatee_multiple_choice_question_option_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION]: ({
        commit,
        state
    }, evaluatee_multiple_choice_question_option_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.evaluatee_multiple_choice_question_options_url}/${evaluatee_multiple_choice_question_option_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION, evaluatee_multiple_choice_question_option_id);
                    resolve(response);
                });
        });
    },
    // EVALUATEE_MATCHING_QUESTIONS evaluatee_matching_questions
    [names.FETCH_EVALUATEE_MATCHING_QUESTION]: ({
        commit,
        state
    }, evaluatee_matching_question_id) => {
        return new Promise((resolve) => {
            const instance = state.evaluatee_matching_questions.find(x => x.id == evaluatee_matching_question_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.evaluatee_matching_questions_url}/${evaluatee_matching_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MATCHING_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATEE_MATCHING_QUESTIONS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.evaluatee_id = 1
            // Estructura del payload.evaluatees_ids = [1,2,3]
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.evaluatee_id) url_append += '&evaluatee__id=' + payload.evaluatee_id
                else if (payload.evaluatees_ids) {
                    if (payload.evaluatees_ids.length > 0)
                        url_append += '&evaluatee__id__in=' + payload.evaluatees_ids.join(",")
                    else return resolve([])
                }
                if (payload.test_id) url_append += '&matching_question__test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&matching_question__test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }

            }
            restful.Get(`${state.evaluatee_matching_questions_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MATCHING_QUESTIONS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_EVALUATEE_MATCHING_QUESTION]: ({
        commit,
        state
    }, evaluatee_matching_question) => {
        return new Promise((resolve) => {
            restful.Post(`${state.evaluatee_matching_questions_url}/`, evaluatee_matching_question)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MATCHING_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATEE_MATCHING_QUESTION]: ({
        commit,
        state
    }, evaluatee_matching_question) => {
        return new Promise((resolve) => {
            restful.Put(`${state.evaluatee_matching_questions_url}/${evaluatee_matching_question.id}/`, evaluatee_matching_question)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MATCHING_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATEE_MATCHING_QUESTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.evaluatee_matching_questions_url}/${payload.evaluatee_matching_question_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MATCHING_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATEE_MATCHING_QUESTION]: ({
        commit,
        state
    }, evaluatee_matching_question_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.evaluatee_matching_questions_url}/${evaluatee_matching_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EVALUATEE_MATCHING_QUESTION, evaluatee_matching_question_id);
                    resolve(response);
                });
        });
    },
    // EVALUATEE_MATCHING_QUESTION_UNITS evaluatee_matching_question_units
    [names.FETCH_EVALUATEE_MATCHING_QUESTION_UNIT]: ({
        commit,
        state
    }, evaluatee_matching_question_unit_id) => {
        return new Promise((resolve) => {
            const instance = state.evaluatee_matching_question_units.find(x => x.id == evaluatee_matching_question_unit_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.evaluatee_matching_question_units_url}/${evaluatee_matching_question_unit_id}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MATCHING_QUESTION_UNIT, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATEE_MATCHING_QUESTION_UNITS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.evaluatee_id = 1
            // Estructura del payload.evaluatees_ids = [1,2,3]
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.evaluatee_id) url_append += '&evaluatee_matching_question__evaluatee__id=' + payload.evaluatee_id
                else if (payload.evaluatees_ids) {
                    if (payload.evaluatees_ids.length > 0)
                        url_append += '&evaluatee_matching_question__evaluatee__id__in=' + payload.evaluatees_ids.join(",")
                    else resolve()
                }
                if (payload.test_id) url_append += '&matching_question_unit_middle_relation__matching_question_unit_1__matching_question__test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&matching_question_unit_middle_relation__matching_question_unit_1__matching_question__test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.evaluatee_matching_question_units_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MATCHING_QUESTION_UNITS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_EVALUATEE_MATCHING_QUESTION_UNIT]: ({
        commit,
        state
    }, evaluatee_matching_question_unit) => {
        return new Promise((resolve) => {
            restful.Post(`${state.evaluatee_matching_question_units_url}/`, evaluatee_matching_question_unit)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MATCHING_QUESTION_UNIT, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATEE_MATCHING_QUESTION_UNIT]: ({
        commit,
        state
    }, evaluatee_matching_question_unit) => {
        return new Promise((resolve) => {
            restful.Put(`${state.evaluatee_matching_question_units_url}/${evaluatee_matching_question_unit.id}/`, evaluatee_matching_question_unit)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MATCHING_QUESTION_UNIT, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATEE_MATCHING_QUESTION_UNIT]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.evaluatee_matching_question_units_url}/${payload.evaluatee_matching_question_unit_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EVALUATEE_MATCHING_QUESTION_UNIT, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATEE_MATCHING_QUESTION_UNIT]: ({
        commit,
        state
    }, evaluatee_matching_question_unit_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.evaluatee_matching_question_units_url}/${evaluatee_matching_question_unit_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EVALUATEE_MATCHING_QUESTION_UNIT, evaluatee_matching_question_unit_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};