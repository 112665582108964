import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    time_allocations: [],
    time_allocations_url: '/mesh/time-allocation',
    matter_time_allocations: [],
    matter_time_allocations_url: '/mesh/matter-time-allocation',
    ep_matter_time_allocations: [],
    ep_matter_time_allocations_url: '/mesh/egress-profile-matter-time-allocation'
}

const getters = {
    [names.TIME_ALLOCATIONS]: state => {
        if (!get.store.state.user) return [];
        return state.time_allocations.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.order - b.order);
    },
    [names.MATTER_TIME_ALLOCATIONS]: state => {
        if (!get.store.state.user) return [];
        return state.matter_time_allocations.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.EP_MATTER_TIME_ALLOCATIONS]: state => {
        if (!get.store.state.user) return [];
        return state.ep_matter_time_allocations.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    // time_allocations
    [names.MUTATE_TIME_ALLOCATIONS]: (state, time_allocations) => {
        time_allocations.forEach(element => {
            let index = state.time_allocations.findIndex(x => x.id == element.id);
            if (index != -1) state.time_allocations.splice(index, 1, element);
            else state.time_allocations.push(element);
        });
    },
    [names.MUTATE_TIME_ALLOCATION]: (state, time_allocation) => {
        let index = state.time_allocations.findIndex(x => x.id == time_allocation.id);
        if (index != -1) state.time_allocations.splice(index, 1, time_allocation);
        else state.time_allocations.push(time_allocation);
    },
    [names.MUTATE_DELETE_TIME_ALLOCATION]: (state, time_allocation_id) => {
        let index = state.time_allocations.findIndex(x => x.id == time_allocation_id);
        if (index != -1) state.time_allocations.splice(index, 1);
    },
    // matter_time_allocations
    [names.MUTATE_MATTER_TIME_ALLOCATIONS]: (state, matter_time_allocations) => {
        matter_time_allocations.forEach(element => {
            let index = state.matter_time_allocations.findIndex(x => x.id == element.id);
            if (index != -1) state.matter_time_allocations.splice(index, 1, element);
            else state.matter_time_allocations.push(element);
        });
    },
    [names.MUTATE_MATTER_TIME_ALLOCATION]: (state, matter_time_allocation) => {
        let index = state.matter_time_allocations.findIndex(x => x.id == matter_time_allocation.id);
        if (index != -1) state.matter_time_allocations.splice(index, 1, matter_time_allocation);
        else state.matter_time_allocations.push(matter_time_allocation);
    },
    [names.MUTATE_DELETE_MATTER_TIME_ALLOCATION]: (state, matter_time_allocation_id) => {
        let index = state.matter_time_allocations.findIndex(x => x.id == matter_time_allocation_id);
        if (index != -1) state.matter_time_allocations.splice(index, 1);
    },
    // ep_matter_time_allocations
    [names.MUTATE_EP_MATTER_TIME_ALLOCATIONS]: (state, ep_matter_time_allocations) => {
        ep_matter_time_allocations.forEach(element => {
            let index = state.ep_matter_time_allocations.findIndex(x => x.id == element.id);
            if (index != -1) state.ep_matter_time_allocations.splice(index, 1, element);
            else state.ep_matter_time_allocations.push(element);
        });
    },
    [names.MUTATE_EP_MATTER_TIME_ALLOCATION]: (state, ep_matter_time_allocation) => {
        let index = state.ep_matter_time_allocations.findIndex(x => x.id == ep_matter_time_allocation.id);
        if (index != -1) state.ep_matter_time_allocations.splice(index, 1, ep_matter_time_allocation);
        else state.ep_matter_time_allocations.push(ep_matter_time_allocation);
    },
    [names.MUTATE_DELETE_EP_MATTER_TIME_ALLOCATION]: (state, ep_matter_time_allocation_id) => {
        let index = state.ep_matter_time_allocations.findIndex(x => x.id == ep_matter_time_allocation_id);
        if (index != -1) state.ep_matter_time_allocations.splice(index, 1);
    },
}

const actions = {
    // time_allocations
    [names.FETCH_TIME_ALLOCATION]: ({
        commit,
        state
    }, time_allocation_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.time_allocations_url}/${time_allocation_id}/`)
                .then(response => {
                    commit(names.MUTATE_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_TIME_ALLOCATIONS]: ({
        commit,
        state,
    }, institution_id) => {
        return new Promise((resolve) => {
            if (institution_id && state.time_allocations.filter(x => x.institution == institution_id).length > 0) {
                resolve(state.time_allocations);
                return;
            } else {
                let partial_ur = ''
                if (institution_id) partial_ur = '?institution=' + institution_id
                restful.Get(`${state.time_allocations_url}/${partial_ur}`)
                    .then(response => {
                        commit(names.MUTATE_TIME_ALLOCATIONS, response);
                        resolve(response);
                    });
            }
        });
    },
    [names.POST_TIME_ALLOCATION]: ({
        commit,
        state
    }, time_allocation) => {
        return new Promise((resolve) => {
            restful.Post(`${state.time_allocations_url}/`, time_allocation)
                .then(response => {
                    commit(names.MUTATE_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_TIME_ALLOCATION]: ({
        commit,
        state
    }, time_allocation) => {
        return new Promise((resolve) => {
            restful.Put(`${state.time_allocations_url}/${time_allocation.id}/`, time_allocation)
                .then(response => {
                    commit(names.MUTATE_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_TIME_ALLOCATION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.time_allocations_url}/${payload.time_allocation_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_TIME_ALLOCATION]: ({
        commit,
        state
    }, time_allocation_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.time_allocations_url}/${time_allocation_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_TIME_ALLOCATION, time_allocation_id);
                    resolve(response);
                });
        });
    },
    // matter_time_allocations
    [names.FETCH_MATTER_TIME_ALLOCATION]: ({
        commit,
        state
    }, matter_time_allocation_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.matter_time_allocations_url}/${matter_time_allocation_id}/`)
                .then(response => {
                    commit(names.MUTATE_MATTER_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_MATTER_TIME_ALLOCATIONS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '';
            if (payload) {
                if (payload.egress_profile_id)
                    url_append += '?egress_profile=' + payload.egress_profile_id
                else
                    if (payload.matter_id) {
                        if (payload.matter_id && state.matter_time_allocations.filter(x => x.matter == payload.matter_id).length > 0) {
                            resolve(state.matter_time_allocations.filter(x => x.matter == payload.matter_id));
                            return;
                        } else if (payload.matter_id) url_append = '?matter=' + payload.matter_id
                    }
            }
            restful.Get(`${state.matter_time_allocations_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_MATTER_TIME_ALLOCATIONS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_MATTER_TIME_ALLOCATION]: ({
        commit,
        state
    }, matter_time_allocation) => {
        return new Promise((resolve) => {
            restful.Post(`${state.matter_time_allocations_url}/`, matter_time_allocation)
                .then(response => {
                    commit(names.MUTATE_MATTER_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_MATTER_TIME_ALLOCATION]: ({
        commit,
        state
    }, matter_time_allocation) => {
        return new Promise((resolve) => {
            restful.Put(`${state.matter_time_allocations_url}/${matter_time_allocation.id}/`, matter_time_allocation)
                .then(response => {
                    commit(names.MUTATE_MATTER_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_MATTER_TIME_ALLOCATION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.matter_time_allocations_url}/${payload.matter_time_allocation_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_MATTER_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_MATTER_TIME_ALLOCATION]: ({
        commit,
        state
    }, matter_time_allocation_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.matter_time_allocations_url}/${matter_time_allocation_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_MATTER_TIME_ALLOCATION, matter_time_allocation_id);
                    resolve(response);
                });
        });
    },
    // ep_matter_time_allocations
    [names.FETCH_EP_MATTER_TIME_ALLOCATION]: ({
        commit,
        state
    }, ep_matter_time_allocation_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.ep_matter_time_allocations_url}/${ep_matter_time_allocation_id}/`)
                .then(response => {
                    commit(names.MUTATE_EP_MATTER_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EP_MATTER_TIME_ALLOCATIONS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '';
            if (payload) {
                if (payload.egress_profile_id)
                    url_append += '?egress_profile=' + payload.egress_profile_id
                else if (payload.egress_profile_matter_id) {
                    if (payload.egress_profile_matter_id && state.ep_matter_time_allocations.filter(x => x.egress_profile_matter == payload.egress_profile_matter_id).length > 0) {
                        resolve(state.ep_matter_time_allocations.filter(x => x.egress_profile_matter == payload.egress_profile_matter_id));
                        return;
                    } else if (payload.egress_profile_matter_id) url_append = '?egress_profile_matter=' + payload.egress_profile_matter_id
                }
            }
            restful.Get(`${state.ep_matter_time_allocations_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_EP_MATTER_TIME_ALLOCATIONS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_EP_MATTER_TIME_ALLOCATION]: ({
        commit,
        state
    }, ep_matter_time_allocation) => {
        return new Promise((resolve) => {
            restful.Post(`${state.ep_matter_time_allocations_url}/`, ep_matter_time_allocation)
                .then(response => {
                    commit(names.MUTATE_EP_MATTER_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EP_MATTER_TIME_ALLOCATION]: ({
        commit,
        state
    }, ep_matter_time_allocation) => {
        return new Promise((resolve) => {
            restful.Put(`${state.ep_matter_time_allocations_url}/${ep_matter_time_allocation.id}/`, ep_matter_time_allocation)
                .then(response => {
                    commit(names.MUTATE_EP_MATTER_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EP_MATTER_TIME_ALLOCATION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.ep_matter_time_allocations_url}/${payload.ep_matter_time_allocation_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EP_MATTER_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EP_MATTER_TIME_ALLOCATION]: ({
        commit,
        state
    }, ep_matter_time_allocation_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.ep_matter_time_allocations_url}/${ep_matter_time_allocation_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EP_MATTER_TIME_ALLOCATION, ep_matter_time_allocation_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};