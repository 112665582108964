import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    evaluation_plannings: [],
    //TODO: no se encuentra la url en el back
    evaluation_plannings_url: '/teaching/evaluation-planning'
}

const getters = {
    [names.EVALUATION_PLANNINGS]: state => {
        if (!get.store.state.user) return [];
        return state.evaluation_plannings.filter((x) => x.school == get.store.state.user.school);
    },
}

const mutations = {
    [names.MUTATE_EVALUATION_PLANNINGS]: (state, evaluation_plannings) => {
        evaluation_plannings.forEach(element => {
            let index = state.evaluation_plannings.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluation_plannings.splice(index, 1, element);
            else state.evaluation_plannings.push(element);
        });
    },
    [names.MUTATE_EVALUATION_PLANNING]: (state, evaluation_planning) => {
        let index = state.evaluation_plannings.findIndex(x => x.id == evaluation_planning.id);
        if (index != -1) state.evaluation_plannings.splice(index, 1, evaluation_planning);
        else state.evaluation_plannings.push(evaluation_planning);
    },
    [names.MUTATE_DELETE_EVALUATION_PLANNING]: (state, evaluation_planning_id) => {
        let index = state.evaluation_plannings.findIndex(x => x.id == evaluation_planning_id);
        if (index != -1) state.evaluation_plannings.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_EVALUATION_PLANNING]: ({
        commit,
        state
    }, evaluation_planning_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.evaluation_plannings_url}/${evaluation_planning_id}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_PLANNING, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATION_PLANNINGS]: ({
        commit,
        state
    }, study_unit_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.evaluation_plannings_url}/?study_unit=${study_unit_id}`)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_PLANNINGS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_EVALUATION_PLANNING]: ({
        commit,
        state
    }, evaluation_planning) => {
        return new Promise((resolve) => {
            restful.Post(`${state.evaluation_plannings_url}/`, evaluation_planning)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_PLANNING, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATION_PLANNING]: ({
        commit,
        state
    }, evaluation_planning) => {
        return new Promise((resolve) => {
            restful.Put(`${state.evaluation_plannings_url}/${evaluation_planning.id}/`, evaluation_planning)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_PLANNING, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATION_PLANNING]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.evaluation_plannings_url}/${payload.evaluation_planning_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_PLANNING, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATION_PLANNING]: ({
        commit,
        state
    }, evaluation_planning_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.evaluation_plannings_url}/${evaluation_planning_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EVALUATION_PLANNING, evaluation_planning_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};