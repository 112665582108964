import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    ra_bases: [],
    ra_bases_url: '/teaching/base-ra',
    egr_profile_ra_bases: [],
    egr_profile_ra_bases_url: '/mesh/egress-profile-base-ra'
}

const getters = {
    [names.RA_BASES]: state => {
        if (!get.store.state.user) return [];
        return state.ra_bases.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.EGR_PROFILE_RA_BASES]: state => {
        if (!get.store.state.user) return [];
        return state.egr_profile_ra_bases.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    // RA_BASE
    [names.MUTATE_RA_BASES]: (state, ra_bases) => {
        ra_bases.forEach(element => {
            let index = state.ra_bases.findIndex(x => x.id == element.id);
            if (index != -1) state.ra_bases.splice(index, 1, element);
            else state.ra_bases.push(element);
        });
    },
    [names.MUTATE_RA_BASE]: (state, ra_base) => {
        let index = state.ra_bases.findIndex(x => x.id == ra_base.id);
        if (index != -1) state.ra_bases.splice(index, 1, ra_base);
        else state.ra_bases.push(ra_base);
    },
    [names.MUTATE_DELETE_RA_BASE]: (state, ra_base_id) => {
        let index = state.ra_bases.findIndex(x => x.id == ra_base_id);
        if (index != -1) state.ra_bases.splice(index, 1);
    },
    // EGR_PROFILE_RA_BASE
    [names.MUTATE_EGR_PROFILE_RA_BASES]: (state, egr_profile_ra_bases) => {
        egr_profile_ra_bases.forEach(element => {
            let index = state.egr_profile_ra_bases.findIndex(x => x.id == element.id);
            if (index != -1) state.egr_profile_ra_bases.splice(index, 1, element);
            else state.egr_profile_ra_bases.push(element);
        });
    },
    [names.MUTATE_EGR_PROFILE_RA_BASE]: (state, egr_profile_ra_base) => {
        let index = state.egr_profile_ra_bases.findIndex(x => x.id == egr_profile_ra_base.id);
        if (index != -1) state.egr_profile_ra_bases.splice(index, 1, egr_profile_ra_base);
        else state.egr_profile_ra_bases.push(egr_profile_ra_base);
    },
    [names.MUTATE_DELETE_EGR_PROFILE_RA_BASE]: (state, egr_profile_ra_base_id) => {
        let index = state.egr_profile_ra_bases.findIndex(x => x.id == egr_profile_ra_base_id);
        if (index != -1) state.egr_profile_ra_bases.splice(index, 1);
    },
    ["MUTATE_DELETE_EGR_PRO_RA_BASE_BY_EGR_PRO_AND_STU_UNI"]: (state, object) => {
        let index = state.egr_profile_ra_bases.findIndex(x => x.egress_profile == object.egress_profile && object.study_units.includes(x.study_unit));
        while (index != -1) {
            state.egr_profile_ra_bases.splice(index, 1);
            index = state.egr_profile_ra_bases.findIndex(x => x.egress_profile == object.egress_profile && object.study_units.includes(x.study_unit));
        }
    },
}

const actions = {
    // RA_BASE
    [names.FETCH_RA_BASE]: ({
        commit,
        state
    }, ra_base_id) => {
        return new Promise((resolve) => {
            const instance = state.ra_bases.find(x => x.id == ra_base_id)
            if (instance) {
                resolve(instance)
                return;
            } else
                restful.Get(`${state.ra_bases_url}/${ra_base_id}/`)
                    .then(response => {
                        commit(names.MUTATE_RA_BASE, response);
                        resolve(response);
                    });
        });
    },
    [names.FETCH_RA_BASES]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '';
            if (payload) {
                if (payload.egress_profile_id)
                    url_append += '?egress_profile=' + payload.egress_profile_id
                else if (payload.competence_id) {
                    if (payload.competence_id && state.ra_bases.filter(x => x.competences.includes(payload.competence_id)).length > 0) {
                        resolve(state.ra_bases.filter(x => x.competences.includes(payload.competence_id)));
                        return;
                    } else if (payload.competence_id) url_append = '?competence=' + payload.competence_id
                }
            }
            restful.Get(`${state.ra_bases_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_RA_BASES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_RA_BASE]: ({
        commit,
        state
    }, ra_base) => {
        return new Promise((resolve) => {
            restful.Post(`${state.ra_bases_url}/`, ra_base)
                .then(response => {
                    commit(names.MUTATE_RA_BASE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_RA_BASE]: ({
        commit,
        state
    }, ra_base) => {
        return new Promise((resolve) => {
            restful.Put(`${state.ra_bases_url}/${ra_base.id}/`, ra_base)
                .then(response => {
                    commit(names.MUTATE_RA_BASE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_RA_BASE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.ra_bases_url}/${payload.ra_base_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_RA_BASE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_RA_BASE]: ({
        commit,
        state
    }, ra_base_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.ra_bases_url}/${ra_base_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_RA_BASE, ra_base_id);
                    resolve(response);
                });
        });
    },
    // EGR_PROFILE_RA_BASE
    [names.FETCH_EGR_PROFILE_RA_BASE]: ({
        commit,
        state
    }, egr_profile_ra_base_id) => {
        return new Promise((resolve) => {
            const instance = state.egr_profile_ra_bases.find(x => x.id == egr_profile_ra_base_id)
            if (instance) {
                resolve(instance)
                return;
            } else
                restful.Get(`${state.egr_profile_ra_bases_url}/${egr_profile_ra_base_id}/`)
                    .then(response => {
                        commit(names.MUTATE_EGR_PROFILE_RA_BASE, response);
                        resolve(response);
                    });
        });
    },
    [names.FETCH_EGR_PROFILE_RA_BASES]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '';
            if (payload) {
                if (payload.egress_profile_id && payload.study_unit_id) {
                    if (state.egr_profile_ra_bases.filter(x => x.study_unit == payload.study_unit_id && x.egress_profile == payload.egress_profile_id).length > 0) {
                        resolve(state.egr_profile_ra_bases.filter(x => x.study_unit == payload.study_unit_id && x.egress_profile == payload.egress_profile_id));
                        return;
                    } else if (payload.egress_profile_id && payload.study_unit_id) url_append = '?egress_profile=' + payload.egress_profile_id + '&study_unit=' + payload.study_unit_id
                }
                else if (payload.egress_profile_id)
                    url_append += '?egress_profile=' + payload.egress_profile_id
                else if (payload.study_unit_id) {
                    if (payload.study_unit_id && state.egr_profile_ra_bases.filter(x => x.study_unit == payload.study_unit_id).length > 0) {
                        resolve(state.egr_profile_ra_bases.filter(x => x.study_unit == payload.study_unit_id));
                        return;
                    } else if (payload.study_unit_id) url_append = '?study_unit=' + payload.study_unit_id
                }
            }
            restful.Get(`${state.egr_profile_ra_bases_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_EGR_PROFILE_RA_BASES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_EGR_PROFILE_RA_BASE]: ({
        commit,
        state
    }, egr_profile_ra_base) => {
        return new Promise((resolve) => {
            restful.Post(`${state.egr_profile_ra_bases_url}/`, egr_profile_ra_base)
                .then(response => {
                    commit(names.MUTATE_EGR_PROFILE_RA_BASE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EGR_PROFILE_RA_BASE]: ({
        commit,
        state
    }, egr_profile_ra_base) => {
        return new Promise((resolve) => {
            restful.Put(`${state.egr_profile_ra_bases_url}/${egr_profile_ra_base.id}/`, egr_profile_ra_base)
                .then(response => {
                    commit(names.MUTATE_EGR_PROFILE_RA_BASE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EGR_PROFILE_RA_BASE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.egr_profile_ra_bases_url}/${payload.egr_profile_ra_base_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EGR_PROFILE_RA_BASE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EGR_PROFILE_RA_BASE]: ({
        commit,
        state
    }, egr_profile_ra_base_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.egr_profile_ra_bases_url}/${egr_profile_ra_base_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EGR_PROFILE_RA_BASE, egr_profile_ra_base_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};