import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    profile_competences: [],
    profile_competences_url: '/mesh/egress-profile-competence',
    profile_competences_mention: [],
    profile_competences_mention_url: '/mesh/egress-profile-competence-mention',
}

const getters = {
    [names.PROFILE_COMPETENCES]: state => {
        if (!get.store.state.user) return [];
        return state.profile_competences.filter((x) => x.school == get.store.state.user.school).sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        });
    },
    [names.PROFILE_COMPETENCES_MENTION]: state => {
        if (!get.store.state.user) return [];
        return state.profile_competences_mention.filter((x) => x.school == get.store.state.user.school).sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        });
    },
}

const mutations = {
    [names.MUTATE_PROFILE_COMPETENCES]: (state, profile_competences) => {
        profile_competences.forEach(element => {
            let index = state.profile_competences.findIndex(x => x.id == element.id);
            if (index != -1) state.profile_competences.splice(index, 1, element);
            else state.profile_competences.push(element);
        });
    },
    [names.MUTATE_PROFILE_COMPETENCE]: (state, profile_competences) => {
        let index = state.profile_competences.findIndex(x => x.id == profile_competences.id);
        if (index != -1) state.profile_competences.splice(index, 1, profile_competences);
        else state.profile_competences.push(profile_competences);
    },
    [names.MUTATE_DELETE_PROFILE_COMPETENCE]: (state, profile_competence_id) => {
        let index = state.profile_competences.findIndex(x => x.id == profile_competence_id);
        if (index != -1) state.profile_competences.splice(index, 1);
    },
    ["MUTATE_PROFILE_COMPETENCE_BY_DELETE_CAPACITY"]: (state, capacity_id) => {
        let index = state.profile_competences.findIndex(x => x.capacities.includes(capacity_id));
        while (index != -1) {
            let index2 = state.profile_competences[index].capacities.findIndex(x => x == capacity_id);
            if (index2 != -1) {
                state.profile_competences[index].capacities.splice(index2, 1);
                index = state.profile_competences.findIndex(x => x.capacities.includes(capacity_id));
            }
        }
    },
    ["MUTATE_DELETE_PROFILE_COMPETENCE_MENTION_BY_PROFILE_COMPETENCE"]: (state, profile_competence_id) => {
        let index1 = state.profile_competences_mention.findIndex(x => x.egress_profile_competences.includes(profile_competence_id));
        while (index1 != -1) {
            const index2 = state.profile_competences_mention[index1].egress_profile_competences.findIndex(x => x == profile_competence_id);
            if (index2 != -1) {
                state.profile_competences_mention[index1].egress_profile_competences.splice(index2, 1);
            }
            index1 = state.profile_competences_mention.findIndex(x => x.egress_profile_competences.includes(profile_competence_id));
        }
    },
    [names.MUTATE_PROFILE_COMPETENCES_MENTION]: (state, profile_competences_mention) => {
        profile_competences_mention.forEach(element => {
            let index = state.profile_competences_mention.findIndex(x => x.id == element.id);
            if (index != -1) state.profile_competences_mention.splice(index, 1, element);
            else state.profile_competences_mention.push(element);
        });
    },
    [names.MUTATE_PROFILE_COMPETENCE_MENTION]: (state, profile_competences_mention) => {
        let index = state.profile_competences_mention.findIndex(x => x.id == profile_competences_mention.id);
        if (index != -1) state.profile_competences_mention.splice(index, 1, profile_competences_mention);
        else state.profile_competences_mention.push(profile_competences_mention);
    },
    [names.MUTATE_DELETE_PROFILE_COMPETENCE_MENTION]: (state, profile_competences_mention_id) => {
        let index = state.profile_competences_mention.findIndex(x => x.id == profile_competences_mention_id);
        if (index != -1) state.profile_competences_mention.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_PROFILE_COMPETENCE]: ({
        commit,
        state
    }, profile_competence_id) => {
        return new Promise((resolve) => {
            const profile_competences = state.profile_competences.find(x => x.id == profile_competence_id);
            if (profile_competences) {
                resolve(profile_competences);
                return;
            }
            restful.Get(`${state.profile_competences_url}/${profile_competence_id}/`)
                .then(response => {
                    commit(names.MUTATE_PROFILE_COMPETENCE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_PROFILE_COMPETENCES]: ({
        commit,
        state
    }, search_payload) => {
        return new Promise((resolve) => {
            let arr = [];
            if (search_payload && search_payload.egress_profile_id) arr.push('egress_profile__id=' + search_payload.egress_profile_id);
            if (search_payload && search_payload.competence_id) arr.push('competence__id=' + search_payload.competence_id);
            if (search_payload && search_payload.cycle_id) arr.push('cycle__id=' + search_payload.cycle_id);
            let partial_ur = '';
            if (arr.length > 0) partial_ur = '?' + arr.join('&&');
            restful.Get(`${state.profile_competences_url}/${partial_ur}`)
                .then(response => {
                    commit(names.MUTATE_PROFILE_COMPETENCES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_PROFILE_COMPETENCE]: ({
        commit,
        state
    }, profile_competences) => {
        return new Promise((resolve) => {
            restful.Post(`${state.profile_competences_url}/`, profile_competences)
                .then(response => {
                    commit(names.MUTATE_PROFILE_COMPETENCE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_PROFILE_COMPETENCE]: ({
        commit,
        state
    }, profile_competence) => {
        return new Promise((resolve) => {
            restful.Put(`${state.profile_competences_url}/${profile_competence.id}/`, profile_competence)
                .then(response => {
                    commit(names.MUTATE_PROFILE_COMPETENCE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_PROFILE_COMPETENCE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.profile_competences_url}/${payload.profile_competence_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_PROFILE_COMPETENCE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_PROFILE_COMPETENCE]: ({
        commit,
        state
    }, profile_competence_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.profile_competences_url}/${profile_competence_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_PROFILE_COMPETENCE, profile_competence_id);
                    commit("MUTATE_DELETE_PROFILE_COMPETENCE_MENTION_BY_PROFILE_COMPETENCE", profile_competence_id);
                    resolve(response);
                });
        });
    },
    // PROFILE_COMPETENCE_MENTION
    [names.FETCH_PROFILE_COMPETENCE_MENTION]: ({
        commit,
        state
    }, profile_competence_mention_id) => {
        return new Promise((resolve) => {
            const profile_competences_mention = state.profile_competences_mention.find(x => x.id == profile_competence_mention_id);
            if (profile_competences_mention) {
                resolve(profile_competences_mention);
                return;
            }
            restful.Get(`${state.profile_competences_mention_url}/${profile_competence_mention_id}/`)
                .then(response => {
                    commit(names.MUTATE_PROFILE_COMPETENCE_MENTION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_PROFILE_COMPETENCES_MENTION]: ({
        commit,
        state
    }, search_payload) => {
        return new Promise((resolve) => {
            let arr = [];
            if (search_payload && search_payload.egress_profile_id) {
                const profile_competences_mention = state.profile_competences_mention.filter(x => x.egress_profile == search_payload.egress_profile_id);
                if (profile_competences_mention.length > 0) {
                    resolve(profile_competences_mention);
                    return;
                } else
                    arr.push('egress_profile=' + search_payload.egress_profile_id);
            }
            let partial_ur = '';
            if (arr.length > 0) partial_ur = '?' + arr.join('&&');
            restful.Get(`${state.profile_competences_mention_url}/${partial_ur}`)
                .then(response => {
                    commit(names.MUTATE_PROFILE_COMPETENCES_MENTION, response);
                    resolve(response);
                });
        });
    },
    [names.POST_PROFILE_COMPETENCE_MENTION]: ({
        commit,
        state
    }, profile_competences_mention) => {
        return new Promise((resolve) => {
            restful.Post(`${state.profile_competences_mention_url}/`, profile_competences_mention)
                .then(response => {
                    commit(names.MUTATE_PROFILE_COMPETENCE_MENTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_PROFILE_COMPETENCE_MENTION]: ({
        commit,
        state
    }, profile_competence_mention) => {
        return new Promise((resolve) => {
            restful.Put(`${state.profile_competences_mention_url}/${profile_competence_mention.id}/`, profile_competence_mention)
                .then(response => {
                    commit(names.MUTATE_PROFILE_COMPETENCE_MENTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_PROFILE_COMPETENCE_MENTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.profile_competences_mention_url}/${payload.profile_competence_mention_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_PROFILE_COMPETENCE_MENTION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_PROFILE_COMPETENCE_MENTION]: ({
        commit,
        state
    }, profile_competence_mention_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.profile_competences_mention_url}/${profile_competence_mention_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_PROFILE_COMPETENCE_MENTION, profile_competence_mention_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};