import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    matter_performance_indicators: [],
    //TODO: URL fue eliminada del Backend.
    matter_performance_indicators_url: '/teaching/matter-performance-indicator'
}

const getters = {
    [names.MATTER_PERFORMANCE_INDICATORS]: state => {
        if (!get.store.state.user) return [];
        return state.matter_performance_indicators.filter((x) => x.school == get.store.state.user.school);
    },
}

const mutations = {
    [names.MUTATE_MATTER_PERFORMANCE_INDICATORS]: (state, matter_performance_indicators) => {
        matter_performance_indicators.forEach(element => {
            let index = state.matter_performance_indicators.findIndex(x => x.id == element.id);
            if (index != -1) state.matter_performance_indicators.splice(index, 1, element);
            else state.matter_performance_indicators.push(element);
        });
    },
    [names.MUTATE_MATTER_PERFORMANCE_INDICATOR]: (state, matter_performance_indicator) => {
        let index = state.matter_performance_indicators.findIndex(x => x.id == matter_performance_indicator.id);
        if (index != -1) state.matter_performance_indicators.splice(index, 1, matter_performance_indicator);
        else state.matter_performance_indicators.push(matter_performance_indicator);
    },
    [names.MUTATE_DELETE_MATTER_PERFORMANCE_INDICATOR]: (state, matter_performance_indicator_id) => {
        let index = state.matter_performance_indicators.findIndex(x => x.id == matter_performance_indicator_id);
        if (index != -1) state.matter_performance_indicators.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_MATTER_PERFORMANCE_INDICATOR]: ({
        commit,
        state
    }, matter_performance_indicator_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.matter_performance_indicators_url}/${matter_performance_indicator_id}/`)
                .then(response => {
                    commit(names.MUTATE_MATTER_PERFORMANCE_INDICATOR, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_MATTER_PERFORMANCE_INDICATORS]: ({
        commit,
        state
    }, matter_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.matter_performance_indicators_url}/?matter=${matter_id}`)
                .then(response => {
                    commit(names.MUTATE_MATTER_PERFORMANCE_INDICATORS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_MATTER_PERFORMANCE_INDICATOR]: ({
        commit,
        state
    }, matter_performance_indicator) => {
        return new Promise((resolve) => {
            restful.Post(`${state.matter_performance_indicators_url}/`, matter_performance_indicator)
                .then(response => {
                    commit(names.MUTATE_MATTER_PERFORMANCE_INDICATOR, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_MATTER_PERFORMANCE_INDICATOR]: ({
        commit,
        state
    }, matter_performance_indicator) => {
        return new Promise((resolve) => {
            restful.Put(`${state.matter_performance_indicators_url}/${matter_performance_indicator.id}/`, matter_performance_indicator)
                .then(response => {
                    commit(names.MUTATE_MATTER_PERFORMANCE_INDICATOR, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_MATTER_PERFORMANCE_INDICATOR]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.matter_performance_indicators_url}/${payload.matter_performance_indicator_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_MATTER_PERFORMANCE_INDICATOR, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_MATTER_PERFORMANCE_INDICATOR]: ({
        commit,
        state
    }, matter_performance_indicator_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.matter_performance_indicators_url}/${matter_performance_indicator_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_MATTER_PERFORMANCE_INDICATOR, matter_performance_indicator_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};