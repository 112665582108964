<template>
  <b-button
    size="sm"
    variant="none"
    title="Eliminar"
    @click="click"
    class="mr-1"
  >
    <b-icon-trash></b-icon-trash>
  </b-button>
</template>

<script>
export default {
  name: "ButtonDelete",
  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>

<style scoped>
button {
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
}
</style>