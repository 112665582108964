import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    bibliographic_resource_types: [],
    bibliographic_resource_types_url: '/teaching/bibliographic-resource-type'
};

const getters = {
    [names.BIBLIOGRAPHIC_RESOURCE_TYPES]: state => {
        if (!get.store.state.user) return [];
        return state.bibliographic_resource_types.filter((x) => x.school == get.store.state.user.school).sort(function(a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        });
    },
};

const mutations = {
    [names.MUTATE_BIBLIOGRAPHIC_RESOURCE_TYPES]: (state, bibliographic_resource_types) => {
        bibliographic_resource_types.forEach(element => {
            let index = state.bibliographic_resource_types.findIndex(x => x.id == element.id);
            if (index != -1) state.bibliographic_resource_types[index] = element;
            else state.bibliographic_resource_types.push(element);
        });
    },
    [names.MUTATE_BIBLIOGRAPHIC_RESOURCE_TYPE]: (state, bibliographic_resource_type) => {
        let index = state.bibliographic_resource_types.findIndex(x => x.id == bibliographic_resource_type.id);
        if (index != -1) state.bibliographic_resource_types[index] = bibliographic_resource_type;
        else state.bibliographic_resource_types.push(bibliographic_resource_type);
    },
    [names.MUTATE_DELETE_BIBLIOGRAPHIC_RESOURCE_TYPE]: (state, bibliographic_resource_type_id) => {
        let index = state.bibliographic_resource_types.findIndex(x => x.id == bibliographic_resource_type_id);
        if (index != -1) state.bibliographic_resource_types.splice(index, 1);
    },
};

const actions = {
    [names.FETCH_BIBLIOGRAPHIC_RESOURCE_TYPE]: ({
        commit,
        state
    }, bibliographic_resource_type_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.bibliographic_resource_types_url}/${bibliographic_resource_type_id}/`)
                .then(response => {
                    commit(names.MUTATE_BIBLIOGRAPHIC_RESOURCE_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_BIBLIOGRAPHIC_RESOURCE_TYPES]: ({
        commit,
        state,
    }) => {
        return new Promise((resolve) => {
            restful.Get(`${state.bibliographic_resource_types_url}/`)
                .then(response => {
                    commit(names.MUTATE_BIBLIOGRAPHIC_RESOURCE_TYPES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_BIBLIOGRAPHIC_RESOURCE_TYPE]: ({
        commit,
        state
    }, bibliographic_resource_type) => {
        return new Promise((resolve) => {
            restful.Post(`${state.bibliographic_resource_types_url}/`, bibliographic_resource_type)
                .then(response => {
                    commit(names.MUTATE_BIBLIOGRAPHIC_RESOURCE_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_BIBLIOGRAPHIC_RESOURCE_TYPE]: ({
        commit,
        state
    }, bibliographic_resource_type) => {
        return new Promise((resolve) => {
            restful.Put(`${state.bibliographic_resource_types_url}/${bibliographic_resource_type.id}/`, bibliographic_resource_type)
                .then(response => {
                    commit(names.MUTATE_BIBLIOGRAPHIC_RESOURCE_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_BIBLIOGRAPHIC_RESOURCE_TYPE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.bibliographic_resource_types_url}/${payload.bibliographic_resource_type_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_BIBLIOGRAPHIC_RESOURCE_TYPE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_BIBLIOGRAPHIC_RESOURCE_TYPE]: ({
        commit,
        state
    }, bibliographic_resource_type_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.bibliographic_resource_types_url}/${bibliographic_resource_type_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_BIBLIOGRAPHIC_RESOURCE_TYPE, bibliographic_resource_type_id);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};