import restful from "@/utils/restful";
import * as names from "@/store/names";
import * as get from "@/store/store";

const state = {
  regions: [],
  regions_url: "/common/region",
};

const getters = {
  [names.REGIONS]: (state) => {
    if (!get.store.state.user) return [];
    return state.regions.filter((x) => x.school == get.store.state.user.school)
  },
};

const mutations = {
  [names.MUTATE_REGIONS]: (state, regions) => {
    regions.forEach((element) => {
      let index = state.regions.findIndex((x) => x.id == element.id);
      if (index != -1) state.regions.splice(index, 1, element);
      else state.regions.push(element);
    });
  },
  [names.MUTATE_REGION]: (state, region) => {
    let index = state.regions.findIndex((x) => x.id == region.id);
    if (index != -1) state.regions.splice(index, 1, region);
    else state.regions.push(region);
  },
  [names.MUTATE_DELETE_REGION]: (state, region_id) => {
    let index = state.regions.findIndex((x) => x.id == region_id);
    if (index != -1) state.regions.splice(index, 1);
  },
};

const actions = {
  [names.FETCH_REGION]: ({ commit, state }, region_id) => {
    return new Promise((resolve) => {
      restful
        .Get(`${state.regions_url}/${region_id}/`)
        .then((response) => {
          commit(names.MUTATE_REGION, response);
          resolve(response);
        });
    });
  },
  [names.FETCH_REGIONS]: ({ commit, state }, country_id) => {
    const url_filter = country_id ? `?country=${country_id}` : '';
    return new Promise((resolve) => {
      restful.Get(`${state.regions_url}/${url_filter}`)
        .then(response => {
          commit(names.MUTATE_REGIONS, response);
          resolve(response);
        });
    });
  },
  [names.POST_REGION]: ({ commit, state }, region) => {
    return new Promise((resolve) => {
      restful
        .Post(`${state.regions_url}/`, region)
        .then((response) => {
          commit(names.MUTATE_REGION, response);
          resolve(response);
        });
    });
  },
  [names.UPDATE_REGION]: ({ commit, state }, region) => {
    return new Promise((resolve) => {
      restful
        .Put(`${state.regions_url}/${region.id}/`, region)
        .then((response) => {
          commit(names.MUTATE_REGION, response);
          resolve(response);
        });
    });
  },
  [names.PATCH_REGION]: ({ commit, state }, payload) => {
    return new Promise((resolve) => {
      restful
        .Patch(
          `${state.regions_url}/${payload.region_id}/`,
          payload.item
        )
        .then((response) => {
          commit(names.MUTATE_REGION, response);
          resolve(response);
        });
    });
  },
  [names.DELETE_REGION]: ({ commit, state }, region_id) => {
    return new Promise((resolve) => {
      restful
        .Delete(`${state.regions_url}/${region_id}/`)
        .then((response) => {
          commit(names.MUTATE_DELETE_REGION, region_id);
          resolve(response);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
