import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    redaction_answers: [],
    redaction_answers_url: '/evaluation/redaction-answer',
    select_answers: [],
    select_answers_url: '/evaluation/multiple-choice-question-answer',
};

const getters = {
    [names.REDACTION_ANSWERS]: state => {
        if (!get.store.state.user) return [];
        return state.redaction_answers.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.SELECT_ANSWERS]: state => {
        if (!get.store.state.user) return [];
        return state.select_answers.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
};

const mutations = {
    [names.MUTATE_REDACTION_ANSWER]: (state, redaction_answer) => {
        let index = state.redaction_answers.findIndex(x => x.id == redaction_answer.id);
        if (index != -1) state.redaction_answers.splice(index, 1, redaction_answer);
        else state.redaction_answers.push(redaction_answer);
    },
    [names.MUTATE_REDACTION_ANSWERS]: (state, redaction_answers) => {
        redaction_answers.forEach(element => {
            let index = state.redaction_answers.findIndex(x => x.id == element.id);
            if (index != -1) state.redaction_answers.splice(index, 1, element);
            else state.redaction_answers.push(element);
        });
    },
    [names.MUTATE_DELETE_REDACTION_ANSWER]: (state, redaction_answer_id) => {
        let index = state.redaction_answers.findIndex(x => x.id == redaction_answer_id);
        if (index != -1) state.redaction_answers.splice(index, 1);
    },
    [names.MUTATE_SELECT_ANSWER]: (state, select_answer) => {
        let index = state.select_answers.findIndex(x => x.id == select_answer.id);
        if (index != -1) state.select_answers.splice(index, 1, select_answer);
        else state.select_answers.push(select_answer);
    },
    [names.MUTATE_SELECT_ANSWERS]: (state, select_answers) => {
        select_answers.forEach(element => {
            let index = state.select_answers.findIndex(x => x.id == element.id);
            if (index != -1) state.select_answers.splice(index, 1, element);
            else state.select_answers.push(element);
        });
    },
    [names.MUTATE_DELETE_SELECT_ANSWER]: (state, select_answer_id) => {
        let index = state.select_answers.findIndex(x => x.id == select_answer_id);
        if (index != -1) state.select_answers.splice(index, 1);
    },
};

const actions = {
    [names.FETCH_REDACTION_ANSWER]: ({
        commit,
        state
    }, redaction_answer_id) => {
        return new Promise((resolve) => {
            const redaction_answer = state.redaction_answers.find(x => x.id == redaction_answer_id);
            if (redaction_answer) {
                resolve(redaction_answer);
                return;
            }
            restful.Get(`${state.redaction_answers_url}/${redaction_answer_id}/`)
                .then(response => {
                    commit(names.MUTATE_REDACTION_ANSWER, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_REDACTION_ANSWERS]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '';
            if (payload) {
                if (payload.question_id && payload.user_id)
                    url_append += '?question=' + payload.question_id + '&student=' + payload.user_id
                else if (payload.question_id)
                    url_append += '?question=' + payload.question_id
                else if (payload.user_id)
                    url_append += '?student=' + payload.user_id
            }
            restful.Get(`${state.redaction_answers_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_REDACTION_ANSWERS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_REDACTION_ANSWER]: ({
        commit,
        state
    }, redaction_answer) => {
        return new Promise((resolve) => {
            restful.Post(`${state.redaction_answers_url}/`, redaction_answer)
                .then(response => {
                    commit(names.MUTATE_REDACTION_ANSWER, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_REDACTION_ANSWER]: ({
        commit,
        state
    }, redaction_answer) => {
        return new Promise((resolve) => {
            restful.Put(`${state.redaction_answers_url}/${redaction_answer.id}/`, redaction_answer)
                .then(response => {
                    commit(names.MUTATE_REDACTION_ANSWER, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_REDACTION_ANSWER]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.redaction_answers_url}/${payload.redaction_answer_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_REDACTION_ANSWER, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_REDACTION_ANSWER]: ({
        commit,
        state
    }, redaction_answer_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.redaction_answers_url}/${redaction_answer_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_REDACTION_ANSWER, redaction_answer_id);
                    resolve(response);
                });
        });
    },

    [names.FETCH_SELECT_ANSWER]: ({
        commit,
        state
    }, select_answer_id) => {
        return new Promise((resolve) => {
            const select_answer = state.select_answers.find(x => x.id == select_answer_id);
            if (select_answer) {
                resolve(select_answer);
                return;
            }
            restful.Get(`${state.select_answers_url}/${select_answer_id}/`)
                .then(response => {
                    commit(names.MUTATE_SELECT_ANSWER, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_SELECT_ANSWERS]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '';
            if (payload) {
                if (payload.question_id && payload.user_id)
                    url_append += '?question=' + payload.question_id + '&student=' + payload.user_id
                else if (payload.question_id)
                    url_append += '?question=' + payload.question_id
                else if (payload.user_id)
                    url_append += '?student=' + payload.user_id
            }
            restful.Get(`${state.select_answers_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_SELECT_ANSWERS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_SELECT_ANSWER]: ({
        commit,
        state
    }, select_answer) => {
        return new Promise((resolve) => {
            restful.Post(`${state.select_answers_url}/`, select_answer)
                .then(response => {
                    commit(names.MUTATE_SELECT_ANSWER, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_SELECT_ANSWER]: ({
        commit,
        state
    }, select_answer) => {
        return new Promise((resolve) => {
            restful.Put(`${state.select_answers_url}/${select_answer.id}/`, select_answer)
                .then(response => {
                    commit(names.MUTATE_SELECT_ANSWER, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_SELECT_ANSWER]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.select_answers_url}/${payload.select_answer_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_SELECT_ANSWER, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_SELECT_ANSWER]: ({
        commit,
        state
    }, select_answer_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.select_answers_url}/${select_answer_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_SELECT_ANSWER, select_answer_id);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};