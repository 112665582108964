<template>
  <b-button
    size="sm"
    variant="none"
    title="Editar"
    @click="click"
    class="mr-1"
  >
    <b-icon-pencil-square></b-icon-pencil-square>
  </b-button>
</template>

<script>
export default {
  name: "EditButton",
  props: {
    fill: {
      type: String,
      default: "gray"
    }
  },
  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>

<style scoped>
button {
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
}
</style>