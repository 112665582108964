// import restful from '@/utils/restful';
import * as names from '@/store/names';
// import * as get from '@/store/store';

const state = {
    filter_intelligence: null,
    show_intelligence_sidebar: false,
    intelligence_options: [
        { app_models: 'mesh.competence', id: 1, order: 1 },
        { app_models: 'teaching.studyunit', id: 2, order: 3 },
        { app_models: 'teaching.evaluationcriteriamicro', id: 3, order: 4 },
        { app_models: 'teaching.tempcompetenceunit', id: 4, order: 2 },
        { app_models: 'teaching.ramacro', id: 5, order: 5 },
        { app_models: 'teaching.evaluationcriteriamacro', id: 6, order: 6 },
    ],
    rules_filter_selector: null,
}

const getters = {
    [names.FILTER_INTELLIGENCE]: state => {
        return state.filter_intelligence;
        //intelligence_option: opciones del filtro, la cual es obligatorio
        //egress_profile_id: campo obligatorio 
        //matter_id: campo necesario para generar "study_unit"
        //study_unit_id: campo necesario para generar "evaluation_criteria_micro"
    },
    [names.SHOW_INTELLIGENCE_SIDEBAR]: state => {
        return state.show_intelligence_sidebar
    },
    [names.INTELLIGENCE_OPTIONS]: state => {
        return state.intelligence_options.sort((a, b) => a.order - b.order)
    },
    [names.RULES_FILTER_SELECTOR]: state => {
        return state.rules_filter_selector
    },
}

const mutations = {
    [names.MUTATE_FILTER_INTELLIGENCE]: (state, filter_intelligence) => {
        state.filter_intelligence = filter_intelligence
    },
    [names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR]: (state, show_intelligence_sidebar) => {
        state.show_intelligence_sidebar = show_intelligence_sidebar
    },
    [names.MUTATE_DELETE_FILTER_INTELLIGENCE]: (state) => {
        state.filter_intelligence = null
    },
    [names.MUTATE_RULES_FILTER_SELECTOR]: (state, rules_filter_selector) => {
        state.rules_filter_selector = rules_filter_selector
    },
    [names.MUTATE_DELETE_RULES_FILTER_SELECTOR]: (state) => {
        state.rules_filter_selector = null
    },
}

const actions = {

}

export default {
    state,
    getters,
    mutations,
    actions,
};