import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    autonomous_teaching_activities: [],
    autonomous_teaching_activities_url: '/mesh/autonomous-teaching-activity'

}

const getters = {
    [names.AUTONOMOUS_TEACHING_ACTIVITIES]: state => {
        if (!get.store.state.user) return [];
        return state.autonomous_teaching_activities.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_AUTONOMOUS_TEACHING_ACTIVITIES]: (state, autonomous_teaching_activities) => {
        autonomous_teaching_activities.forEach(element => {
            let index = state.autonomous_teaching_activities.findIndex(x => x.id == element.id);
            if (index != -1) state.autonomous_teaching_activities.splice(index, 1, element);
            else state.autonomous_teaching_activities.push(element);
        });
    },
    [names.MUTATE_AUTONOMOUS_TEACHING_ACTIVITY]: (state, autonomous_teaching_activity) => {
        let index = state.autonomous_teaching_activities.findIndex(x => x.id == autonomous_teaching_activity.id);
        if (index != -1) state.autonomous_teaching_activities.splice(index, 1, autonomous_teaching_activity);
        else state.autonomous_teaching_activities.push(autonomous_teaching_activity);
    },
    [names.MUTATE_DELETE_AUTONOMOUS_TEACHING_ACTIVITY]: (state, autonomous_teaching_activity_id) => {
        let index = state.autonomous_teaching_activities.findIndex(x => x.id == autonomous_teaching_activity_id);
        if (index != -1) state.autonomous_teaching_activities.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_AUTONOMOUS_TEACHING_ACTIVITY]: ({
        commit,
        state
    }, autonomous_teaching_activity_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.autonomous_teaching_activities_url}/${autonomous_teaching_activity_id}/`)
                .then(response => {
                    commit(names.MUTATE_AUTONOMOUS_TEACHING_ACTIVITY, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_AUTONOMOUS_TEACHING_ACTIVITIES]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.autonomous_teaching_activities.length > 0) {
                resolve(state.autonomous_teaching_activities);
                return;
            }
            restful.Get(`${state.autonomous_teaching_activities_url}/`)
                .then(response => {
                    commit(names.MUTATE_AUTONOMOUS_TEACHING_ACTIVITIES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_AUTONOMOUS_TEACHING_ACTIVITY]: ({
        commit,
        state
    }, autonomous_teaching_activity) => {
        return new Promise((resolve) => {
            restful.Post(`${state.autonomous_teaching_activities_url}/`, autonomous_teaching_activity)
                .then(response => {
                    commit(names.MUTATE_AUTONOMOUS_TEACHING_ACTIVITY, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_AUTONOMOUS_TEACHING_ACTIVITY]: ({
        commit,
        state
    }, autonomous_teaching_activity) => {
        return new Promise((resolve) => {
            restful.Put(`${state.autonomous_teaching_activities_url}/${autonomous_teaching_activity.id}/`, autonomous_teaching_activity)
                .then(response => {
                    commit(names.MUTATE_AUTONOMOUS_TEACHING_ACTIVITY, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_AUTONOMOUS_TEACHING_ACTIVITY]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.autonomous_teaching_activities_url}/${payload.autonomous_teaching_activity_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_AUTONOMOUS_TEACHING_ACTIVITY, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_AUTONOMOUS_TEACHING_ACTIVITY]: ({
        commit,
        state
    }, autonomous_teaching_activity_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.autonomous_teaching_activities_url}/${autonomous_teaching_activity_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_AUTONOMOUS_TEACHING_ACTIVITY, autonomous_teaching_activity_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};