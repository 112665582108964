// alternative_names
export const ALTERNATIVE_NAMES = 'alternative_names/ALTERNATIVE_NAMES';
export const MUTATE_ALTERNATIVE_NAMES = 'alternative_names/MUTATE_ALTERNATIVE_NAMES';
export const MUTATE_ALTERNATIVE_NAME = 'alternative_names/MUTATE_ALTERNATIVE_NAME';
export const MUTATE_DELETE_ALTERNATIVE_NAME = 'alternative_names/MUTATE_DELETE_ALTERNATIVE_NAME';
export const FETCH_ALTERNATIVE_NAME = 'alternative_names/FETCH_ALTERNATIVE_NAME';
export const FETCH_ALTERNATIVE_NAMES = 'alternative_names/FETCH_ALTERNATIVE_NAMES';
export const POST_ALTERNATIVE_NAME = 'alternative_names/POST_ALTERNATIVE_NAME';
export const UPDATE_ALTERNATIVE_NAME = 'alternative_names/UPDATE_ALTERNATIVE_NAME';
export const PATCH_ALTERNATIVE_NAME = 'alternative_names/PATCH_ALTERNATIVE_NAME';
export const DELETE_ALTERNATIVE_NAME = 'alternative_names/DELETE_ALTERNATIVE_NAME';
// autonomous_teaching_activities
export const AUTONOMOUS_TEACHING_ACTIVITIES = 'autonomous_teaching_activities/AUTONOMOUS_TEACHING_ACTIVITIES';
export const MUTATE_AUTONOMOUS_TEACHING_ACTIVITIES = 'autonomous_teaching_activities/MUTATE_AUTONOMOUS_TEACHING_ACTIVITIES';
export const MUTATE_AUTONOMOUS_TEACHING_ACTIVITY = 'autonomous_teaching_activities/MUTATE_AUTONOMOUS_TEACHING_ACTIVITY';
export const MUTATE_DELETE_AUTONOMOUS_TEACHING_ACTIVITY = 'autonomous_teaching_activities/MUTATE_DELETE_AUTONOMOUS_TEACHING_ACTIVITY';
export const FETCH_AUTONOMOUS_TEACHING_ACTIVITY = 'autonomous_teaching_activities/FETCH_AUTONOMOUS_TEACHING_ACTIVITY';
export const FETCH_AUTONOMOUS_TEACHING_ACTIVITIES = 'autonomous_teaching_activities/FETCH_AUTONOMOUS_TEACHING_ACTIVITIES';
export const POST_AUTONOMOUS_TEACHING_ACTIVITY = 'autonomous_teaching_activities/POST_AUTONOMOUS_TEACHING_ACTIVITY';
export const UPDATE_AUTONOMOUS_TEACHING_ACTIVITY = 'autonomous_teaching_activities/UPDATE_AUTONOMOUS_TEACHING_ACTIVITY';
export const PATCH_AUTONOMOUS_TEACHING_ACTIVITY = 'autonomous_teaching_activities/PATCH_AUTONOMOUS_TEACHING_ACTIVITY';
export const DELETE_AUTONOMOUS_TEACHING_ACTIVITY = 'autonomous_teaching_activities/DELETE_AUTONOMOUS_TEACHING_ACTIVITY';
// bibliographic_resource_types
export const BIBLIOGRAPHIC_RESOURCE_TYPES = 'bibliographic_resource_types/BIBLIOGRAPHIC_RESOURCE_TYPES';
export const MUTATE_BIBLIOGRAPHIC_RESOURCE_TYPES = 'bibliographic_resource_types/MUTATE_BIBLIOGRAPHIC_RESOURCE_TYPES';
export const MUTATE_BIBLIOGRAPHIC_RESOURCE_TYPE = 'bibliographic_resource_types/MUTATE_BIBLIOGRAPHIC_RESOURCE_TYPE';
export const MUTATE_DELETE_BIBLIOGRAPHIC_RESOURCE_TYPE = 'bibliographic_resource_types/MUTATE_DELETE_BIBLIOGRAPHIC_RESOURCE_TYPE';
export const FETCH_BIBLIOGRAPHIC_RESOURCE_TYPE = 'bibliographic_resource_types/FETCH_BIBLIOGRAPHIC_RESOURCE_TYPE';
export const FETCH_BIBLIOGRAPHIC_RESOURCE_TYPES = 'bibliographic_resource_types/FETCH_BIBLIOGRAPHIC_RESOURCE_TYPES';
export const POST_BIBLIOGRAPHIC_RESOURCE_TYPE = 'bibliographic_resource_types/POST_BIBLIOGRAPHIC_RESOURCE_TYPE';
export const UPDATE_BIBLIOGRAPHIC_RESOURCE_TYPE = 'bibliographic_resource_types/UPDATE_BIBLIOGRAPHIC_RESOURCE_TYPE';
export const PATCH_BIBLIOGRAPHIC_RESOURCE_TYPE = 'bibliographic_resource_types/PATCH_BIBLIOGRAPHIC_RESOURCE_TYPE';
export const DELETE_BIBLIOGRAPHIC_RESOURCE_TYPE = 'bibliographic_resource_types/DELETE_BIBLIOGRAPHIC_RESOURCE_TYPE';
// campuses
export const CAMPUSES = 'campuses/CAMPUSES';
export const MUTATE_CAMPUSES = 'campuses/MUTATE_CAMPUSES';
export const MUTATE_CAMPUS = 'campuses/MUTATE_CAMPUS';
export const MUTATE_DELETE_CAMPUS = 'campuses/MUTATE_DELETE_CAMPUS';
export const FETCH_CAMPUS = 'campuses/FETCH_CAMPUS';
export const FETCH_CAMPUSES = 'campuses/FETCH_CAMPUSES';
export const POST_CAMPUS = 'campuses/POST_CAMPUS';
export const UPDATE_CAMPUS = 'campuses/UPDATE_CAMPUS';
export const PATCH_CAMPUS = 'campuses/PATCH_CAMPUS';
export const DELETE_CAMPUS = 'campuses/DELETE_CAMPUS';
// careers
export const CAREERS = 'careers/CAREERS';
export const MUTATE_CAREERS = 'careers/MUTATE_CAREERS';
export const MUTATE_CAREER = 'careers/MUTATE_CAREER';
export const MUTATE_DELETE_CAREER = 'careers/MUTATE_DELETE_CAREER';
export const FETCH_CAREER = 'careers/FETCH_CAREER';
export const GENERIC_CAREERS = 'careers/GENERIC_CAREERS';
export const MUTATE_GENERIC_CAREERS = 'careers/MUTATE_GENERIC_CAREERS';
export const MUTATE_GENERIC_CAREER = 'careers/MUTATE_GENERIC_CAREER';
export const MUTATE_DELETE_GENERIC_CAREER = 'careers/MUTATE_DELETE_GENERIC_CAREER';
export const FETCH_GENERIC_CAREER = 'careers/FETCH_GENERIC_CAREER';
export const FETCH_GENERIC_CAREERS = 'careers/FETCH_GENERIC_CAREERS';
export const POST_GENERIC_CAREER = 'careers/POST_GENERIC_CAREER';
export const UPDATE_GENERIC_CAREER = 'careers/UPDATE_GENERIC_CAREER';
export const PATCH_GENERIC_CAREER = 'careers/PATCH_GENERIC_CAREER';
export const DELETE_GENERIC_CAREER = 'careers/DELETE_GENERIC_CAREER';
// export const FETCH_CAREER_BY_EGRESS_PROFILE = 'careers/FETCH_CAREER_BY_EGRESS_PROFILE';
export const FETCH_CAREERS = 'careers/FETCH_CAREERS';
export const POST_CAREER = 'careers/POST_CAREER';
export const UPDATE_CAREER = 'careers/UPDATE_CAREER';
export const PATCH_CAREER = 'careers/PATCH_CAREER';
export const DELETE_CAREER = 'careers/DELETE_CAREER';
// capacities
export const CAPACITIES = 'capacities/CAPACITIES';
export const MUTATE_CAPACITIES = 'capacities/MUTATE_CAPACITIES';
export const MUTATE_CAPACITY = 'capacities/MUTATE_CAPACITY';
export const MUTATE_DELETE_CAPACITY = 'capacities/MUTATE_DELETE_CAPACITY';
export const FETCH_CAPACITY = 'capacities/FETCH_CAPACITY';
export const FETCH_CAPACITIES = 'capacities/FETCH_CAPACITIES';
export const POST_CAPACITY = 'capacities/POST_CAPACITY';
export const UPDATE_CAPACITY = 'capacities/UPDATE_CAPACITY';
export const PATCH_CAPACITY = 'capacities/PATCH_CAPACITY';
export const DELETE_CAPACITY = 'capacities/DELETE_CAPACITY';
// certifications
export const CERTIFICATIONS = 'certifications/CERTIFICATIONS';
export const MUTATE_CERTIFICATIONS = 'certifications/MUTATE_CERTIFICATIONS';
export const MUTATE_CERTIFICATION = 'certifications/MUTATE_CERTIFICATION';
export const MUTATE_DELETE_CERTIFICATION = 'certifications/MUTATE_DELETE_CERTIFICATION';
export const FETCH_CERTIFICATION = 'certifications/FETCH_CERTIFICATION';
export const FETCH_CERTIFICATIONS = 'certifications/FETCH_CERTIFICATIONS';
export const POST_CERTIFICATION = 'certifications/POST_CERTIFICATION';
export const UPDATE_CERTIFICATION = 'certifications/UPDATE_CERTIFICATION';
export const PATCH_CERTIFICATION = 'certifications/PATCH_CERTIFICATION';
export const DELETE_CERTIFICATION = 'certifications/DELETE_CERTIFICATION';
// communes
export const COMMUNES = 'communes/COMMUNES';
export const MUTATE_COMMUNES = 'communes/MUTATE_COMMUNES';
export const MUTATE_COMMUNE = 'communes/MUTATE_COMMUNE';
export const MUTATE_DELETE_COMMUNE = 'communes/MUTATE_DELETE_COMMUNE';
export const FETCH_COMMUNE = 'communes/FETCH_COMMUNE';
export const FETCH_COMMUNES = 'communes/FETCH_COMMUNES';
export const POST_COMMUNE = 'communes/POST_COMMUNE';
export const UPDATE_COMMUNE = 'communes/UPDATE_COMMUNE';
export const PATCH_COMMUNE = 'communes/PATCH_COMMUNE';
export const DELETE_COMMUNE = 'communes/DELETE_COMMUNE';
// courses_wrapper
export const COURSES_WRAPPER = 'courses_wrapper/COURSES_WRAPPER';
export const MUTATE_COURSES_WRAPPER = 'courses_wrapper/MUTATE_COURSES_WRAPPER';
export const MUTATE_COURSE_WRAPPER = 'courses_wrapper/MUTATE_COURSE_WRAPPER';
export const MUTATE_DELETE_COURSE_WRAPPER = 'courses_wrapper/MUTATE_DELETE_COURSE_WRAPPER';
export const FETCH_COURSE_WRAPPER = 'courses_wrapper/FETCH_COURSE_WRAPPER';
export const FETCH_COURSES_WRAPPER = 'courses_wrapper/FETCH_COURSES_WRAPPER';
export const POST_COURSE_WRAPPER = 'courses_wrapper/POST_COURSE_WRAPPER';
export const UPDATE_COURSE_WRAPPER = 'courses_wrapper/UPDATE_COURSE_WRAPPER';
export const PATCH_COURSE_WRAPPER = 'courses_wrapper/PATCH_COURSE_WRAPPER';
export const DELETE_COURSE_WRAPPER = 'courses_wrapper/DELETE_COURSE_WRAPPER';
// base_matters_wrapper
export const BASE_MATTERS_WRAPPER = 'courses_wrapper/BASE_MATTERS_WRAPPER';
export const MUTATE_BASE_MATTERS_WRAPPER = 'courses_wrapper/MUTATE_BASE_MATTERS_WRAPPER';
export const MUTATE_BASE_MATTER_WRAPPER = 'courses_wrapper/MUTATE_BASE_MATTER_WRAPPER';
export const MUTATE_DELETE_BASE_MATTER_WRAPPER = 'courses_wrapper/MUTATE_DELETE_BASE_MATTER_WRAPPER';
export const FETCH_BASE_MATTER_WRAPPER = 'courses_wrapper/FETCH_BASE_MATTER_WRAPPER';
export const FETCH_BASE_MATTERS_WRAPPER = 'courses_wrapper/FETCH_BASE_MATTERS_WRAPPER';
export const POST_BASE_MATTER_WRAPPER = 'courses_wrapper/POST_BASE_MATTER_WRAPPER';
export const UPDATE_BASE_MATTER_WRAPPER = 'courses_wrapper/UPDATE_BASE_MATTER_WRAPPER';
export const PATCH_BASE_MATTER_WRAPPER = 'courses_wrapper/PATCH_BASE_MATTER_WRAPPER';
export const DELETE_BASE_MATTER_WRAPPER = 'courses_wrapper/DELETE_BASE_MATTER_WRAPPER';
// cycles
export const CYCLES = 'cycles/CYCLES';
export const MUTATE_CYCLES = 'cycles/MUTATE_CYCLES';
export const MUTATE_CYCLE = 'cycles/MUTATE_CYCLE';
export const MUTATE_DELETE_CYCLE = 'cycles/MUTATE_DELETE_CYCLE';
export const FETCH_CYCLE = 'cycles/FETCH_CYCLE';
export const FETCH_CYCLES = 'cycles/FETCH_CYCLES';
export const POST_CYCLE = 'cycles/POST_CYCLE';
export const UPDATE_CYCLE = 'cycles/UPDATE_CYCLE';
export const PATCH_CYCLE = 'cycles/PATCH_CYCLE';
export const DELETE_CYCLE = 'cycles/DELETE_CYCLE';
// competences
export const COMPETENCES = 'competences/COMPETENCES';
export const MUTATE_COMPETENCES = 'competences/MUTATE_COMPETENCES';
export const MUTATE_COMPETENCE = 'competences/MUTATE_COMPETENCE';
export const MUTATE_DELETE_COMPETENCE = 'competences/MUTATE_DELETE_COMPETENCE';
export const FETCH_COMPETENCE = 'competences/FETCH_COMPETENCE';
export const FETCH_COMPETENCES = 'competences/FETCH_COMPETENCES';
export const POST_COMPETENCE = 'competences/POST_COMPETENCE';
export const UPDATE_COMPETENCE = 'competences/UPDATE_COMPETENCE';
export const PATCH_COMPETENCE = 'competences/PATCH_COMPETENCE';
export const DELETE_COMPETENCE = 'competences/DELETE_COMPETENCE';
// competence_types
export const COMPETENCE_TYPES = 'competence_types/COMPETENCE_TYPES';
export const MUTATE_COMPETENCE_TYPES = 'competence_types/MUTATE_COMPETENCE_TYPES';
export const MUTATE_COMPETENCE_TYPE = 'competence_types/MUTATE_COMPETENCE_TYPE';
export const MUTATE_DELETE_COMPETENCE_TYPE = 'competence_types/MUTATE_DELETE_COMPETENCE_TYPE';
export const FETCH_COMPETENCE_TYPE = 'competence_types/FETCH_COMPETENCE_TYPE';
export const FETCH_COMPETENCE_TYPES = 'competence_types/FETCH_COMPETENCE_TYPES';
export const POST_COMPETENCE_TYPE = 'competence_types/POST_COMPETENCE_TYPE';
export const UPDATE_COMPETENCE_TYPE = 'competence_types/UPDATE_COMPETENCE_TYPE';
export const PATCH_COMPETENCE_TYPE = 'competence_types/PATCH_COMPETENCE_TYPE';
export const DELETE_COMPETENCE_TYPE = 'competence_types/DELETE_COMPETENCE_TYPE';
// competence_units
export const COMPETENCE_UNITS = "competence_units/COMPETENCE_UNITS";
export const MUTATE_COMPETENCE_UNITS = "competence_units/MUTATE_COMPETENCE_UNITS";
export const MUTATE_STUDY_COMPETENCE = "competence_units/MUTATE_STUDY_COMPETENCE";
export const MUTATE_DELETE_STUDY_COMPETENCE = "competence_units/MUTATE_DELETE_STUDY_COMPETENCE";
export const FETCH_STUDY_COMPETENCE = "competence_units/FETCH_STUDY_COMPETENCE";
export const FETCH_COMPETENCE_UNITS = "competence_units/FETCH_COMPETENCE_UNITS";
export const POST_STUDY_COMPETENCE = "competence_units/POST_STUDY_COMPETENCE";
export const UPDATE_STUDY_COMPETENCE = "competence_units/UPDATE_STUDY_COMPETENCE";
export const PATCH_STUDY_COMPETENCE = "competence_units/PATCH_STUDY_COMPETENCE";
export const DELETE_STUDY_COMPETENCE = "competence_units/DELETE_STUDY_COMPETENCE";
// contents
export const CONTENTS = 'contents/CONTENTS';
export const MUTATE_CONTENTS = 'contents/MUTATE_CONTENTS';
export const MUTATE_CONTENT = 'contents/MUTATE_CONTENT';
export const MUTATE_DELETE_CONTENT = 'contents/MUTATE_DELETE_CONTENT';
export const FETCH_CONTENT = 'contents/FETCH_CONTENT';
export const FETCH_CONTENTS = 'contents/FETCH_CONTENTS';
export const POST_CONTENT = 'contents/POST_CONTENT';
export const UPDATE_CONTENT = 'contents/UPDATE_CONTENT';
export const PATCH_CONTENT = 'contents/PATCH_CONTENT';
export const DELETE_CONTENT = 'contents/DELETE_CONTENT';
// countries
export const COUNTRIES = 'countries/COUNTRIES';
export const MUTATE_COUNTRIES = 'countries/MUTATE_COUNTRIES';
export const MUTATE_COUNTRY = 'countries/MUTATE_COUNTRY';
export const MUTATE_DELETE_COUNTRY = 'countries/MUTATE_DELETE_COUNTRY';
export const FETCH_COUNTRY = 'countries/FETCH_COUNTRY';
export const FETCH_COUNTRIES = 'countries/FETCH_COUNTRIES';
export const POST_COUNTRY = 'countries/POST_COUNTRY';
export const UPDATE_COUNTRY = 'countries/UPDATE_COUNTRY';
export const PATCH_COUNTRY = 'countries/PATCH_COUNTRY';
export const DELETE_COUNTRY = 'countries/DELETE_COUNTRY';
// direct_teaching_activities
export const DIRECT_TEACHING_ACTIVITIES = 'direct_teaching_activities/DIRECT_TEACHING_ACTIVITIES';
export const MUTATE_DIRECT_TEACHING_ACTIVITIES = 'direct_teaching_activities/MUTATE_DIRECT_TEACHING_ACTIVITIES';
export const MUTATE_DIRECT_TEACHING_ACTIVITY = 'direct_teaching_activities/MUTATE_DIRECT_TEACHING_ACTIVITY';
export const MUTATE_DELETE_DIRECT_TEACHING_ACTIVITY = 'direct_teaching_activities/MUTATE_DELETE_DIRECT_TEACHING_ACTIVITY';
export const FETCH_DIRECT_TEACHING_ACTIVITY = 'direct_teaching_activities/FETCH_DIRECT_TEACHING_ACTIVITY';
export const FETCH_DIRECT_TEACHING_ACTIVITIES = 'direct_teaching_activities/FETCH_DIRECT_TEACHING_ACTIVITIES';
export const POST_DIRECT_TEACHING_ACTIVITY = 'direct_teaching_activities/POST_DIRECT_TEACHING_ACTIVITY';
export const UPDATE_DIRECT_TEACHING_ACTIVITY = 'direct_teaching_activities/UPDATE_DIRECT_TEACHING_ACTIVITY';
export const PATCH_DIRECT_TEACHING_ACTIVITY = 'direct_teaching_activities/PATCH_DIRECT_TEACHING_ACTIVITY';
export const DELETE_DIRECT_TEACHING_ACTIVITY = 'direct_teaching_activities/DELETE_DIRECT_TEACHING_ACTIVITY';
// evaluation_feedbacks
export const EVALUATION_FEEDBACKS = 'evaluation_feedbacks/EVALUATION_FEEDBACKS';
export const MUTATE_EVALUATION_FEEDBACKS = 'evaluation_feedbacks/MUTATE_EVALUATION_FEEDBACKS';
export const MUTATE_EVALUATION_FEEDBACK = 'evaluation_feedbacks/MUTATE_EVALUATION_FEEDBACK';
export const MUTATE_DELETE_EVALUATION_FEEDBACK = 'evaluation_feedbacks/MUTATE_DELETE_EVALUATION_FEEDBACK';
export const FETCH_EVALUATION_FEEDBACK = 'evaluation_feedbacks/FETCH_EVALUATION_FEEDBACK';
export const FETCH_EVALUATION_FEEDBACKS = 'evaluation_feedbacks/FETCH_EVALUATION_FEEDBACKS';
export const POST_EVALUATION_FEEDBACK = 'evaluation_feedbacks/POST_EVALUATION_FEEDBACK';
export const UPDATE_EVALUATION_FEEDBACK = 'evaluation_feedbacks/UPDATE_EVALUATION_FEEDBACK';
export const PATCH_EVALUATION_FEEDBACK = 'evaluation_feedbacks/PATCH_EVALUATION_FEEDBACK';
export const DELETE_EVALUATION_FEEDBACK = 'evaluation_feedbacks/DELETE_EVALUATION_FEEDBACK';
// egress profile matter
export const EGRESS_PROFILES_MATTERS = 'egress_profiles_matters/EGRESS_PROFILES_MATTERS';
export const EGRESS_PROFILE_MATTER_DESCRIPTORS = 'egress_profiles_matters/EGRESS_PROFILE_MATTER_DESCRIPTORS';
export const EPM_MIDDLE_DESCRIPTORS = 'egress_profiles_matters/EPM_MIDDLE_DESCRIPTORS';
export const MUTATE_EGRESS_PROFILES_MATTERS = 'egress_profiles_matters/MUTATE_EGRESS_PROFILES_MATTERS';
export const MUTATE_EGRESS_PROFILE_MATTER = 'egress_profiles_matters/MUTATE_EGRESS_PROFILE_MATTER';
export const MUTATE_DELETE_EGRESS_PROFILE_MATTER = 'egress_profiles_matters/MUTATE_DELETE_EGRESS_PROFILE_MATTER';
export const MUTATE_EGRESS_PROFILE_MATTER_DESCRIPTOR = 'egress_profiles_matters/MUTATE_EGRESS_PROFILE_MATTER_DESCRIPTOR';
export const MUTATE_EGRESS_PROFILE_MATTER_DESCRIPTORS = 'egress_profiles_matters/MUTATE_EGRESS_PROFILE_MATTER_DESCRIPTORS';
export const MUTATE_DELETE_EGRESS_PROFILE_MATTER_DESCRIPTORS = 'egress_profiles_matters/MUTATE_DELETE_EGRESS_PROFILE_MATTER_DESCRIPTORS';
export const MUTATE_EPM_MIDDLE_DESCRIPTOR = 'egress_profiles_matters/MUTATE_EPM_MIDDLE_DESCRIPTOR';
export const MUTATE_EPM_MIDDLE_DESCRIPTORS = 'egress_profiles_matters/MUTATE_EPM_MIDDLE_DESCRIPTORS';
export const MUTATE_DELETE_EPM_MIDDLE_DESCRIPTORS = 'egress_profiles_matters/MUTATE_DELETE_EPM_MIDDLE_DESCRIPTORS';
export const FETCH_EGRESS_PROFILE_MATTER = 'egress_profiles_matters/FETCH_EGRESS_PROFILE_MATTER';
export const FETCH_EGRESS_PROFILES_MATTERS = 'egress_profiles_matters/FETCH_EGRESS_PROFILES_MATTERS';
export const POST_EGRESS_PROFILE_MATTER = 'egress_profiles_matters/POST_EGRESS_PROFILE_MATTER';
export const UPDATE_EGRESS_PROFILE_MATTER = 'egress_profiles_matters/UPDATE_EGRESS_PROFILE_MATTER';
export const PATCH_EGRESS_PROFILE_MATTER = 'egress_profiles_matters/PATCH_EGRESS_PROFILE_MATTER';
export const DELETE_EGRESS_PROFILE_MATTER = 'egress_profiles_matters/DELETE_EGRESS_PROFILE_MATTER';
export const MATTER_CODES = 'egress_profiles_matters/MATTER_CODES';
export const MUTATE_MATTER_CODES = 'egress_profiles_matters/MUTATE_MATTER_CODES';
export const FETCH_MATTER_CODES = 'egress_profiles_matters/FETCH_MATTER_CODES';
export const FETCH_EGRESS_PROFILE_MATTER_DESCRIPTORS = 'egress_profiles_matters/FETCH_EGRESS_PROFILE_MATTER_DESCRIPTORS';
export const POST_EGRESS_PROFILE_MATTER_DESCRIPTORS = 'egress_profiles_matters/POST_EGRESS_PROFILE_MATTER_DESCRIPTORS';
export const PATCH_EGRESS_PROFILE_MATTER_DESCRIPTORS = 'egress_profiles_matters/PATCH_EGRESS_PROFILE_MATTER_DESCRIPTORS';
export const DELETE_EGRESS_PROFILE_MATTER_DESCRIPTORS = 'egress_profiles_matters/DELETE_EGRESS_PROFILE_MATTER_DESCRIPTORS';
export const FETCH_EPM_MIDDLE_DESCRIPTORS = 'egress_profiles_matters/FETCH_EPM_MIDDLE_DESCRIPTORS';
export const POST_EPM_MIDDLE_DESCRIPTORS = 'egress_profiles_matters/POST_EPM_MIDDLE_DESCRIPTORS';
export const PATCH_EPM_MIDDLE_DESCRIPTORS = 'egress_profiles_matters/PATCH_EPM_MIDDLE_DESCRIPTORS';
export const DELETE_EPM_MIDDLE_DESCRIPTORS = 'egress_profiles_matters/DELETE_EPM_MIDDLE_DESCRIPTORS';
// egress profile
export const EGRESS_PROFILES = 'egress_profiles/EGRESS_PROFILES';
export const EGRESS_PROFILE_DESCRIPTORS = 'egress_profiles/EGRESS_PROFILE_DESCRIPTORS';
export const EP_MIDDLE_DESCRIPTORS = 'egress_profiles/EP_MIDDLE_DESCRIPTORS';
export const MUTATE_EGRESS_PROFILES = 'egress_profiles/MUTATE_EGRESS_PROFILES';
export const MUTATE_EGRESS_PROFILE = 'egress_profiles/MUTATE_EGRESS_PROFILE';
export const MUTATE_DELETE_EGRESS_PROFILE = 'egress_profiles/MUTATE_DELETE_EGRESS_PROFILE';
export const MUTATE_PROFILE_DESCRIPTOR = 'egress_profiles/MUTATE_PROFILE_DESCRIPTOR';
export const MUTATE_PROFILE_DESCRIPTORS = 'egress_profiles/MUTATE_PROFILE_DESCRIPTORS';
export const MUTATE_EP_MIDDLE_DESCRIPTOR = 'egress_profiles/MUTATE_EP_MIDDLE_DESCRIPTOR';
export const MUTATE_EP_MIDDLE_DESCRIPTORS = 'egress_profiles/MUTATE_EP_MIDDLE_DESCRIPTORS';
export const MUTATE_DELETE_PROFILE_DESCRIPTORS = 'egress_profiles/MUTATE_DELETE_PROFILE_DESCRIPTORS';
export const MUTATE_DELETE_EP_MIDDLE_DESCRIPTORS = 'egress_profiles/MUTATE_DELETE_EP_MIDDLE_DESCRIPTORS';
export const FETCH_EGRESS_PROFILE = 'egress_profiles/FETCH_EGRESS_PROFILE';
export const FETCH_EGRESS_PROFILE_BY_CAREER = 'egress_profiles/FETCH_EGRESS_PROFILE_BY_CAREER';
export const FETCH_EGRESS_PROFILES = 'egress_profiles/FETCH_EGRESS_PROFILES';
export const POST_EGRESS_PROFILE = 'egress_profiles/POST_EGRESS_PROFILE';
export const UPDATE_EGRESS_PROFILE = 'egress_profiles/UPDATE_EGRESS_PROFILE';
export const PATCH_EGRESS_PROFILE = 'egress_profiles/PATCH_EGRESS_PROFILE';
export const DELETE_EGRESS_PROFILE = 'egress_profiles/DELETE_EGRESS_PROFILE';
export const FETCH_PROFILE_DESCRIPTORS = 'egress_profiles/FETCH_PROFILE_DESCRIPTORS';
export const POST_PROFILE_DESCRIPTORS = 'egress_profiles/POST_PROFILE_DESCRIPTORS';
export const PATCH_PROFILE_DESCRIPTORS = 'egress_profiles/PATCH_PROFILE_DESCRIPTORS';
export const DELETE_PROFILE_DESCRIPTORS = 'egress_profiles/DELETE_PROFILE_DESCRIPTORS';
export const FETCH_EP_MIDDLE_DESCRIPTORS = 'egress_profiles/FETCH_EP_MIDDLE_DESCRIPTORS';
export const POST_EP_MIDDLE_DESCRIPTORS = 'egress_profiles/POST_EP_MIDDLE_DESCRIPTORS';
export const PATCH_EP_MIDDLE_DESCRIPTORS = 'egress_profiles/PATCH_EP_MIDDLE_DESCRIPTORS';
export const DELETE_EP_MIDDLE_DESCRIPTORS = 'egress_profiles/DELETE_EP_MIDDLE_DESCRIPTORS';
export const ADMISSION_TYPES = 'egress_profiles/ADMISSION_TYPES';
export const MUTATE_ADMISSION_TYPES = 'egress_profiles/MUTATE_ADMISSION_TYPES';
export const FETCH_ADMISSION_TYPES = 'egress_profiles/FETCH_ADMISSION_TYPES';
// egress profile type
export const PROFILE_TYPES = 'egress_profile_type/PROFILE_TYPES';
export const MUTATE_PROFILE_TYPES = 'egress_profile_type/MUTATE_PROFILE_TYPES';
export const MUTATE_PROFILE_TYPE = 'egress_profile_type/MUTATE_PROFILE_TYPE';
export const MUTATE_DELETE_PROFILE_TYPE = 'egress_profile_type/MUTATE_DELETE_PROFILE_TYPE';
export const FETCH_PROFILE_TYPE = 'egress_profile_type/FETCH_PROFILE_TYPE';
export const FETCH_PROFILE_TYPES = 'egress_profile_type/FETCH_PROFILE_TYPES';
export const POST_PROFILE_TYPE = 'egress_profile_type/POST_PROFILE_TYPE';
export const UPDATE_PROFILE_TYPE = 'egress_profile_type/UPDATE_PROFILE_TYPE';
export const PATCH_PROFILE_TYPE = 'egress_profile_type/PATCH_PROFILE_TYPE';
export const DELETE_PROFILE_TYPE = 'egress_profile_type/DELETE_PROFILE_TYPE';
// evaluation_criterias
export const EVALUATION_CRITERIAS = 'evaluation_criterias/EVALUATION_CRITERIAS';
export const MUTATE_EVALUATION_CRITERIAS = 'evaluation_criterias/MUTATE_EVALUATION_CRITERIAS';
export const MUTATE_EVALUATION_CRITERIA = 'evaluation_criterias/MUTATE_EVALUATION_CRITERIA';
export const MUTATE_DELETE_EVALUATION_CRITERIA = 'evaluation_criterias/MUTATE_DELETE_EVALUATION_CRITERIA';
export const FETCH_EVALUATION_CRITERIA = 'evaluation_criterias/FETCH_EVALUATION_CRITERIA';
export const FETCH_EVALUATION_CRITERIAS = 'evaluation_criterias/FETCH_EVALUATION_CRITERIAS';
export const POST_EVALUATION_CRITERIA = 'evaluation_criterias/POST_EVALUATION_CRITERIA';
export const UPDATE_EVALUATION_CRITERIA = 'evaluation_criterias/UPDATE_EVALUATION_CRITERIA';
export const PATCH_EVALUATION_CRITERIA = 'evaluation_criterias/PATCH_EVALUATION_CRITERIA';
export const DELETE_EVALUATION_CRITERIA = 'evaluation_criterias/DELETE_EVALUATION_CRITERIA';
export const EVALUATION_CRITERIAS_MACROS = 'evaluation_criterias/EVALUATION_CRITERIAS_MACROS';
export const MUTATE_EVALUATION_CRITERIAS_MACROS = 'evaluation_criterias/MUTATE_EVALUATION_CRITERIAS_MACROS';
export const MUTATE_EVALUATION_CRITERIA_MACRO = 'evaluation_criterias/MUTATE_EVALUATION_CRITERIA_MACRO';
export const MUTATE_DELETE_EVALUATION_CRITERIA_MACRO = 'evaluation_criterias/MUTATE_DELETE_EVALUATION_CRITERIA_MACRO';
export const FETCH_EVALUATION_CRITERIA_MACRO = 'evaluation_criterias/FETCH_EVALUATION_CRITERIA_MACRO';
export const FETCH_EVALUATION_CRITERIAS_MACROS = 'evaluation_criterias/FETCH_EVALUATION_CRITERIAS_MACROS';
export const POST_EVALUATION_CRITERIA_MACRO = 'evaluation_criterias/POST_EVALUATION_CRITERIA_MACRO';
export const UPDATE_EVALUATION_CRITERIA_MACRO = 'evaluation_criterias/UPDATE_EVALUATION_CRITERIA_MACRO';
export const PATCH_EVALUATION_CRITERIA_MACRO = 'evaluation_criterias/PATCH_EVALUATION_CRITERIA_MACRO';
export const DELETE_EVALUATION_CRITERIA_MACRO = 'evaluation_criterias/DELETE_EVALUATION_CRITERIA_MACRO';
// evaluation_instruments
export const EVALUATION_INSTRUMENTS = 'evaluation_instruments/EVALUATION_INSTRUMENTS';
export const MUTATE_EVALUATION_INSTRUMENTS = 'evaluation_instruments/MUTATE_EVALUATION_INSTRUMENTS';
export const MUTATE_EVALUATION_INSTRUMENT = 'evaluation_instruments/MUTATE_EVALUATION_INSTRUMENT';
export const MUTATE_DELETE_EVALUATION_INSTRUMENT = 'evaluation_instruments/MUTATE_DELETE_EVALUATION_INSTRUMENT';
export const FETCH_EVALUATION_INSTRUMENT = 'evaluation_instruments/FETCH_EVALUATION_INSTRUMENT';
export const FETCH_EVALUATION_INSTRUMENTS = 'evaluation_instruments/FETCH_EVALUATION_INSTRUMENTS';
export const POST_EVALUATION_INSTRUMENT = 'evaluation_instruments/POST_EVALUATION_INSTRUMENT';
export const UPDATE_EVALUATION_INSTRUMENT = 'evaluation_instruments/UPDATE_EVALUATION_INSTRUMENT';
export const PATCH_EVALUATION_INSTRUMENT = 'evaluation_instruments/PATCH_EVALUATION_INSTRUMENT';
export const DELETE_EVALUATION_INSTRUMENT = 'evaluation_instruments/DELETE_EVALUATION_INSTRUMENT';
// evaluation_planning
export const EVALUATION_PLANNINGS = 'evaluation_planning/EVALUATION_PLANNINGS';
export const MUTATE_EVALUATION_PLANNINGS = 'evaluation_planning/MUTATE_EVALUATION_PLANNINGS';
export const MUTATE_EVALUATION_PLANNING = 'evaluation_planning/MUTATE_EVALUATION_PLANNING';
export const MUTATE_DELETE_EVALUATION_PLANNING = 'evaluation_planning/MUTATE_DELETE_EVALUATION_PLANNING';
export const FETCH_EVALUATION_PLANNING = 'evaluation_planning/FETCH_EVALUATION_PLANNING';
export const FETCH_EVALUATION_PLANNINGS = 'evaluation_planning/FETCH_EVALUATION_PLANNINGS';
export const POST_EVALUATION_PLANNING = 'evaluation_planning/POST_EVALUATION_PLANNING';
export const UPDATE_EVALUATION_PLANNING = 'evaluation_planning/UPDATE_EVALUATION_PLANNING';
export const PATCH_EVALUATION_PLANNING = 'evaluation_planning/PATCH_EVALUATION_PLANNING';
export const DELETE_EVALUATION_PLANNING = 'evaluation_planning/DELETE_EVALUATION_PLANNING';
// evaluations
export const EVALUATION = 'evaluations/EVALUATION';
export const EVALUATIONS = 'evaluations/EVALUATIONS';
export const MUTATE_EVALUATIONS = 'evaluations/MUTATE_EVALUATIONS';
export const MUTATE_EVALUATION = 'evaluations/MUTATE_EVALUATION';
export const MUTATE_DELETE_EVALUATION = 'evaluations/MUTATE_DELETE_EVALUATION';
export const FETCH_EVALUATION = 'evaluations/FETCH_EVALUATION';
export const FETCH_EVALUATIONS_BY_STUDY_UNIT = 'evaluations/FETCH_EVALUATIONS_BY_STUDY_UNIT';
export const FETCH_EVALUATIONS_BY_SECTION = 'evaluations/FETCH_EVALUATIONS_BY_SECTION';
export const POST_EVALUATION = 'evaluations/POST_EVALUATION';
export const UPDATE_EVALUATION = 'evaluations/UPDATE_EVALUATION';
export const PATCH_EVALUATION = 'evaluations/PATCH_EVALUATION';
export const DELETE_EVALUATION = 'evaluations/DELETE_EVALUATION';
export const INSTRUMENT_SUB_TYPES = 'evaluations/INSTRUMENT_SUB_TYPES';
export const EVALUATION_TYPES = 'evaluations/EVALUATION_TYPES';
export const MUTATE_EVALUATION_TYPES = 'evaluations/MUTATE_EVALUATION_TYPES';
export const FETCH_EVALUATION_TYPES = 'evaluations/FETCH_EVALUATION_TYPES';
export const AUTOMATED_SCORE_TYPES = 'evaluations/AUTOMATED_SCORE_TYPES';
export const MUTATE_AUTOMATED_SCORE_TYPES = 'evaluations/MUTATE_AUTOMATED_SCORE_TYPES ';
export const FETCH_AUTOMATED_SCORE_TYPES = 'evaluations/FETCH_AUTOMATED_SCORE_TYPES';
export const MUTATE_INSTRUMENT_SUB_TYPES = 'evaluations/MUTATE_INSTRUMENT_SUB_TYPES';
export const FETCH_INSTRUMENT_SUB_TYPES = 'evaluations/FETCH_INSTRUMENT_SUB_TYPES';
export const SECTION_EVALUATIONS = 'evaluations/SECTION_EVALUATIONS';
export const MUTATE_SECTION_EVALUATIONS = 'evaluations/MUTATE_SECTION_EVALUATIONS';
export const MUTATE_SECTION_EVALUATION = 'evaluations/MUTATE_SECTION_EVALUATION';
export const MUTATE_DELETE_SECTION_EVALUATION = 'evaluations/MUTATE_DELETE_SECTION_EVALUATION';
export const FETCH_SECTION_EVALUATIONS = 'evaluations/FETCH_SECTION_EVALUATIONS';
export const FETCH_SECTION_EVALUATION = 'evaluations/FETCH_SECTION_EVALUATION';
export const POST_SECTION_EVALUATION = 'evaluations/POST_SECTION_EVALUATION';
export const PATCH_SECTION_EVALUATION = 'evaluations/PATCH_SECTION_EVALUATION';
export const DELETE_SECTION_EVALUATION = 'evaluations/DELETE_SECTION_EVALUATION';
export const EVALUATION_CATEGORIES = 'evaluations/EVALUATION_CATEGORIES';
export const MUTATE_EVALUATION_CATEGORIES = 'evaluations/MUTATE_EVALUATION_CATEGORIES';
export const FETCH_EVALUATION_CATEGORIES = 'evaluations/FETCH_EVALUATION_CATEGORIES';
//new_assessment assessment_types
export const ASSESSMENT_TYPES = 'new_assessments/assessment_types/ASSESSMENT_TYPES';
export const MUTATE_ASSESSMENT_TYPES = 'new_assessments/assessment_types/MUTATE_ASSESSMENT_TYPES';
export const MUTATE_ASSESSMENT_TYPE = 'new_assessments/assessment_types/MUTATE_ASSESSMENT_TYPE';
export const MUTATE_DELETE_ASSESSMENT_TYPE = 'new_assessments/assessment_types/MUTATE_DELETE_ASSESSMENT_TYPE';
export const FETCH_ASSESSMENT_TYPE = 'new_assessments/assessment_types/FETCH_ASSESSMENT_TYPE';
export const FETCH_ASSESSMENT_TYPES = 'new_assessments/assessment_types/FETCH_ASSESSMENT_TYPES';
export const POST_ASSESSMENT_TYPE = 'new_assessments/assessment_types/POST_ASSESSMENT_TYPE';
export const UPDATE_ASSESSMENT_TYPE = 'new_assessments/assessment_types/UPDATE_ASSESSMENT_TYPE';
export const PATCH_ASSESSMENT_TYPE = 'new_assessments/assessment_types/PATCH_ASSESSMENT_TYPE';
export const DELETE_ASSESSMENT_TYPE = 'new_assessments/assessment_types/DELETE_ASSESSMENT_TYPE';
// new_assessment assessments
export const ASSESSMENTS = 'new_assessments/assessments/ASSESSMENTS';
export const ASSESSMENT_MESH_OBJET_RELATIONS = 'new_assessments/assessments/ASSESSMENT_MESH_OBJET_RELATIONS';
export const ASSESSMENT_RELATIONS = 'new_assessments/assessments/ASSESSMENT_RELATIONS';
export const MUTATE_ASSESSMENTS = 'new_assessments/assessments/MUTATE_ASSESSMENTS';
export const MUTATE_ASSESSMENT = 'new_assessments/assessments/MUTATE_ASSESSMENT';
export const MUTATE_DELETE_ASSESSMENT = 'new_assessments/assessments/MUTATE_DELETE_ASSESSMENT';
export const MUTATE_ASSESSMENT_MESH_OBJET_RELATIONS = 'new_assessments/assessments/MUTATE_ASSESSMENT_MESH_OBJET_RELATIONS';
export const MUTATE_ASSESSMENT_MESH_OBJET_RELATION = 'new_assessments/assessments/MUTATE_ASSESSMENT_MESH_OBJET_RELATION';
export const MUTATE_DELETE_ASSESSMENT_MESH_OBJET_RELATION = 'new_assessments/assessments/MUTATE_DELETE_ASSESSMENT_MESH_OBJET_RELATION';
export const MUTATE_ASSESSMENT_RELATIONS = 'new_assessments/assessments/MUTATE_ASSESSMENT_RELATIONS';
export const MUTATE_ASSESSMENT_RELATION = 'new_assessments/assessments/MUTATE_ASSESSMENT_RELATION';
export const MUTATE_DELETE_ASSESSMENT_RELATION = 'new_assessments/assessments/MUTATE_DELETE_ASSESSMENT_RELATION';
export const FETCH_ASSESSMENT = 'new_assessments/assessments/FETCH_ASSESSMENT';
export const FETCH_ASSESSMENTS = 'new_assessments/assessments/FETCH_ASSESSMENTS';
export const POST_ASSESSMENT = 'new_assessments/assessments/POST_ASSESSMENT';
export const UPDATE_ASSESSMENT = 'new_assessments/assessments/UPDATE_ASSESSMENT';
export const PATCH_ASSESSMENT = 'new_assessments/assessments/PATCH_ASSESSMENT';
export const DELETE_ASSESSMENT = 'new_assessments/assessments/DELETE_ASSESSMENT';
export const FETCH_ASSESSMENT_MESH_OBJET_RELATION = 'new_assessments/assessments/FETCH_ASSESSMENT_MESH_OBJET_RELATION';
export const FETCH_ASSESSMENT_MESH_OBJET_RELATIONS = 'new_assessments/assessments/FETCH_ASSESSMENT_MESH_OBJET_RELATIONS';
export const POST_ASSESSMENT_MESH_OBJET_RELATION = 'new_assessments/assessments/POST_ASSESSMENT_MESH_OBJET_RELATION';
export const UPDATE_ASSESSMENT_MESH_OBJET_RELATION = 'new_assessments/assessments/UPDATE_ASSESSMENT_MESH_OBJET_RELATION';
export const PATCH_ASSESSMENT_MESH_OBJET_RELATION = 'new_assessments/assessments/PATCH_ASSESSMENT_MESH_OBJET_RELATION';
export const DELETE_ASSESSMENT_MESH_OBJET_RELATION = 'new_assessments/assessments/DELETE_ASSESSMENT_MESH_OBJET_RELATION';
export const FETCH_ASSESSMENT_RELATION = 'new_assessments/assessments/FETCH_ASSESSMENT_RELATION';
export const FETCH_ASSESSMENT_RELATIONS = 'new_assessments/assessments/FETCH_ASSESSMENT_RELATIONS';
export const POST_ASSESSMENT_RELATION = 'new_assessments/assessments/POST_ASSESSMENT_RELATION';
export const UPDATE_ASSESSMENT_RELATION = 'new_assessments/assessments/UPDATE_ASSESSMENT_RELATION';
export const PATCH_ASSESSMENT_RELATION = 'new_assessments/assessments/PATCH_ASSESSMENT_RELATION';
export const DELETE_ASSESSMENT_RELATION = 'new_assessments/assessments/DELETE_ASSESSMENT_RELATION';
// new_assessment evaluatee_assessments
export const EVALUATEE_ASSESSMENTS = 'new_assessments/evaluatee_assessments/EVALUATEE_ASSESSMENTS';
export const MUTATE_EVALUATEE_ASSESSMENTS = 'new_assessments/evaluatee_assessments/MUTATE_EVALUATEE_ASSESSMENTS';
export const MUTATE_EVALUATEE_ASSESSMENT = 'new_assessments/evaluatee_assessments/MUTATE_EVALUATEE_ASSESSMENT';
export const MUTATE_DELETE_EVALUATEE_ASSESSMENT = 'new_assessments/evaluatee_assessments/MUTATE_DELETE_EVALUATEE_ASSESSMENT';
export const FETCH_EVALUATEE_ASSESSMENT = 'new_assessments/evaluatee_assessments/FETCH_EVALUATEE_ASSESSMENT';
export const FETCH_EVALUATEE_ASSESSMENTS = 'new_assessments/evaluatee_assessments/FETCH_EVALUATEE_ASSESSMENTS';
export const POST_EVALUATEE_ASSESSMENT = 'new_assessments/evaluatee_assessments/POST_EVALUATEE_ASSESSMENT';
export const UPDATE_EVALUATEE_ASSESSMENT = 'new_assessments/evaluatee_assessments/UPDATE_EVALUATEE_ASSESSMENT';
export const PATCH_EVALUATEE_ASSESSMENT = 'new_assessments/evaluatee_assessments/PATCH_EVALUATEE_ASSESSMENT';
export const DELETE_EVALUATEE_ASSESSMENT = 'new_assessments/evaluatee_assessments/DELETE_EVALUATEE_ASSESSMENT';
//New Evaluation
export const NEW_EVALUATIONS = 'new_evaluation_modules/new_evaluation/NEW_EVALUATIONS';
export const MUTATE_NEW_EVALUATIONS = 'new_evaluation_modules/new_evaluation/MUTATE_NEW_EVALUATIONS';
export const MUTATE_NEW_EVALUATION = 'new_evaluation_modules/new_evaluation/MUTATE_NEW_EVALUATION';
export const MUTATE_DELETE_NEW_EVALUATION = 'new_evaluation_modules/new_evaluation/MUTATE_DELETE_NEW_EVALUATION';
export const FETCH_NEW_EVALUATION = 'new_evaluation_modules/new_evaluation/FETCH_NEW_EVALUATION';
export const FETCH_NEW_EVALUATIONS = 'new_evaluation_modules/new_evaluation/FETCH_NEW_EVALUATIONS';
export const POST_NEW_EVALUATION = 'new_evaluation_modules/new_evaluation/POST_NEW_EVALUATION';
export const UPDATE_NEW_EVALUATION = 'new_evaluation_modules/new_evaluation/UPDATE_NEW_EVALUATION';
export const PATCH_NEW_EVALUATION = 'new_evaluation_modules/new_evaluation/PATCH_NEW_EVALUATION';
export const DELETE_NEW_EVALUATION = 'new_evaluation_modules/new_evaluation/DELETE_NEW_EVALUATION';
export const NEW_TESTS = 'new_evaluation_modules/new_evaluation/NEW_TESTS';
export const MUTATE_NEW_TESTS = 'new_evaluation_modules/new_evaluation/MUTATE_NEW_TESTS';
export const MUTATE_NEW_TEST = 'new_evaluation_modules/new_evaluation/MUTATE_NEW_TEST';
export const MUTATE_DELETE_NEW_TEST = 'new_evaluation_modules/new_evaluation/MUTATE_DELETE_NEW_TEST';
export const FETCH_NEW_TEST = 'new_evaluation_modules/new_evaluation/FETCH_NEW_TEST';
export const FETCH_NEW_TESTS = 'new_evaluation_modules/new_evaluation/FETCH_NEW_TESTS';
export const POST_NEW_TEST = 'new_evaluation_modules/new_evaluation/POST_NEW_TEST';
export const UPDATE_NEW_TEST = 'new_evaluation_modules/new_evaluation/UPDATE_NEW_TEST';
export const PATCH_NEW_TEST = 'new_evaluation_modules/new_evaluation/PATCH_NEW_TEST';
export const DELETE_NEW_TEST = 'new_evaluation_modules/new_evaluation/DELETE_NEW_TEST';
export const NEW_TEST_EVALUATION_CRITERIA_MICROS = 'new_evaluation_modules/new_evaluation/NEW_TEST_EVALUATION_CRITERIA_MICROS';
export const MUTATE_NEW_TEST_EVALUATION_CRITERIA_MICROS = 'new_evaluation_modules/new_evaluation/MUTATE_NEW_TEST_EVALUATION_CRITERIA_MICROS';
export const MUTATE_NEW_TEST_EVALUATION_CRITERIA_MICRO = 'new_evaluation_modules/new_evaluation/MUTATE_NEW_TEST_EVALUATION_CRITERIA_MICRO';
export const MUTATE_DELETE_NEW_TEST_EVALUATION_CRITERIA_MICRO = 'new_evaluation_modules/new_evaluation/MUTATE_DELETE_NEW_TEST_EVALUATION_CRITERIA_MICRO';
export const FETCH_NEW_TEST_EVALUATION_CRITERIA_MICRO = 'new_evaluation_modules/new_evaluation/FETCH_NEW_TEST_EVALUATION_CRITERIA_MICRO';
export const FETCH_NEW_TEST_EVALUATION_CRITERIA_MICROS = 'new_evaluation_modules/new_evaluation/FETCH_NEW_TEST_EVALUATION_CRITERIA_MICROS';
export const POST_NEW_TEST_EVALUATION_CRITERIA_MICRO = 'new_evaluation_modules/new_evaluation/POST_NEW_TEST_EVALUATION_CRITERIA_MICRO';
export const UPDATE_NEW_TEST_EVALUATION_CRITERIA_MICRO = 'new_evaluation_modules/new_evaluation/UPDATE_NEW_TEST_EVALUATION_CRITERIA_MICRO';
export const PATCH_NEW_TEST_EVALUATION_CRITERIA_MICRO = 'new_evaluation_modules/new_evaluation/PATCH_NEW_TEST_EVALUATION_CRITERIA_MICRO';
export const DELETE_NEW_TEST_EVALUATION_CRITERIA_MICRO = 'new_evaluation_modules/new_evaluation/DELETE_NEW_TEST_EVALUATION_CRITERIA_MICRO';
export const NEW_EVALUATION_TYPES = 'new_evaluation_modules/new_evaluation/NEW_EVALUATION_TYPES';
export const NEW_EVALUATION_SCOPES = 'new_evaluation_modules/new_evaluation/NEW_EVALUATION_SCOPES';
export const NEW_EVALUATION_CATEGORIES = 'new_evaluation_modules/new_evaluation/NEW_EVALUATION_CATEGORIES';
export const NEW_TEST_TYPES = 'new_evaluation_modules/new_evaluation/NEW_TEST_TYPES';
export const MUTATE_NEW_EVALUATION_TYPES = 'new_evaluation_modules/new_evaluation/MUTATE_NEW_EVALUATION_TYPES';
export const MUTATE_NEW_EVALUATION_SCOPES = 'new_evaluation_modules/new_evaluation/MUTATE_NEW_EVALUATION_SCOPES';
export const MUTATE_NEW_EVALUATION_CATEGORIES = 'new_evaluation_modules/new_evaluation/MUTATE_NEW_EVALUATION_CATEGORIES';
export const MUTATE_NEW_TEST_TYPES = 'new_evaluation_modules/new_evaluation/MUTATE_NEW_TEST_TYPES';
export const FETCH_NEW_EVALUATION_TYPES = 'new_evaluation_modules/new_evaluation/FETCH_NEW_EVALUATION_TYPES';
export const FETCH_NEW_EVALUATION_SCOPES = 'new_evaluation_modules/new_evaluation/FETCH_NEW_EVALUATION_SCOPES';
export const FETCH_NEW_EVALUATION_CATEGORIES = 'new_evaluation_modules/new_evaluation/FETCH_NEW_EVALUATION_CATEGORIES';
export const FETCH_NEW_TEST_TYPES = 'new_evaluation_modules/new_evaluation/FETCH_NEW_TEST_TYPES';
export const NEW_TEST_DESCRIPTORS = "new_evaluation_modules/new_evaluation/NEW_TEST_DESCRIPTORS";
export const MUTATE_NEW_TEST_DESCRIPTORS = "new_evaluation_modules/new_evaluation/MUTATE_NEW_TEST_DESCRIPTORS";
export const MUTATE_NEW_TEST_DESCRIPTOR = "new_evaluation_modules/new_evaluation/MUTATE_NEW_TEST_DESCRIPTOR";
export const MUTATE_DELETE_NEW_TEST_DESCRIPTOR = "new_evaluation_modules/new_evaluation/MUTATE_DELETE_NEW_TEST_DESCRIPTOR";
export const FETCH_NEW_TEST_DESCRIPTOR = "new_evaluation_modules/new_evaluation/FETCH_NEW_TEST_DESCRIPTOR";
export const FETCH_NEW_TEST_DESCRIPTORS = "new_evaluation_modules/new_evaluation/FETCH_NEW_TEST_DESCRIPTORS";
export const POST_NEW_TEST_DESCRIPTOR = "new_evaluation_modules/new_evaluation/POST_NEW_TEST_DESCRIPTOR";
export const UPDATE_NEW_TEST_DESCRIPTOR = "new_evaluation_modules/new_evaluation/UPDATE_NEW_TEST_DESCRIPTOR";
export const PATCH_NEW_TEST_DESCRIPTOR = "new_evaluation_modules/new_evaluation/PATCH_NEW_TEST_DESCRIPTOR";
export const DELETE_NEW_TEST_DESCRIPTOR = "new_evaluation_modules/new_evaluation/DELETE_NEW_TEST_DESCRIPTOR";
export const NEW_TEST_GROUPS = "new_evaluation_modules/new_evaluation/NEW_TEST_GROUPS";
export const MUTATE_NEW_TEST_GROUPS = "new_evaluation_modules/new_evaluation/MUTATE_NEW_TEST_GROUPS";
export const MUTATE_NEW_TEST_GROUP = "new_evaluation_modules/new_evaluation/MUTATE_NEW_TEST_GROUP";
export const MUTATE_DELETE_NEW_TEST_GROUP = "new_evaluation_modules/new_evaluation/MUTATE_DELETE_NEW_TEST_GROUP";
export const FETCH_NEW_TEST_GROUP = "new_evaluation_modules/new_evaluation/FETCH_NEW_TEST_GROUP";
export const FETCH_NEW_TEST_GROUPS = "new_evaluation_modules/new_evaluation/FETCH_NEW_TEST_GROUPS";
export const POST_NEW_TEST_GROUP = "new_evaluation_modules/new_evaluation/POST_NEW_TEST_GROUP";
export const UPDATE_NEW_TEST_GROUP = "new_evaluation_modules/new_evaluation/UPDATE_NEW_TEST_GROUP";
export const PATCH_NEW_TEST_GROUP = "new_evaluation_modules/new_evaluation/PATCH_NEW_TEST_GROUP";
export const DELETE_NEW_TEST_GROUP = "new_evaluation_modules/new_evaluation/DELETE_NEW_TEST_GROUP";

// New Question
export const NEW_TEST_SEGMENTS = 'new_evaluation_modules/new_questions/NEW_TEST_SEGMENTS';
export const MUTATE_NEW_TEST_SEGMENTS = 'new_evaluation_modules/new_questions/MUTATE_NEW_TEST_SEGMENTS';
export const MUTATE_NEW_TEST_SEGMENT = 'new_evaluation_modules/new_questions/MUTATE_NEW_TEST_SEGMENT';
export const MUTATE_DELETE_NEW_TEST_SEGMENT = 'new_evaluation_modules/new_questions/MUTATE_DELETE_NEW_TEST_SEGMENT';
export const FETCH_NEW_TEST_SEGMENT = 'new_evaluation_modules/new_questions/FETCH_NEW_TEST_SEGMENT';
export const FETCH_NEW_TEST_SEGMENTS = 'new_evaluation_modules/new_questions/FETCH_NEW_TEST_SEGMENTS';
export const POST_NEW_TEST_SEGMENT = 'new_evaluation_modules/new_questions/POST_NEW_TEST_SEGMENT';
export const UPDATE_NEW_TEST_SEGMENT = 'new_evaluation_modules/new_questions/UPDATE_NEW_TEST_SEGMENT';
export const PATCH_NEW_TEST_SEGMENT = 'new_evaluation_modules/new_questions/PATCH_NEW_TEST_SEGMENT';
export const DELETE_NEW_TEST_SEGMENT = 'new_evaluation_modules/new_questions/DELETE_NEW_TEST_SEGMENT';
export const NEW_REDACTION_QUESTIONS = 'new_evaluation_modules/new_questions/NEW_REDACTION_QUESTIONS';
export const MUTATE_NEW_REDACTION_QUESTIONS = 'new_evaluation_modules/new_questions/MUTATE_NEW_REDACTION_QUESTIONS';
export const MUTATE_NEW_REDACTION_QUESTION = 'new_evaluation_modules/new_questions/MUTATE_NEW_REDACTION_QUESTION';
export const MUTATE_DELETE_NEW_REDACTION_QUESTION = 'new_evaluation_modules/new_questions/MUTATE_DELETE_NEW_REDACTION_QUESTION';
export const FETCH_NEW_REDACTION_QUESTION = 'new_evaluation_modules/new_questions/FETCH_NEW_REDACTION_QUESTION';
export const FETCH_NEW_REDACTION_QUESTIONS = 'new_evaluation_modules/new_questions/FETCH_NEW_REDACTION_QUESTIONS';
export const POST_NEW_REDACTION_QUESTION = 'new_evaluation_modules/new_questions/POST_NEW_REDACTION_QUESTION';
export const UPDATE_NEW_REDACTION_QUESTION = 'new_evaluation_modules/new_questions/UPDATE_NEW_REDACTION_QUESTION';
export const PATCH_NEW_REDACTION_QUESTION = 'new_evaluation_modules/new_questions/PATCH_NEW_REDACTION_QUESTION';
export const DELETE_NEW_REDACTION_QUESTION = 'new_evaluation_modules/new_questions/DELETE_NEW_REDACTION_QUESTION';
export const REDACTION_QUESTION_LENGTH_ANSWERS = 'new_evaluation_modules/new_questions/REDACTION_QUESTION_LENGTH_ANSWERS';
export const MUTATE_REDACTION_QUESTION_LENGTH_ANSWERS = 'new_evaluation_modules/new_questions/MUTATE_REDACTION_QUESTION_LENGTH_ANSWERS';
export const FETCH_REDACTION_QUESTION_LENGTH_ANSWERS = 'new_evaluation_modules/new_questions/FETCH_REDACTION_QUESTION_LENGTH_ANSWERS';
export const NEW_MULTIPLE_CHOICE_QUESTIONS = 'new_evaluation_modules/new_questions/NEW_MULTIPLE_CHOICE_QUESTIONS';
export const MUTATE_NEW_MULTIPLE_CHOICE_QUESTIONS = 'new_evaluation_modules/new_questions/MUTATE_NEW_MULTIPLE_CHOICE_QUESTIONS';
export const MUTATE_NEW_MULTIPLE_CHOICE_QUESTION = 'new_evaluation_modules/new_questions/MUTATE_NEW_MULTIPLE_CHOICE_QUESTION';
export const MUTATE_DELETE_NEW_MULTIPLE_CHOICE_QUESTION = 'new_evaluation_modules/new_questions/MUTATE_DELETE_NEW_MULTIPLE_CHOICE_QUESTION';
export const FETCH_NEW_MULTIPLE_CHOICE_QUESTION = 'new_evaluation_modules/new_questions/FETCH_NEW_MULTIPLE_CHOICE_QUESTION';
export const FETCH_NEW_MULTIPLE_CHOICE_QUESTIONS = 'new_evaluation_modules/new_questions/FETCH_NEW_MULTIPLE_CHOICE_QUESTIONS';
export const POST_NEW_MULTIPLE_CHOICE_QUESTION = 'new_evaluation_modules/new_questions/POST_NEW_MULTIPLE_CHOICE_QUESTION';
export const UPDATE_NEW_MULTIPLE_CHOICE_QUESTION = 'new_evaluation_modules/new_questions/UPDATE_NEW_MULTIPLE_CHOICE_QUESTION';
export const PATCH_NEW_MULTIPLE_CHOICE_QUESTION = 'new_evaluation_modules/new_questions/PATCH_NEW_MULTIPLE_CHOICE_QUESTION';
export const DELETE_NEW_MULTIPLE_CHOICE_QUESTION = 'new_evaluation_modules/new_questions/DELETE_NEW_MULTIPLE_CHOICE_QUESTION';
export const MULTIPLE_QUESTION_SCORE_TYPES = 'new_evaluation_modules/new_questions/MULTIPLE_QUESTION_SCORE_TYPES';
export const MUTATE_MULTIPLE_QUESTION_SCORE_TYPES = 'new_evaluation_modules/new_questions/MUTATE_MULTIPLE_QUESTION_SCORE_TYPES';
export const FETCH_MULTIPLE_QUESTION_SCORE_TYPES = 'new_evaluation_modules/new_questions/FETCH_MULTIPLE_QUESTION_SCORE_TYPES';
export const MULTIPLE_CHOICE_QUESTION_SUB_TYPES = 'new_evaluation_modules/new_questions/MULTIPLE_CHOICE_QUESTION_SUB_TYPES';
export const MUTATE_MULTIPLE_CHOICE_QUESTION_SUB_TYPES = 'new_evaluation_modules/new_questions/MUTATE_MULTIPLE_CHOICE_QUESTION_SUB_TYPES';
export const FETCH_MULTIPLE_CHOICE_QUESTION_SUB_TYPES = 'new_evaluation_modules/new_questions/FETCH_MULTIPLE_CHOICE_QUESTION_SUB_TYPES';
export const NEW_MULTIPLE_CHOICE_QUESTION_OPTIONS = 'new_evaluation_modules/new_questions/NEW_MULTIPLE_CHOICE_QUESTION_OPTIONS';
export const MUTATE_NEW_MULTIPLE_CHOICE_QUESTION_OPTIONS = 'new_evaluation_modules/new_questions/MUTATE_NEW_MULTIPLE_CHOICE_QUESTION_OPTIONS';
export const MUTATE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION = 'new_evaluation_modules/new_questions/MUTATE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION';
export const MUTATE_DELETE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION = 'new_evaluation_modules/new_questions/MUTATE_DELETE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION';
export const FETCH_NEW_MULTIPLE_CHOICE_QUESTION_OPTION = 'new_evaluation_modules/new_questions/FETCH_NEW_MULTIPLE_CHOICE_QUESTION_OPTION';
export const FETCH_NEW_MULTIPLE_CHOICE_QUESTION_OPTIONS = 'new_evaluation_modules/new_questions/FETCH_NEW_MULTIPLE_CHOICE_QUESTION_OPTIONS';
export const POST_NEW_MULTIPLE_CHOICE_QUESTION_OPTION = 'new_evaluation_modules/new_questions/POST_NEW_MULTIPLE_CHOICE_QUESTION_OPTION';
export const UPDATE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION = 'new_evaluation_modules/new_questions/UPDATE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION';
export const PATCH_NEW_MULTIPLE_CHOICE_QUESTION_OPTION = 'new_evaluation_modules/new_questions/PATCH_NEW_MULTIPLE_CHOICE_QUESTION_OPTION';
export const DELETE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION = 'new_evaluation_modules/new_questions/DELETE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION';
export const NEW_FILLING_QUESTIONS = 'new_evaluation_modules/new_questions/NEW_FILLING_QUESTIONS';
export const NEW_FILLING_QUESTION_OPTIONS = 'new_evaluation_modules/new_questions/NEW_FILLING_QUESTION_OPTIONS';
export const NEW_FILLING_QUESTION_SUB_TYPES = 'new_evaluation_modules/new_questions/NEW_FILLING_QUESTION_SUB_TYPES';
export const MUTATE_NEW_FILLING_QUESTIONS = 'new_evaluation_modules/new_questions/MUTATE_NEW_FILLING_QUESTIONS';
export const MUTATE_NEW_FILLING_QUESTION = 'new_evaluation_modules/new_questions/MUTATE_NEW_FILLING_QUESTION';
export const MUTATE_DELETE_NEW_FILLING_QUESTION = 'new_evaluation_modules/new_questions/MUTATE_DELETE_NEW_FILLING_QUESTION';
export const MUTATE_NEW_FILLING_QUESTION_OPTIONS = 'new_evaluation_modules/new_questions/MUTATE_NEW_FILLING_QUESTION_OPTIONS';
export const MUTATE_NEW_FILLING_QUESTION_OPTION = 'new_evaluation_modules/new_questions/MUTATE_NEW_FILLING_QUESTION_OPTION';
export const MUTATE_DELETE_NEW_FILLING_QUESTION_OPTION = 'new_evaluation_modules/new_questions/MUTATE_DELETE_NEW_FILLING_QUESTION_OPTION';
export const MUTATE_NEW_FILLING_QUESTION_SUB_TYPES = 'new_evaluation_modules/new_questions/MUTATE_NEW_FILLING_QUESTION_SUB_TYPES';
export const FETCH_NEW_FILLING_QUESTION = 'new_evaluation_modules/new_questions/FETCH_NEW_FILLING_QUESTION';
export const FETCH_NEW_FILLING_QUESTIONS = 'new_evaluation_modules/new_questions/FETCH_NEW_FILLING_QUESTIONS';
export const POST_NEW_FILLING_QUESTION = 'new_evaluation_modules/new_questions/POST_NEW_FILLING_QUESTION';
export const UPDATE_NEW_FILLING_QUESTION = 'new_evaluation_modules/new_questions/UPDATE_NEW_FILLING_QUESTION';
export const PATCH_NEW_FILLING_QUESTION = 'new_evaluation_modules/new_questions/PATCH_NEW_FILLING_QUESTION';
export const DELETE_NEW_FILLING_QUESTION = 'new_evaluation_modules/new_questions/DELETE_NEW_FILLING_QUESTION';
export const FETCH_NEW_FILLING_QUESTION_OPTION = 'new_evaluation_modules/new_questions/FETCH_NEW_FILLING_QUESTION_OPTION';
export const FETCH_NEW_FILLING_QUESTION_OPTIONS = 'new_evaluation_modules/new_questions/FETCH_NEW_FILLING_QUESTION_OPTIONS';
export const POST_NEW_FILLING_QUESTION_OPTION = 'new_evaluation_modules/new_questions/POST_NEW_FILLING_QUESTION_OPTION';
export const UPDATE_NEW_FILLING_QUESTION_OPTION = 'new_evaluation_modules/new_questions/UPDATE_NEW_FILLING_QUESTION_OPTION';
export const PATCH_NEW_FILLING_QUESTION_OPTION = 'new_evaluation_modules/new_questions/PATCH_NEW_FILLING_QUESTION_OPTION';
export const DELETE_NEW_FILLING_QUESTION_OPTION = 'new_evaluation_modules/new_questions/DELETE_NEW_FILLING_QUESTION_OPTION';
export const FETCH_NEW_FILLING_QUESTION_SUB_TYPES = 'new_evaluation_modules/new_questions/FETCH_NEW_FILLING_QUESTION_SUB_TYPES';
export const NEW_SEQUENTIAL_QUESTIONS = 'new_evaluation_modules/new_question/NEW_SEQUENTIAL_QUESTIONS';
export const MUTATE_NEW_SEQUENTIAL_QUESTIONS = 'new_evaluation_modules/new_question/MUTATE_NEW_SEQUENTIAL_QUESTIONS';
export const MUTATE_NEW_SEQUENTIAL_QUESTION = 'new_evaluation_modules/new_question/MUTATE_NEW_SEQUENTIAL_QUESTION';
export const MUTATE_DELETE_NEW_SEQUENTIAL_QUESTION = 'new_evaluation_modules/new_question/MUTATE_DELETE_NEW_SEQUENTIAL_QUESTION';
export const FETCH_NEW_SEQUENTIAL_QUESTION = 'new_evaluation_modules/new_question/FETCH_NEW_SEQUENTIAL_QUESTION';
export const FETCH_NEW_SEQUENTIAL_QUESTIONS = 'new_evaluation_modules/new_question/FETCH_NEW_SEQUENTIAL_QUESTIONS';
export const POST_NEW_SEQUENTIAL_QUESTION = 'new_evaluation_modules/new_question/POST_NEW_SEQUENTIAL_QUESTION';
export const UPDATE_NEW_SEQUENTIAL_QUESTION = 'new_evaluation_modules/new_question/UPDATE_NEW_SEQUENTIAL_QUESTION';
export const PATCH_NEW_SEQUENTIAL_QUESTION = 'new_evaluation_modules/new_question/PATCH_NEW_SEQUENTIAL_QUESTION';
export const DELETE_NEW_SEQUENTIAL_QUESTION = 'new_evaluation_modules/new_question/DELETE_NEW_SEQUENTIAL_QUESTION';
export const NEW_MATCHING_QUESTIONS = 'new_evaluation_modules/new_question/NEW_MATCHING_QUESTIONS';
export const NEW_MATCHING_QUESTION_OPTIONS = 'new_evaluation_modules/new_question/NEW_MATCHING_QUESTION_OPTIONS';
export const MUTATE_NEW_MATCHING_QUESTIONS = 'new_evaluation_modules/new_question/MUTATE_NEW_MATCHING_QUESTIONS';
export const MUTATE_NEW_MATCHING_QUESTION = 'new_evaluation_modules/new_question/MUTATE_NEW_MATCHING_QUESTION';
export const MUTATE_DELETE_NEW_MATCHING_QUESTION = 'new_evaluation_modules/new_question/MUTATE_DELETE_NEW_MATCHING_QUESTION';
export const MUTATE_NEW_MATCHING_QUESTION_OPTIONS = 'new_evaluation_modules/new_question/MUTATE_NEW_MATCHING_QUESTION_OPTIONS';
export const MUTATE_NEW_MATCHING_QUESTION_OPTION = 'new_evaluation_modules/new_question/MUTATE_NEW_MATCHING_QUESTION_OPTION';
export const MUTATE_DELETE_NEW_MATCHING_QUESTION_OPTION = 'new_evaluation_modules/new_question/MUTATE_DELETE_NEW_MATCHING_QUESTION_OPTION';
export const FETCH_NEW_MATCHING_QUESTION = 'new_evaluation_modules/new_question/FETCH_NEW_MATCHING_QUESTION';
export const FETCH_NEW_MATCHING_QUESTIONS = 'new_evaluation_modules/new_question/FETCH_NEW_MATCHING_QUESTIONS';
export const POST_NEW_MATCHING_QUESTION = 'new_evaluation_modules/new_question/POST_NEW_MATCHING_QUESTION';
export const UPDATE_NEW_MATCHING_QUESTION = 'new_evaluation_modules/new_question/UPDATE_NEW_MATCHING_QUESTION';
export const PATCH_NEW_MATCHING_QUESTION = 'new_evaluation_modules/new_question/PATCH_NEW_MATCHING_QUESTION';
export const DELETE_NEW_MATCHING_QUESTION = 'new_evaluation_modules/new_question/DELETE_NEW_MATCHING_QUESTION';
export const FETCH_NEW_MATCHING_QUESTION_OPTION = 'new_evaluation_modules/new_question/FETCH_NEW_MATCHING_QUESTION_OPTION';
export const FETCH_NEW_MATCHING_QUESTION_OPTIONS = 'new_evaluation_modules/new_question/FETCH_NEW_MATCHING_QUESTION_OPTIONS';
export const POST_NEW_MATCHING_QUESTION_OPTION = 'new_evaluation_modules/new_question/POST_NEW_MATCHING_QUESTION_OPTION';
export const UPDATE_NEW_MATCHING_QUESTION_OPTION = 'new_evaluation_modules/new_question/UPDATE_NEW_MATCHING_QUESTION_OPTION';
export const PATCH_NEW_MATCHING_QUESTION_OPTION = 'new_evaluation_modules/new_question/PATCH_NEW_MATCHING_QUESTION_OPTION';
export const DELETE_NEW_MATCHING_QUESTION_OPTION = 'new_evaluation_modules/new_question/DELETE_NEW_MATCHING_QUESTION_OPTION';
export const NEW_MATCHING_QUESTION_SCORES = 'new_evaluation_modules/new_question/NEW_MATCHING_QUESTION_SCORES';
export const MUTATE_NEW_MATCHING_QUESTION_SCORES = 'new_evaluation_modules/new_question/MUTATE_NEW_MATCHING_QUESTION_SCORES';
export const MUTATE_NEW_MATCHING_QUESTION_SCORE = 'new_evaluation_modules/new_question/MUTATE_NEW_MATCHING_QUESTION_SCORE';
export const MUTATE_DELETE_NEW_MATCHING_QUESTION_SCORE = 'new_evaluation_modules/new_question/MUTATE_DELETE_NEW_MATCHING_QUESTION_SCORE';
export const FETCH_NEW_MATCHING_QUESTION_SCORE = 'new_evaluation_modules/new_question/FETCH_NEW_MATCHING_QUESTION_SCORE';
export const FETCH_NEW_MATCHING_QUESTION_SCORES = 'new_evaluation_modules/new_question/FETCH_NEW_MATCHING_QUESTION_SCORES';
export const FETCH_NEW_MATCHING_QUESTION_SCORES_BY = 'new_evaluation_modules/new_question/FETCH_NEW_MATCHING_QUESTION_SCORES_BY';
export const POST_NEW_MATCHING_QUESTION_SCORE = 'new_evaluation_modules/new_question/POST_NEW_MATCHING_QUESTION_SCORE';
export const UPDATE_NEW_MATCHING_QUESTION_SCORE = 'new_evaluation_modules/new_question/UPDATE_NEW_MATCHING_QUESTION_SCORE';
export const PATCH_NEW_MATCHING_QUESTION_SCORE = 'new_evaluation_modules/new_question/PATCH_NEW_MATCHING_QUESTION_SCORE';
export const DELETE_NEW_MATCHING_QUESTION_SCORE = 'new_evaluation_modules/new_question/DELETE_NEW_MATCHING_QUESTION_SCORE';
// New Rubric
export const NEW_RUBRIC_INSTRUMENTS = 'new_evaluation_modules/new_rubrics/NEW_RUBRIC_INSTRUMENTS';
export const NEW_RUBRIC_CRITERIAS = 'new_evaluation_modules/new_rubrics/NEW_RUBRIC_CRITERIAS';
export const NEW_RUBRIC_ACHIEVEMENTS = 'new_evaluation_modules/new_rubrics/NEW_RUBRIC_ACHIEVEMENTS';
export const NEW_RUBRIC_DESCRIPTORS = 'new_evaluation_modules/new_rubrics/NEW_RUBRIC_DESCRIPTORS';
export const RUBRIC_PRESETS = 'new_evaluation_modules/new_rubrics/RUBRIC_PRESETS';
export const RUBRIC_PRESET_UNITS = 'new_evaluation_modules/new_rubrics/RUBRIC_PRESET_UNITS';
export const MUTATE_RUBRIC_PRESET = 'new_evaluation_modules/new_rubrics/MUTATE_RUBRIC_PRESET';
export const MUTATE_DELETE_RUBRIC_PRESET = 'new_evaluation_modules/new_rubrics/MUTATE_DELETE_RUBRIC_PRESET';
export const MUTATE_NEW_RUBRIC_INSTRUMENT = 'new_evaluation_modules/new_rubrics/MUTATE_NEW_RUBRIC_INSTRUMENT';
export const MUTATE_DELETE_NEW_RUBRIC_INSTRUMENT = 'new_evaluation_modules/new_rubrics/MUTATE_DELETE_NEW_RUBRIC_INSTRUMENT';
export const MUTATE_RUBRIC_PRESETS = 'new_evaluation_modules/new_rubrics/MUTATE_RUBRIC_PRESETS';
export const MUTATE_RUBRIC_PRESET_UNITS = 'new_evaluation_modules/new_rubrics/MUTATE_RUBRIC_PRESET_UNITS';
export const MUTATE_RUBRIC_PRESET_UNIT = 'new_evaluation_modules/new_rubrics/MUTATE_RUBRIC_PRESET_UNIT';
export const MUTATE_DELETE_RUBRIC_PRESET_UNIT = 'new_evaluation_modules/new_rubrics/MUTATE_DELETE_RUBRIC_PRESET_UNIT';
export const MUTATE_NEW_RUBRIC_ACHIEVEMENT = 'new_evaluation_modules/new_rubrics/MUTATE_NEW_RUBRIC_ACHIEVEMENT';
export const MUTATE_DELETE_NEW_RUBRIC_ACHIEVEMENT = 'new_evaluation_modules/new_rubrics/MUTATE_DELETE_NEW_RUBRIC_ACHIEVEMENT';
export const MUTATE_NEW_RUBRIC_CRITERIA = 'new_evaluation_modules/new_rubrics/MUTATE_NEW_RUBRIC_CRITERIA';
export const MUTATE_DELETE_NEW_RUBRIC_CRITERIA = 'new_evaluation_modules/new_rubrics/MUTATE_DELETE_NEW_RUBRIC_CRITERIA';
export const MUTATE_NEW_RUBRIC_DESCRIPTOR = 'new_evaluation_modules/new_rubrics/MUTATE_NEW_RUBRIC_DESCRIPTOR';
export const MUTATE_DELETE_NEW_RUBRIC_DESCRIPTOR = 'new_evaluation_modules/new_rubrics/MUTATE_DELETE_NEW_RUBRIC_DESCRIPTOR';
export const FETCH_NEW_RUBRIC_INSTRUMENT = 'new_evaluation_modules/new_rubrics/FETCH_NEW_RUBRIC_INSTRUMENT';
export const FETCH_NEW_RUBRIC_INSTRUMENTS = 'new_evaluation_modules/new_rubrics/FETCH_NEW_RUBRIC_INSTRUMENTS';
export const POST_NEW_RUBRIC_INSTRUMENT = 'new_evaluation_modules/new_rubrics/POST_NEW_RUBRIC_INSTRUMENT';
export const UPDATE_NEW_RUBRIC_INSTRUMENT = 'new_evaluation_modules/new_rubrics/UPDATE_NEW_RUBRIC_INSTRUMENT';
export const PATCH_NEW_RUBRIC_INSTRUMENT = 'new_evaluation_modules/new_rubrics/PATCH_NEW_RUBRIC_INSTRUMENT';
export const DELETE_NEW_RUBRIC_INSTRUMENT = 'new_evaluation_modules/new_rubrics/DELETE_NEW_RUBRIC_INSTRUMENT';
export const FETCH_RUBRIC_PRESETS = 'new_evaluation_modules/new_rubrics/FETCH_RUBRIC_PRESETS';
export const POST_RUBRIC_PRESET = 'new_evaluation_modules/new_rubrics/POST_RUBRIC_PRESET';
export const UPDATE_RUBRIC_PRESET = 'new_evaluation_modules/new_rubrics/UPDATE_RUBRIC_PRESET';
export const PATCH_RUBRIC_PRESET = 'new_evaluation_modules/new_rubrics/PATCH_RUBRIC_PRESET';
export const DELETE_RUBRIC_PRESET = 'new_evaluation_modules/new_rubrics/DELETE_RUBRIC_PRESET';
export const FETCH_RUBRIC_PRESET_UNITS = 'new_evaluation_modules/new_rubrics/FETCH_RUBRIC_PRESET_UNITS';
export const POST_RUBRIC_PRESET_UNIT = 'new_evaluation_modules/new_rubrics/POST_RUBRIC_PRESET_UNIT';
export const UPDATE_RUBRIC_PRESET_UNIT = 'new_evaluation_modules/new_rubrics/UPDATE_RUBRIC_PRESET_UNIT';
export const PATCH_RUBRIC_PRESET_UNIT = 'new_evaluation_modules/new_rubrics/PATCH_RUBRIC_PRESET_UNIT';
export const DELETE_RUBRIC_PRESET_UNIT = 'new_evaluation_modules/new_rubrics/DELETE_RUBRIC_PRESET_UNIT';
export const FETCH_NEW_RUBRIC_ACHIEVEMENT = 'new_evaluation_modules/new_rubrics/FETCH_NEW_RUBRIC_ACHIEVEMENT';
export const FETCH_NEW_RUBRIC_ACHIEVEMENTS = 'new_evaluation_modules/new_rubrics/FETCH_NEW_RUBRIC_ACHIEVEMENTS';
export const POST_NEW_RUBRIC_ACHIEVEMENT = 'new_evaluation_modules/new_rubrics/POST_NEW_RUBRIC_ACHIEVEMENT';
export const UPDATE_NEW_RUBRIC_ACHIEVEMENT = 'new_evaluation_modules/new_rubrics/UPDATE_NEW_RUBRIC_ACHIEVEMENT';
export const PATCH_NEW_RUBRIC_ACHIEVEMENT = 'new_evaluation_modules/new_rubrics/PATCH_NEW_RUBRIC_ACHIEVEMENT';
export const DELETE_NEW_RUBRIC_ACHIEVEMENT = 'new_evaluation_modules/new_rubrics/DELETE_NEW_RUBRIC_ACHIEVEMENT';
export const FETCH_NEW_RUBRIC_CRITERIA = 'new_evaluation_modules/new_rubrics/FETCH_NEW_RUBRIC_CRITERIA';
export const FETCH_NEW_RUBRIC_CRITERIAS = 'new_evaluation_modules/new_rubrics/FETCH_NEW_RUBRIC_CRITERIAS';
export const POST_NEW_RUBRIC_CRITERIA = 'new_evaluation_modules/new_rubrics/POST_NEW_RUBRIC_CRITERIA';
export const UPDATE_NEW_RUBRIC_CRITERIA = 'new_evaluation_modules/new_rubrics/UPDATE_NEW_RUBRIC_CRITERIA';
export const PATCH_NEW_RUBRIC_CRITERIA = 'new_evaluation_modules/new_rubrics/PATCH_NEW_RUBRIC_CRITERIA';
export const DELETE_NEW_RUBRIC_CRITERIA = 'new_evaluation_modules/new_rubrics/DELETE_NEW_RUBRIC_CRITERIA';
export const FETCH_NEW_RUBRIC_DESCRIPTOR = 'new_evaluation_modules/new_rubrics/FETCH_NEW_RUBRIC_DESCRIPTOR';
export const FETCH_NEW_RUBRIC_DESCRIPTORS = 'new_evaluation_modules/new_rubrics/FETCH_NEW_RUBRIC_DESCRIPTORS';
export const FETCH_NEW_RUBRIC_DESCRIPTORS_BY = 'new_evaluation_modules/new_rubrics/FETCH_NEW_RUBRIC_DESCRIPTORS_BY';
export const POST_NEW_RUBRIC_DESCRIPTOR = 'new_evaluation_modules/new_rubrics/POST_NEW_RUBRIC_DESCRIPTOR';
export const UPDATE_NEW_RUBRIC_DESCRIPTOR = 'new_evaluation_modules/new_rubrics/UPDATE_NEW_RUBRIC_DESCRIPTOR';
export const PATCH_NEW_RUBRIC_DESCRIPTOR = 'new_evaluation_modules/new_rubrics/PATCH_NEW_RUBRIC_DESCRIPTOR';
export const DELETE_NEW_RUBRIC_DESCRIPTOR = 'new_evaluation_modules/new_rubrics/DELETE_NEW_RUBRIC_DESCRIPTOR';
// New evaluate score
export const EVALUATEE_EVALUATIONS = 'new_evaluation_modules/new_evaluatee_score/EVALUATEE_EVALUATIONS';
export const EVALUATEE_TESTS = 'new_evaluation_modules/new_evaluatee_score/EVALUATEE_TESTS';
export const EVALUATEE_RUBRICS = 'new_evaluation_modules/new_evaluatee_score/EVALUATEE_RUBRICS';
export const EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS = 'new_evaluation_modules/new_evaluatee_score/EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS';
export const EVALUATEE_REDACTION_QUESTIONS = 'new_evaluation_modules/new_evaluatee_score/EVALUATEE_REDACTION_QUESTIONS';
export const EVALUATEE_MULTIPLE_CHOICE_QUESTIONS = 'new_evaluation_modules/new_evaluatee_score/EVALUATEE_MULTIPLE_CHOICE_QUESTIONS';
export const EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS = 'new_evaluation_modules/new_evaluatee_score/EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS';
export const EVALUATEE_MATCHING_QUESTIONS = 'new_evaluation_modules/new_evaluatee_score/EVALUATEE_MATCHING_QUESTIONS';
export const EVALUATEE_MATCHING_QUESTION_UNITS = 'new_evaluation_modules/new_evaluatee_score/EVALUATEE_MATCHING_QUESTION_UNITS';
export const MUTATE_EVALUATEE_EVALUATIONS = "new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_EVALUATIONS"
export const MUTATE_EVALUATEE_EVALUATION = "new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_EVALUATION"
export const MUTATE_DELETE_EVALUATEE_EVALUATION = "new_evaluation_modules/new_evaluatee_score/MUTATE_DELETE_EVALUATEE_EVALUATION"
export const MUTATE_EVALUATEE_TESTS = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_TESTS';
export const MUTATE_EVALUATEE_TEST = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_TEST';
export const MUTATE_DELETE_EVALUATEE_TEST = 'new_evaluation_modules/new_evaluatee_score/MUTATE_DELETE_EVALUATEE_TEST';
export const MUTATE_EVALUATEE_RUBRICS = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_RUBRICS';
export const MUTATE_EVALUATEE_RUBRIC = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_RUBRIC';
export const MUTATE_DELETE_EVALUATEE_RUBRIC = 'new_evaluation_modules/new_evaluatee_score/MUTATE_DELETE_EVALUATEE_RUBRIC';
export const MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS';
export const MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL';
export const MUTATE_DELETE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL = 'new_evaluation_modules/new_evaluatee_score/MUTATE_DELETE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL';
export const MUTATE_EVALUATEE_REDACTION_QUESTIONS = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_REDACTION_QUESTIONS';
export const MUTATE_EVALUATEE_REDACTION_QUESTION = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_REDACTION_QUESTION';
export const MUTATE_DELETE_EVALUATEE_REDACTION_QUESTION = 'new_evaluation_modules/new_evaluatee_score/MUTATE_DELETE_EVALUATEE_REDACTION_QUESTION';
export const MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTIONS = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTIONS';
export const MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION';
export const MUTATE_DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION = 'new_evaluation_modules/new_evaluatee_score/MUTATE_DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION';
export const MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS';
export const MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION';
export const MUTATE_DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION = 'new_evaluation_modules/new_evaluatee_score/MUTATE_DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION';
export const MUTATE_EVALUATEE_MATCHING_QUESTIONS = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_MATCHING_QUESTIONS';
export const MUTATE_EVALUATEE_MATCHING_QUESTION = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_MATCHING_QUESTION';
export const MUTATE_DELETE_EVALUATEE_MATCHING_QUESTION = 'new_evaluation_modules/new_evaluatee_score/MUTATE_DELETE_EVALUATEE_MATCHING_QUESTION';
export const MUTATE_EVALUATEE_MATCHING_QUESTION_UNITS = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_MATCHING_QUESTION_UNITS';
export const MUTATE_EVALUATEE_MATCHING_QUESTION_UNIT = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_MATCHING_QUESTION_UNIT';
export const MUTATE_DELETE_EVALUATEE_MATCHING_QUESTION_UNIT = 'new_evaluation_modules/new_evaluatee_score/MUTATE_DELETE_EVALUATEE_MATCHING_QUESTION_UNIT';
export const FETCH_EVALUATEE_EVALUATION = "new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_EVALUATION";
export const FETCH_EVALUATEE_EVALUATIONS = "new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_EVALUATIONS";
export const POST_EVALUATEE_EVALUATION = "new_evaluation_modules/new_evaluatee_score/POST_EVALUATEE_EVALUATION";
export const UPDATE_EVALUATEE_EVALUATION = "new_evaluation_modules/new_evaluatee_score/UPDATE_EVALUATEE_EVALUATION";
export const PATCH_EVALUATEE_EVALUATION = "new_evaluation_modules/new_evaluatee_score/PATCH_EVALUATEE_EVALUATION";
export const DELETE_EVALUATEE_EVALUATION = "new_evaluation_modules/new_evaluatee_score/DELETE_EVALUATEE_EVALUATION";
export const FETCH_EVALUATEE_TEST = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_TEST';
export const FETCH_EVALUATEE_TESTS = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_TESTS';
export const POST_EVALUATEE_TEST = 'new_evaluation_modules/new_evaluatee_score/POST_EVALUATEE_TEST';
export const UPDATE_EVALUATEE_TEST = 'new_evaluation_modules/new_evaluatee_score/UPDATE_EVALUATEE_TEST';
export const PATCH_EVALUATEE_TEST = 'new_evaluation_modules/new_evaluatee_score/PATCH_EVALUATEE_TEST';
export const DELETE_EVALUATEE_TEST = 'new_evaluation_modules/new_evaluatee_score/DELETE_EVALUATEE_TEST';
export const FETCH_EVALUATEE_RUBRIC = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_RUBRIC';
export const FETCH_EVALUATEE_RUBRICS = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_RUBRICS';
export const POST_EVALUATEE_RUBRIC = 'new_evaluation_modules/new_evaluatee_score/POST_EVALUATEE_RUBRIC';
export const UPDATE_EVALUATEE_RUBRIC = 'new_evaluation_modules/new_evaluatee_score/UPDATE_EVALUATEE_RUBRIC';
export const PATCH_EVALUATEE_RUBRIC = 'new_evaluation_modules/new_evaluatee_score/PATCH_EVALUATEE_RUBRIC';
export const DELETE_EVALUATEE_RUBRIC = 'new_evaluation_modules/new_evaluatee_score/DELETE_EVALUATEE_RUBRIC';
export const FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL';
export const FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS';
export const POST_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL = 'new_evaluation_modules/new_evaluatee_score/POST_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL';
export const UPDATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL = 'new_evaluation_modules/new_evaluatee_score/UPDATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL';
export const PATCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL = 'new_evaluation_modules/new_evaluatee_score/PATCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL';
export const DELETE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL = 'new_evaluation_modules/new_evaluatee_score/DELETE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL';
export const EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS = 'new_evaluation_modules/new_evaluatee_score/EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS';
export const MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS';
export const MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR = 'new_evaluation_modules/new_evaluatee_score/MUTATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR';
export const MUTATE_DELETE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR = 'new_evaluation_modules/new_evaluatee_score/MUTATE_DELETE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR';
export const FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR';
export const FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS';
export const POST_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR = 'new_evaluation_modules/new_evaluatee_score/POST_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR';
export const UPDATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR = 'new_evaluation_modules/new_evaluatee_score/UPDATE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR';
export const PATCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR = 'new_evaluation_modules/new_evaluatee_score/PATCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR';
export const DELETE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR = 'new_evaluation_modules/new_evaluatee_score/DELETE_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR';
export const FETCH_EVALUATEE_REDACTION_QUESTION = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_REDACTION_QUESTION';
export const FETCH_EVALUATEE_REDACTION_QUESTIONS = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_REDACTION_QUESTIONS';
export const POST_EVALUATEE_REDACTION_QUESTION = 'new_evaluation_modules/new_evaluatee_score/POST_EVALUATEE_REDACTION_QUESTION';
export const UPDATE_EVALUATEE_REDACTION_QUESTION = 'new_evaluation_modules/new_evaluatee_score/UPDATE_EVALUATEE_REDACTION_QUESTION';
export const PATCH_EVALUATEE_REDACTION_QUESTION = 'new_evaluation_modules/new_evaluatee_score/PATCH_EVALUATEE_REDACTION_QUESTION';
export const DELETE_EVALUATEE_REDACTION_QUESTION = 'new_evaluation_modules/new_evaluatee_score/DELETE_EVALUATEE_REDACTION_QUESTION';
export const FETCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION';
export const FETCH_EVALUATEE_MULTIPLE_CHOICE_QUESTIONS = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_MULTIPLE_CHOICE_QUESTIONS';
export const POST_EVALUATEE_MULTIPLE_CHOICE_QUESTION = 'new_evaluation_modules/new_evaluatee_score/POST_EVALUATEE_MULTIPLE_CHOICE_QUESTION';
export const UPDATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION = 'new_evaluation_modules/new_evaluatee_score/UPDATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION';
export const PATCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION = 'new_evaluation_modules/new_evaluatee_score/PATCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION';
export const DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION = 'new_evaluation_modules/new_evaluatee_score/DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION';
export const FETCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION';
export const FETCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS';
export const POST_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION = 'new_evaluation_modules/new_evaluatee_score/POST_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION';
export const UPDATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION = 'new_evaluation_modules/new_evaluatee_score/UPDATE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION';
export const PATCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION = 'new_evaluation_modules/new_evaluatee_score/PATCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION';
export const DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION = 'new_evaluation_modules/new_evaluatee_score/DELETE_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTION';
export const FETCH_EVALUATEE_MATCHING_QUESTION = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_MATCHING_QUESTION';
export const FETCH_EVALUATEE_MATCHING_QUESTIONS = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_MATCHING_QUESTIONS';
export const POST_EVALUATEE_MATCHING_QUESTION = 'new_evaluation_modules/new_evaluatee_score/POST_EVALUATEE_MATCHING_QUESTION';
export const UPDATE_EVALUATEE_MATCHING_QUESTION = 'new_evaluation_modules/new_evaluatee_score/UPDATE_EVALUATEE_MATCHING_QUESTION';
export const PATCH_EVALUATEE_MATCHING_QUESTION = 'new_evaluation_modules/new_evaluatee_score/PATCH_EVALUATEE_MATCHING_QUESTION';
export const DELETE_EVALUATEE_MATCHING_QUESTION = 'new_evaluation_modules/new_evaluatee_score/DELETE_EVALUATEE_MATCHING_QUESTION';
export const FETCH_EVALUATEE_MATCHING_QUESTION_UNIT = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_MATCHING_QUESTION_UNIT';
export const FETCH_EVALUATEE_MATCHING_QUESTION_UNITS = 'new_evaluation_modules/new_evaluatee_score/FETCH_EVALUATEE_MATCHING_QUESTION_UNITS';
export const POST_EVALUATEE_MATCHING_QUESTION_UNIT = 'new_evaluation_modules/new_evaluatee_score/POST_EVALUATEE_MATCHING_QUESTION_UNIT';
export const UPDATE_EVALUATEE_MATCHING_QUESTION_UNIT = 'new_evaluation_modules/new_evaluatee_score/UPDATE_EVALUATEE_MATCHING_QUESTION_UNIT';
export const PATCH_EVALUATEE_MATCHING_QUESTION_UNIT = 'new_evaluation_modules/new_evaluatee_score/PATCH_EVALUATEE_MATCHING_QUESTION_UNIT';
export const DELETE_EVALUATEE_MATCHING_QUESTION_UNIT = 'new_evaluation_modules/new_evaluatee_score/DELETE_EVALUATEE_MATCHING_QUESTION_UNIT';
// faculties
export const FACULTIES = 'faculties/FACULTIES';
export const MUTATE_FACULTIES = 'faculties/MUTATE_FACULTIES';
export const MUTATE_FACULTY = 'faculties/MUTATE_FACULTY';
export const MUTATE_DELETE_FACULTY = 'faculties/MUTATE_DELETE_FACULTY';
export const FETCH_FACULTY = 'faculties/FETCH_FACULTY';
export const FETCH_FACULTIES = 'faculties/FETCH_FACULTIES';
export const POST_FACULTY = 'faculties/POST_FACULTY';
export const UPDATE_FACULTY = 'faculties/UPDATE_FACULTY';
export const PATCH_FACULTY = 'faculties/PATCH_FACULTY';
export const DELETE_FACULTY = 'faculties/DELETE_FACULTY';
// files
export const FILES = 'files/FILES';
export const MUTATE_FILE = 'files/MUTATE_FILE';
export const MUTATE_DELETE_FILE = 'files/MUTATE_DELETE_FILE';
export const FETCH_FILE = 'files/FETCH_FILE';
export const POST_FILE = 'files/POST_FILE';
export const UPDATE_FILE = 'files/UPDATE_FILE';
export const PATCH_FILE = 'files/PATCH_FILE';
export const DELETE_FILE = 'files/DELETE_FILE';
// formation_areas
export const FORMATION_AREAS = 'formation_areas/FORMATION_AREAS';
export const MUTATE_FORMATION_AREAS = 'formation_areas/MUTATE_FORMATION_AREAS';
export const MUTATE_FORMATION_AREA = 'formation_areas/MUTATE_FORMATION_AREA';
export const MUTATE_DELETE_FORMATION_AREA = 'formation_areas/MUTATE_DELETE_FORMATION_AREA';
export const FETCH_FORMATION_AREA = 'formation_areas/FETCH_FORMATION_AREA';
export const FETCH_FORMATION_AREAS = 'formation_areas/FETCH_FORMATION_AREAS';
export const POST_FORMATION_AREA = 'formation_areas/POST_FORMATION_AREA';
export const UPDATE_FORMATION_AREA = 'formation_areas/UPDATE_FORMATION_AREA';
export const PATCH_FORMATION_AREA = 'formation_areas/PATCH_FORMATION_AREA';
export const DELETE_FORMATION_AREA = 'formation_areas/DELETE_FORMATION_AREA';
// formation_lines
export const FORMATION_LINES = 'formation_lines/FORMATION_LINES';
export const MUTATE_FORMATION_LINES = 'formation_lines/MUTATE_FORMATION_LINES';
export const MUTATE_FORMATION_LINE = 'formation_lines/MUTATE_FORMATION_LINE';
export const MUTATE_DELETE_FORMATION_LINE = 'formation_lines/MUTATE_DELETE_FORMATION_LINE';
export const FETCH_FORMATION_LINE = 'formation_lines/FETCH_FORMATION_LINE';
export const FETCH_FORMATION_LINES = 'formation_lines/FETCH_FORMATION_LINES';
export const POST_FORMATION_LINE = 'formation_lines/POST_FORMATION_LINE';
export const UPDATE_FORMATION_LINE = 'formation_lines/UPDATE_FORMATION_LINE';
export const PATCH_FORMATION_LINE = 'formation_lines/PATCH_FORMATION_LINE';
export const DELETE_FORMATION_LINE = 'formation_lines/DELETE_FORMATION_LINE';
// profile_competences
export const PROFILE_COMPETENCES = 'profile_competences/PROFILE_COMPETENCES';
export const MUTATE_PROFILE_COMPETENCES = 'profile_competences/MUTATE_PROFILE_COMPETENCES';
export const MUTATE_PROFILE_COMPETENCE = 'profile_competences/MUTATE_PROFILE_COMPETENCE';
export const MUTATE_DELETE_PROFILE_COMPETENCE = 'profile_competences/MUTATE_DELETE_PROFILE_COMPETENCE';
export const FETCH_PROFILE_COMPETENCE = 'profile_competences/FETCH_PROFILE_COMPETENCE';
export const FETCH_PROFILE_COMPETENCES = 'profile_competences/FETCH_PROFILE_COMPETENCES';
export const POST_PROFILE_COMPETENCE = 'profile_competences/POST_PROFILE_COMPETENCE';
export const UPDATE_PROFILE_COMPETENCE = 'profile_competences/UPDATE_PROFILE_COMPETENCE';
export const PATCH_PROFILE_COMPETENCE = 'profile_competences/PATCH_PROFILE_COMPETENCE';
export const DELETE_PROFILE_COMPETENCE = 'profile_competences/DELETE_PROFILE_COMPETENCE';
//profile_competences_mention
export const PROFILE_COMPETENCES_MENTION = 'profile_competences/PROFILE_COMPETENCES_MENTION';
export const MUTATE_PROFILE_COMPETENCES_MENTION = 'profile_competences/MUTATE_PROFILE_COMPETENCES_MENTION';
export const MUTATE_PROFILE_COMPETENCE_MENTION = 'profile_competences/MUTATE_PROFILE_COMPETENCE_MENTION';
export const MUTATE_DELETE_PROFILE_COMPETENCE_MENTION = 'profile_competences/MUTATE_DELETE_PROFILE_COMPETENCE_MENTION';
export const FETCH_PROFILE_COMPETENCE_MENTION = 'profile_competences/FETCH_PROFILE_COMPETENCE_MENTION';
export const FETCH_PROFILE_COMPETENCES_MENTION = 'profile_competences/FETCH_PROFILE_COMPETENCES_MENTION';
export const POST_PROFILE_COMPETENCE_MENTION = 'profile_competences/POST_PROFILE_COMPETENCE_MENTION';
export const UPDATE_PROFILE_COMPETENCE_MENTION = 'profile_competences/UPDATE_PROFILE_COMPETENCE_MENTION';
export const PATCH_PROFILE_COMPETENCE_MENTION = 'profile_competences/PATCH_PROFILE_COMPETENCE_MENTION';
export const DELETE_PROFILE_COMPETENCE_MENTION = 'profile_competences/DELETE_PROFILE_COMPETENCE_MENTION';
// profile_cycle
export const PROFILE_CYCLES = 'profile_cycles/PROFILE_CYCLES';
export const MUTATE_PROFILE_CYCLES = 'profile_cycles/MUTATE_PROFILE_CYCLES';
export const MUTATE_PROFILE_CYCLE = 'profile_cycles/MUTATE_PROFILE_CYCLE';
export const MUTATE_DELETE_PROFILE_CYCLE = 'profile_cycles/MUTATE_DELETE_PROFILE_CYCLE';
export const FETCH_PROFILE_CYCLE = 'profile_cycles/FETCH_PROFILE_CYCLE';
export const FETCH_PROFILE_CYCLES = 'profile_cycles/FETCH_PROFILE_CYCLES';
export const POST_PROFILE_CYCLE = 'profile_cycles/POST_PROFILE_CYCLE';
export const UPDATE_PROFILE_CYCLE = 'profile_cycles/UPDATE_PROFILE_CYCLE';
export const PATCH_PROFILE_CYCLE = 'profile_cycles/PATCH_PROFILE_CYCLE';
export const DELETE_PROFILE_CYCLE = 'profile_cycles/DELETE_PROFILE_CYCLE';
// institution_competences
export const INSTITUTION_COMPETENCES = 'institution_competences/INSTITUTION_COMPETENCES';
export const MUTATE_INSTITUTION_COMPETENCES = 'institution_competences/MUTATE_INSTITUTION_COMPETENCES';
export const MUTATE_INSTITUTION_COMPETENCE = 'institution_competences/MUTATE_INSTITUTION_COMPETENCE';
export const MUTATE_DELETE_INSTITUTION_COMPETENCE = 'institution_competences/MUTATE_DELETE_INSTITUTION_COMPETENCE';
export const FETCH_INSTITUTION_COMPETENCE = 'institution_competences/FETCH_INSTITUTION_COMPETENCE';
export const FETCH_INSTITUTION_COMPETENCES = 'institution_competences/FETCH_INSTITUTION_COMPETENCES';
export const POST_INSTITUTION_COMPETENCE = 'institution_competences/POST_INSTITUTION_COMPETENCE';
export const UPDATE_INSTITUTION_COMPETENCE = 'institution_competences/UPDATE_INSTITUTION_COMPETENCE';
export const PATCH_INSTITUTION_COMPETENCE = 'institution_competences/PATCH_INSTITUTION_COMPETENCE';
export const DELETE_INSTITUTION_COMPETENCE = 'institution_competences/DELETE_INSTITUTION_COMPETENCE';
//instructives
export const INSTRUCTIVES = 'instructives/INSTRUCTIVES';
export const MUTATE_INSTRUCTIVES = 'instructives/MUTATE_INSTRUCTIVES';
export const MUTATE_INSTRUCTIVE = 'instructives/MUTATE_INSTRUCTIVE';
export const MUTATE_DELETE_INSTRUCTIVE = 'instructives/MUTATE_DELETE_INSTRUCTIVE';
export const FETCH_INSTRUCTIVES = 'instructives/FETCH_INSTRUCTIVES';
export const FETCH_INSTRUCTIVE = 'instructives/FETCH_INSTRUCTIVE';
export const POST_INSTRUCTIVE = 'instructives/POST_INSTRUCTIVE';
export const UPDATE_INSTRUCTIVE = 'instructives/UPDATE_INSTRUCTIVE';
export const PATCH_INSTRUCTIVE = 'instructives/PATCH_INSTRUCTIVE';
export const DELETE_INSTRUCTIVE = 'instructives/DELETE_INSTRUCTIVE';
// learning results
export const LEARNING_RESULTS = 'learning_results/LEARNING_RESULTS';
export const FETCH_LEARNING_RESULT = 'learning_results/FETCH_LEARNING_RESULT';
export const FETCH_LEARNING_RESULTS = 'learning_results/FETCH_LEARNING_RESULTS';
export const POST_LEARNING_RESULT = 'learning_results/POST_LEARNING_RESULT';
export const UPDATE_LEARNING_RESULT = 'learning_results/UPDATE_LEARNING_RESULT';
export const PATCH_LEARNING_RESULT = 'learning_results/PATCH_LEARNING_RESULT';
export const DELETE_LEARNING_RESULT = 'learning_results/DELETE_LEARNING_RESULT';
export const MUTATE_LEARNING_RESULTS = 'learning_results/MUTATE_LEARNING_RESULTS';
export const MUTATE_LEARNING_RESULT = 'learning_results/MUTATE_LEARNING_RESULT';
export const MUTATE_DELETE_LEARNING_RESULT = 'learning_results/MUTATE_DELETE_LEARNING_RESULT';
// matters
export const MATTERS = 'matters/MATTERS';
export const M_MIDDLE_DESCRIPTORS = 'matters/M_MIDDLE_DESCRIPTORS';
export const MUTATE_MATTERS = 'matters/MUTATE_MATTERS';
export const MUTATE_MATTER = 'matters/MUTATE_MATTER';
export const MUTATE_DELETE_MATTER = 'matters/MUTATE_DELETE_MATTER';
export const MUTATE_M_MIDDLE_DESCRIPTOR = 'matters/MUTATE_M_MIDDLE_DESCRIPTOR';
export const MUTATE_M_MIDDLE_DESCRIPTORS = 'matters/MUTATE_M_MIDDLE_DESCRIPTORS';
export const MUTATE_DELETE_M_MIDDLE_DESCRIPTORS = 'egress_profiles_matters/MUTATE_DELETE_M_MIDDLE_DESCRIPTORS';
export const FETCH_MATTER = 'matters/FETCH_MATTER';
export const FETCH_MATTERS = 'matters/FETCH_MATTERS';
export const POST_MATTER = 'matters/POST_MATTER';
export const UPDATE_MATTER = 'matters/UPDATE_MATTER';
export const PATCH_MATTER = 'matters/PATCH_MATTER';
export const DELETE_MATTER = 'matters/DELETE_MATTER';
export const FETCH_M_MIDDLE_DESCRIPTORS = 'matters/FETCH_M_MIDDLE_DESCRIPTORS';
export const POST_M_MIDDLE_DESCRIPTORS = 'matters/POST_M_MIDDLE_DESCRIPTORS';
export const PATCH_M_MIDDLE_DESCRIPTORS = 'egress_profiles_matters/PATCH_M_MIDDLE_DESCRIPTORS';
export const DELETE_M_MIDDLE_DESCRIPTORS = 'egress_profiles_matters/DELETE_M_MIDDLE_DESCRIPTORS';
export const MATTER_CATEGORIES = 'matters/MATTER_CATEGORIES';
export const MUTATE_MATTER_CATEGORIES = 'matters/MUTATE_MATTER_CATEGORIES';
export const MUTATE_MATTER_CATEGORY = 'matters/MUTATE_MATTER_CATEGORY';
export const MUTATE_DELETE_MATTER_CATEGORY = 'matters/MUTATE_DELETE_MATTER_CATEGORY';
export const FETCH_MATTER_CATEGORY = 'matters/FETCH_MATTER_CATEGORY';
export const FETCH_MATTER_CATEGORIES = 'matters/FETCH_MATTER_CATEGORIES';
export const POST_MATTER_CATEGORY = 'matters/POST_MATTER_CATEGORY';
export const UPDATE_MATTER_CATEGORY = 'matters/UPDATE_MATTER_CATEGORY';
export const PATCH_MATTER_CATEGORY = 'matters/PATCH_MATTER_CATEGORY';
export const DELETE_MATTER_CATEGORY = 'matters/DELETE_MATTER_CATEGORY';
export const LEARNING_EXPERIENCES = 'matters/LEARNING_EXPERIENCES';
export const MUTATE_LEARNING_EXPERIENCES = 'matters/MUTATE_LEARNING_EXPERIENCES';
export const MUTATE_LEARNING_EXPERIENCE = 'matters/MUTATE_LEARNING_EXPERIENCE';
export const MUTATE_DELETE_LEARNING_EXPERIENCE = 'matters/MUTATE_DELETE_LEARNING_EXPERIENCE';
export const FETCH_LEARNING_EXPERIENCE = 'matters/FETCH_LEARNING_EXPERIENCE';
export const FETCH_LEARNING_EXPERIENCES = 'matters/FETCH_LEARNING_EXPERIENCES';
export const POST_LEARNING_EXPERIENCE = 'matters/POST_LEARNING_EXPERIENCE';
export const UPDATE_LEARNING_EXPERIENCE = 'matters/UPDATE_LEARNING_EXPERIENCE';
export const PATCH_LEARNING_EXPERIENCE = 'matters/PATCH_LEARNING_EXPERIENCE';
export const DELETE_LEARNING_EXPERIENCE = 'matters/DELETE_LEARNING_EXPERIENCE';
export const MATTER_EVALUATIONS = 'matters/MATTER_EVALUATIONS';
export const MUTATE_MATTER_EVALUATIONS = 'matters/MUTATE_MATTER_EVALUATIONS';
export const MUTATE_MATTER_EVALUATION = 'matters/MUTATE_MATTER_EVALUATION';
export const MUTATE_DELETE_MATTER_EVALUATION = 'matters/MUTATE_DELETE_MATTER_EVALUATION';
export const FETCH_MATTER_EVALUATION = 'matters/FETCH_MATTER_EVALUATION';
export const FETCH_MATTER_EVALUATIONS = 'matters/FETCH_MATTER_EVALUATIONS';
export const POST_MATTER_EVALUATION = 'matters/POST_MATTER_EVALUATION';
export const UPDATE_MATTER_EVALUATION = 'matters/UPDATE_MATTER_EVALUATION';
export const PATCH_MATTER_EVALUATION = 'matters/PATCH_MATTER_EVALUATION';
export const DELETE_MATTER_EVALUATION = 'matters/DELETE_MATTER_EVALUATION';
export const EPM_EVALUATION_TYPES = 'egress_profiles/EPM_EVALUATION_TYPES';
export const MUTATE_EPM_EVALUATION_TYPES = 'egress_profiles/MUTATE_EPM_EVALUATION_TYPES';
export const FETCH_EPM_EVALUATION_TYPES = 'egress_profiles/FETCH_EPM_EVALUATION_TYPES';
// matter_contributions
export const MATTER_CONTRIBUTIONS = 'matter_contributions/MATTER_CONTRIBUTIONS';
export const MUTATE_MATTER_CONTRIBUTIONS = 'matter_contributions/MUTATE_MATTER_CONTRIBUTIONS';
export const MUTATE_MATTER_CONTRIBUTION = 'matter_contributions/MUTATE_MATTER_CONTRIBUTION';
export const MUTATE_DELETE_MATTER_CONTRIBUTION = 'matter_contributions/MUTATE_DELETE_MATTER_CONTRIBUTION';
export const FETCH_MATTER_CONTRIBUTION = 'matter_contributions/FETCH_MATTER_CONTRIBUTION';
export const FETCH_MATTER_CONTRIBUTIONS = 'matter_contributions/FETCH_MATTER_CONTRIBUTIONS';
export const POST_MATTER_CONTRIBUTION = 'matter_contributions/POST_MATTER_CONTRIBUTION';
export const UPDATE_MATTER_CONTRIBUTION = 'matter_contributions/UPDATE_MATTER_CONTRIBUTION';
export const PATCH_MATTER_CONTRIBUTION = 'matter_contributions/PATCH_MATTER_CONTRIBUTION';
export const DELETE_MATTER_CONTRIBUTION = 'matter_contributions/DELETE_MATTER_CONTRIBUTION';
// matter_performance_indicators
export const MATTER_PERFORMANCE_INDICATORS = 'matter_performance_indicators/MATTER_PERFORMANCE_INDICATORS';
export const MUTATE_MATTER_PERFORMANCE_INDICATORS = 'matter_performance_indicators/MUTATE_MATTER_PERFORMANCE_INDICATORS';
export const MUTATE_MATTER_PERFORMANCE_INDICATOR = 'matter_performance_indicators/MUTATE_MATTER_PERFORMANCE_INDICATOR';
export const MUTATE_DELETE_MATTER_PERFORMANCE_INDICATOR = 'matter_performance_indicators/MUTATE_DELETE_MATTER_PERFORMANCE_INDICATOR';
export const FETCH_MATTER_PERFORMANCE_INDICATOR = 'matter_performance_indicators/FETCH_MATTER_PERFORMANCE_INDICATOR';
export const FETCH_MATTER_PERFORMANCE_INDICATORS = 'matter_performance_indicators/FETCH_MATTER_PERFORMANCE_INDICATORS';
export const POST_MATTER_PERFORMANCE_INDICATOR = 'matter_performance_indicators/POST_MATTER_PERFORMANCE_INDICATOR';
export const UPDATE_MATTER_PERFORMANCE_INDICATOR = 'matter_performance_indicators/UPDATE_MATTER_PERFORMANCE_INDICATOR';
export const PATCH_MATTER_PERFORMANCE_INDICATOR = 'matter_performance_indicators/PATCH_MATTER_PERFORMANCE_INDICATOR';
export const DELETE_MATTER_PERFORMANCE_INDICATOR = 'matter_performance_indicators/DELETE_MATTER_PERFORMANCE_INDICATOR';
// matter_types
export const MATTER_TYPES = 'matter_types/MATTER_TYPES';
export const MUTATE_MATTER_TYPES = 'matter_types/MUTATE_MATTER_TYPES';
export const MUTATE_MATTER_TYPE = 'matter_types/MUTATE_MATTER_TYPE';
export const MUTATE_DELETE_MATTER_TYPE = 'matter_types/MUTATE_DELETE_MATTER_TYPE';
export const FETCH_MATTER_TYPE = 'matter_types/FETCH_MATTER_TYPE';
export const FETCH_MATTER_TYPES = 'matter_types/FETCH_MATTER_TYPES';
export const POST_MATTER_TYPE = 'matter_types/POST_MATTER_TYPE';
export const UPDATE_MATTER_TYPE = 'matter_types/UPDATE_MATTER_TYPE';
export const PATCH_MATTER_TYPE = 'matter_types/PATCH_MATTER_TYPE';
export const DELETE_MATTER_TYPE = 'matter_types/DELETE_MATTER_TYPE';
// methodological_strategies
export const METHODOLOGICAL_STRATEGIES = 'methodological_strategies/METHODOLOGICAL_STRATEGIES';
export const MUTATE_METHODOLOGICAL_STRATEGIES = 'methodological_strategies/MUTATE_METHODOLOGICAL_STRATEGIES';
export const MUTATE_METHODOLOGICAL_STRATEGY = 'methodological_strategies/MUTATE_METHODOLOGICAL_STRATEGY';
export const MUTATE_DELETE_METHODOLOGICAL_STRATEGY = 'methodological_strategies/MUTATE_DELETE_METHODOLOGICAL_STRATEGY';
export const FETCH_METHODOLOGICAL_STRATEGY = 'methodological_strategies/FETCH_METHODOLOGICAL_STRATEGY';
export const FETCH_METHODOLOGICAL_STRATEGIES = 'methodological_strategies/FETCH_METHODOLOGICAL_STRATEGIES';
export const POST_METHODOLOGICAL_STRATEGY = 'methodological_strategies/POST_METHODOLOGICAL_STRATEGY';
export const UPDATE_METHODOLOGICAL_STRATEGY = 'methodological_strategies/UPDATE_METHODOLOGICAL_STRATEGY';
export const PATCH_METHODOLOGICAL_STRATEGY = 'methodological_strategies/PATCH_METHODOLOGICAL_STRATEGY';
export const DELETE_METHODOLOGICAL_STRATEGY = 'methodological_strategies/DELETE_METHODOLOGICAL_STRATEGY';
// modalities
export const MODALITIES = 'modalities/MODALITIES';
export const MUTATE_MODALITIES = 'modalities/MUTATE_MODALITIES';
export const MUTATE_MODALITY = 'modalities/MUTATE_MODALITY';
export const MUTATE_DELETE_MODALITY = 'modalities/MUTATE_DELETE_MODALITY';
export const FETCH_MODALITY = 'modalities/FETCH_MODALITY';
export const FETCH_MODALITIES = 'modalities/FETCH_MODALITIES';
export const POST_MODALITY = 'modalities/POST_MODALITY';
export const UPDATE_MODALITY = 'modalities/UPDATE_MODALITY';
export const PATCH_MODALITY = 'modalities/PATCH_MODALITY';
export const DELETE_MODALITY = 'modalities/DELETE_MODALITY';
export const MODALITIES_EGRESS_PROFILE = 'modalities/MODALITIES_EGRESS_PROFILE';
export const MUTATE_MODALITIES_EGRESS_PROFILE = 'modalities/MUTATE_MODALITIES_EGRESS_PROFILE';
export const MUTATE_MODALITY_EGRESS_PROFILE = 'modalities/MUTATE_MODALITY_EGRESS_PROFILE';
export const MUTATE_DELETE_MODALITY_EGRESS_PROFILE = 'modalities/MUTATE_DELETE_MODALITY_EGRESS_PROFILE';
export const FETCH_MODALITY_EGRESS_PROFILE = 'modalities/FETCH_MODALITY_EGRESS_PROFILE';
export const FETCH_MODALITIES_EGRESS_PROFILE = 'modalities/FETCH_MODALITIES_EGRESS_PROFILE';
export const POST_MODALITY_EGRESS_PROFILE = 'modalities/POST_MODALITY_EGRESS_PROFILE';
export const UPDATE_MODALITY_EGRESS_PROFILE = 'modalities/UPDATE_MODALITY_EGRESS_PROFILE';
export const PATCH_MODALITY_EGRESS_PROFILE = 'modalities/PATCH_MODALITY_EGRESS_PROFILE';
export const DELETE_MODALITY_EGRESS_PROFILE = 'modalities/DELETE_MODALITY_EGRESS_PROFILE';
// performance_indicators
export const PERFORMANCE_INDICATORS = 'performance_indicators/PERFORMANCE_INDICATORS';
export const MUTATE_PERFORMANCE_INDICATORS = 'performance_indicators/MUTATE_PERFORMANCE_INDICATORS';
export const MUTATE_PERFORMANCE_INDICATOR = 'performance_indicators/MUTATE_PERFORMANCE_INDICATOR';
export const MUTATE_DELETE_PERFORMANCE_INDICATOR = 'performance_indicators/MUTATE_DELETE_PERFORMANCE_INDICATOR';
export const FETCH_PERFORMANCE_INDICATOR = 'performance_indicators/FETCH_PERFORMANCE_INDICATOR';
export const FETCH_PERFORMANCE_INDICATORS = 'performance_indicators/FETCH_PERFORMANCE_INDICATORS';
export const FETCH_PERFORMANCE_INDICATORS_BY_CYCLE = 'performance_indicators/FETCH_PERFORMANCE_INDICATORS_BY_CYCLE';
export const FETCH_PERFORMANCE_INDICATORS_BY_COMPETENCE = 'performance_indicators/FETCH_PERFORMANCE_INDICATORS_BY_COMPETENCE';
export const POST_PERFORMANCE_INDICATOR = 'performance_indicators/POST_PERFORMANCE_INDICATOR';
export const UPDATE_PERFORMANCE_INDICATOR = 'performance_indicators/UPDATE_PERFORMANCE_INDICATOR';
export const PATCH_PERFORMANCE_INDICATOR = 'performance_indicators/PATCH_PERFORMANCE_INDICATOR';
export const DELETE_PERFORMANCE_INDICATOR = 'performance_indicators/DELETE_PERFORMANCE_INDICATOR';
// periods
export const PERIODS = 'periods/PERIODS';
export const MUTATE_PERIODS = 'periods/MUTATE_PERIODS';
export const MUTATE_PERIOD = 'periods/MUTATE_PERIOD';
export const MUTATE_DELETE_PERIOD = 'periods/MUTATE_DELETE_PERIOD';
export const FETCH_PERIOD = 'periods/FETCH_PERIOD';
export const FETCH_PERIODS = 'periods/FETCH_PERIODS';
export const POST_PERIOD = 'periods/POST_PERIOD';
export const UPDATE_PERIOD = 'periods/UPDATE_PERIOD';
export const PATCH_PERIOD = 'periods/PATCH_PERIOD';
export const DELETE_PERIOD = 'periods/DELETE_PERIOD';
// positions
export const POSITIONS = 'positions/POSITIONS';
export const USER_POSITION = 'positions/USER_POSITION';
export const TABLE_ACCESS = 'positions/TABLE_ACCESS';
export const MUTATE_POSITIONS = 'positions/MUTATE_POSITIONS';
export const MUTATE_POSITION = 'positions/MUTATE_POSITION';
export const MUTATE_TABLE_ACCESS = 'positions/MUTATE_TABLE_ACCESS';
export const MUTATE_CURRENT_POSITION = 'positions/MUTATE_CURRENT_POSITION';
export const MUTATE_DELETE_POSITION = 'positions/MUTATE_DELETE_POSITION';
export const FETCH_POSITION = 'positions/FETCH_POSITION';
export const FETCH_POSITIONS = 'positions/FETCH_POSITIONS';
export const FETCH_CURRENT_POSITION = 'positions/FETCH_CURRENT_POSITION';
export const POST_POSITION = 'positions/POST_POSITION';
export const UPDATE_POSITION = 'positions/UPDATE_POSITION';
export const PATCH_POSITION = 'positions/PATCH_POSITION';
export const DELETE_POSITION = 'positions/DELETE_POSITION';
// procedure_evidences
export const PROCEDURE_EVIDENCES = 'procedure_evidences/PROCEDURE_EVIDENCES';
export const MUTATE_PROCEDURE_EVIDENCES = 'procedure_evidences/MUTATE_PROCEDURE_EVIDENCES';
export const MUTATE_PROCEDURE_EVIDENCE = 'procedure_evidences/MUTATE_PROCEDURE_EVIDENCE';
export const MUTATE_DELETE_PROCEDURE_EVIDENCE = 'procedure_evidences/MUTATE_DELETE_PROCEDURE_EVIDENCE';
export const FETCH_PROCEDURE_EVIDENCE = 'procedure_evidences/FETCH_PROCEDURE_EVIDENCE';
export const FETCH_PROCEDURE_EVIDENCES = 'procedure_evidences/FETCH_PROCEDURE_EVIDENCES';
export const POST_PROCEDURE_EVIDENCE = 'procedure_evidences/POST_PROCEDURE_EVIDENCE';
export const UPDATE_PROCEDURE_EVIDENCE = 'procedure_evidences/UPDATE_PROCEDURE_EVIDENCE';
export const PATCH_PROCEDURE_EVIDENCE = 'procedure_evidences/PATCH_PROCEDURE_EVIDENCE';
export const DELETE_PROCEDURE_EVIDENCE = 'procedure_evidences/DELETE_PROCEDURE_EVIDENCE';
// ra_base
export const RA_BASES = 'ra_base/RA_BASES';
export const MUTATE_RA_BASES = 'ra_base/MUTATE_RA_BASES';
export const MUTATE_RA_BASE = 'ra_base/MUTATE_RA_BASE';
export const MUTATE_DELETE_RA_BASE = 'ra_base/MUTATE_DELETE_RA_BASE';
export const FETCH_RA_BASE = 'ra_base/FETCH_RA_BASE';
export const FETCH_RA_BASES = 'ra_base/FETCH_RA_BASES';
export const POST_RA_BASE = 'ra_base/POST_RA_BASE';
export const UPDATE_RA_BASE = 'ra_base/UPDATE_RA_BASE';
export const PATCH_RA_BASE = 'ra_base/PATCH_RA_BASE';
export const DELETE_RA_BASE = 'ra_base/DELETE_RA_BASE';
// egr_profile_ra_base
export const EGR_PROFILE_RA_BASES = 'ra_base/EGR_PROFILE_RA_BASES';
export const MUTATE_EGR_PROFILE_RA_BASES = 'ra_base/MUTATE_EGR_PROFILE_RA_BASES';
export const MUTATE_EGR_PROFILE_RA_BASE = 'ra_base/MUTATE_EGR_PROFILE_RA_BASE';
export const MUTATE_DELETE_EGR_PROFILE_RA_BASE = 'ra_base/MUTATE_DELETE_EGR_PROFILE_RA_BASE';
export const FETCH_EGR_PROFILE_RA_BASE = 'ra_base/FETCH_EGR_PROFILE_RA_BASE';
export const FETCH_EGR_PROFILE_RA_BASES = 'ra_base/FETCH_EGR_PROFILE_RA_BASES';
export const POST_EGR_PROFILE_RA_BASE = 'ra_base/POST_EGR_PROFILE_RA_BASE';
export const UPDATE_EGR_PROFILE_RA_BASE = 'ra_base/UPDATE_EGR_PROFILE_RA_BASE';
export const PATCH_EGR_PROFILE_RA_BASE = 'ra_base/PATCH_EGR_PROFILE_RA_BASE';
export const DELETE_EGR_PROFILE_RA_BASE = 'ra_base/DELETE_EGR_PROFILE_RA_BASE';
// ra_macros
export const RA_MACROS = 'ra_macros/RA_MACROS';
export const MUTATE_RA_MACROS = 'ra_macros/MUTATE_RA_MACROS';
export const MUTATE_RA_MACRO = 'ra_macros/MUTATE_RA_MACRO';
export const MUTATE_DELETE_RA_MACRO = 'ra_macros/MUTATE_DELETE_RA_MACRO';
export const FETCH_RA_MACRO = 'ra_macros/FETCH_RA_MACRO';
export const FETCH_RA_MACROS = 'ra_macros/FETCH_RA_MACROS';
export const POST_RA_MACRO = 'ra_macros/POST_RA_MACRO';
export const UPDATE_RA_MACRO = 'ra_macros/UPDATE_RA_MACRO';
export const PATCH_RA_MACRO = 'ra_macros/PATCH_RA_MACRO';
export const DELETE_RA_MACRO = 'ra_macros/DELETE_RA_MACRO';
// regimes
export const REGIMES = 'regimes/REGIMES';
export const MUTATE_REGIMES = 'regimes/MUTATE_REGIMES';
export const MUTATE_REGIME = 'regimes/MUTATE_REGIME';
export const MUTATE_DELETE_REGIME = 'regimes/MUTATE_DELETE_REGIME';
export const FETCH_REGIME = 'regimes/FETCH_REGIME';
export const FETCH_REGIMES = 'regimes/FETCH_REGIMES';
export const POST_REGIME = 'regimes/POST_REGIME';
export const UPDATE_REGIME = 'regimes/UPDATE_REGIME';
export const PATCH_REGIME = 'regimes/PATCH_REGIME';
export const DELETE_REGIME = 'regimes/DELETE_REGIME';
// regions
export const REGIONS = 'regions/REGIONS';
export const MUTATE_REGIONS = 'regions/MUTATE_REGIONS';
export const MUTATE_REGION = 'regions/MUTATE_REGION';
export const MUTATE_DELETE_REGION = 'regions/MUTATE_DELETE_REGION';
export const FETCH_REGION = 'regions/FETCH_REGION';
export const FETCH_REGIONS = 'regions/FETCH_REGIONS';
export const POST_REGION = 'regions/POST_REGION';
export const UPDATE_REGION = 'regions/UPDATE_REGION';
export const PATCH_REGION = 'regions/PATCH_REGION';
export const DELETE_REGION = 'regions/DELETE_REGION';
// ra_micros
export const RA_MICROS = 'ra_micros/RA_MICROS';
export const MUTATE_RA_MICROS = 'ra_micros/MUTATE_RA_MICROS';
export const MUTATE_RA_MICRO = 'ra_micros/MUTATE_RA_MICRO';
export const MUTATE_DELETE_RA_MICRO = 'ra_micros/MUTATE_DELETE_RA_MICRO';
export const FETCH_RA_MICRO = 'ra_micros/FETCH_RA_MICRO';
export const FETCH_RA_MICROS = 'ra_micros/FETCH_RA_MICROS';
export const POST_RA_MICRO = 'ra_micros/POST_RA_MICRO';
export const UPDATE_RA_MICRO = 'ra_micros/UPDATE_RA_MICRO';
export const PATCH_RA_MICRO = 'ra_micros/PATCH_RA_MICRO';
export const DELETE_RA_MICRO = 'ra_micros/DELETE_RA_MICRO';
// sections
export const SECTIONS = 'sections/SECTIONS';
export const MUTATE_SECTIONS = 'sections/MUTATE_SECTIONS';
export const MUTATE_SECTION = 'sections/MUTATE_SECTION';
export const MUTATE_DELETE_SECTION = 'sections/MUTATE_DELETE_SECTION';
export const FETCH_SECTION = 'sections/FETCH_SECTION';
export const FETCH_SECTIONS = 'sections/FETCH_SECTIONS';
export const POST_SECTION = 'sections/POST_SECTION';
export const UPDATE_SECTION = 'sections/UPDATE_SECTION';
export const PATCH_SECTION = 'sections/PATCH_SECTION';
export const DELETE_SECTION = 'sections/DELETE_SECTION';
// session_dates
export const SESSION_DATES = 'session_dates/SESSION_DATES';
export const MUTATE_SESSION_DATES = 'session_dates/MUTATE_SESSION_DATES';
export const MUTATE_SESSION_DATE = 'session_dates/MUTATE_SESSION_DATE';
export const MUTATE_DELETE_SESSION_DATE = 'session_dates/MUTATE_DELETE_SESSION_DATE';
export const FETCH_SESSION_DATE = 'session_dates/FETCH_SESSION_DATE';
export const FETCH_SESSION_DATES = 'session_dates/FETCH_SESSION_DATES';
export const POST_SESSION_DATE = 'session_dates/POST_SESSION_DATE';
export const POST_BULK_SESSION_DATES = 'session_dates/POST_BULK_SESSION_DATES';
export const UPDATE_SESSION_DATE = 'session_dates/UPDATE_SESSION_DATE';
export const PATCH_SESSION_DATE = 'session_dates/PATCH_SESSION_DATE';
export const DELETE_SESSION_DATE = 'session_dates/DELETE_SESSION_DATE';
// session_plannings
export const SESSION_PLANNINGS = 'session_plannings/SESSION_PLANNINGS';
export const MUTATE_SESSION_PLANNINGS = 'session_plannings/MUTATE_SESSION_PLANNINGS';
export const MUTATE_SESSION_PLANNING = 'session_plannings/MUTATE_SESSION_PLANNING';
export const MUTATE_DELETE_SESSION_PLANNING = 'session_plannings/MUTATE_DELETE_SESSION_PLANNING';
export const FETCH_SESSION_PLANNING = 'session_plannings/FETCH_SESSION_PLANNING';
export const FETCH_SESSION_PLANNINGS = 'session_plannings/FETCH_SESSION_PLANNING';
export const POST_SESSION_PLANNING = 'session_plannings/POST_SESSION_PLANNING';
export const UPDATE_SESSION_PLANNING = 'session_plannings/UPDATE_SESSION_PLANNING';
export const PATCH_SESSION_PLANNING = 'session_plannings/PATCH_SESSION_PLANNING';
export const DELETE_SESSION_PLANNING = 'session_plannings/DELETE_SESSION_PLANNING';
// shifts
export const SHIFTS = 'shifts/SHIFTS';
export const MUTATE_SHIFTS = 'shifts/MUTATE_SHIFTS';
export const MUTATE_SHIFT = 'shifts/MUTATE_SHIFT';
export const FETCH_SHIFTS = 'shifts/FETCH_SHIFTS';
export const FETCH_SHIFT = 'shifts/FETCH_SHIFT';
// study_environments
export const STUDY_ENVIRONMENTS = 'study_environments/STUDY_ENVIRONMENTS';
export const MUTATE_STUDY_ENVIRONMENTS = 'study_environments/MUTATE_STUDY_ENVIRONMENTS';
export const MUTATE_STUDY_ENVIRONMENT = 'study_environments/MUTATE_STUDY_ENVIRONMENT';
export const MUTATE_DELETE_STUDY_ENVIRONMENT = 'study_environments/MUTATE_DELETE_STUDY_ENVIRONMENT';
export const FETCH_STUDY_ENVIRONMENT = 'study_environments/FETCH_STUDY_ENVIRONMENT';
export const FETCH_STUDY_ENVIRONMENTS = 'study_environments/FETCH_STUDY_ENVIRONMENTS';
export const POST_STUDY_ENVIRONMENT = 'study_environments/POST_STUDY_ENVIRONMENT';
export const UPDATE_STUDY_ENVIRONMENT = 'study_environments/UPDATE_STUDY_ENVIRONMENT';
export const PATCH_STUDY_ENVIRONMENT = 'study_environments/PATCH_STUDY_ENVIRONMENT';
export const DELETE_STUDY_ENVIRONMENT = 'study_environments/DELETE_STUDY_ENVIRONMENT';
// study_environments_time_allocation
export const STUDY_ENVIRONMENTS_TIME_ALLOCATION = 'study_environments/STUDY_ENVIRONMENTS_TIME_ALLOCATION';
export const MUTATE_STUDY_ENVIRONMENTS_TIME_ALLOCATION = 'study_environments/MUTATE_STUDY_ENVIRONMENTS_TIME_ALLOCATION';
export const MUTATE_STUDY_ENVIRONMENT_TIME_ALLOCATION = 'study_environments/MUTATE_STUDY_ENVIRONMENT_TIME_ALLOCATION';
export const MUTATE_DELETE_STUDY_ENVIRONMENT_TIME_ALLOCATION = 'study_environments/MUTATE_DELETE_STUDY_ENVIRONMENT_TIME_ALLOCATION';
export const FETCH_STUDY_ENVIRONMENT_TIME_ALLOCATION = 'study_environments/FETCH_STUDY_ENVIRONMENT_TIME_ALLOCATION';
export const FETCH_STUDY_ENVIRONMENTS_TIME_ALLOCATION = 'study_environments/FETCH_STUDY_ENVIRONMENTS_TIME_ALLOCATION';
export const POST_STUDY_ENVIRONMENT_TIME_ALLOCATION = 'study_environments/POST_STUDY_ENVIRONMENT_TIME_ALLOCATION';
export const UPDATE_STUDY_ENVIRONMENT_TIME_ALLOCATION = 'study_environments/UPDATE_STUDY_ENVIRONMENT_TIME_ALLOCATION';
export const PATCH_STUDY_ENVIRONMENT_TIME_ALLOCATION = 'study_environments/PATCH_STUDY_ENVIRONMENT_TIME_ALLOCATION';
export const DELETE_STUDY_ENVIRONMENT_TIME_ALLOCATION = 'study_environments/DELETE_STUDY_ENVIRONMENT_TIME_ALLOCATION';
// study_plan_comments
export const STUDY_PLAN_COMMENTS = 'study_plan_comments/STUDY_PLAN_COMMENTS';
export const MUTATE_STUDY_PLAN_COMMENTS = 'study_plan_comments/MUTATE_STUDY_PLAN_COMMENTS';
export const MUTATE_STUDY_PLAN_COMMENT = 'study_plan_comments/MUTATE_STUDY_PLAN_COMMENT';
export const MUTATE_DELETE_STUDY_PLAN_COMMENT = 'study_plan_comments/MUTATE_DELETE_STUDY_PLAN_COMMENT';
export const FETCH_STUDY_PLAN_COMMENT = 'study_plan_comments/FETCH_STUDY_PLAN_COMMENT';
export const FETCH_STUDY_PLAN_COMMENTS = 'study_plan_comments/FETCH_STUDY_PLAN_COMMENTS';
export const POST_STUDY_PLAN_COMMENT = 'study_plan_comments/POST_STUDY_PLAN_COMMENT';
export const UPDATE_STUDY_PLAN_COMMENT = 'study_plan_comments/UPDATE_STUDY_PLAN_COMMENT';
export const PATCH_STUDY_PLAN_COMMENT = 'study_plan_comments/PATCH_STUDY_PLAN_COMMENT';
export const DELETE_STUDY_PLAN_COMMENT = 'study_plan_comments/DELETE_STUDY_PLAN_COMMENT';
// study_units
export const STUDY_UNITS = 'study_units/STUDY_UNITS';
export const STUDY_UNIT = 'study_units/STUDY_UNIT';
export const MUTATE_STUDY_UNITS = 'study_units/MUTATE_STUDY_UNITS';
export const MUTATE_STUDY_UNIT = 'study_units/MUTATE_STUDY_UNIT';
export const MUTATE_DELETE_STUDY_UNIT = 'study_units/MUTATE_DELETE_STUDY_UNIT';
export const FETCH_STUDY_UNIT = 'study_units/FETCH_STUDY_UNIT';
export const FETCH_STUDY_UNITS = 'study_units/FETCH_STUDY_UNITS';
export const FETCH_STUDY_UNITS_BY_CICLE_AND_PROFILE = 'study_units/FETCH_STUDY_UNITS_BY_CICLE_AND_PROFILE';
export const POST_STUDY_UNIT = 'study_units/POST_STUDY_UNIT';
export const UPDATE_STUDY_UNIT = 'study_units/UPDATE_STUDY_UNIT';
export const PATCH_STUDY_UNIT = 'study_units/PATCH_STUDY_UNIT';
export const DELETE_STUDY_UNIT = 'study_units/DELETE_STUDY_UNIT';
// support_resources
export const SUPPORT_RESOURCES = 'support_resources/SUPPORT_RESOURCES';
export const MUTATE_SUPPORT_RESOURCES = 'support_resources/MUTATE_SUPPORT_RESOURCES';
export const MUTATE_SUPPORT_RESOURCE = 'support_resources/MUTATE_SUPPORT_RESOURCE';
export const MUTATE_DELETE_SUPPORT_RESOURCE = 'support_resources/MUTATE_DELETE_SUPPORT_RESOURCE';
export const FETCH_SUPPORT_RESOURCE = 'support_resources/FETCH_SUPPORT_RESOURCE';
export const FETCH_SUPPORT_RESOURCES = 'support_resources/FETCH_SUPPORT_RESOURCES';
export const POST_SUPPORT_RESOURCE = 'support_resources/POST_SUPPORT_RESOURCE';
export const UPDATE_SUPPORT_RESOURCE = 'support_resources/UPDATE_SUPPORT_RESOURCE';
export const PATCH_SUPPORT_RESOURCE = 'support_resources/PATCH_SUPPORT_RESOURCE';
export const DELETE_SUPPORT_RESOURCE = 'support_resources/DELETE_SUPPORT_RESOURCE';
// support_resource_types
export const SUPPORT_RESOURCE_TYPES = 'support_resource_types/SUPPORT_RESOURCE_TYPES';
export const MUTATE_SUPPORT_RESOURCE_TYPES = 'support_resource_types/MUTATE_SUPPORT_RESOURCE_TYPES';
export const MUTATE_SUPPORT_RESOURCE_TYPE = 'support_resource_types/MUTATE_SUPPORT_RESOURCE_TYPE';
export const MUTATE_DELETE_SUPPORT_RESOURCE_TYPE = 'support_resource_types/MUTATE_DELETE_SUPPORT_RESOURCE_TYPE';
export const FETCH_SUPPORT_RESOURCE_TYPE = 'support_resource_types/FETCH_SUPPORT_RESOURCE_TYPE';
export const FETCH_SUPPORT_RESOURCE_TYPES = 'support_resource_types/FETCH_SUPPORT_RESOURCE_TYPES';
export const POST_SUPPORT_RESOURCE_TYPE = 'support_resource_types/POST_SUPPORT_RESOURCE_TYPE';
export const UPDATE_SUPPORT_RESOURCE_TYPE = 'support_resource_types/UPDATE_SUPPORT_RESOURCE_TYPE';
export const PATCH_SUPPORT_RESOURCE_TYPE = 'support_resource_types/PATCH_SUPPORT_RESOURCE_TYPE';
export const DELETE_SUPPORT_RESOURCE_TYPE = 'support_resource_types/DELETE_SUPPORT_RESOURCE_TYPE';
// taxonomies
export const TAXONOMIES = 'taxonomies/TAXONOMIES';
export const TAXONOMY = 'taxonomies/TAXONOMY';
export const MUTATE_TAXONOMIES = 'taxonomies/MUTATE_TAXONOMIES';
export const MUTATE_TAXONOMY = 'taxonomies/MUTATE_TAXONOMY';
export const MUTATE_DELETE_TAXONOMY = 'taxonomies/MUTATE_DELETE_TAXONOMY';
export const FETCH_TAXONOMY = 'taxonomies/FETCH_TAXONOMY';
export const FETCH_TAXONOMIES = 'taxonomies/FETCH_TAXONOMIES';
export const POST_TAXONOMY = 'taxonomies/POST_TAXONOMY';
export const UPDATE_TAXONOMY = 'taxonomies/UPDATE_TAXONOMY';
export const PATCH_TAXONOMY = 'taxonomies/PATCH_TAXONOMY';
export const DELETE_TAXONOMY = 'taxonomies/DELETE_TAXONOMY';
// users
export const USERS = 'users/USERS';
export const MUTATE_USERS = 'users/MUTATE_USERS';
export const MUTATE_USER = 'users/MUTATE_USER';
export const MUTATE_DELETE_USER = 'users/MUTATE_DELETE_USER';
export const FETCH_USER = 'users/FETCH_USER';
export const FETCH_USERS = 'users/FETCH_USERS';
export const FETCH_ALT_USERS = 'users/FETCH_ALT_USERS';
export const POST_USER = 'users/POST_USER';
export const UPDATE_USER = 'users/UPDATE_USER';
export const PATCH_USER = 'users/PATCH_USER';
export const DELETE_USER = 'users/DELETE_USER';
export const ROLES = 'users/ROLES';
export const MUTATE_ROLES = 'users/MUTATE_ROLES';
export const FETCH_ROLES = 'users/FETCH_ROLES';
// time_allocations
export const TIME_ALLOCATIONS = 'time_allocations/TIME_ALLOCATIONS';
export const MUTATE_TIME_ALLOCATIONS = 'time_allocations/MUTATE_TIME_ALLOCATIONS';
export const MUTATE_TIME_ALLOCATION = 'time_allocations/MUTATE_TIME_ALLOCATION';
export const MUTATE_DELETE_TIME_ALLOCATION = 'time_allocations/MUTATE_DELETE_TIME_ALLOCATION';
export const FETCH_TIME_ALLOCATION = 'time_allocations/FETCH_TIME_ALLOCATION';
export const FETCH_TIME_ALLOCATIONS = 'time_allocations/FETCH_TIME_ALLOCATIONS';
export const POST_TIME_ALLOCATION = 'time_allocations/POST_TIME_ALLOCATION';
export const UPDATE_TIME_ALLOCATION = 'time_allocations/UPDATE_TIME_ALLOCATION';
export const PATCH_TIME_ALLOCATION = 'time_allocations/PATCH_TIME_ALLOCATION';
export const DELETE_TIME_ALLOCATION = 'time_allocations/DELETE_TIME_ALLOCATION';
// matter_time_allocations
export const MATTER_TIME_ALLOCATIONS = 'time_allocations/MATTER_TIME_ALLOCATIONS';
export const MUTATE_MATTER_TIME_ALLOCATIONS = 'time_allocations/MUTATE_MATTER_TIME_ALLOCATIONS';
export const MUTATE_MATTER_TIME_ALLOCATION = 'time_allocations/MUTATE_MATTER_TIME_ALLOCATION';
export const MUTATE_DELETE_MATTER_TIME_ALLOCATION = 'time_allocations/MUTATE_DELETE_MATTER_TIME_ALLOCATION';
export const FETCH_MATTER_TIME_ALLOCATION = 'time_allocations/FETCH_MATTER_TIME_ALLOCATION';
export const FETCH_MATTER_TIME_ALLOCATIONS = 'time_allocations/FETCH_MATTER_TIME_ALLOCATIONS';
export const POST_MATTER_TIME_ALLOCATION = 'time_allocations/POST_MATTER_TIME_ALLOCATION';
export const UPDATE_MATTER_TIME_ALLOCATION = 'time_allocations/UPDATE_MATTER_TIME_ALLOCATION';
export const PATCH_MATTER_TIME_ALLOCATION = 'time_allocations/PATCH_MATTER_TIME_ALLOCATION';
export const DELETE_MATTER_TIME_ALLOCATION = 'time_allocations/DELETE_MATTER_TIME_ALLOCATION';
// ep_matter_time_allocations
export const EP_MATTER_TIME_ALLOCATIONS = 'time_allocations/EP_MATTER_TIME_ALLOCATIONS';
export const MUTATE_EP_MATTER_TIME_ALLOCATIONS = 'time_allocations/MUTATE_EP_MATTER_TIME_ALLOCATIONS';
export const MUTATE_EP_MATTER_TIME_ALLOCATION = 'time_allocations/MUTATE_EP_MATTER_TIME_ALLOCATION';
export const MUTATE_DELETE_EP_MATTER_TIME_ALLOCATION = 'time_allocations/MUTATE_DELETE_EP_MATTER_TIME_ALLOCATION';
export const FETCH_EP_MATTER_TIME_ALLOCATION = 'time_allocations/FETCH_EP_MATTER_TIME_ALLOCATION';
export const FETCH_EP_MATTER_TIME_ALLOCATIONS = 'time_allocations/FETCH_EP_MATTER_TIME_ALLOCATIONS';
export const POST_EP_MATTER_TIME_ALLOCATION = 'time_allocations/POST_EP_MATTER_TIME_ALLOCATION';
export const UPDATE_EP_MATTER_TIME_ALLOCATION = 'time_allocations/UPDATE_EP_MATTER_TIME_ALLOCATION';
export const PATCH_EP_MATTER_TIME_ALLOCATION = 'time_allocations/PATCH_EP_MATTER_TIME_ALLOCATION';
export const DELETE_EP_MATTER_TIME_ALLOCATION = 'time_allocations/DELETE_EP_MATTER_TIME_ALLOCATION';
// QuestionsTests
export const QUESTION_TESTS = 'question_tests/QUESTION_TESTS';
export const MUTATE_QUESTION_TEST = 'question_tests/MUTATE_QUESTION_TEST';
export const MUTATE_QUESTION_TESTS = 'question_tests/MUTATE_QUESTION_TESTS';
export const MUTATE_DELETE_QUESTION_TEST = 'question_tests/MUTATE_DELETE_QUESTION_TEST';
export const FETCH_QUESTION_TEST = 'question_tests/FETCH_QUESTION_TEST';
export const FETCH_QUESTION_TESTS = 'question_tests/FETCH_QUESTION_TESTS';
export const POST_QUESTION_TEST = 'question_tests/POST_QUESTION_TEST';
export const UPDATE_QUESTION_TEST = 'question_tests/UPDATE_QUESTION_TEST';
export const PATCH_QUESTION_TEST = 'question_tests/PATCH_QUESTION_TEST';
export const DELETE_QUESTION_TEST = 'question_tests/DELETE_QUESTION_TEST';
// QuestionsTestsScore
export const QUESTION_TESTS_SCORE = 'question_tests/QUESTION_TESTS_SCORE';
export const MUTATE_QUESTION_TEST_SCORE = 'question_tests/MUTATE_QUESTION_TEST_SCORE';
export const MUTATE_QUESTION_TESTS_SCORE = 'question_tests/MUTATE_QUESTION_TESTS_SCORE';
export const MUTATE_DELETE_QUESTION_TEST_SCORE = 'question_tests/MUTATE_DELETE_QUESTION_TEST_SCORE';
export const FETCH_QUESTION_TEST_SCORE = 'question_tests/FETCH_QUESTION_TEST_SCORE';
export const FETCH_QUESTION_TESTS_SCORE = 'question_tests/FETCH_QUESTION_TESTS_SCORE';
export const POST_QUESTION_TEST_SCORE = 'question_tests/POST_QUESTION_TEST_SCORE';
export const UPDATE_QUESTION_TEST_SCORE = 'question_tests/UPDATE_QUESTION_TEST_SCORE';
export const PATCH_QUESTION_TEST_SCORE = 'question_tests/PATCH_QUESTION_TEST_SCORE';
export const DELETE_QUESTION_TEST_SCORE = 'question_tests/DELETE_QUESTION_TEST_SCORE';
// Questions
export const QUESTIONS = 'tests/QUESTIONS';
export const MUTATE_QUESTION = 'tests/MUTATE_QUESTION';
export const MUTATE_QUESTIONS = 'tests/MUTATE_QUESTIONS';
export const MUTATE_DELETE_QUESTION = 'tests/MUTATE_DELETE_QUESTION';
export const FETCH_QUESTION = 'tests/FETCH_QUESTION';
export const FETCH_MULTIPLE_CHOICE_QUESTION = 'tests/FETCH_MULTIPLE_CHOICE_QUESTION';
export const FETCH_MULTIPLE_CHOICE_QUESTIONS = 'tests/FETCH_MULTIPLE_CHOICE_QUESTIONS';
export const FETCH_QUESTIONS = 'tests/FETCH_QUESTIONS';
export const POST_QUESTION = 'tests/POST_QUESTION';
export const POST_MULTIPLE_CHOICE_QUESTION = 'tests/POST_MULTIPLE_CHOICE_QUESTION';
export const UPDATE_QUESTION = 'tests/UPDATE_QUESTION';
export const PATCH_QUESTION = 'tests/PATCH_QUESTION';
export const PATCH_MULTIPLE_CHOICE_QUESTION = 'tests/PATCH_MULTIPLE_CHOICE_QUESTION';
export const DELETE_QUESTION = 'tests/DELETE_QUESTION';
// Question Types
export const QUESTION_TYPES = 'tests/QUESTION_TYPES';
export const MUTATE_QUESTION_TYPES = 'tests/MUTATE_QUESTION_TYPES';
export const FETCH_QUESTION_TYPES = 'tests/FETCH_QUESTION_TYPES';
// Score Types
export const SCORE_TYPES = 'tests/SCORE_TYPES';
export const MUTATE_SCORE_TYPES = 'tests/MUTATE_SCORE_TYPES';
export const FETCH_SCORE_TYPES = 'tests/FETCH_SCORE_TYPES';
// Question Options
export const MULTIPLE_CHOICE_QUESTION_OPTIONS = 'tests/MULTIPLE_CHOICE_QUESTION_OPTIONS';
export const MUTATE_MULTIPLE_CHOICE_QUESTION_OPTION = 'tests/MUTATE_MULTIPLE_CHOICE_QUESTION_OPTION';
export const MUTATE_MULTIPLE_CHOICE_QUESTION_OPTIONS = 'tests/MUTATE_MULTIPLE_CHOICE_QUESTION_OPTIONS';
export const MUTATE_DELETE_MULTIPLE_CHOICE_QUESTION_OPTION = 'tests/MUTATE_DELETE_MULTIPLE_CHOICE_QUESTION_OPTION';
export const FETCH_MULTIPLE_CHOICE_QUESTION_OPTIONS = 'tests/FETCH_MULTIPLE_CHOICE_QUESTION_OPTIONS';
export const FETCH_MULTIPLE_CHOICE_QUESTION_OPTION = 'tests/FETCH_MULTIPLE_CHOICE_QUESTION_OPTION';
export const POST_MULTIPLE_CHOICE_QUESTION_OPTION = 'tests/POST_MULTIPLE_CHOICE_QUESTION_OPTION';
export const UPDATE_MULTIPLE_CHOICE_QUESTION_OPTION = 'tests/UPDATE_MULTIPLE_CHOICE_QUESTION_OPTION';
export const PATCH_MULTIPLE_CHOICE_QUESTION_OPTION = 'tests/PATCH_MULTIPLE_CHOICE_QUESTION_OPTION';
export const DELETE_MULTIPLE_CHOICE_QUESTION_OPTION = 'tests/DELETE_MULTIPLE_CHOICE_QUESTION_OPTION';
// Question Answers
export const REDACTION_ANSWERS = 'test_answers/REDACTION_ANSWERS';
export const MUTATE_REDACTION_ANSWER = 'test_answers/MUTATE_REDACTION_ANSWER';
export const MUTATE_REDACTION_ANSWERS = 'test_answers/MUTATE_REDACTION_ANSWERS';
export const MUTATE_DELETE_REDACTION_ANSWER = 'test_answers/MUTATE_DELETE_REDACTION_ANSWER';
export const FETCH_REDACTION_ANSWER = 'test_answers/FETCH_REDACTION_ANSWER';
export const FETCH_REDACTION_ANSWERS = 'test_answers/FETCH_REDACTION_ANSWERS';
export const POST_REDACTION_ANSWER = 'test_answers/POST_REDACTION_ANSWER';
export const UPDATE_REDACTION_ANSWER = 'test_answers/UPDATE_REDACTION_ANSWER';
export const PATCH_REDACTION_ANSWER = 'test_answers/PATCH_REDACTION_ANSWER';
export const DELETE_REDACTION_ANSWER = 'test_answers/DELETE_REDACTION_ANSWER';

export const SELECT_ANSWERS = 'test_answers/SELECT_ANSWERS';
export const MUTATE_SELECT_ANSWER = 'test_answers/MUTATE_SELECT_ANSWER';
export const MUTATE_SELECT_ANSWERS = 'test_answers/MUTATE_SELECT_ANSWERS';
export const MUTATE_DELETE_SELECT_ANSWER = 'test_answers/MUTATE_DELETE_SELECT_ANSWER';
export const FETCH_SELECT_ANSWER = 'test_answers/FETCH_SELECT_ANSWER';
export const FETCH_SELECT_ANSWERS = 'test_answers/FETCH_SELECT_ANSWERS';
export const POST_SELECT_ANSWER = 'test_answers/POST_SELECT_ANSWER';
export const UPDATE_SELECT_ANSWER = 'test_answers/UPDATE_SELECT_ANSWER';
export const PATCH_SELECT_ANSWER = 'test_answers/PATCH_SELECT_ANSWER';
export const DELETE_SELECT_ANSWER = 'test_answers/DELETE_SELECT_ANSWER';
// Observation Instruments
export const OBSERVATION_INSTRUMENTS = 'observation_instruments/OBSERVATION_INSTRUMENTS';
export const MUTATE_OBSERVATION_INSTRUMENT = 'observation_instruments/MUTATE_OBSERVATION_INSTRUMENT';
export const MUTATE_DELETE_OBSERVATION_INSTRUMENT = 'observation_instruments/MUTATE_DELETE_OBSERVATION_INSTRUMENT';
export const FETCH_OBSERVATION_INSTRUMENT = 'observation_instruments/FETCH_OBSERVATION_INSTRUMENT';
export const FETCH_OBSERVATION_INSTRUMENTS = 'observation_instruments/FETCH_OBSERVATION_INSTRUMENTS';
export const POST_OBSERVATION_INSTRUMENT = 'observation_instruments/POST_OBSERVATION_INSTRUMENT';
export const PATCH_OBSERVATION_INSTRUMENT = 'observation_instruments/PATCH_OBSERVATION_INSTRUMENT';
export const UPDATE_OBSERVATION_INSTRUMENT = 'observation_instruments/UPDATE_OBSERVATION_INSTRUMENT';
export const DELETE_OBSERVATION_INSTRUMENT = 'observation_instruments/DELETE_OBSERVATION_INSTRUMENT';
// Observation Instrument Achievements
export const OBSERVATION_ACHIEVEMENTS = 'observation_instruments/OBSERVATION_ACHIEVEMENTS';
export const MUTATE_OBSERVATION_ACHIEVEMENT = 'observation_instruments/MUTATE_OBSERVATION_ACHIEVEMENT';
export const MUTATE_DELETE_OBSERVATION_ACHIEVEMENT = 'observation_instruments/MUTATE_DELETE_OBSERVATION_ACHIEVEMENT';
export const FETCH_OBSERVATION_ACHIEVEMENT = 'observation_instruments/FETCH_OBSERVATION_ACHIEVEMENT';
export const FETCH_OBSERVATION_ACHIEVEMENTS = 'observation_instruments/FETCH_OBSERVATION_ACHIEVEMENTS';
export const POST_OBSERVATION_ACHIEVEMENT = 'observation_instruments/POST_OBSERVATION_ACHIEVEMENT';
export const UPDATE_OBSERVATION_ACHIEVEMENT = 'observation_instruments/UPDATE_OBSERVATION_ACHIEVEMENT';
export const PATCH_OBSERVATION_ACHIEVEMENT = 'observation_instruments/PATCH_OBSERVATION_ACHIEVEMENT';
export const DELETE_OBSERVATION_ACHIEVEMENT = 'observation_instruments/DELETE_OBSERVATION_ACHIEVEMENT';
// Observation Instrument Instances
export const OBSERVATION_INSTANCES = 'observation_instruments/OBSERVATION_INSTANCES';
export const MUTATE_OBSERVATION_INSTANCE = 'observation_instruments/MUTATE_OBSERVATION_INSTANCE';
export const MUTATE_DELETE_OBSERVATION_INSTANCE = 'observation_instruments/MUTATE_DELETE_OBSERVATION_INSTANCE';
export const FETCH_OBSERVATION_INSTANCE = 'observation_instruments/FETCH_OBSERVATION_INSTANCE';
export const FETCH_OBSERVATION_INSTANCES = 'observation_instruments/FETCH_OBSERVATION_INSTANCES';
export const POST_RUBRIC_INSTANCE = 'observation_instruments/POST_RUBRIC_INSTANCE';
export const UPDATE_OBSERVATION_INSTANCE = 'observation_instruments/UPDATE_OBSERVATION_INSTANCE';
export const PATCH_OBSERVATION_INSTANCE = 'observation_instruments/PATCH_OBSERVATION_INSTANCE';
export const DELETE_OBSERVATION_INSTANCE = 'observation_instruments/DELETE_OBSERVATION_INSTANCE';
// Observation Instrument Achievement Descriptors
export const OBSERVATION_DESCRIPTORS = 'observation_instruments/OBSERVATION_DESCRIPTORS';
export const MUTATE_OBSERVATION_DESCRIPTOR = 'observation_instruments/MUTATE_OBSERVATION_DESCRIPTOR';
export const MUTATE_DELETE_OBSERVATION_DESCRIPTOR = 'observation_instruments/MUTATE_DELETE_OBSERVATION_DESCRIPTOR';
export const FETCH_OBSERVATION_DESCRIPTOR = 'observation_instruments/FETCH_OBSERVATION_DESCRIPTOR';
export const FETCH_RUBRIC_DESCRIPTORS = 'observation_instruments/FETCH_RUBRIC_DESCRIPTORS';
export const FETCH_RUBRIC_DESCRIPTOR_BY = 'observation_instruments/FETCH_RUBRIC_DESCRIPTOR_BY';
export const POST_OBSERVATION_DESCRIPTOR = 'observation_instruments/POST_OBSERVATION_DESCRIPTOR';
export const UPDATE_OBSERVATION_DESCRIPTOR = 'observation_instruments/UPDATE_OBSERVATION_DESCRIPTOR';
export const PATCH_OBSERVATION_DESCRIPTOR = 'observation_instruments/PATCH_OBSERVATION_DESCRIPTOR';
export const DELETE_OBSERVATION_DESCRIPTOR = 'observation_instruments/DELETE_OBSERVATION_DESCRIPTOR';
// Observation Instrument Scores
export const OBSERVATION_SCORES = 'observation_instruments/OBSERVATION_SCORES';
export const MUTATE_OBSERVATION_SCORE = 'observation_instruments/MUTATE_OBSERVATION_SCORE';
export const MUTATE_DELETE_OBSERVATION_SCORE = 'observation_instruments/MUTATE_DELETE_OBSERVATION_SCORE';
export const FETCH_OBSERVATION_SCORE = 'observation_instruments/FETCH_OBSERVATION_SCORE';
export const FETCH_SCORE_BY_INSTRUMENT = 'observation_instruments/FETCH_SCORE_BY_INSTRUMENT';
export const UPDATE_OBSERVATION_SCORE = 'observation_instruments/UPDATE_OBSERVATION_SCORE';
export const POST_OBSERVATION_SCORE = 'observation_instruments/POST_OBSERVATION_SCORE';
export const PATCH_OBSERVATION_SCORE = 'observation_instruments/PATCH_OBSERVATION_SCORE';
export const DELETE_OBSERVATION_SCORE = 'observation_instruments/DELETE_OBSERVATION_SCORE';
// Images
export const IMAGES = 'images/IMAGES';
export const MUTATE_IMAGE = 'images/MUTATE_IMAGE';
export const MUTATE_DELETE_IMAGE = 'images/MUTATE_DELETE_IMAGE';
export const FETCH_IMAGE = 'images/FETCH_IMAGE';
export const POST_IMAGE = 'images/POST_IMAGE';
export const PATCH_IMAGE = 'images/PATCH_IMAGE';
export const UPDATE_IMAGE = 'images/UPDATE_IMAGE';
export const DELETE_IMAGE = 'images/DELETE_IMAGE';
// utils
export const NUMBERS_TO_WORDS = 'utils/NUMBERS_TO_WORDS';
export const ALLOWS_CRUD = 'utils/ALLOWS_CRUD';
export const MUTATE_ALLOWS_CRUD = 'utils/MUTATE_ALLOWS_CRUD';
export const VISIBILITY_CRUD = 'utils/VISIBILITY_CRUD';
export const MUTATE_VISIBILITY_CRUD = 'utils/MUTATE_VISIBILITY_CRUD';
export const PRINT_ZOOM_SCALE = 'utils/PRINT_ZOOM_SCALE';
export const MUTATE_PRINT_ZOOM_SCALE = 'utils/MUTATE_PRINT_ZOOM_SCALE';
export const RESET_PRINT_ZOOM_SCALE = 'utils/RESET_PRINT_ZOOM_SCALE'
export const PRINT_ORIENTATION = 'utils/PRINT_ORIENTATION';
export const MUTATE_PRINT_ORIENTATION = 'utils/MUTATE_PRINT_ORIENTATION';
export const RESET_PRINT_ORIENTATION = 'utils/RESET_PRINT_ORIENTATION';
export const TITLE_OF_THE_PRINT = 'utils/TITLE_OF_THE_PRINT';
export const MUTATE_TITLE_OF_THE_PRINT = 'utils/MUTATE_TITLE_OF_THE_PRINT';
export const RESET_TITLE_OF_THE_PRINT = 'utils/RESET_TITLE_OF_THE_PRINT';

//OpenAi
export const SHOW_INTELLIGENCE_SIDEBAR = 'openai/intelligence/SHOW_INTELLIGENCE_SIDEBAR';
export const MUTATE_SHOW_INTELLIGENCE_SIDEBAR = 'openai/intelligence/MUTATE_SHOW_INTELLIGENCE_SIDEBAR';
export const FILTER_INTELLIGENCE = 'openai/intelligence/FILTER_INTELLIGENCE';
export const MUTATE_FILTER_INTELLIGENCE = 'openai/intelligence/MUTATE_FILTER_INTELLIGENCE';
export const MUTATE_DELETE_FILTER_INTELLIGENCE = 'openai/intelligence/MUTATE_DELETE_FILTER_INTELLIGENCE';
export const INTELLIGENCE_OPTIONS = 'openai/intelligence/INTELLIGENCE_OPTIONS';
export const MUTATE_RULES_FILTER_SELECTOR = 'openai/intelligence/MUTATE_RULES_FILTER_SELECTOR';
export const MUTATE_DELETE_RULES_FILTER_SELECTOR = 'openai/intelligence/MUTATE_DELETE_RULES_FILTER_SELECTOR';
export const RULES_FILTER_SELECTOR = 'openai/intelligence/RULES_FILTER_SELECTOR';