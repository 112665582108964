<template>
  <b-button
    size="sm"
    variant="none"
    title="Adicionar"
    @click="click"
    class="mr-1"
  >
    <b-icon-plus-square></b-icon-plus-square>
  </b-button>
</template>

<script>
export default {
  name: "AddButton",
  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>

<style scoped>
button {
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
}
</style>