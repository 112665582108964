import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    support_resources: [],
    support_resources_url: '/teaching/support-resource'
}

const getters = {
    [names.SUPPORT_RESOURCES]: state => {
        if (!get.store.state.user) return [];
        return state.support_resources.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_SUPPORT_RESOURCES]: (state, support_resources) => {
        support_resources.forEach(element => {
            let index = state.support_resources.findIndex(x => x.id == element.id);
            if (index != -1) state.support_resources.splice(index, 1, element);
            else state.support_resources.push(element);
        });
    },
    [names.MUTATE_SUPPORT_RESOURCE]: (state, support_resource) => {
        let index = state.support_resources.findIndex(x => x.id == support_resource.id);
        if (index != -1) state.support_resources.splice(index, 1, support_resource);
        else state.support_resources.push(support_resource);
    },
    [names.MUTATE_DELETE_SUPPORT_RESOURCE]: (state, support_resource_id) => {
        let index = state.support_resources.findIndex(x => x.id == support_resource_id);
        if (index != -1) state.support_resources.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_SUPPORT_RESOURCE]: ({
        commit,
        state
    }, support_resource_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.support_resources_url}/${support_resource_id}/`)
                .then(response => {
                    commit(names.MUTATE_SUPPORT_RESOURCE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_SUPPORT_RESOURCES]: ({
        commit,
        state
    }, matter_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.support_resources_url}/?matter=${matter_id}`)
                .then(response => {
                    commit(names.MUTATE_SUPPORT_RESOURCES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_SUPPORT_RESOURCE]: ({
        commit,
        state
    }, support_resource) => {
        return new Promise((resolve) => {
            restful.Post(`${state.support_resources_url}/`, support_resource)
                .then(response => {
                    commit(names.MUTATE_SUPPORT_RESOURCE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_SUPPORT_RESOURCE]: ({
        commit,
        state
    }, support_resource) => {
        return new Promise((resolve) => {
            restful.Put(`${state.support_resources_url}/${support_resource.id}/`, support_resource)
                .then(response => {
                    commit(names.MUTATE_SUPPORT_RESOURCE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_SUPPORT_RESOURCE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.support_resources_url}/${payload.support_resource_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_SUPPORT_RESOURCE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_SUPPORT_RESOURCE]: ({
        commit,
        state
    }, support_resource_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.support_resources_url}/${support_resource_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_SUPPORT_RESOURCE, support_resource_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};