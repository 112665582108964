import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    cycles: [],
    cycles_url: '/mesh/cycle'
};

const getters = {
    [names.CYCLES]: state => {
        if (!get.store.state.user) return [];
        return state.cycles.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
};

const mutations = {
    [names.MUTATE_CYCLES]: (state, cycles) => {
        cycles.forEach(element => {
            let index = state.cycles.findIndex(x => x.id == element.id);
            if (index != -1) state.cycles.splice(index, 1, element);
            else state.cycles.push(element);
        });
    },
    [names.MUTATE_CYCLE]: (state, cycle) => {
        let index = state.cycles.findIndex(x => x.id == cycle.id);
        if (index != -1) state.cycles.splice(index, 1, cycle);
        else state.cycles.push(cycle);
    },
    [names.MUTATE_DELETE_CYCLE]: (state, cycle_id) => {
        let index = state.cycles.findIndex(x => x.id == cycle_id);
        if (index != -1) state.cycles.splice(index, 1);
    },
};

const actions = {
    [names.FETCH_CYCLE]: ({
        commit,
        state
    }, cycle_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.cycles_url}/${cycle_id}/`)
                .then(response => {
                    commit(names.MUTATE_CYCLE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_CYCLES]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.cycles.length > 0) {
                resolve(state.cycles);
                return;
            }
            restful.Get(`${state.cycles_url}/`)
                .then(response => {
                    commit(names.MUTATE_CYCLES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_CYCLE]: ({
        commit,
        state
    }, cycle) => {
        return new Promise((resolve) => {
            restful.Post(`${state.cycles_url}/`, cycle)
                .then(response => {
                    commit(names.MUTATE_CYCLE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_CYCLE]: ({
        commit,
        state
    }, cycle) => {
        return new Promise((resolve) => {
            restful.Put(`${state.cycles_url}/${cycle.id}/`, cycle)
                .then(response => {
                    commit(names.MUTATE_CYCLE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_CYCLE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.cycles_url}/${payload.cycle_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_CYCLE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_CYCLE]: ({
        commit,
        state
    }, cycle_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.cycles_url}/${cycle_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_CYCLE, cycle_id);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};