import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    users: [],
    users_url: '/authentication/user',
    alt_users_url: '/authentication/alt-user',
    roles: [],
    roles_url: '/teaching/roles'

};

const getters = {
    [names.USERS]: state => {
        if (!get.store.state.user) return [];
        return state.users.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.ROLES]: state => {
        // TODO: No cuenta con filtro dado que es un dato estático en el Backend
        return state.roles;
    },
};

const mutations = {
    [names.MUTATE_USERS]: (state, users) => {
        users.forEach(element => {
            let index = state.users.findIndex(x => x.id == element.id);
            if (index != -1) state.users.splice(index, 1, element);
            else state.users.push(element);
        });
    },
    [names.MUTATE_USER]: (state, user) => {
        let index = state.users.findIndex(x => x.id == user.id);
        if (index != -1) state.users.splice(index, 1, user);
        else state.users.push(user);
    },
    [names.MUTATE_DELETE_USER]: (state, user_id) => {
        let index = state.users.findIndex(x => x.id == user_id);
        if (index != -1) state.users.splice(index, 1);
    },
    [names.MUTATE_ROLES]: (state, roles) => {
        roles.forEach(element => {
            let index = state.roles.findIndex(x => x.id == element.id);
            if (index != -1) state.roles.splice(index, 1, element);
            else state.roles.push(element);
        });
    },
};

const actions = {
    [names.FETCH_USER]: ({
        commit,
        state
    }, user_id) => {
        return new Promise((resolve) => {
            // Se comentó dado que al perdir las secciones en el dashboard y la planificación se mandaba el usuario antiguo sin actualizar las nuevas creadas.
            // const user = state.users.find(x => x.id == user_id);
            // if (user) {
            //     resolve(user);
            //     return;
            // }
            restful.Get(`${state.users_url}/${user_id}/`)
                .then(response => {
                    commit(names.MUTATE_USER, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_USERS]: ({
        commit,
        state,
    },) => {
        return new Promise((resolve) => {
            restful.Get(`${state.users_url}/`)
                .then(response => {
                    commit(names.MUTATE_USERS, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_ALT_USERS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            if (payload) {
                if (payload.user_ids) {
                    if (payload.user_ids.length > 0)
                        url_append += '&id__in=' + payload.user_ids.join(",")
                    else return resolve([])

                }
                if (payload.groups_ids) {
                    if (payload.groups_ids.length > 0)
                        url_append += '&groups__id__in=' + payload.groups_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.alt_users_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_USERS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_USER]: ({
        commit,
        state
    }, user) => {
        return new Promise((resolve) => {
            restful.Post(`${state.users_url}/`, user)
                .then(response => {
                    commit(names.MUTATE_USER, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_USER]: ({
        commit,
        state
    }, user) => {
        return new Promise((resolve) => {
            restful.Put(`${state.users_url}/${user.id}/`, user)
                .then(response => {
                    commit(names.MUTATE_USER, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_USER]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.users_url}/${payload.user_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_USER, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_USER]: ({
        commit,
        state
    }, user_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.users_url}/${user_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_USER, user_id);
                    resolve(response);
                });
        });
    },
    [names.FETCH_ROLES]: ({
        commit,
        state
    },) => {
        return new Promise((resolve) => {
            if (state.roles.length > 0) {
                resolve(state.roles);
                return;
            }
            restful.Get(`${state.roles_url}/`)
                .then(response => {
                    commit(names.MUTATE_ROLES, response);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};