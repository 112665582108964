import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    direct_teaching_activities: [],
    direct_teaching_activities_url: '/mesh/direct-teaching-activity'
}

const getters = {
    [names.DIRECT_TEACHING_ACTIVITIES]: state => {
        if (!get.store.state.user) return [];
        return state.direct_teaching_activities.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_DIRECT_TEACHING_ACTIVITIES]: (state, direct_teaching_activities) => {
        direct_teaching_activities.forEach(element => {
            let index = state.direct_teaching_activities.findIndex(x => x.id == element.id);
            if (index != -1) state.direct_teaching_activities.splice(index, 1, element);
            else state.direct_teaching_activities.push(element);
        });
    },
    [names.MUTATE_DIRECT_TEACHING_ACTIVITY]: (state, direct_teaching_activity) => {
        let index = state.direct_teaching_activities.findIndex(x => x.id == direct_teaching_activity.id);
        if (index != -1) state.direct_teaching_activities.splice(index, 1, direct_teaching_activity);
        else state.direct_teaching_activities.push(direct_teaching_activity);
    },
    [names.MUTATE_DELETE_DIRECT_TEACHING_ACTIVITY]: (state, direct_teaching_activity_id) => {
        let index = state.direct_teaching_activities.findIndex(x => x.id == direct_teaching_activity_id);
        if (index != -1) state.direct_teaching_activities.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_DIRECT_TEACHING_ACTIVITY]: ({
        commit,
        state
    }, direct_teaching_activity_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.direct_teaching_activities_url}/${direct_teaching_activity_id}/`)
                .then(response => {
                    commit(names.MUTATE_DIRECT_TEACHING_ACTIVITY, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_DIRECT_TEACHING_ACTIVITIES]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.direct_teaching_activities.length > 0) {
                resolve(state.direct_teaching_activities);
                return;
            }
            restful.Get(`${state.direct_teaching_activities_url}/`)
                .then(response => {
                    commit(names.MUTATE_DIRECT_TEACHING_ACTIVITIES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_DIRECT_TEACHING_ACTIVITY]: ({
        commit,
        state
    }, direct_teaching_activity) => {
        return new Promise((resolve) => {
            restful.Post(`${state.direct_teaching_activities_url}/`, direct_teaching_activity)
                .then(response => {
                    commit(names.MUTATE_DIRECT_TEACHING_ACTIVITY, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_DIRECT_TEACHING_ACTIVITY]: ({
        commit,
        state
    }, direct_teaching_activity) => {
        return new Promise((resolve) => {
            restful.Put(`${state.direct_teaching_activities_url}/${direct_teaching_activity.id}/`, direct_teaching_activity)
                .then(response => {
                    commit(names.MUTATE_DIRECT_TEACHING_ACTIVITY, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_DIRECT_TEACHING_ACTIVITY]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.direct_teaching_activities_url}/${payload.direct_teaching_activity_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_DIRECT_TEACHING_ACTIVITY, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_DIRECT_TEACHING_ACTIVITY]: ({
        commit,
        state
    }, direct_teaching_activity_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.direct_teaching_activities_url}/${direct_teaching_activity_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_DIRECT_TEACHING_ACTIVITY, direct_teaching_activity_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};