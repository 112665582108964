import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    learning_results: [],
    learning_results_url: '/teaching/learning-result'
}

const getters = {
    [names.LEARNING_RESULTS]: state => {
        if (!get.store.state.user) return [];
        return state.learning_results.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_LEARNING_RESULTS]: (state, learning_results) => {
        learning_results.forEach(element => {
            let index = state.learning_results.findIndex(x => x.id == element.id);
            if (index != -1) state.learning_results.splice(index, 1, element);
            else state.learning_results.push(element);
        });
    },
    [names.MUTATE_LEARNING_RESULT]: (state, learning_result) => {
        let index = state.learning_results.findIndex(x => x.id == learning_result.id);
        if (index != -1) state.learning_results.splice(index, 1, learning_result);
        else state.learning_results.push(learning_result);
    },
    [names.MUTATE_DELETE_LEARNING_RESULT]: (state, learning_result_id) => {
        let index = state.learning_results.findIndex(x => x.id == learning_result_id);
        if (index != -1) state.learning_results.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_LEARNING_RESULT]: ({
        commit,
        state
    }, learning_result_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.learning_results_url}/${learning_result_id}/`)
                .then(response => {
                    commit(names.MUTATE_LEARNING_RESULT, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_LEARNING_RESULTS]: ({
        commit,
        state
    }, study_unit_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.learning_results_url}/?study_unit=${study_unit_id}`)
                .then(response => {
                    commit(names.MUTATE_LEARNING_RESULTS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_LEARNING_RESULT]: ({
        commit,
        state
    }, learning_result) => {
        return new Promise((resolve) => {
            restful.Post(`${state.learning_results_url}/`, learning_result)
                .then(response => {
                    commit(names.MUTATE_LEARNING_RESULT, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_LEARNING_RESULT]: ({
        commit,
        state
    }, learning_result) => {
        return new Promise((resolve) => {
            restful.Put(`${state.learning_results_url}/${learning_result.id}/`, learning_result)
                .then(response => {
                    commit(names.MUTATE_LEARNING_RESULT, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_LEARNING_RESULT]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.learning_results_url}/${payload.learning_result_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_LEARNING_RESULT, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_LEARNING_RESULT]: ({
        commit,
        state
    }, learning_result_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.learning_results_url}/${learning_result_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_LEARNING_RESULT, learning_result_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};