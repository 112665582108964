import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    capacities: [],
    capacities_url: '/mesh/capacity'
}

const getters = {
    [names.CAPACITIES]: state => {
        if (!get.store.state.user) return [];
        return state.capacities.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.name.localeCompare(b.name));
    },
}
const mutations = {
    [names.MUTATE_CAPACITIES]: (state, capacities) => {
        capacities.forEach(element => {
            let index = state.capacities.findIndex(x => x.id == element.id);
            if (index != -1) state.capacities.splice(index, 1, element);
            else state.capacities.push(element);
        });
    },
    [names.MUTATE_CAPACITY]: (state, capacity) => {
        let index = state.capacities.findIndex(x => x.id == capacity.id);
        if (index != -1) state.capacities.splice(index, 1, capacity);
        else state.capacities.push(capacity);
    },
    [names.MUTATE_DELETE_CAPACITY]: (state, capacity_id) => {
        let index = state.capacities.findIndex(x => x.id == capacity_id);
        if (index != -1) state.capacities.splice(index, 1);
    },
}
const actions = {
    [names.FETCH_CAPACITY]: ({
        commit,
        state
    }, capacity_id) => {
        return new Promise((resolve) => {
            const capacity = state.capacities.find(x => x.id == capacity_id);
            if (capacity) {
                resolve(capacity);
                return;
            }
            restful.Get(`${state.capacities_url}/${capacity_id}/`)
                .then(response => {
                    commit(names.MUTATE_CAPACITY, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_CAPACITIES]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            let partial_ur = '';
            if (payload && payload.faculty_id) partial_ur = '?faculties__id=' + payload.faculty_id;
            if (payload && payload.faculty_ids) partial_ur = '?faculties__in=' + payload.faculty_ids;
            restful.Get(`${state.capacities_url}/${partial_ur}`)
                .then(response => {
                    commit(names.MUTATE_CAPACITIES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_CAPACITY]: ({
        commit,
        state
    }, capacity) => {
        return new Promise((resolve) => {
            restful.Post(`${state.capacities_url}/`, capacity)
                .then(response => {
                    commit(names.MUTATE_CAPACITY, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_CAPACITY]: ({
        commit,
        state
    }, capacity) => {
        return new Promise((resolve) => {
            restful.Put(`${state.capacities_url}/${capacity.id}/`, capacity)
                .then(response => {
                    commit(names.MUTATE_CAPACITY, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_CAPACITY]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.capacities_url}/${payload.capacity_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_CAPACITY, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_CAPACITY]: ({
        commit,
        state
    }, capacity_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.capacities_url}/${capacity_id}/`)
                .then(response => {
                    commit("MUTATE_EGRESS_PROFILE_MATTER_BY_DELETE_CAPACITY", capacity_id);
                    commit("MUTATE_PROFILE_COMPETENCE_BY_DELETE_CAPACITY", capacity_id);
                    commit(names.MUTATE_DELETE_CAPACITY, capacity_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};