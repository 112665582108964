import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';
import { buildUrlParams } from '@/utils/utils';

const state = {
    assessments: [],
    assessments_url: '/assessment2/assessment',
    assessment_mesh_objet_relations: [],
    assessment_mesh_objet_relations_url: '/assessment2/assessment-mesh-object-relation',
    assessment_relation: [],
    assessment_relation_url: '/assessment2/assessment-relation'
}

const getters = {
    [names.ASSESSMENTS]: state => {
        if (!get.store.state.user) return [];
        return state.assessments.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.ASSESSMENT_MESH_OBJET_RELATIONS]: state => {
        if (!get.store.state.user) return [];
        return state.assessment_mesh_objet_relations.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.ASSESSMENT_RELATIONS]: state => {
        if (!get.store.state.user) return [];
        return state.assessment_relations.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_ASSESSMENTS]: (state, assessments) => {
        assessments.forEach(element => {
            let index = state.assessments.findIndex(x => x.id == element.id);
            if (index != -1) state.assessments.splice(index, 1, element);
            else state.assessments.push(element);
        });
    },
    [names.MUTATE_ASSESSMENT]: (state, assessment) => {
        let index = state.assessments.findIndex(x => x.id == assessment.id);
        if (index != -1) state.assessments.splice(index, 1, assessment);
        else state.assessments.push(assessment);
    },
    [names.MUTATE_DELETE_ASSESSMENT]: (state, assessment_id) => {
        let index = state.assessments.findIndex(x => x.id == assessment_id);
        if (index != -1) state.assessments.splice(index, 1);
    },
    // ASSESSMENT_MESH_OBJET_RELATION
    [names.MUTATE_ASSESSMENT_MESH_OBJET_RELATIONS]: (state, assessment_mesh_objet_relations) => {
        assessment_mesh_objet_relations.forEach(element => {
            let index = state.assessment_mesh_objet_relations.findIndex(x => x.id == element.id);
            if (index != -1) state.assessment_mesh_objet_relations.splice(index, 1, element);
            else state.assessment_mesh_objet_relations.push(element);
        });
    },
    [names.MUTATE_ASSESSMENT_MESH_OBJET_RELATION]: (state, assessment_mesh_objet_relations) => {
        let index = state.assessment_mesh_objet_relations.findIndex(x => x.id == assessment_mesh_objet_relations.id);
        if (index != -1) state.assessment_mesh_objet_relations.splice(index, 1, assessment_mesh_objet_relations);
        else state.assessment_mesh_objet_relations.push(assessment_mesh_objet_relations);
    },
    [names.MUTATE_DELETE_ASSESSMENT_MESH_OBJET_RELATION]: (state, assessment_mesh_objet_relations_id) => {
        let index = state.assessment_mesh_objet_relations.findIndex(x => x.id == assessment_mesh_objet_relations_id);
        if (index != -1) state.assessment_mesh_objet_relations.splice(index, 1);
    },
    // ASSESSMENT_RELATION
    [names.MUTATE_ASSESSMENT_RELATIONS]: (state, assessment_relations) => {
        assessment_relations.forEach(element => {
            let index = state.assessment_relations.findIndex(x => x.id == element.id);
            if (index != -1) state.assessment_relations.splice(index, 1, element);
            else state.assessment_relations.push(element);
        });
    },
    [names.MUTATE_ASSESSMENT_RELATION]: (state, assessment_relations) => {
        let index = state.assessment_relations.findIndex(x => x.id == assessment_relations.id);
        if (index != -1) state.assessment_relations.splice(index, 1, assessment_relations);
        else state.assessment_relations.push(assessment_relations);
    },
    [names.MUTATE_DELETE_ASSESSMENT_RELATION]: (state, assessment_relations_id) => {
        let index = state.assessment_relations.findIndex(x => x.id == assessment_relations_id);
        if (index != -1) state.assessment_relations.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_ASSESSMENT]: ({
        commit,
        state
    }, assessment_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.assessments_url}/${assessment_id}/`)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_ASSESSMENTS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            if (!payload && state.assessments.length > 0) {
                resolve(state.assessments);
                return;
            }
            // filterset_fields agregados en AssessmentViewSet:
            //  id: Number || id__in: Array 
            // contributes_to__id: Number || contributes_to__id__in: Array
            // contributors__id: Number || contributors__id__in: Array
            // assessment_type__id: Number || assessment_type__id__in: Array
            const urlParams = buildUrlParams(payload);
            if (!urlParams) {
                resolve([]);
                return;
            }
            restful.Get(`${state.assessments_url}/?${urlParams}`).then(response => {
                commit(names.MUTATE_ASSESSMENTS, response.results);
                resolve(response.results);
            });
        });
    },
    [names.POST_ASSESSMENT]: ({
        commit,
        state
    }, assessment) => {
        return new Promise((resolve) => {
            restful.Post(`${state.assessments_url}/`, assessment)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_ASSESSMENT]: ({
        commit,
        state
    }, assessment) => {
        return new Promise((resolve) => {
            restful.Put(`${state.assessments_url}/${assessment.id}/`, assessment)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_ASSESSMENT]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.assessments_url}/${payload.assessment_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_ASSESSMENT]: ({
        commit,
        state
    }, assessment_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.assessments_url}/${assessment_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_ASSESSMENT, assessment_id);
                    resolve(response);
                });
        });
    },
    // ASSESSMENT_MESH_OBJET_RELATION
    [names.FETCH_ASSESSMENT_MESH_OBJET_RELATION]: ({
        commit,
        state
    }, assessment_mesh_objet_relation_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.assessment_mesh_objet_relations_url}/${assessment_mesh_objet_relation_id}/`)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT_MESH_OBJET_RELATION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_ASSESSMENT_MESH_OBJET_RELATIONS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            if (!payload && state.assessment_mesh_objet_relations.length > 0) {
                resolve(state.assessment_mesh_objet_relations);
                return;
            }
            // filterset_fields agregados en AssessmentMeshObjectRelationViewSet:
            //  id: Number || id__in: Array 
            // assessment__id: Number || assessment__id__in: Array
            // content_type__id: Number || content_type__id__in: Array
            // content_type__model: Number || content_type__model__in: Array
            // mesh_object_id: Number || mesh_object_id__in: Array
            const urlParams = buildUrlParams(payload);
            if (!urlParams) {
                resolve([]);
                return;
            }
            restful.Get(`${state.assessment_mesh_objet_relations_url}/${urlParams}`)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT_MESH_OBJET_RELATIONS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_ASSESSMENT_MESH_OBJET_RELATION]: ({
        commit,
        state
    }, assessment_mesh_objet_relation) => {
        return new Promise((resolve) => {
            restful.Post(`${state.assessment_mesh_objet_relations_url}/`, assessment_mesh_objet_relation)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT_MESH_OBJET_RELATION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_ASSESSMENT_MESH_OBJET_RELATION]: ({
        commit,
        state
    }, assessment_mesh_objet_relation) => {
        return new Promise((resolve) => {
            restful.Put(`${state.assessment_mesh_objet_relations_url}/${assessment_mesh_objet_relation.id}/`, assessment_mesh_objet_relation)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT_MESH_OBJET_RELATION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_ASSESSMENT_MESH_OBJET_RELATION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.assessment_mesh_objet_relations_url}/${payload.id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT_MESH_OBJET_RELATION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_ASSESSMENT_MESH_OBJET_RELATION]: ({
        commit,
        state
    }, assessment_mesh_objet_relation_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.assessment_mesh_objet_relations_url}/${assessment_mesh_objet_relation_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_ASSESSMENT_MESH_OBJET_RELATION, assessment_mesh_objet_relation_id);
                    resolve(response);
                });
        });
    },
    // ASSESSMENT_RELATION
    [names.FETCH_ASSESSMENT_RELATION]: ({
        commit,
        state
    }, assessment_relation_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.assessment_relations_url}/${assessment_relation_id}/`)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT_RELATION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_ASSESSMENT_RELATIONS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            if (!payload && state.assessment_relations.length > 0) {
                resolve(state.assessment_relations);
                return;
            }
            // filterset_fields agregados en AssessmentMeshObjectRelationViewSet:
            //  id: Number || id__in: Array 
            // contributor__id: Number || contributor__id__in: Array
            // contributed__id: Number || contributed__id__in: Array
            const urlParams = buildUrlParams(payload);
            if (!urlParams) {
                resolve([]);
                return;
            }
            restful.Get(`${state.assessment_relations_url}/${urlParams}`)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT_RELATIONS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_ASSESSMENT_RELATION]: ({
        commit,
        state
    }, assessment_relation) => {
        return new Promise((resolve) => {
            restful.Post(`${state.assessment_relations_url}/`, assessment_relation)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT_RELATION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_ASSESSMENT_RELATION]: ({
        commit,
        state
    }, assessment_relation) => {
        return new Promise((resolve) => {
            restful.Put(`${state.assessment_relations_url}/${assessment_relation.id}/`, assessment_relation)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT_RELATION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_ASSESSMENT_RELATION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.assessment_relations_url}/${payload.id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_ASSESSMENT_RELATION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_ASSESSMENT_RELATION]: ({
        commit,
        state
    }, assessment_relation_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.assessment_relations_url}/${assessment_relation_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_ASSESSMENT_RELATION, assessment_relation_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};