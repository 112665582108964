import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    instructives: [],
    instructives_url: '/common/instructive'
};

const getters = {
    [names.INSTRUCTIVES]: state => {
        if (!get.store.state.user) return [];
        return state.instructives.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
};

const mutations = {
    [names.MUTATE_INSTRUCTIVES]: (state, instructives) => {
        instructives.forEach(element => {
            let index = state.instructives.findIndex(x => x.id == element.id);
            if (index != -1) state.instructives.splice(index, 1, element);
            else state.instructives.push(element);
        });
    },
    [names.MUTATE_INSTRUCTIVE]: (state, instructive) => {
        let index = state.instructives.findIndex(x => x.id == instructive.id);
        if (index != -1) state.instructives.splice(index, 1, instructive);
        else state.instructives.push(instructive);
    },
    [names.MUTATE_DELETE_INSTRUCTIVE]: (state, instructive_id) => {
        let index = state.instructives.findIndex(x => x.id == instructive_id);
        if (index != -1) state.instructives.splice(index, 1);
    },
};

const actions = {
    [names.FETCH_INSTRUCTIVES]: ({
        commit,
        state
    }) => {
        return new Promise((resolve) => {
            restful.Get(`${state.instructives_url}/`)
                .then(response => {
                    commit(names.MUTATE_INSTRUCTIVES, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_INSTRUCTIVE]: ({
        commit,
        state
    }, instructive_id) => {
        return new Promise((resolve) => {
            const file = state.instructives.find(x => x.id == instructive_id);
            if (file) {
                resolve(file);
                return;
            }
            restful.Get(`${state.instructives_url}/${instructive_id}/`)
                .then(response => {
                    commit(names.MUTATE_INSTRUCTIVE, response);
                    resolve(response);
                });
        });
    },
    [names.POST_INSTRUCTIVE]: ({
        commit,
        state
    }, file) => {
        return new Promise((resolve) => {
            restful.Post(`${state.instructives_url}/`, file)
                .then(response => {
                    commit(names.MUTATE_INSTRUCTIVE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_INSTRUCTIVE]: ({
        commit,
        state
    }, file) => {
        return new Promise((resolve) => {
            restful.Put(`${state.instructives_url}/${file.id}/`, file)
                .then(response => {
                    commit(names.MUTATE_INSTRUCTIVE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_INSTRUCTIVE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.instructives_url}/${payload.instructive_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_INSTRUCTIVE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_INSTRUCTIVE]: ({
        commit,
        state
    }, instructive_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.instructives_url}/${instructive_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_INSTRUCTIVE, instructive_id);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};