import restful from "@/utils/restful";
import * as names from "@/store/names";
import * as get from "@/store/store";

const state = {
  countries: [],
  countries_url: "/common/country",
};

const getters = {
  [names.COUNTRIES]: (state) => {
    if (!get.store.state.user) return [];
    return state.countries
      .filter((x) => x.school == get.store.state.user.school)
      .sort((a, b) => a.order - b.order);
  },
};

const mutations = {
  [names.MUTATE_COUNTRIES]: (state, countries) => {
    countries.forEach((element) => {
      let index = state.countries.findIndex((x) => x.id == element.id);
      if (index != -1) state.countries.splice(index, 1, element);
      else state.countries.push(element);
    });
  },
  [names.MUTATE_COUNTRY]: (state, country) => {
    let index = state.countries.findIndex((x) => x.id == country.id);
    if (index != -1) state.countries.splice(index, 1, country);
    else state.countries.push(country);
  },
  [names.MUTATE_DELETE_COUNTRY]: (state, country_id) => {
    let index = state.countries.findIndex((x) => x.id == country_id);
    if (index != -1) state.countries.splice(index, 1);
  },
};

const actions = {
  [names.FETCH_COUNTRY]: ({ commit, state }, country_id) => {
    return new Promise((resolve) => {
      restful
        .Get(`${state.countries_url}/${country_id}/`)
        .then((response) => {
          commit(names.MUTATE_COUNTRY, response);
          resolve(response);
        });
    });
  },
  [names.FETCH_COUNTRIES]: ({ commit, state }, force) => {
    return new Promise((resolve) => {
      if (!force && state.countries.length > 0) {
        resolve(state.countries);
        return;
      }
      restful.Get(`${state.countries_url}/`).then((response) => {
        commit(names.MUTATE_COUNTRIES, response);
        resolve(response);
      });
    });
  },
  [names.POST_COUNTRY]: ({ commit, state }, country) => {
    return new Promise((resolve) => {
      restful
        .Post(`${state.countries_url}/`, country)
        .then((response) => {
          commit(names.MUTATE_COUNTRY, response);
          resolve(response);
        });
    });
  },
  [names.UPDATE_COUNTRY]: ({ commit, state }, country) => {
    return new Promise((resolve) => {
      restful
        .Put(`${state.countries_url}/${country.id}/`, country)
        .then((response) => {
          commit(names.MUTATE_COUNTRY, response);
          resolve(response);
        });
    });
  },
  [names.PATCH_COUNTRY]: ({ commit, state }, payload) => {
    return new Promise((resolve) => {
      restful
        .Patch(
          `${state.countries_url}/${payload.country_id}/`,
          payload.item
        )
        .then((response) => {
          commit(names.MUTATE_COUNTRY, response);
          resolve(response);
        });
    });
  },
  [names.DELETE_COUNTRY]: ({ commit, state }, country_id) => {
    return new Promise((resolve) => {
      restful
        .Delete(`${state.countries_url}/${country_id}/`)
        .then((response) => {
          commit(names.MUTATE_DELETE_COUNTRY, country_id);
          resolve(response);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
