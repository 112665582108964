import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    ra_micros: [],
    ra_micros_url: '/teaching/ra-micro'
}

const getters = {
    [names.RA_MICROS]: state => {
        if (!get.store.state.user) return [];
        return state.ra_micros.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_RA_MICROS]: (state, ra_micros) => {
        ra_micros.forEach(element => {
            let index = state.ra_micros.findIndex(x => x.id == element.id);
            if (index != -1) state.ra_micros.splice(index, 1, element);
            else state.ra_micros.push(element);
        });
    },
    [names.MUTATE_RA_MICRO]: (state, ra_micro) => {
        let index = state.ra_micros.findIndex(x => x.id == ra_micro.id);
        if (index != -1) state.ra_micros.splice(index, 1, ra_micro);
        else state.ra_micros.push(ra_micro);
    },
    [names.MUTATE_DELETE_RA_MICRO]: (state, ra_micro_id) => {
        let index = state.ra_micros.findIndex(x => x.id == ra_micro_id);
        if (index != -1) state.ra_micros.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_RA_MICRO]: ({
        commit,
        state
    }, ra_micro_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.ra_micros_url}/${ra_micro_id}/`)
                .then(response => {
                    commit(names.MUTATE_RA_MICRO, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_RA_MICROS]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.ra_micros.length > 0) {
                resolve(state.ra_micros);
                return;
            }
            restful.Get(`${state.ra_micros_url}/`)
                .then(response => {
                    commit(names.MUTATE_RA_MICROS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_RA_MICRO]: ({
        commit,
        state
    }, ra_micro) => {
        return new Promise((resolve) => {
            restful.Post(`${state.ra_micros_url}/`, ra_micro)
                .then(response => {
                    commit(names.MUTATE_RA_MICRO, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_RA_MICRO]: ({
        commit,
        state
    }, ra_micro) => {
        return new Promise((resolve) => {
            restful.Put(`${state.ra_micros_url}/${ra_micro.id}/`, ra_micro)
                .then(response => {
                    commit(names.MUTATE_RA_MICRO, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_RA_MICRO]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.ra_micros_url}/${payload.ra_micro_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_RA_MICRO, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_RA_MICRO]: ({
        commit,
        state
    }, ra_micro_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.ra_micros_url}/${ra_micro_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_RA_MICRO, ra_micro_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};