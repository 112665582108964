<template>
  <div class="footer noprint mt-3" v-if="isLoged">
    <div class="container-new-footer">
      <!-- <span>
        Para soporte técnico comunicarse a: admin@klearning.cl o al WhatsApp
        +56(9) 7708 1206
      </span> -->
      <span>
        Copyright © KEY LEARNING SpA {{ currentYear }}. Todos los derechos
        reservados.
      </span>
    </div>
  </div>
</template>

<script>
import { APP_NAME } from "@/utils/globals";
import { mapGetters } from "vuex";

export default {
  name: "NewFooter",
  data() {
    return {
      app_name: APP_NAME,
      currentYear: null,
    };
  },
  computed: {
    ...mapGetters({
      isLoged: "isLoged",
    }),
  },
  methods: {
    getCurrentYear() {
      const date = new Date();
      this.currentYear = date.getFullYear();
    },
    // goToWelcome() {
    //   this.$router.push({ name: "Welcome" });
    // },
  },
  mounted() {
    // Llama al método para obtener el año actual cuando el componente está montado
    this.getCurrentYear();
  },
};
</script>

<style scoped>
.footer {
  position: relative;
  z-index: 9;
  bottom: 0;
  width: 100%;
  background-color: #212121;
  text-align: center;
  color: rgba(255, 255, 255, 0.815);
  height: 120px !important;
  padding: 18px 0;
  align-content: center;
}

.container-new-footer {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  font-size: var(--secondary-font-size);
  max-width: auto;
}
.go-to-welcome {
  cursor: pointer;
}
.go-to-welcome:hover {
  color: rgb(0, 132, 255);
  cursor: pointer;
}
@media (max-width: 750px) {
  .container-new-footer {
    height: 21px;
  }
}
</style>
