import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    contents: [],
    contents_url: '/teaching/study-unit-content'
}

const getters = {
    [names.CONTENTS]: state => {
        if (!get.store.state.user) return [];
        return state.contents.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_CONTENTS]: (state, contents) => {
        contents.forEach(element => {
            let index = state.contents.findIndex(x => x.id == element.id);
            if (index != -1) state.contents.splice(index, 1, element);
            else state.contents.push(element);
        });
    },
    [names.MUTATE_CONTENT]: (state, content) => {
        let index = state.contents.findIndex(x => x.id == content.id);
        if (index != -1) state.contents.splice(index, 1, content);
        else state.contents.push(content);
    },
    [names.MUTATE_DELETE_CONTENT]: (state, content_id) => {
        let index = state.contents.findIndex(x => x.id == content_id);
        if (index != -1) state.contents.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_CONTENT]: ({
        commit,
        state
    }, content_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.contents_url}/${content_id}/`)
                .then(response => {
                    commit(names.MUTATE_CONTENT, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_CONTENTS]: ({
        commit,
        state
    }, study_unit_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.contents_url}/?study_unit=${study_unit_id}`)
                .then(response => {
                    commit(names.MUTATE_CONTENTS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_CONTENT]: ({
        commit,
        state
    }, content) => {
        return new Promise((resolve) => {
            restful.Post(`${state.contents_url}/`, content)
                .then(response => {
                    commit(names.MUTATE_CONTENT, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_CONTENT]: ({
        commit,
        state
    }, content) => {
        return new Promise((resolve) => {
            restful.Put(`${state.contents_url}/${content.id}/`, content)
                .then(response => {
                    commit(names.MUTATE_CONTENT, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_CONTENT]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.contents_url}/${payload.content_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_CONTENT, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_CONTENT]: ({
        commit,
        state
    }, content_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.contents_url}/${content_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_CONTENT, content_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};