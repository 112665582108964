import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    institution_competences: [],
    //TODO: Url fue Eliminada.
    institution_competences_url: '/mesh/institution-competence'
}

const getters = {
    [names.INSTITUTION_COMPETENCES]: state => {
        if (!get.store.state.user) return [];
        return state.institution_competences.filter((x) => x.school == get.store.state.user.school);
    },
}

const mutations = {
    [names.MUTATE_INSTITUTION_COMPETENCES]: (state, institution_competences) => {
        institution_competences.forEach(element => {
            let index = state.institution_competences.findIndex(x => x.id == element.id);
            if (index != -1) state.institution_competences.splice(index, 1, element);
            else state.institution_competences.push(element);
        });
    },
    [names.MUTATE_INSTITUTION_COMPETENCE]: (state, institution_competence) => {
        let index = state.institution_competences.findIndex(x => x.id == institution_competence.id);
        if (index != -1) state.institution_competences.splice(index, 1, institution_competence);
        else state.institution_competences.push(institution_competence);
    },
    [names.MUTATE_DELETE_INSTITUTION_COMPETENCE]: (state, institution_competence_id) => {
        let index = state.institution_competences.findIndex(x => x.id == institution_competence_id);
        if (index != -1) state.institution_competences.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_INSTITUTION_COMPETENCE]: ({
        commit,
        state
    }, institution_competence_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.institution_competences_url}/${institution_competence_id}/`)
                .then(response => {
                    commit(names.MUTATE_INSTITUTION_COMPETENCE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_INSTITUTION_COMPETENCES]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.institution_competences.length > 0) {
                resolve(state.institution_competences);
                return;
            }
            restful.Get(`${state.institution_competences_url}/`)
                .then(response => {
                    commit(names.MUTATE_INSTITUTION_COMPETENCES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_INSTITUTION_COMPETENCE]: ({
        commit,
        state
    }, institution_competence) => {
        return new Promise((resolve) => {
            restful.Post(`${state.institution_competences_url}/`, institution_competence)
                .then(response => {
                    commit(names.MUTATE_INSTITUTION_COMPETENCE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_INSTITUTION_COMPETENCE]: ({
        commit,
        state
    }, institution_competence) => {
        return new Promise((resolve) => {
            restful.Put(`${state.institution_competences_url}/${institution_competence.id}/`, institution_competence)
                .then(response => {
                    commit(names.MUTATE_INSTITUTION_COMPETENCE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_INSTITUTION_COMPETENCE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.institution_competences_url}/${payload.institution_competence_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_INSTITUTION_COMPETENCE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_INSTITUTION_COMPETENCE]: ({
        commit,
        state
    }, institution_competence_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.institution_competences_url}/${institution_competence_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_INSTITUTION_COMPETENCE, institution_competence_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};