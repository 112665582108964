import restful from "@/utils/restful";
import * as names from "@/store/names";
import * as get from "@/store/store";

const state = {
  certifications: [],
  certifications_url: "/mesh/certification",
};

const getters = {
  [names.CERTIFICATIONS]: (state) => {
    if (!get.store.state.user) return [];
    return state.certifications
      .filter((x) => x.school == get.store.state.user.school)
      .sort((a, b) => a.id - b.id);
  },
};

const mutations = {
  [names.MUTATE_CERTIFICATIONS]: (state, certifications) => {
    certifications.forEach((element) => {
      let index = state.certifications.findIndex((x) => x.id == element.id);
      if (index != -1) state.certifications.splice(index, 1, element);
      else state.certifications.push(element);
    });
  },
  [names.MUTATE_CERTIFICATION]: (state, certification) => {
    let index = state.certifications.findIndex((x) => x.id == certification.id);
    if (index != -1) state.certifications.splice(index, 1, certification);
    else state.certifications.push(certification);
  },
  [names.MUTATE_DELETE_CERTIFICATION]: (state, certification_id) => {
    let index = state.certifications.findIndex((x) => x.id == certification_id);
    if (index != -1) state.certifications.splice(index, 1);
  },
};

const actions = {
  [names.FETCH_CERTIFICATION]: ({ commit, state }, certification_id) => {
    return new Promise((resolve) => {
      restful
        .Get(`${state.certifications_url}/${certification_id}/`)
        .then((response) => {
          commit(names.MUTATE_CERTIFICATION, response);
          resolve(response);
        });
    });
  },
  [names.FETCH_CERTIFICATIONS]: ({ commit, state }, force) => {
    return new Promise((resolve) => {
      if (!force && state.certifications.length > 0) {
        resolve(state.certifications);
        return;
      }
      restful.Get(`${state.certifications_url}/`).then((response) => {
        commit(names.MUTATE_CERTIFICATIONS, response);
        resolve(response);
      });
    });
  },
  [names.POST_CERTIFICATION]: ({ commit, state }, certification) => {
    return new Promise((resolve) => {
      restful
        .Post(`${state.certifications_url}/`, certification)
        .then((response) => {
          commit(names.MUTATE_CERTIFICATION, response);
          resolve(response);
        });
    });
  },
  [names.UPDATE_CERTIFICATION]: ({ commit, state }, certification) => {
    return new Promise((resolve) => {
      restful
        .Put(`${state.certifications_url}/${certification.id}/`, certification)
        .then((response) => {
          commit(names.MUTATE_CERTIFICATION, response);
          resolve(response);
        });
    });
  },
  [names.PATCH_CERTIFICATION]: ({ commit, state }, payload) => {
    return new Promise((resolve) => {
      restful
        .Patch(
          `${state.certifications_url}/${payload.certification_id}/`,
          payload.item
        )
        .then((response) => {
          commit(names.MUTATE_CERTIFICATION, response);
          resolve(response);
        });
    });
  },
  [names.DELETE_CERTIFICATION]: ({ commit, state }, certification_id) => {
    return new Promise((resolve) => {
      restful
        .Delete(`${state.certifications_url}/${certification_id}/`)
        .then((response) => {
          commit(names.MUTATE_DELETE_CERTIFICATION, certification_id);
          resolve(response);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
