import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    observation_instruments: [],
    observation_instruments_url: '/evaluation/observation-instrument',
    observation_achievements: [],
    observation_achievement_url: '/evaluation/observation-achievement',
    observation_instances: [],
    observation_instances_url: '/evaluation/observation-instance',
    achievement_descriptors: [],
    achievement_descriptors_url: '/evaluation/observation-descriptor',
    observation_scores: [],
    observation_scores_url: '/evaluation/observation-score',
};

const getters = {
    [names.OBSERVATION_INSTRUMENTS]: state => {
        if (!get.store.state.user) return [];
        return state.observation_instruments.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.OBSERVATION_ACHIEVEMENTS]: state => {
        if (!get.store.state.user) return [];
        return state.observation_achievements.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.OBSERVATION_INSTANCES]: state => {
        if (!get.store.state.user) return [];
        return state.observation_instances.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.OBSERVATION_DESCRIPTORS]: state => {
        if (!get.store.state.user) return [];
        return state.achievement_descriptors.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.OBSERVATION_SCORES]: state => {
        if (!get.store.state.user) return [];
        return state.observation_scores.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
};

const mutations = {
    [names.MUTATE_OBSERVATION_INSTRUMENT]: (state, observation_instrument) => {
        let index = state.observation_instruments.findIndex(x => x.id == observation_instrument.id);
        if (index != -1) state.observation_instruments.splice(index, 1, observation_instrument);
        else state.observation_instruments.push(observation_instrument);
    },
    [names.MUTATE_DELETE_OBSERVATION_INSTRUMENT]: (state, observation_instrument_id) => {
        let index = state.observation_instruments.findIndex(x => x.id == observation_instrument_id);
        if (index != -1) state.observation_instruments.splice(index, 1);
    },
    [names.MUTATE_OBSERVATION_ACHIEVEMENT]: (state, observation_achievement) => {
        let index = state.observation_achievements.findIndex(x => x.id == observation_achievement.id);
        if (index != -1) state.observation_achievements.splice(index, 1, observation_achievement);
        else state.observation_achievements.push(observation_achievement);
    },
    [names.MUTATE_DELETE_OBSERVATION_ACHIEVEMENT]: (state, observation_achievement_id) => {
        let index = state.observation_achievements.findIndex(x => x.id == observation_achievement_id);
        if (index != -1) state.observation_achievements.splice(index, 1);
    },
    [names.MUTATE_OBSERVATION_INSTANCE]: (state, observation_instance) => {
        let index = state.observation_instances.findIndex(x => x.id == observation_instance.id);
        if (index != -1) state.observation_instances.splice(index, 1, observation_instance);
        else state.observation_instances.push(observation_instance);
    },
    [names.MUTATE_DELETE_OBSERVATION_INSTANCE]: (state, observation_instance_id) => {
        let index = state.observation_instances.findIndex(x => x.id == observation_instance_id);
        if (index != -1) state.observation_instances.splice(index, 1);
    },
    [names.MUTATE_OBSERVATION_DESCRIPTOR]: (state, achievement_descriptor) => {
        let index = state.achievement_descriptors.findIndex(x => x.id == achievement_descriptor.id);
        if (index != -1) state.achievement_descriptors.splice(index, 1, achievement_descriptor);
        else state.achievement_descriptors.push(achievement_descriptor);
    },
    [names.MUTATE_DELETE_OBSERVATION_DESCRIPTOR]: (state, achievement_descriptor_id) => {
        let index = state.achievement_descriptors.findIndex(x => x.id == achievement_descriptor_id);
        if (index != -1) state.achievement_descriptors.splice(index, 1);
    },
    [names.MUTATE_OBSERVATION_SCORE]: (state, observation_score) => {
        let index = state.observation_scores.findIndex(x => x.id == observation_score.id);
        if (index != -1) state.observation_scores.splice(index, 1, observation_score);
        else state.observation_scores.push(observation_score);
    },
    [names.MUTATE_DELETE_OBSERVATION_SCORE]: (state, observation_score_id) => {
        let index = state.observation_scores.findIndex(x => x.id == observation_score_id);
        if (index != -1) state.observation_scores.splice(index, 1);
    },
};

const actions = {
    [names.FETCH_OBSERVATION_INSTRUMENT]: ({
        commit,
        dispatch,
        state
    }, observation_instrument_id) => {
        return new Promise((resolve) => {
            const observation_instrument = state.observation_instruments.find(x => x.id == observation_instrument_id);
            if (observation_instrument) {
                resolve(observation_instrument);
                return;
            }
            restful.Get(`${state.observation_instruments_url}/${observation_instrument_id}/`)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_INSTRUMENT, response);
                    dispatch(names.FETCH_OBSERVATION_ACHIEVEMENTS, response.id);
                    dispatch(names.FETCH_OBSERVATION_INSTANCES, response.id);
                    dispatch(names.FETCH_RUBRIC_DESCRIPTORS, response.id);
                    resolve(response);
                });
        });
    },
    [names.FETCH_OBSERVATION_INSTRUMENTS]: ({
        commit,
        dispatch,
        state
    }, evaluation_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.observation_instruments_url}/?evaluation=${evaluation_id}`)
                .then(response => {
                    response.forEach(element => {
                        commit(names.MUTATE_OBSERVATION_INSTRUMENT, element);
                        dispatch(names.FETCH_OBSERVATION_ACHIEVEMENTS, element.id);
                        dispatch(names.FETCH_OBSERVATION_INSTANCES, element.id);
                        dispatch(names.FETCH_RUBRIC_DESCRIPTORS, element.id);
                    });
                    resolve(response);
                });
        });
    },
    [names.POST_OBSERVATION_INSTRUMENT]: ({
        commit,
        dispatch,
        state
    }, observation_instrument) => {
        return new Promise((resolve) => {
            restful.Post(`${state.observation_instruments_url}/`, observation_instrument)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_INSTRUMENT, response);
                    dispatch(names.FETCH_OBSERVATION_ACHIEVEMENTS, response.id);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_OBSERVATION_INSTRUMENT]: ({
        commit,
        state
    }, observation_instrument) => {
        return new Promise((resolve) => {
            restful.Put(`${state.observation_instruments_url}/${observation_instrument.id}/`, observation_instrument)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_INSTRUMENT, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_OBSERVATION_INSTRUMENT]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.observation_instruments_url}/${payload.observation_instrument_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_INSTRUMENT, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_OBSERVATION_INSTRUMENT]: ({
        commit,
        state
    }, observation_instrument_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.observation_instruments_url}/${observation_instrument_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_OBSERVATION_INSTRUMENT, observation_instrument_id);
                    resolve(response);
                });
        });
    },
    // OBSERVATION_ACHIEVEMENTS
    [names.FETCH_OBSERVATION_ACHIEVEMENT]: ({
        commit,
        state
    }, observation_achievement_id) => {
        return new Promise((resolve) => {
            const observation_achievement = state.observation_achievements.find(x => x.id == observation_achievement_id);
            if (observation_achievement) {
                resolve(observation_achievement);
                return;
            }
            restful.Get(`${state.observation_achievement_url}/${observation_achievement_id}/`)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_ACHIEVEMENT, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_OBSERVATION_ACHIEVEMENTS]: ({
        commit,
        state
    }, observation_instrument_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.observation_achievement_url}/?observation_instrument=${observation_instrument_id}`)
                .then(response => {
                    response.forEach(element => {
                        commit(names.MUTATE_OBSERVATION_ACHIEVEMENT, element);
                    });
                    resolve(response);
                });
        });
    },
    [names.POST_OBSERVATION_ACHIEVEMENT]: ({
        commit,
        state
    }, observation_achievement) => {
        return new Promise((resolve) => {
            restful.Post(`${state.observation_achievement_url}/`, observation_achievement)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_ACHIEVEMENT, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_OBSERVATION_ACHIEVEMENT]: ({
        commit,
        state
    }, observation_achievement) => {
        return new Promise((resolve) => {
            restful.Put(`${state.observation_achievement_url}/${observation_achievement.id}/`, observation_achievement)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_ACHIEVEMENT, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_OBSERVATION_ACHIEVEMENT]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.observation_achievement_url}/${payload.observation_achievement_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_ACHIEVEMENT, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_OBSERVATION_ACHIEVEMENT]: ({
        commit,
        state
    }, observation_achievement_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.observation_achievement_url}/${observation_achievement_id}/`)
                .then(() => {
                    commit(names.MUTATE_DELETE_OBSERVATION_ACHIEVEMENT, observation_achievement_id);
                    resolve(observation_achievement_id);
                });
        });
    },
    // OBSERVATION_INSTANCES
    [names.FETCH_OBSERVATION_INSTANCE]: ({
        commit,
        dispatch,
        state
    }, observation_instance_id) => {
        return new Promise((resolve) => {
            const instance = state.observation_instances.find(x => x.id == observation_instance_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.observation_instances_url}/${observation_instance_id}/`)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_INSTANCE, response);
                    dispatch(names.FETCH_RUBRIC_DESCRIPTORS, response.id);
                    resolve(response);
                });
        });
    },
    [names.FETCH_OBSERVATION_INSTANCES]: ({
        commit,
        dispatch,
        state
    }, observation_instrument_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.observation_instances_url}/?observation_instrument=${observation_instrument_id}`)
                .then(response => {
                    response.forEach(element => {
                        commit(names.MUTATE_OBSERVATION_INSTANCE, element);
                        dispatch(names.FETCH_RUBRIC_DESCRIPTORS, element.id);
                    });
                    resolve(response);
                });
        });
    },
    [names.POST_RUBRIC_INSTANCE]: ({
        commit,
        state
    }, observation_instance) => {
        return new Promise((resolve) => {
            restful.Post(`${state.observation_instances_url}/`, observation_instance)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_INSTANCE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_OBSERVATION_INSTANCE]: ({
        commit,
        state
    }, observation_instance) => {
        return new Promise((resolve) => {
            restful.Put(`${state.observation_instances_url}/${observation_instance.id}/`, observation_instance)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_INSTANCE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_OBSERVATION_INSTANCE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.observation_instances_url}/${payload.observation_instance_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_INSTANCE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_OBSERVATION_INSTANCE]: ({
        commit,
        state
    }, observation_instance_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.observation_instances_url}/${observation_instance_id}/`)
                .then(() => {
                    commit(names.MUTATE_DELETE_OBSERVATION_INSTANCE, observation_instance_id);
                    resolve(observation_instance_id);
                });
        });
    },
    // Descriptors
    [names.FETCH_OBSERVATION_DESCRIPTOR]: ({
        commit,
        state
    }, observation_descriptor_id) => {
        return new Promise((resolve) => {
            const instance = state.achievement_descriptors.find(x => x.id == observation_descriptor_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.achievement_descriptors_url}/${observation_descriptor_id}/`)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_RUBRIC_DESCRIPTOR_BY]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve, ) => {
            const instance = state.achievement_descriptors.find(
                (x) => x.observation_instance == payload.observation_instance_id &&
                x.observation_achievement == payload.observation_achievement_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.achievement_descriptors_url}/?observation_instance=${payload.observation_instance_id}&&observation_achievement=${payload.observation_achievement_id}`)
                .then(response => {
                    response.forEach(element => {
                        commit(names.MUTATE_OBSERVATION_DESCRIPTOR, element);
                    });
                    if (response.length > 0)
                        resolve(response[0]);
                    else
                        resolve(null);
                });
        });
    },
    [names.FETCH_RUBRIC_DESCRIPTORS]: ({
        commit,
        state
    }, observation_instance_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.achievement_descriptors_url}/?observation_instance=${observation_instance_id}`)
                .then(response => {
                    response.forEach(element => {
                        commit(names.MUTATE_OBSERVATION_DESCRIPTOR, element);
                    });
                    resolve(response);
                });
        });
    },
    [names.POST_OBSERVATION_DESCRIPTOR]: ({
        commit,
        state
    }, observation_descriptor) => {
        return new Promise((resolve) => {
            restful.Post(`${state.achievement_descriptors_url}/`, observation_descriptor)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_OBSERVATION_DESCRIPTOR]: ({
        commit,
        state
    }, observation_descriptor) => {
        return new Promise((resolve) => {
            restful.Put(`${state.achievement_descriptors_url}/${observation_descriptor.id}/`, observation_descriptor)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_OBSERVATION_DESCRIPTOR]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.achievement_descriptors_url}/${payload.observation_descriptor_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_OBSERVATION_DESCRIPTOR]: ({
        commit,
        state
    }, observation_descriptor_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.achievement_descriptors_url}/${observation_descriptor_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_OBSERVATION_DESCRIPTOR, observation_descriptor_id);
                    resolve(response);
                });
        });
    },
    // Rubric Scores
    [names.FETCH_OBSERVATION_SCORE]: ({
        commit,
        state
    }, observation_instrument_id) => {
        return new Promise((resolve) => {
            const observation_score = state.observation_scores.find(x => x.id == observation_instrument_id);
            if (observation_score) {
                resolve(observation_score);
                return;
            }
            restful.Get(`${state.observation_scores_url}/${observation_instrument_id}/`)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_SCORE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_SCORE_BY_INSTRUMENT]: ({
        commit,
        state
    }, observation_instrument_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.observation_scores_url}/?observation_instrument=${observation_instrument_id}`)
                .then(response => {
                    response.forEach(element => {
                        commit(names.MUTATE_OBSERVATION_SCORE, element);
                    });
                    resolve(response);
                });
        });
    },
    [names.POST_OBSERVATION_SCORE]: ({
        commit,
        state
    }, observation_instrument) => {
        return new Promise((resolve) => {
            restful.Post(`${state.observation_scores_url}/`, observation_instrument)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_SCORE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_OBSERVATION_SCORE]: ({
        commit,
        state
    }, observation_score) => {
        return new Promise((resolve) => {
            restful.Put(`${state.observation_scores_url}/${observation_score.id}/`, observation_score)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_SCORE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_OBSERVATION_SCORE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.observation_scores_url}/${payload.observation_score_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_OBSERVATION_SCORE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_OBSERVATION_SCORE]: ({
        commit,
        state
    }, observation_score_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.observation_scores_url}/${observation_score_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_OBSERVATION_SCORE, observation_score_id);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};