import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    faculties: [],
    faculties_url: '/mesh/faculty'
}

const getters = {
    [names.FACULTIES]: state => {
        if (!get.store.state.user) return [];
        return state.faculties.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_FACULTIES]: (state, faculties) => {
        faculties.forEach(element => {
            let index = state.faculties.findIndex(x => x.id == element.id);
            if (index != -1) state.faculties.splice(index, 1, element);
            else state.faculties.push(element);
        });
    },
    [names.MUTATE_FACULTY]: (state, faculty) => {
        let index = state.faculties.findIndex(x => x.id == faculty.id);
        if (index != -1) state.faculties.splice(index, 1, faculty);
        else state.faculties.push(faculty);
    },
    [names.MUTATE_DELETE_FACULTY]: (state, faculty_id) => {
        let index = state.faculties.findIndex(x => x.id == faculty_id);
        if (index != -1) state.faculties.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_FACULTY]: ({
        commit,
        state
    }, faculty_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.faculties_url}/${faculty_id}/`)
                .then(response => {
                    commit(names.MUTATE_FACULTY, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_FACULTIES]: ({
        commit,
        state,
    }, ) => {
        return new Promise((resolve) => {
            restful.Get(`${state.faculties_url}/`)
                .then(response => {
                    commit(names.MUTATE_FACULTIES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_FACULTY]: ({
        commit,
        state
    }, faculty) => {
        return new Promise((resolve) => {
            restful.Post(`${state.faculties_url}/`, faculty)
                .then(response => {
                    commit(names.MUTATE_FACULTY, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_FACULTY]: ({
        commit,
        state
    }, faculty) => {
        return new Promise((resolve) => {
            restful.Put(`${state.faculties_url}/${faculty.id}/`, faculty)
                .then(response => {
                    commit(names.MUTATE_FACULTY, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_FACULTY]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.faculties_url}/${payload.faculty_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_FACULTY, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_FACULTY]: ({
        commit,
        state
    }, faculty_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.faculties_url}/${faculty_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_FACULTY, faculty_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};