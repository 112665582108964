import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    formation_areas: [],
    formation_areas_url: '/mesh/formation-area'
}

const getters = {
    [names.FORMATION_AREAS]: state => {
        if (!get.store.state.user) return [];
        return state.formation_areas.filter((x) => x.school == get.store.state.user.school).sort(function(a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        });
    },
}

const mutations = {
    [names.MUTATE_FORMATION_AREAS]: (state, formation_areas) => {
        formation_areas.forEach(element => {
            let index = state.formation_areas.findIndex(x => x.id == element.id);
            if (index != -1) state.formation_areas.splice(index, 1, element);
            else state.formation_areas.push(element);
        });
    },
    [names.MUTATE_FORMATION_AREA]: (state, formation_area) => {
        let index = state.formation_areas.findIndex(x => x.id == formation_area.id);
        if (index != -1) state.formation_areas.splice(index, 1, formation_area);
        else state.formation_areas.push(formation_area);
    },
    [names.MUTATE_DELETE_FORMATION_AREA]: (state, formation_area_id) => {
        let index = state.formation_areas.findIndex(x => x.id == formation_area_id);
        if (index != -1) state.formation_areas.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_FORMATION_AREA]: ({
        commit,
        state
    }, formation_area_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.formation_areas_url}/${formation_area_id}/`)
                .then(response => {
                    commit(names.MUTATE_FORMATION_AREA, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_FORMATION_AREAS]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.formation_areas.length > 0) {
                resolve(state.formation_areas);
                return;
            }
            restful.Get(`${state.formation_areas_url}/`)
                .then(response => {
                    commit(names.MUTATE_FORMATION_AREAS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_FORMATION_AREA]: ({
        commit,
        state
    }, formation_area) => {
        return new Promise((resolve) => {
            restful.Post(`${state.formation_areas_url}/`, formation_area)
                .then(response => {
                    commit(names.MUTATE_FORMATION_AREA, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_FORMATION_AREA]: ({
        commit,
        state
    }, formation_area) => {
        return new Promise((resolve) => {
            restful.Put(`${state.formation_areas_url}/${formation_area.id}/`, formation_area)
                .then(response => {
                    commit(names.MUTATE_FORMATION_AREA, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_FORMATION_AREA]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.formation_areas_url}/${payload.formation_area_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_FORMATION_AREA, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_FORMATION_AREA]: ({
        commit,
        state
    }, formation_area_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.formation_areas_url}/${formation_area_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_FORMATION_AREA, formation_area_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};