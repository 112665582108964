
import Vue from 'vue';
import * as names from "@/store/names";
import router from '@/router/index'
import axios from '@/utils/api'

export const login = ({ commit, dispatch }, user_data) => {
    axios.post('/token/', user_data)
        .then((response) => {
            const token = response.data.access;
            const refresh_token = response.data.refresh;
            commit('setTokens', { token, refresh_token });
            commit('setIsLoged', true);
            const user = JSON.parse(atob(token.split('.')[1]));
            dispatch('fetchUserData', user.user_id);
            router.push({ name: 'Welcome' });
        })
        .catch((error) => {
            if (error.response && error.response.data && error.response.data.detail == 'La Institución no se encuentra activa.')
                Vue.swal.fire({
                    title: "Login Error",
                    text: "La Institución no se encuentra activa.",
                    type: "warning",
                });
            else
                Vue.swal.fire({
                    title: "Login Error",
                    text: "Compruebe su nombre de usuario y clave.",
                    type: "warning",
                });
        })
};

export const fetchUserData = ({ commit, dispatch }, user_id) => {
    axios.get(`/authentication/user/${user_id}/`)
        .then((response) => {
            commit('setUser', response.data);
            dispatch('fetchUserPermissions');
            dispatch('fetchUserInstitution', response.data.school);
            dispatch(names.FETCH_CURRENT_POSITION, user_id);
            dispatch(names.FETCH_ALTERNATIVE_NAMES).then((alternative_names) => {
                localStorage.setItem("alternative_names", JSON.stringify(alternative_names));
            })
        })
        .catch((error) => {
            console.log(error);
        });
};

export const fetchUserPermissions = ({ commit }) => {
    axios.get('/authentication/user/permissions/')
        .then((response) => {
            commit('setUserPermissions', response.data);
        })
        .catch((error) => {
            console.log(error);
        })
};

export const fetchUserInstitution = ({ commit, dispatch }, institution_id) => {
    axios.get(`/mesh/institution/${institution_id}/`)
        .then((response) => {
            commit('setUserInstitution', response.data);
            dispatch(names.FETCH_TAXONOMIES);
            dispatch(names.FETCH_CYCLES);
            if (response.data.image) {
                dispatch(names.FETCH_IMAGE, response.data.image).then(image => {
                    commit('setInstitutionLogo', image.image);
                });
            }
        })
        .catch((error) => {
            console.log(error)
        });

};

// #TODO: Eliminar esta action. Hay que revisar unos cuantos archivos. Hacerlo con cuidado.
export const logOut = ({ commit }) => {
    commit('clearLoginData');
};

export const updateToken = ({ state, commit }) => {
    if (!state.refresh_token) return;
    axios.post('/token/refresh/', { refresh: state.refresh_token })
        .then((response) => {
            if (response) {
                commit('setToken', response.data.access)
            } else {
                console.log('Unable to refresh token. ', response);
                commit('clearLoginData');
            }
        })
};
